
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, Observable, of } from 'rxjs';
import { selectCommentsForMessage } from '../../../../core/store/comments/comments.selectors';
import { CommentEntity } from '../../../../core/store/comments/comments.state';
import { RootState } from '../../../../core/store/root/public-api';
import { MessagesDatasource } from '../../../services/messages.datasource';
import { WenComment } from '../types';


@Injectable()
export class CommentListDataSource extends MessagesDatasource<WenComment> {

  hasMessages$: Observable<boolean>;
  messages$: Observable<WenComment[]>;
  lastUnreadMessage$: Observable<WenComment> = of(null);

  constructor(private store: Store<RootState>) {
    super();
  }

  override initialize(parentId?: string): void {
    this.getCommentListForMessage(parentId);
  }

  private getCommentListForMessage(messageId: string): void {
    this.messages$ = this.store.pipe(
      select(selectCommentsForMessage),
      map((projectorFn) => projectorFn(messageId)),
      map((commentEntity: CommentEntity) => Array.isArray(commentEntity) ? commentEntity : commentEntity?.commentList)
    );
  }

}
