import { NgModule } from '@angular/core';
import { SocketApiExtensionsModule } from '@portal/wen-backend-api';
import { ChatMessageComposerTransactionFactory } from './chat-message-composer-transaction';
import { MessagePreviewGeneratorFactory } from './services/message-preview-generator';
import { ChatRoomPrepareServiceFactory } from './services/prepare-chat-room-service';
import { ChatAvailabilityChecker } from './services/chat-availability-checker';
import { ShareKeyEncryptor } from './session-keys/share-key-encryptor';
import { EncryptedSessionKeyShareService, NonEncryptedSessionKeyShareService, SessionKeyShareServiceProvider } from './session-keys/session-key-share-service';
import { PrepareDialogRoomService } from './services/prepare-chat-room/prepare-dialog-room';

@NgModule({
  imports: [
    SocketApiExtensionsModule
  ],
  providers: [
    ShareKeyEncryptor,
    NonEncryptedSessionKeyShareService,
    EncryptedSessionKeyShareService,
    SessionKeyShareServiceProvider,
    ChatMessageComposerTransactionFactory,
    ChatAvailabilityChecker,
    MessagePreviewGeneratorFactory,
    ChatRoomPrepareServiceFactory,
    PrepareDialogRoomService,
  ]
})
export class MessageSendTransactionModule { }
