import { MediaValidator } from '../../../../core/services/util/media-validator';
import { TranslateService } from '@ngx-translate/core';
import { ActionMenuItem } from '@portal/wen-components';
import { Observable } from 'rxjs';
import { FeatureEnablementService } from '../../../../core/services/configuration/feature-enablement';
import { FileSelectorHandler } from '../../../../core/services/util/file-selector-handler';
import { MediaPreviewHandler } from '../../../../core/services/util/media-preview-handler';

export class MediaUploadAction implements ActionMenuItem {

  label: string;
  icon = 'image_stacked';
  isVisible$ = () => this.featureEnablementService.featureConfigObs.enableMediaUpload$;

  constructor(
    private mediaPreviewHandler: MediaPreviewHandler,
    protected translateService: TranslateService,
    protected featureEnablementService: FeatureEnablementService,
    protected fileSelector: FileSelectorHandler,
    private mediaValidator: MediaValidator,
  ) {
    this.label = this.translateService.instant(
      'CONTEXT_MENU_ACTION_MEDIA_LABEL'
    );
  }

  prepareFile(): Observable<File> {
    return this.fileSelector.openFileSelector(this.mediaValidator.allowedMediaExtensions);
  }

  execute() {
    this.prepareFile().subscribe(file => {
      this.mediaPreviewHandler.handleMediaPreview(file);
    });
  }
}

export const mediaUploadActionFactory = (
  mediaPreviewHandler: MediaPreviewHandler,
  translateService: TranslateService,
  featureEnablementService: FeatureEnablementService,
  fileSelector: FileSelectorHandler,
  mediaValidator: MediaValidator
) => new MediaUploadAction(mediaPreviewHandler, translateService, featureEnablementService, fileSelector, mediaValidator);
