import { NgModule } from '@angular/core';
import { ImgFallbackDirective } from './image-fallback.directive';

@NgModule({
  declarations: [
    ImgFallbackDirective
  ],
  exports: [
    ImgFallbackDirective
  ]
})
export class ImgFallbackModule { }
