import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { MemberState, MessageEventResponses, SocketIoService, ToRoomEventType } from '@portal/wen-backend-api';
import { filter, map, merge, switchMap, withLatestFrom } from 'rxjs';
import { WenOAuthService } from '../../../services/user-management/wen-oauth.service';
import { doBackNavigation } from '../../header/header.actions';
import { RootState } from '../../root/public-api';
import { addMembersToRoom, joinToRoom, leaveRoom, memberLeftRoom, promoteRoomMemberToAdmin, removeAdminStatusFromRoomMember, removeMemberFromRoom, updateMemberState, upsertNewMemberJoined, upsertOtherRoomMemberLeft } from '../actions/chat-member.actions';
import { loadRoomDetails, roomRemoved, subscribeChatUpdates } from '../chat.actions';
import { selectCurrentRoom, selectSharedInboundGroupSessions } from '../chat.selectors';
import { removeShareInboundGroupSessionForCurrentRoom, requestExchangeInboundGroupSessionForCurrentRoom } from '../key-actions';

@Injectable()
export class ChatRoomMemberEffects {

  get userId() {
    return this.oAuthService.getUserData()?.userId;
  }

  promoteRoomMemberToAdmin$ = createEffect(() => this.actions$.pipe(
    ofType(promoteRoomMemberToAdmin),
    withLatestFrom(this.store.pipe(select(selectCurrentRoom))),
    switchMap(([{ userId }, { id: roomId }]) => {
      return this.socketIoService.chat.grantOwnership.acknowledgement$({ userId, roomId }).pipe(
        map((response) => updateMemberState(response))
      );
    })
  ));

  removeAdminStatusFromRoomMember$ = createEffect(() => this.actions$.pipe(
    ofType(removeAdminStatusFromRoomMember),
    withLatestFrom(this.store.pipe(select(selectCurrentRoom))),
    switchMap(([{ userId }, { id: roomId }]) => {
      return this.socketIoService.chat.revokeOwnership.acknowledgement$({ userId, roomId }).pipe(
        map(response => updateMemberState(response))
      );
    })
  ));

  memberLeaveFromRoom$ = createEffect(() => {
    const leave$ = this.actions$.pipe(ofType(leaveRoom), map(() => ({ userId: this.userId })));
    const removeMember$ = this.actions$.pipe(ofType(removeMemberFromRoom), map(({ userId }) => ({ userId })));
    return merge(
      leave$,
      removeMember$
    ).pipe(
      withLatestFrom(this.store.pipe(select(selectCurrentRoom))),
      switchMap(([{ userId }, { id: roomId }]) => {
        return this.socketIoService.chat.room.leaveRoom.acknowledgement$({ roomId, userId }).pipe(
          filter(response => response.userId === this.userId),
          map(response => roomRemoved({ roomId: response.roomId }))
        );
      })
    );
  });

  onMemberUpdateEvent$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeChatUpdates),
    switchMap(() => {
      return this.socketIoService.chat.room.messages.listen.pipe(
        map((response) => this.updateRoomMember(response.payload, response.roomId)),
        filter(action => !!action)
      );
    })
  ));

  onAddMembersToGroup$ = createEffect(() => this.actions$.pipe(
    ofType(addMembersToRoom),
    withLatestFrom(this.store.pipe(select(selectCurrentRoom))),
    switchMap(([{ userIds }, { id: roomId }]) => {
      return this.socketIoService.chat.room.addMembers.acknowledgment$({ roomId, userIds }).pipe(
        switchMap(() => {
          return [
            removeShareInboundGroupSessionForCurrentRoom({ roomId }),
            requestExchangeInboundGroupSessionForCurrentRoom({ withDialogFallback: false }),
            doBackNavigation()
          ];
        })
      );
    })
  ));

  onMemberLeave$ = createEffect(() => this.actions$.pipe(
    ofType(memberLeftRoom),
    withLatestFrom(this.store.pipe(select(selectSharedInboundGroupSessions))),
    switchMap(([{ userId, roomId }, inboundGroupSessions]) => {
      const roomAction = userId === this.userId ? roomRemoved({ roomId }) : upsertOtherRoomMemberLeft({ roomId, userId });
      const hasInboundSessionForRoom = inboundGroupSessions.some(session => session.roomId === roomId);
      const actions: Action[] = [
        roomAction,
        ...hasInboundSessionForRoom ? [removeShareInboundGroupSessionForCurrentRoom({ roomId })] : []
      ];
      return actions;
    }),
  ));


  loadRoomOnJoin$ = createEffect(() => this.actions$.pipe(
    ofType(joinToRoom),
    filter(({ userId }) => userId === this.userId),
    map(({ roomId }) => loadRoomDetails({ roomId }))
  ));

  loadNewRoomMember$ = createEffect(() => this.actions$.pipe(
    ofType(joinToRoom),
    filter(({ userId }) => userId !== this.userId),
    map(({ userId, roomId, avatarUrl, displayName, memberSince, memberState }) =>
      upsertNewMemberJoined({ newUser: {userId, state: memberState, avatarUrl, displayName, memberSince}, roomId }))
  ));

  constructor(
    private oAuthService: WenOAuthService,
    private store: Store<RootState>,
    private actions$: Actions,
    private socketIoService: SocketIoService
  ) { }

  private updateRoomMember(payload: MessageEventResponses, roomId: string): Action {
    let action: Action;
    switch (payload.eventType) {
      case ToRoomEventType.OWNERSHIP_GRANTED:
        action = updateMemberState({
          roomId,
          userId: payload.newOwnerUserId,
          newState: MemberState.OWNER
        });
        break;
      case ToRoomEventType.OWNERSHIP_REVOKED:
        action = updateMemberState({
          roomId,
          userId: payload.revokedFromUserId,
          newState: MemberState.JOINED
        });
        break;
      case ToRoomEventType.LEFT:
        action = memberLeftRoom({ userId: payload.leftUserId, roomId });
        break;
      case ToRoomEventType.JOINED:
        action = joinToRoom({
          userId: payload.joinedUserId, roomId, avatarUrl: payload.avatarUrl,
          displayName: payload.displayName, memberSince: payload.memberSince, memberState: MemberState.JOINED
        });
        break;
      default:
        break;
    }
    return action;
  }

}
