
export interface WalletCredential {
  type: WalletCredentialType;
  firstName: string;
  lastName: string;
  validSince: string;
}

export enum WalletCredentialType {
  UNITED_KIOSK_NEWS_FLAT = 'UNITED_KIOSK_NEWS_FLAT'
}

export type WalletCredentialDetailsResponse = WalletCredential[];

export type WalletCreateCredentialPayload = {
  credentialOffer: string;
};

export type WalletRemoveCredentialPayload = {
  credentialType: WalletCredentialType;
};

export interface WalletCreateCredentialResponse {
  credentialType: WalletCredentialType;
  ok: boolean;
  errorMsg?: string;
}

export interface WalletRemoveCredentialResponse {
  ok: boolean;
  errorMsg?: string;
}

export enum WalletCredentialCreatedKey {
  ok,
  warn_removed_duplicates,
  error_anonymous_user,
  error_fetch_credential
}
