import { Component, OnInit } from '@angular/core';
import { tryLogin } from '../../../../core/store/auth/auth.actions';
import { RootState } from '../../../../core/store/root/public-api';
import { Store } from '@ngrx/store';
import { PopupEventHandler } from '../../../../core/services/util/popup-event-handler';

@Component({
  selector: 'wen-oauth-callback',
  templateUrl: './oauth-callback.component.html'
})
export class OauthCallbackComponent implements OnInit {

  constructor(
    private store: Store<RootState>,
    private popupEventHandler: PopupEventHandler
  ) { }

  ngOnInit(): void {
    this.popupEventHandler.postAuthTokens();
    this.store.dispatch(tryLogin());
  }

}
