import { Injectable, inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { listElevatedPrivilegeChannelMembers } from '../../../../core/store/channel/channel.actions';
import { RootState } from '../../../../core/store/root/public-api';

export const channelElevatedPrivilegeMemberListResolver: ResolveFn<void> = () => {
  return inject(ChannelElevatedPrivilegeMemberListResolver).resolve();
};

@Injectable()
export class ChannelElevatedPrivilegeMemberListResolver {

  constructor(
    private readonly store: Store<RootState>,
  ) { }

  resolve() {
    this.store.dispatch(listElevatedPrivilegeChannelMembers());
  }

}
