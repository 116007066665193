import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wen-channel-schedules-desktop',
  templateUrl: './channel-schedules-desktop.component.html',
  styleUrls: ['./channel-schedules-desktop.component.scss']
})
export class ChannelSchedulesDesktopComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
