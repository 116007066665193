import { createAction, props } from '@ngrx/store';

export const redactChatMessageEvent = createAction(
  `[Chat] Redact a chat message's event`,
  props<{ eventId: string }>()
);

export const editScheduledChatMessageEvent = createAction(
  `[Chat] Edit a scheduled chat message's event`,
  props<{ eventId: string }>()
);

export const editChatMessageEvent = createAction(
  `[Chat] Edit a normal chat message's event`,
  props<{ eventId: string }>()
);
