import { Component } from '@angular/core';
import { ColorPalette } from '../../../../../../../shared/directives/colorize/palette-provider';
import { UserProfileViewConfiguration } from '../../user-profile-view-configuration';
import { EntryId } from '../../user-profile-view-types';
import { WenNavigationHelper } from '../../../../../../../core/services/navigation/types';
import { AppNavigator } from '../../../../../../../core/services/navigation/app-navigator';
import { AppConfigurationProvider } from '../../../../../../../core/services/configuration/app-confguration';
import { WenRouteId } from '../../../../../../../frame/routing/types';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'wen-help-view',
  templateUrl: './help-view.component.html',
  styleUrls: ['./help-view.component.scss'],
  providers: [UserProfileViewConfiguration]
})
export class HelpViewComponent {

  protected readonly ColorPalette = ColorPalette;

  constructor(
    public config: UserProfileViewConfiguration,
    private navigationHelper: WenNavigationHelper,
    private appNavigator: AppNavigator,
    private readonly appConfig: AppConfigurationProvider,
    private translateService: TranslateService,
  ) { }

  handleEntryClick(entryId: EntryId) {
    switch (entryId) {
      case EntryId.FAQ: {
        this.navigationHelper.navigateToFAQ();
        return;
      }
      case EntryId.MARKDOWN: {
        this.navigationHelper.navigateToMarkdown();
        return;
      }
      case EntryId.MANIFEST: {
        this.appNavigator.navigateToRoute(WenRouteId.USERPROFILE_MANIFEST);
        return;
      }
      case EntryId.COMMUNITY_GUIDELINE: {
        this.navigationHelper.navigateToEmbeddedPage(this.translateService.instant('COMMUNITY_GUIDE_URL'));
        return;
      }
      case EntryId.DATA_PROTECTION: {
        this.navigationHelper.navigateToEmbeddedPage(this.translateService.instant('PRIVACY_POLICY_URL'));
        return;
      }
      case EntryId.TERMS_OF_USE: {
        this.navigationHelper.navigateToEmbeddedPage(this.translateService.instant('TERMS_AND_CONDITIONS_URL'));
        return;
      }
      case EntryId.IMPRESSUM: {
        this.navigationHelper.navigateToEmbeddedPage(this.translateService.instant('IMPRESSUM_URL'));
        return;
      }
      case EntryId.LICENSES: {
        this.appNavigator.navigateToRoute(WenRouteId.USERPROFILE_LICENSES);
        return;
      }
      case EntryId.NETIQUETTE: {
        this.navigationHelper.navigateToEmbeddedPage(this.translateService.instant('NETIQUETTE_URL'));
        return;
      }
      case EntryId.CONTACT_SUPPORT: {
        this.navigationHelper.navigateToSendEmailTo(this.appConfig.contactInfoConfig.supportEmail);
        return;
      }
      case EntryId.ACCESSIBILITY: {
        this.navigationHelper.navigateToEmbeddedPage(this.translateService.instant('ACCESSIBILITY_URL'));
        return;
      }
      case EntryId.HOTLINE: {
        this.navigationHelper.navigateToHotline();
        return;
      }
      default : {
        return;
      }
    }
  }
}
