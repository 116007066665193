import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ActionMenuItem } from '@portal/wen-components';
import { EMPTY, Observable, Subject, merge, of, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, first, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { DataContext } from '../../../../core/common/types/data-context';
import { FeatureEnablementService } from '../../../../core/services/configuration/feature-enablement';
import { WenNavigationHelper } from '../../../../core/services/navigation/types';
import { DataContextHelper } from '../../../../core/services/util/data-context-helper';
import { FilterType } from '../../../../core/store/filter/models/filter';
import { HeaderCommand } from '../../../../core/store/header/models/HeaderCommand';
import { createNewChatMenu } from '../../../../core/store/header/models/base-commands';
import { RootState } from '../../../../core/store/root/public-api';
import { selectUserAppPermission, selectUserChannelPermission } from '../../../../core/store/user/user.selectors';
import { FilterElementDirective } from '../../../../shared/directives/filter-element/filter-element.directive';
import { SearchEventsHandlerDirective } from '../../../../shared/directives/search-config/search-events-handler.directive';
import { WenRouteId } from '../../../routing/types';
import { AppNavigator } from '../../../../core/services/navigation/app-navigator';
import { selectCurrentUserData } from '../../../../core/store/auth/auth.selectors';
import { PermissionLevel } from '../../../../core/services/user-management/permission-level';
import { ForbiddenForAnonymousUserDialogService } from '../../../../shared/services/forbidden-for-anonymous-user-dialog.service';
import { MissingPermissionDialogService } from '../../../../shared/services/missing-permission-dialog.service';

@Component({
  selector: 'wen-search-bar-desktop',
  templateUrl: './search-bar-desktop.component.html',
  styleUrls: ['./search-bar-desktop.component.scss']
})
export class SearchBarDesktopComponent implements OnInit, OnDestroy {

  onDestroy$ = new Subject<void>();
  searchTerm$: Observable<string>;
  canCreateChannel$: Observable<boolean>;
  canCreateApp$: Observable<boolean>;
  canCreateChat$: Observable<boolean>;
  actions$: Observable<ActionMenuItem[]>;
  rightCommand: HeaderCommand;
  hasGroupChatEnabled: boolean;

  @Input() disabled: boolean;
  @Input() searchBarPlaceholder: string;

  private input$ = new Subject<string>();

  constructor(
    private store: Store<RootState>,
    private navigationHelper: WenNavigationHelper,
    private appNavigator: AppNavigator,
    private featureEnablement: FeatureEnablementService,
    private dataContextHelper: DataContextHelper,
    private missingPermissionDialogService: MissingPermissionDialogService,
    private anonymousUserDialogService: ForbiddenForAnonymousUserDialogService,
    @Optional() private filterElementDirective: FilterElementDirective,
    @Optional() private searchEventsHandlerDirective: SearchEventsHandlerDirective,
  ) { }

  ngOnInit(): void {
    this.hasGroupChatEnabled = this.featureEnablement.featureFlagMethods.isEnableGroupChat();
    this.rightCommand = createNewChatMenu();

    this.searchTerm$ = (this?.filterElementDirective?.activeFilter$ || EMPTY).pipe(
      map((activeFilter) => {
        if (activeFilter?.filterType === FilterType.SEARCH) {
          const searchTerm = activeFilter.filterValue;
          return searchTerm;
        }
        return '';
      }),
      distinctUntilChanged()
    );
    const initialContext$ = this.dataContextHelper.selectCurrentDataContext();
    const contextChanges$ = this.dataContextHelper.onDataContextChanges().pipe(
      map(({ toContext }) => toContext),
    );
    const dataContext$ = merge(
      initialContext$, contextChanges$
    ).pipe(
      shareReplay(1),
    );
    dataContext$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((contextChanged) => {
      if (contextChanged) {
        this.onClear();
      }
    });
    this.canCreateChannel$ = dataContext$.pipe(
      switchMap(context => {
        if (context === DataContext.CHANNEL) {
          return this.featureEnablement.featureFlagMethods.isEnableChannelCreatePopUp() ? of(true) :
            this.store.pipe(
              select(selectUserChannelPermission),
              map(permission => permission?.canCreate),
              takeUntil(this.onDestroy$)
            );
        }
        else {
          return of(false);
        }
      })
    );
    this.canCreateApp$ = dataContext$.pipe(
      switchMap(context => {
        if (context === DataContext.APP) {
          return this.store.pipe(
            select(selectUserAppPermission),
            map(permission => permission?.canCreate),
            takeUntil(this.onDestroy$)
          );
        }
        else {
          return of(false);
        }
      })
    );

    this.canCreateChat$ = dataContext$.pipe(
      map(context => context === DataContext.CHAT),
      distinctUntilChanged()
    );

    this.input$.pipe(
      map(searchTerm => searchTerm?.trim()),
      filter(() => !!this.filterElementDirective),
      takeUntil(this.onDestroy$)
    ).subscribe((searchTerm) => {
      this.filterElementDirective.updateFilter(searchTerm, FilterType.SEARCH);
    });
  }

  onChange(searchTerm: string) {
    this.input$.next(searchTerm);
  }

  onClear() {
    this.input$.next(null);
  }

  navigateToChannelAdd() {
    const userPermission$ = this.store.pipe(
      select(selectUserChannelPermission),
      map(permission => permission.canCreate)
    );
    const isAnonymUser$ = this.store.pipe(
      select(selectCurrentUserData),
      map(value => value.permissionLevel === PermissionLevel.ANONYMOUS)
    );

    combineLatest([userPermission$, isAnonymUser$]).pipe(
      first(),
      switchMap(([canCreate, isAnonym]) => {
        if (canCreate) {
          this.navigationHelper.navigateToChannelAddWelcome();
          return of(null);
        } else if (isAnonym) {
          return this.anonymousUserDialogService.openChannelCreationForbiddenWhenAnonymous();
        } else {
          return this.missingPermissionDialogService.openChannelCreationForbiddenWhenEmailNotVerified();
        }
      })
    ).subscribe();
  }

  navigateToAppAdd() {
    this.appNavigator.navigateToRoute(WenRouteId.APP_ADD_WELCOME);
  }

  navigateToNewChat() {
    this.navigationHelper.navigateToDialogChatCreation();
  }

  onSearchInputFocused() {
    this.searchEventsHandlerDirective.onSearchnputFocused();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }

}
