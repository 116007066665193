import { Component, EventEmitter, HostBinding, Injectable, Input, OnInit, Output } from '@angular/core';
import { VariantConfig } from '../../directives/styling/variant-config';

export enum LocationPreviewVariant {
  normal = 'normal',
  small = 'small',
}

@Injectable()
class LocationPreviewVariantConfig extends VariantConfig {
  possibleVariants = LocationPreviewVariant;
  defaultVariant = 'normal';
}

@Component({
  selector: 'wen-location-preview',
  templateUrl: './location-preview.component.html',
  styleUrls: ['./location-preview.component.scss'],
  providers: [
    { provide: VariantConfig, useClass: LocationPreviewVariantConfig }
  ]
})
export class LocationPreviewComponent implements OnInit {

  @Input() iconName: string;
  @Input() locationData: string;

  @Output() dismiss = new EventEmitter();

  @HostBinding('attr.title') get title() {
    return this.locationData || '';
  }

  get isDismissable() {
    return this.dismiss.observed;
  }

  ngOnInit(): void {
  }

  emitDismiss() {
    this.dismiss.emit();
  }
}
