import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first, Observable, tap } from 'rxjs';
import { ConfirmationDialogComponent, ConfirmationDialogResult, OverlayManager } from '@portal/wen-components';
import { WenNavigationHelper } from '../../core/services/navigation/types';


@Injectable()
export class MissingPermissionDialogService {

  constructor(
    private translateService: TranslateService,
    private overlayManager: OverlayManager,
    private navigationHelper: WenNavigationHelper,
  ) { }

  openChannelCreationForbiddenWhenEmailNotVerified(): Observable<ConfirmationDialogResult> {
    const dialog = this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, {
      header: this.translateService.instant('CHANNEL_ADD_POP_UP_HEADER'),
      content: this.translateService.instant('CHANNEL_ADD_NON_EMAIL_VERIFIED_USER_TEXT'),
      okLabel: this.translateService.instant('CHANNEL_ADD_NON_EMAIL_VERIFIED_OK_BUTTON'),
      dismissLabel: this.translateService.instant('CANCEL_BUTTON_LABEL')
    }, { closeOnNavigation: true });

    return dialog.afterClosed().pipe(
      first(),
      tap(data => {
        if (data?.result === 'ok') {
          this.navigationHelper.navigateToEmailSettings();
        }
      })
    );
  }
}
