import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: 'section-header-title, [section-header-title]'
})
export class SectionHeaderTitleDirective { }

@Directive({
  selector: 'section-header-action, [section-header-action]'
})
export class SectionHeaderActionDirective { }

@Directive({
  selector: 'section-content, [section-content]'
})
export class SectionContentDirective {
  constructor(readonly elRef: ElementRef<HTMLElement>) {}
}
