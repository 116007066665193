import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { spaceSeparatedInitialExtractor } from './initial-extractor/space-seperated-initial-extractor';

export type InitialExtractorFn = (text: string) => string;

@Component({
  selector: 'wen-circle-initial-view',
  templateUrl: './circle-initial-view.component.html',
  styleUrls: ['./circle-initial-view.component.scss']
})
export class CircleInitialViewComponent implements OnInit {

  @HostBinding('class.wen-circle-initial-view') className = true;

  initialText: string;
  originalText: string;
  initialExtractor: InitialExtractorFn;

  @Input() shape: 'circle'|'square' = 'circle';

  @Input() set initialExtractorFn(newFn: InitialExtractorFn) {
    this.initialExtractor = newFn;
    this.updateInitialText();
  }

  @Input() set text(newValue: string) {
    this.originalText = newValue || '';
    this.updateInitialText();
  }

  @Input() fontSize = 22;

  constructor() { }

  ngOnInit(): void {
  }

  private updateInitialText() {
    const fn = this.initialExtractor || spaceSeparatedInitialExtractor;
    this.initialText = fn(this.originalText);
  }

  isCircular() {
    return this.shape === 'circle';
  }

}
