import { NgModule } from '@angular/core';
import { WenNativeApi } from './api/wen-native-api.service';

@NgModule({
  declarations: [
  ],
  imports: [
  ],
  providers: [
    WenNativeApi
  ],
  exports: [
  ]
})
export class WenNativeApiModule { }
