<div class="wen-user-profile-scroller" cdkScrollable>
  <div class="wen-user-profile-header-container" [wen-test-id]="'wen-user-profile-header'">
    <div class="wen-user-profile-registered-user-header" *ngIf="isRegisteredUser$ | async; else anonymousTemplate"
      (click)="goToProfileRead()">
      <ng-container *ngIf="userProfile$ | async; let userProfile">
        <wen-circle-image-view [imageUrl]="userProfile.avatarUrl" [imageSize]="{height:134}" [placeholderText]="userProfile.displayname">
        </wen-circle-image-view>
        <h1 #summaryWrapper class="wen-user-profile-header-username">{{userProfile.displayname}}</h1>
      </ng-container>
    </div>
    <ng-template #anonymousTemplate>
      <div class="wen-user-profile-anonymous-header" [wen-test-id]="'wen-user-anonymous-profile-header'">
        <div class="wen-user-profile-anonymous-header-info">
          <img class="wen-user-profile-anonymous-image" src="/assets/wen-widget/image/anonymous-cover.svg">
          <h2 class="wen-user-profile-anonymous-message">{{ 'USER_PROFILE_ANONYMOUS_MESSAGE' | translate }}</h2>
        </div>
        <div class="wen-user-profile-anonymous-actions" #summaryWrapper>
          <button *ngIf="config.anonymousRegisterEntry$ | async; let registerEntry" portal-button
            (click)="handleEntryClick(registerEntry.entryId)">
              <span portal-title>{{ registerEntry.translationKey | translate }}</span>
          </button>
          <wen-rounded-button *ngIf="config.anonymousLoginEntry$ | async; let loginEntry"
            (click)="handleEntryClick(loginEntry.entryId)">
            <span portal-title>{{ loginEntry.translationKey | translate }}</span>
          </wen-rounded-button>
        </div>
      </div>
    </ng-template>
    <wen-auto-reply-text class="wen-auto-reply-text" (click)="goToAutoReplyEdit()"></wen-auto-reply-text>
  </div>
  <div class="wen-user-profile-view-content">
    <div class="wen-user-profile-view-entries" [wen-test-id]="'wen-user-profile-menus'">
      <ng-container *ngIf="config.dataEntries$ | async; let dataEntries">
        <div class="wen-user-profile-view-entry-group wen-user-profile-view-space-before" *ngIf="dataEntries.length">
          <ng-container *ngFor="let entry of dataEntries">
            <wen-menu-entry (click)="handleEntryClick(entry.entryId)" [attr.entry-id]="entry.entryId"
              *wenComingSoonFeature="entry.comingSoonFeature">
              <wen-icon [iconName]="entry.iconName" [fillColor]="entry.fillColor" [color]="'white'"
                class="wen-icon-medium" menuEntryIcon>
              </wen-icon>
              <span portal-body-1 menuEntryTitle>{{ entry.translationKey | translate }}</span>
              <ng-container menuEntryDescription *ngIf="entry.notificationConfig?.count$ | async; let notificationCount">
                <ng-container *ngIf="notificationCount > 0">
                  <wen-notification-count class="wen-notification-count-big" [value]="notificationCount"></wen-notification-count>
                </ng-container>
              </ng-container>
            </wen-menu-entry>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="config.generalOptions$ | async; let generalOptions">
        <div class="wen-user-profile-view-entry-group wen-user-profile-view-space-before" *ngIf="generalOptions.length">
          <ng-container *ngFor="let option of generalOptions">
            <wen-menu-entry (click)="handleEntryClick(option.entryId)" [attr.entry-id]="option.entryId"
              *wenComingSoonFeature="option.comingSoonFeature">
              <wen-icon [iconName]="option.iconName" [fillColor]="option.fillColor" [color]="'white'"
                class="wen-icon-medium" menuEntryIcon>
              </wen-icon>
              <span portal-body-1 menuEntryTitle>{{ option.translationKey | translate }}</span>
              <ng-container menuEntryDescription *ngIf="option.notificationConfig?.count$ | async; let notificationCount">
                <ng-container *ngIf="notificationCount > 0">
                  <wen-notification-count class="wen-notification-count-big" [value]="notificationCount"></wen-notification-count>
                </ng-container>
              </ng-container>
            </wen-menu-entry>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="config.personalEntries$ | async; let personalEntries">
        <div class="wen-user-profile-view-entry-group wen-user-profile-view-space-before"
          *ngIf="personalEntries.length">
          <ng-container *ngFor="let entry of personalEntries">
            <wen-menu-entry (click)="handleEntryClick(entry.entryId)" [attr.entry-id]="entry.entryId"
              *wenComingSoonFeature="entry.comingSoonFeature">
              <wen-icon [iconName]="entry.iconName" [fillColor]="entry.fillColor" [color]="'white'"
                class="wen-icon-medium" menuEntryIcon>
              </wen-icon>
              <span portal-body-1 menuEntryTitle>{{ entry.translationKey | translate }}</span>
              <ng-container menuEntryDescription *ngIf="entry.notificationConfig?.count$ | async; let notificationCount">
                <ng-container *ngIf="notificationCount > 0">
                  <wen-notification-count class="wen-notification-count-big" [value]="notificationCount"></wen-notification-count>
                </ng-container>
              </ng-container>
            </wen-menu-entry>
          </ng-container>
        </div>
      </ng-container>
      <div class="wen-user-profile-view-trademark wen-user-profile-view-space-before" *ngIf="isTrademarkVisible">
        <div portal-subheading-1>{{ 'USER_PROFILE_TRADEMARK' | translate }}</div>
        <div class="wen-user-profile-view-logo"></div>
      </div>
    </div>
  </div>
</div>
