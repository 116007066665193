import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AppConfigurationProvider } from '../configuration/app-confguration';
import { FeatureEnablementService } from '../configuration/feature-enablement';
import { WenNativeApi } from '@portal/wen-native-api';
import { WenStorageService } from '../storage/wen-storage.service';
import { ExclusiveInviteHelper } from '../util/exclusive-invite-helper';
import { DeepLinkHandler } from './deep-link/deep-link-handler';
import { ShareMenuHandler } from './share-menu/share-menu-handler';
import { WenNavigationHelper } from './types';
import { NativeNotificationHandler } from '../native-notification/native-notification-handler';
import { NativeNotificationPayload } from '@portal/wen-native-api';
import { InitialDeepLinkData, ShareMenuData } from '@portal/react-native-api';

export const appInitGuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(AppInitGuard).canActivate(route, state);
};

@Injectable()
export class AppInitGuard {

  constructor(
    private router: Router,
    private config: AppConfigurationProvider,
    private nativeApi: WenNativeApi,
    private storageService: WenStorageService,
    private featureConfiguration: FeatureEnablementService,
    private exclusiveInviteHelper: ExclusiveInviteHelper,
    private navigationHelper: WenNavigationHelper,
    private shareMenuHandler: ShareMenuHandler,
    private notificationHandler: NativeNotificationHandler,
    private deepLinkHandler: DeepLinkHandler,
  ) { }

  private doDefaultNavigation() {
    this.router.navigateByUrl(this.config.startPage);
    return of(false);
  }

  private handledNotificationData(notificationData: NativeNotificationPayload) {
    if (Boolean(notificationData?.data)) {
      this.notificationHandler.handleNotification(notificationData, false);
      return true;
    }
    return false;
  }

  private handledDeepLinkData(deepLinkData: InitialDeepLinkData) {
    if (Boolean(deepLinkData?.url)) {
      const { canHandle, didNavigate } = this.deepLinkHandler.handleDeepLink(deepLinkData.url);
      return canHandle && didNavigate;
    }
    return false;
  }

  private handledShareData(shareData: ShareMenuData) {
    if (Boolean(shareData)) {
      return this.shareMenuHandler.processShareData(shareData);
    }
    return false;
  }

  private handleNativeApp() {
    if (!this.storageService.isOnboardingDone() && !this.featureConfiguration.featureFlagMethods.isEnableSkipOnboarding()) {
      this.router.navigateByUrl('/onboarding');
      return of(false);
    }
    if (this.exclusiveInviteHelper.isCodeDialogNeeded()) {
      this.navigationHelper.navigateToSignIn(this.config.startPage);
      return of(false);
    }
    if (this.storageService.isInitialNativeDataProcessed()) {
      return this.doDefaultNavigation();
    }
    return this.nativeApi.initialData$.pipe(
      switchMap((initialData) => {
        this.storageService.setInitialNativeDataProcessed();
        const { initialShareData, initialNotificationData, initialDeepLinkData } = initialData;
        if (
          this.handledDeepLinkData(initialDeepLinkData) ||
          this.handledNotificationData(initialNotificationData) ||
          this.handledShareData(initialShareData)
        ) {
          return of(false);
        }
        return this.doDefaultNavigation();
      })
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.nativeApi.isReactNativeApp()) {
      return this.handleNativeApp();
    }
    return this.doDefaultNavigation();
  }

}
