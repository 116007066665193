import { NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, inject, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ChannelTimeSetting, TimeControlProps } from '@portal/wen-backend-api';
import { CanCreateControl } from '../../form-handling/control-creation';
import { FormValueManager } from '../../form-handling/form-value-manager';
import { AbstractControl, FormBuilder, FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'wen-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgxMatTimepickerModule,
    TranslateModule,
    ReactiveFormsModule
  ]
})
export class TimeComponent implements OnInit, CanCreateControl, OnDestroy
{
  private onDestroy$ = new Subject<void>();

  private parentFormGroup = inject(FormGroupDirective);
  private formValueManager = inject(FormValueManager);
  private formBuilder = inject(FormBuilder);
  parentGroup: FormGroup;
  actualTimeControl: AbstractControl;
  visibleTimeDateControl: AbstractControl;

  internalContext: TimeControlProps;

  @Input() set context(value: TimeControlProps) {
    this.internalContext = value;
  }

  get context(): TimeControlProps {
    return this.internalContext;
  }

  ngOnInit() {
    this.parentGroup = this.parentFormGroup.form;
    this.createControl(this.internalContext);
    this.visibleTimeDateControl.valueChanges.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((newValue: Date) => {
      this.updateTime({
        hour: newValue.getHours(),
        minute: newValue.getMinutes()
      });
    });
  }

  createControl(context: TimeControlProps) {
    const formValue = this.formValueManager.getFormValue();
    const selectedValue = formValue[context.name];

    this.createActualValueHoldingControl(selectedValue);

    this.createVisuallyAppearingControl(selectedValue);
  }

  private createActualValueHoldingControl(selectedValue: any) {
    this.actualTimeControl = this.formBuilder.control({
      value: selectedValue, disabled: false
    });
    this.parentGroup.addControl(this.context.name, this.actualTimeControl);
  }

  private createVisuallyAppearingControl(selectedValue: any) {
    const date = new Date();
    date.setHours(selectedValue.hour);
    date.setMinutes(selectedValue.minute);
    this.visibleTimeDateControl = this.formBuilder.control({
      value: date, disabled: false
    });
    this.parentGroup.addControl('frontendOnlyTimeDateControl', this.visibleTimeDateControl);
  }

  private updateTime(newValue: ChannelTimeSetting) {
    this.actualTimeControl.setValue(newValue);
    this.actualTimeControl.markAsDirty();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
