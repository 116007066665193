import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ChannelMessagesDetailResponse, ChannelMessagesModificationsResponse, ChannelMessagesResponse } from '@portal/wen-backend-api';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { subscribeToChannelMessageModifications, subscribeToDiscoverChannelUpdates, subscribeToUserChannelUpdates, updateChannelExtraMessages, updateChannelMessages, updateChannelMessagesHistory, upsertChannelExtraMessages, upsertChannelMessages } from '../channel.actions';

export const createChannelMessageEffect = (
  actions$: Actions,
  channelMessages$: Observable<ChannelMessagesResponse>,
) => {
  return createEffect(() => actions$.pipe(
    ofType(subscribeToUserChannelUpdates, subscribeToDiscoverChannelUpdates),
    first(),
    switchMap(() => {
      return channelMessages$.pipe(
        map((messages) => upsertChannelMessages({ messages }))
      );
    })
  ));
};

export const createChannelExtraMessagesEffect = (
  actions$: Actions,
  channelMessagesDetail$: Observable<ChannelMessagesDetailResponse>,
) => {
  return createEffect(() => actions$.pipe(
    ofType(subscribeToUserChannelUpdates, subscribeToDiscoverChannelUpdates),
    first(),
    switchMap(() => {
      return channelMessagesDetail$.pipe(
        map(({ message }) => {
          return upsertChannelExtraMessages({ messages: [message] });
        })
      );
    })
  ));
};

export const createChannelMessageModificationsEffect = (
  actions$: Actions,
  channelMessageModifications$: Observable<ChannelMessagesModificationsResponse>,
) => {
  return createEffect(() => actions$.pipe(
    ofType(subscribeToChannelMessageModifications),
    first(),
    switchMap(() => {
      return channelMessageModifications$.pipe(
        map((modifiedMessage) => {
          return Array.isArray(modifiedMessage) ? modifiedMessage[0] : modifiedMessage;
        }),
        switchMap((modifiedMessage) => [
          updateChannelMessages({ messages: [modifiedMessage] }),
          updateChannelExtraMessages({ messages: [modifiedMessage] }),
          updateChannelMessagesHistory({ channelId: modifiedMessage.channelId, messages: [modifiedMessage], flags: null })
        ])
      );
    })
  ));
};
