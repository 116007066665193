import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WenIconModule } from '@portal/wen-components';
import { HeaderModule } from '../../../frame/components/header/header.module';
import { LinkEmbedPreviewModule } from '../../../shared/components/link-embed-preview/link-embed-preview.module';
import { CHANNEL_MESSAGE_TYPE_SELECTOR_MENU_ID } from '../../../shared/components/message-type-selector/constants/type-selector-ids';
import { MessageTypeSelectorModule } from '../../../shared/components/message-type-selector/message-type-selector.module';
import { CHANNEL_MESSAGE_TYPE_ACTIONS } from '../../../shared/components/message-type-selector/providers/message-type-actions-provider';
import { SharedModule } from '../../../shared/shared.module';
import { ChannelMessageErrorModule } from '../channel-message-detail/components/message-error/message-error.module';
import { ChannelMessagesViewModule } from '../common/components/channel-messages-view/channel-messages-view.module';
import { ChannelScheduledMessageSendFieldComponent } from './components/channel-schedule-message-send-field/channel-schedule-message-send-field.component';
import { ChannelSchedulesDesktopHeaderComponent } from './components/channel-schedules-desktop/channel-schedules-desktop-header/channel-schedules-desktop-header.component';
import { ChannelSchedulesDesktopComponent } from './components/channel-schedules-desktop/channel-schedules-desktop.component';
import { ChannelSchedulesComponent } from './components/channel-schedules/channel-schedules.component';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    ChannelMessagesViewModule,
    TranslateModule.forChild(),
    ChannelMessageErrorModule,
    HeaderModule,
    WenIconModule,
    MessageTypeSelectorModule.forRoot(
      { menuId: CHANNEL_MESSAGE_TYPE_SELECTOR_MENU_ID, menuItems: CHANNEL_MESSAGE_TYPE_ACTIONS }
    ),
    LinkEmbedPreviewModule,
  ],
  declarations: [
    ChannelSchedulesComponent,
    ChannelSchedulesDesktopComponent,
    ChannelSchedulesDesktopHeaderComponent,
    ChannelScheduledMessageSendFieldComponent,
  ],
})
export class ChannelSchedulesModule { }
