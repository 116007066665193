import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'wen-translate-with-icon',
  templateUrl: './translate-with-icon.component.html',
  styleUrls: ['./translate-with-icon.component.scss']
})
export class TranslateWithIconComponent {

  private readonly iconPlaceholder = '[icon]';
  translation$: Observable<string>;
  translationParts: string[];

  @Input() set translationKey(key: string) {
    const translatedText = this.translateService.instant(key);
    this.translationParts = translatedText.split(this.iconPlaceholder);
  }
  @Input() iconName: string;

  constructor(
    private translateService: TranslateService
  ) { }

}
