import { Directive, ElementRef, Inject, Input, Renderer2 } from '@angular/core';
import { E2E_MODE_CONFIG } from '../../util/tokens';

@Directive({
  selector: '[wen-test-id]'
})
export class TestIdDirective {

  @Input('wen-test-id') set testId(newId: string) {
    if (this.isE2EMode) {
      this.renderer.setAttribute(this.host.nativeElement, 'wen-test-id', newId);
    }
  }

  constructor(
    private host: ElementRef<HTMLElement>,
    @Inject(E2E_MODE_CONFIG) private isE2EMode: boolean,
    private renderer: Renderer2
  ) { }

}
