import { EntityState } from '@ngrx/entity';
import { CommentEntity, commentEntityAdapter } from '../comments.state';

export const commentHelper = {
  setCount: (
    commentCountByParentId: { [key: string]: number },
    commentEntities: EntityState<CommentEntity>
  ): EntityState<CommentEntity> => {

    const mappedCommentEntities: CommentEntity[] = Object.entries(
      commentCountByParentId
    ).map(([referenceId, count]: [string, number]) => {
      return {
        referenceId,
        count
      };
    });

    return commentEntityAdapter.upsertMany(mappedCommentEntities, commentEntities);
  },
  deleteComments: (messageIds: string[], comments: EntityState<CommentEntity>) => {
    return commentEntityAdapter.removeMany(messageIds, comments);
  }
};
