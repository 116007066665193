import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataObjectType } from '../../../../../core/store/common/data-objects';
import { FormValueConverter } from '../../../../../shared/form-store/form-store.providers';
import { FormStoreService } from '../../../../../shared/form-store/form-store.service';
import { UserSettingsFormValueConverter } from './user-settings-form-value-converter';

@Component({
  selector: 'wen-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
  providers: [{
    provide: FormValueConverter,
    useClass: UserSettingsFormValueConverter
  }]
})
export class UserSettingsComponent implements OnInit {

  userSettingsFormGroup = new FormGroup({});

  constructor(
    private formStoreService: FormStoreService,
  ) { }

  ngOnInit() {
    this.formStoreService.clearFormData();
  }

  saveSettings() {
    this.formStoreService.saveForm(DataObjectType.USER);
  }

}
