import { AfterViewInit, Component, Input, OnDestroy, Self, ViewChild } from '@angular/core';
import { FormControlName } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Subject, takeUntil, timer } from 'rxjs';
import { ControlValueAccessorProxy, CVA_PROXY_PROVIDERS } from '../../../forms/control-value-accessor-proxy';
import { SelectionOption } from '../../../wen-selection-list/types';
import { SelectorDialogData } from '../../models/dialog-data';
import { SelectorDialogComponent, SelectorDialogResult } from '../selector-dialog/selector-dialog.component';
import { OverlayManager } from '../../../../services/overlays/overlay-manager';

@Component({
  selector: 'wen-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    ...CVA_PROXY_PROVIDERS
  ]
})
export class SelectComponent implements AfterViewInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  @ViewChild(MatSelect) matSelect: MatSelect;

  @Input() possibleValues: SelectionOption<string>[];
  @Input() mainLabel: string;
  @Input() bottomLabel: string;

  constructor(
    @Self() private readonly formControlName: FormControlName,
    private readonly cvaProxy: ControlValueAccessorProxy,
    private readonly overlayManager: OverlayManager,
  ) { }

  ngAfterViewInit() {
    timer(0).subscribe(() => {
      this.formControlName.control.disable();
      this.cvaProxy.initializeControl(this.matSelect, this.formControlName);
    });
  }

  openSelectorDialog() {
    const dialogRef = this.overlayManager.dialog.openSelectorDialog<
      SelectorDialogComponent,
      SelectorDialogData,
      SelectorDialogResult>(
        SelectorDialogComponent,
        {
          mainLabel: this.mainLabel,
          possibleValues: this.possibleValues,
          activeValues: this.cvaProxy.formControl.value
        }
      );
    dialogRef.afterClosed().pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(dialogResult => {
      if (!dialogResult?.result) {
        return;
      }
      const newValues = dialogResult.result || [];
      this.cvaProxy.formControl.markAsDirty();
      this.cvaProxy.formControl.setValue(newValues);
    });
  }

  compareIds(possible: SelectionOption<string>, active: SelectionOption<string>) {
    return possible && active && possible.id === active.id;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
