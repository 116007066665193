import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'wen-create-welcome-markdown',
  templateUrl: './create-welcome-markdown.component.html',
  styleUrls: ['./create-welcome-markdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateWelcomeMarkdownComponent {
  @Input()
  content: string;
}
