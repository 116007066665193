import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { selectCurrentHeaderData } from '../../../../../core/store/header/header.selectors';
import { HeaderCommand } from '../../../../../core/store/header/models/HeaderCommand';
import { RootState } from '../../../../../core/store/root/public-api';

@Component({
  selector: 'wen-desktop-header',
  templateUrl: './desktop-header.component.html',
  styleUrls: ['./desktop-header.component.scss']
})
export class DesktopHeaderComponent implements OnInit {

  leftCommand$: Observable<HeaderCommand>;

  constructor(
    private store: Store<RootState>
  ) { }

  ngOnInit() {
    this.leftCommand$ = this.store.pipe(
      select(selectCurrentHeaderData),
      filter(data => Boolean(data)),
      map(({ leftCommand }) => leftCommand)
    );
  }

}
