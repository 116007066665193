import { Component, Inject, Input } from '@angular/core';
import { EmbeddedLocation } from '../../../../../../core/store/channel/channel.state';
import { WenNavigationHelper } from '../../../../../../core/services/navigation/types';
import { YELLOWMAP_CONFIG, YellowMapConfig } from '../../../../../../frame/api/tokens';
import { YellowMapMapService } from '../../../../../../core/services/yellowmap/yellowmap-map-service';
import { isNullOrUndefined } from '../../../../../../core/common/operators/null-check-util';

@Component({
  selector: 'wen-embed-location',
  templateUrl: './embed-location.component.html',
  styleUrls: ['./embed-location.component.scss']
})
export class EmbedLocationComponent {

  canOpenLocation = this.yellowMapConfig?.yellowMapUrl && this.yellowMapConfig?.yellowMapApiKey;

  @Input() model: EmbeddedLocation;

  constructor(
    @Inject(YELLOWMAP_CONFIG) private yellowMapConfig: YellowMapConfig,
    private navigationHelper: WenNavigationHelper,
    private yellowMapMapService: YellowMapMapService,
  ) { }

  get locationData() {
    return this.model?.locationData;
  }

  openLocation() {
    if (!this.canOpenLocation) {
      return;
    }
    const latitude = this.model?.locationData?.geometry?.coordinates[0];
    const longitude = this.model?.locationData?.geometry?.coordinates[1];
    if (isNullOrUndefined(latitude) || isNullOrUndefined(longitude)) {
      return;
    }
    const url = this.yellowMapMapService.getMapUrlFromGeoData({ latitude, longitude });
    this.navigationHelper.navigateToUrlWithFallback(url);
  }
}
