import { Component, createNgModule, Injector, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'wen-reader',
  templateUrl: './reader.component.html'
})
export class ReaderComponent implements OnInit {
  @ViewChild('xreader', { read: ViewContainerRef }) xreader: ViewContainerRef;

  constructor(private injector: Injector) { }

  async ngOnInit() {
    await this.loadXReaderModule();
  }

  async loadXReaderModule() {
    let module: Type<any>;
    await import('@portal/wen-xreader').then(m => module = m.WenXReaderModule);

    let ref;
    try {
      this.xreader.clear();

      const moduleRef = createNgModule(module, this.injector);
      ref = moduleRef.instance.createComponent(this.xreader);
    } catch (e) {
      console.error('Loading module @portal/wen-xreader failed. ' + e);
    }
    return ref;
  }
}
