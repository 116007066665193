import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { RestrictionContextType, SocketIoService } from '@portal/wen-backend-api';
import { Subject, merge } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { existy } from '../../core/common/operators/existy';
import { selectorWithParam } from '../../core/common/util/selector-with-param';
import { selectCurrentChannel, selectIsChannelSubscribed } from '../../core/store/channel/channel.selectors';
import { RootState } from '../../core/store/root/public-api';

@Injectable()
export class RestrictionAutoRefresherService implements OnDestroy {

  private onDestroy$ = new Subject<void>();

  constructor(
    private readonly store: Store<RootState>,
    private readonly socketIoService: SocketIoService
  ) { }

  attach() {
    const currentChannelId$ = this.store.pipe(
      select(selectCurrentChannel),
      map((channel) => channel?.id),
      existy(),
      distinctUntilChanged(),
      shareReplay(1)
    );
    const unSubscribed$ = currentChannelId$.pipe(
      switchMap(channelId => this.store.pipe(
        selectorWithParam(selectIsChannelSubscribed, channelId),
        distinctUntilChanged()
      )),
      filter((isSubscribed) => !isSubscribed)
    );
    const channelPermissionUpdates$ = this.socketIoService.system.channelPolicy.listen.pipe(
      filter((response) => response && !response.channelId),
    );
    merge(unSubscribed$, channelPermissionUpdates$).pipe(
      withLatestFrom(currentChannelId$),
      takeUntil(this.onDestroy$)
    ).subscribe(([_, channelId]) => {
      this.socketIoService.restrictions.evaluate.emit({ contextId: channelId, contextType: RestrictionContextType.Channel });
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
