import { Component, ContentChild, ElementRef, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ListItemSubtitleDirective } from './list-item-directives';

@Component({
  selector: 'wen-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListItemComponent implements OnInit {

  @HostBinding('class.wen-list-item') className = true;

  @HostBinding('class.wen-list-item-selected') selectedClassName = false;
  @Input() set selected(newValue: boolean) { this.selectedClassName = newValue; }

  @HostBinding('class.wen-list-item-disabled') disabledClassName = false;
  @Input() set disabled(newValue: boolean) { this.disabledClassName = newValue; }

  @ContentChild(ListItemSubtitleDirective, { static: false }) listItemSubtitle: ListItemSubtitleDirective;

  @Input() showAfter = false;
  @Input() justifyInfoContent = 'flex-start';

  constructor(public readonly element: ElementRef) { }

  ngOnInit(): void {
  }

}
