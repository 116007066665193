import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { FormValueConverter } from '../../../../../../shared/form-store/form-store.providers';

interface ChatInfoEditModel {
  icon: string;
  description: string;
  title: string;
}

@Injectable()
export class ChatInfoEditFormValueConverter extends FormValueConverter<ChatInfoEditModel, ChatInfoEditModel> {

  convertToFormModel(roomEntity) {
    if (!roomEntity || Object.keys(roomEntity).length === 0) {
      return of(null);
    }
    const { details } = roomEntity;
    const { description, title, icon } = details ?? roomEntity;
    const formModel: ChatInfoEditModel = { description, title, icon };
    return of(formModel);
  }

  convertToServerModel(formModel: ChatInfoEditModel) {
    return of(formModel);
  }
}
