import { Injectable, inject } from '@angular/core';
import { WenNativeApi } from '@portal/wen-native-api';
import { BackNavigationBehavior, BackNavigationBehaviorResult } from './back-navigation-behavior';

@Injectable()
export class SuppressBackNavigationOrMinimizeApp implements BackNavigationBehavior {

  private nativeApi = inject(WenNativeApi);

  execute(): BackNavigationBehaviorResult {
    if (this.nativeApi.isReactNativeApp()) {
      this.nativeApi.minimizeApp();
    }
    return {
      canHandle: true
    };
  }

}
