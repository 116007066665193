import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { EventSubscription } from '@portal/wen-backend-api';
import { subscribeForEvent, unsubscribeFromEvent } from '../../../../../core/store/events/event.actions';
import { RootState } from '../../../../../core/store/root/public-api';

@Component({
  selector: 'wen-event-subscribe-button',
  templateUrl: './event-subscribe-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventSubscribeButtonComponent implements OnInit {

  subscribe_icon: string;
  private innerSubscription: EventSubscription | undefined;
  @Input() set subscription(e: EventSubscription) {
    this.innerSubscription = e;
    this.subscribe_icon = e?.subscribedByUser ? 'favorite_active' : 'favorite_inactive';
  }

  ngOnInit() {
  }

  constructor(private store: Store<RootState>) { }

  handleSubscription() {
    if (this.innerSubscription?.subscribedByUser) {
      this.store.dispatch(unsubscribeFromEvent());
    } else {
      this.store.dispatch(subscribeForEvent());
    }
  }
}
