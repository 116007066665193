import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { selectCurrentUserData } from '../../auth/auth.selectors';
import { RootState } from '../../root/public-api';
import { acknowledgeDeletedApp, reloadDiscoverAppList, subscribeToAppUpdates, updateApp, updateAppDetail } from '../apps.actions';
import { selectDiscoverAppIds } from '../apps.selectors';
import { AppEntity } from '../apps.state';

export const createAppUpdateEffect = (
  actions$: Actions,
  appUpdates$: Observable<AppEntity>,
  appDetails$: Observable<AppEntity>,
) => {
  return createEffect(() => actions$.pipe(
    ofType(subscribeToAppUpdates),
    first(),
    switchMap(() => {
      return merge(
        appUpdates$.pipe(map((app) => updateApp({ app }))),
        appDetails$.pipe(map((app) => updateAppDetail({ app })))
      );
    }),
  ));
};

export const createAppDeleteAcknowledgeEffect = (
  actions$: Actions,
  store: Store<RootState>,
  socketIoService: Pick<SocketIoService, 'app'>
) => {
  return createEffect(() => actions$.pipe(
    ofType(acknowledgeDeletedApp),
    withLatestFrom(store.pipe(
      select(selectCurrentUserData))
    ),
    tap(([{ appId }, { userId }]) => socketIoService.app.deleteAcknowledge.emit({ userId, appId })),
    switchMap(([{ appId }]) => {
      return combineLatest([
        of(appId),
        store.pipe(select(selectDiscoverAppIds), first())
      ]);
    }),
    filter(([appId, discoverAppIds]) => discoverAppIds.includes(appId)),
    map(() => {
      return reloadDiscoverAppList();
    })
  ));
};
