import { ActionMenuConfig } from '@portal/wen-components';
import { ChatMuteAction } from './chat-mute.action';
import { ChatPinAction } from './chat-pin.action';

export const chatListItemContextMenuId = 'chat-list-item-context-menu-id';

export const CHAT_LIST_ITEM_CONTEXT_MENU_ITEMS: ActionMenuConfig = {
    menuId: chatListItemContextMenuId,
    menuItems: [ChatPinAction, ChatMuteAction]
};
