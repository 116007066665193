import { BehaviorSubject } from 'rxjs';

export interface SignInViewConfig {
  showLoginButton: boolean;
  showAnonLoginButton: boolean;
  showRegisterButton: boolean;
  showLoginPrompt: boolean;
  termsAndConditions: boolean;
  popupFlow?: {
    returnUrl: string;
  };
}

export abstract class SignInActionsHandler {
  viewConfig: SignInViewConfig;

  abstract goToRegister(): void;

  abstract goToLogin(): void;

  abstract goToAnonymousLogin(): void;

  abstract openTermsAndConditionsPage(): void;

  abstract openPrivacyPage(): void;

  init(startSilentLogout: BehaviorSubject<boolean>) { }
}
