import { createAction, props } from '@ngrx/store';
import { Filter, FilterEntityType } from './models/filter';

export const upsertActiveFilter = createAction(
  '[Filter] Add or change filters for a specific context',
  props<{ filterEntityType: FilterEntityType; filter: Filter }>()
);

export const replaceActiveFilter = createAction(
  '[Filter] Change filters for a specific context',
  props<{ filterEntityType: FilterEntityType; filter: Filter }>()
);

export const removeFilter = createAction(
  '[Filter] Remove filter element',
  props<{ filterEntityType: FilterEntityType }>()
);
