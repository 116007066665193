import { Component, ElementRef, Input, Renderer2, ViewEncapsulation, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { WeatherModel } from './weather.model';

@Component({
  selector: 'wen-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WeatherComponent {

  @HostBinding('class.wen-weather') className = true;
  weatherViewValue: WeatherModel;

  @Input() set model(weatherData: WeatherModel) {
    this.weatherViewValue = weatherData;
    const { background } = weatherData;
    const { start, end } = background.gradient;
    this.renderer.setStyle(this.elementRef.nativeElement, 'background', `linear-gradient(to right, ${start}, ${end})`);
  }

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef<HTMLElement>
  ) { }

}
