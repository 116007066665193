import { Component, HostBinding } from '@angular/core';
import { CreateWelcomeContentResolver } from './provider/create-welcome-content-resolver';
import { FeatureEnablementService } from '../../../core/services/configuration/feature-enablement';

@Component({
  selector: 'wen-create-welcome',
  templateUrl: './create-welcome.component.html',
  styleUrls: ['./create-welcome.component.scss'],
  viewProviders: [
    CreateWelcomeContentResolver
  ]
})
export class CreateWelcomeComponent {
  @HostBinding('class.wen-create-welcome') className = true;

  imageUrl: string;
  translationKey: string;
  navigate: () => void;
  isPartnerApp: boolean;

  constructor(
    contentResolver: CreateWelcomeContentResolver,
    featureEnablementService: FeatureEnablementService,
  ) {
    const content = contentResolver.resolveContent();
    this.navigate = content.navigateAction;
    this.translationKey = content.translationKey;
    this.imageUrl = content.imagePath;
    this.isPartnerApp = featureEnablementService.isPartnerApp();
  }

}
