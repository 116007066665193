import { Inject, Injectable } from '@angular/core';
import { TEXT_TO_SPEECH_CONFIG, TextToSpeechConfig } from '../../../frame/api/tokens';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class TextToSpeechService {

  constructor(
    @Inject(TEXT_TO_SPEECH_CONFIG) private textToSpeechConfig: TextToSpeechConfig,
    private http: HttpClient
  ) { }

  getAudio(text: string): Observable<string> {
    const { textToSpeechUrl, textToSpeechApiKey } = this.textToSpeechConfig;
    const headers = {
      Accept: 'application/mpeg',
      'Content-Type': 'application/json',
      'api-key': textToSpeechApiKey
    };
    // for prototype only coqui backend in german shall be used
    const body = { backend: 'coqui', text, lang: 'de' };
    return this.http.post(textToSpeechUrl, body, { headers, responseType: 'blob' }).pipe(
      map((data) => URL.createObjectURL(data))
    );
  }
}
