import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UserRoleComponent } from './user-role.component';

@NgModule({
  imports: [TranslateModule.forChild(), CommonModule],
  exports: [UserRoleComponent],
  declarations: [UserRoleComponent]
})
export class UserRoleModule { }
