import { WenLanguage, WenNetwork } from '@portal/wen-backend-api';
import { EnvironmentType } from './environment-type';
import { getVersionNr } from '../utils/version-helper';


export const environment: EnvironmentType = {
  production: true,
  tracerConfig: {
    dsn: 'https://fcc36ee805e046bc8c2afd4c0411773f@sentry.cas.de/10',
    appEnv: 'wen-beta',
    version: getVersionNr(),
    sentryIntegrations: {
      browserApiErrorsIntegration: true,
      browserProfilingIntegration: true,
      browserTracingIntegration: true,
    },
    sentryParams: {
      tracesSampleRate: 0.1,
      profilesSampleRate: 0.1,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,
    },
    enableSensitiveDataSending: false
  },
  backendConfig: {
    backendUrl: 'https://websocket-central.beta.we.network/',
    mediaServiceBaseUrl: 'https://media-central.beta.we.network/',
    network: WenNetwork.WE_NETWORK
  },
  oauthConfig: {
    postLogoutRedirectUri: '/signin/start',
    clientId: 'wen-1012766a-db47-472a-9279-0d4271bfc2d1',
    issuer: 'https://identity-central.beta.we.network',
    tokenEndpoint: 'https://identity-central.beta.we.network/oauth/token',
    logoutUrl: 'https://identity-central.beta.we.network/forcelogout',
    revocationEndpoint: 'https://identity-central.beta.we.network/oauth/token/revoke'
  },
  weLocalBaseUrl: 'https://multimedia.we.network',
  yellowMapConfig: {
    yellowMapUrl: 'https://www.yellowmap.de',
    yellowMapApiKey: 'dXsavGwBsiBmtLMq8+C0Y5iufdUC5/ZoS35Pu8v9Dv0='
  },
  contactInfoConfig: {
    supportEmail: 'info@we.network',
    waitlistUrl: 'https://www.we.network/anmeldeformular',
  },
  exclusiveInviteConfig: {
    inviteUrl: 'https://get.we.network'
  },
  nativeConfig: {
    nativeDeepLink: 'we-network',
    useNativeDialog: false,
    storeInfos: {
      androidPlayStoreId: 'we.network',
      iosAppName: 'we-network',
      iosAppStoreId: '6449700172'
    }
  },
  appConfig: {
    enablePwa: true,
    enableChat: true,
    enableDevTools: false,
    enableChatDebugMode: false,
    enableEvent: true,
    enableAppStatePersistence: true,
    enableAppBanner: true,
    enableAgb: false,
    enableInviteOnlyNetwork: false,
    enableInviteToNetwork: true,
    enableDisconnectErrorPage: true,
    enableShareMenu: true,
    enableCRMDistributionCancelling: false,
  },
  debugOptions: {
    enableNativeBridgeDebug: true,
  },
  walletConfig: {
    credentialProviderForUnitedKiosk: 'https://test-credential-issuer.k8s.united-kiosk.de'
  },
  supportedLanguages: [WenLanguage.GERMAN, WenLanguage.ENGLISH]
};
