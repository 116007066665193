import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { InvitePayload, SocketIoService } from '@portal/wen-backend-api';
import { ConfirmationDialogData, OverlayManager } from '@portal/wen-components';
import { combineLatest, filter, first, merge, of, switchMap } from 'rxjs';
import { FormStoreMediator } from '../../../../shared/form-store/form-store-mediator';
import { CRMClosingDialogComponent, CRMClosingDialogData } from '../../../../views/crm/crm-closing-dialog/crm-closing-dialog.component';
import { mapWithFirstFrom } from '../../../common/operators/map-with-first-from';
import { switchMapFirst } from '../../../common/operators/switch-map-first';
import { convertToInviteeDTO } from '../../../common/util/convert-to-invitee-dto';
import { FeatureEnablementService } from '../../../services/configuration/feature-enablement';
import { SmartDesignApi } from '../../../services/smartdesign/smartdesing-api';
import { DataObjectType } from '../../common/data-objects';
import { doBackNavigation } from '../../header/header.actions';
import { RootState } from '../../root/public-api';
import { selectIsFromSmartDesign } from '../../smartdesign/smartdesign.selectors';
import { updateProgressState } from '../../ui/ui.actions';
import { ProgressProcessType } from '../../ui/ui.state';
import { clearFormValues } from '../form.actions';
import { selectEditFormById } from '../form.selectors';
import { InviteToChannelChangedValues, InviteToChannelInitialValues, InviteToNetworkChangedValues } from '../types/invite-types';

@Injectable()
export class InviteToNetworkFormEffects {

  createInviteToNetworkFormSendEffect$ = this.formStoreMediator.createSaveEditFormEffect((saveAction) => of(saveAction).pipe(
    filter(action => action.dataObjectType === DataObjectType.INVITE_TO_NETWORK),
    switchMap(({ formId }) => this.store.pipe(
      select(selectEditFormById(formId)),
      first()
    )),
    mapWithFirstFrom(() => this.withTenantId()),
    switchMap(([editForm, tenantId]) => {
      const { formId, changedValues } = editForm;
      const { emailBody, invitees = [], importedInvitees = [] } = changedValues as InviteToNetworkChangedValues;
      const improtedInviteesDTO = convertToInviteeDTO(importedInvitees);
      const payload: InvitePayload = {
        language: 'de',
        customMailText: emailBody,
        invitees: [...invitees, ...improtedInviteesDTO]
      };
      const extraActions: Action[] = [];
      if (tenantId) {
        payload.tenantId = tenantId;
      } else {
        extraActions.push(doBackNavigation());
      }
      const save$ = this.socketIoService.invites.create.acknowledgement$(payload).pipe(
        switchMap(() => {
          const data: CRMClosingDialogData = {
            image: '/assets/wen-widget/icons/icon_mail.svg',
            title: this.translateService.instant('INVITE_NETWORK_HAS_BEEN_SENT_MESSAGE'),
            acceptLabel: this.translateService.instant('INVITE_HAS_BEEN_SENT_OK_LABEL')
          };
          this.overlayManager.dialog.openConfirmationDialog(
            CRMClosingDialogComponent,
            data as ConfirmationDialogData, { disableClose: true }
          );
          return [
            updateProgressState({ newState: { hasProgress: false } }),
            clearFormValues({ formId }),
            ...extraActions
          ];
        })
      );
      return merge(
        of(updateProgressState({ newState: { hasProgress: true, progressType: ProgressProcessType.FOREGROUND } })),
        save$
      );
    })
  ));

  createInviteToChannelFormSendEffect$ = this.formStoreMediator.createSaveEditFormEffect((saveAction) => of(saveAction).pipe(
    filter(action => action.dataObjectType === DataObjectType.INVITE_TO_CHANNEL),
    switchMapFirst(({ formId }) => combineLatest([
      this.store.pipe(
        select(selectEditFormById(formId)),
        first()
      ),
      this.withTenantId(),
      this.featureEnablementService.featureConfigObs.enableCRMEmailInviteToNetwork$
    ])),
    switchMap(([editForm, tenantId, isInviteEmailEnabled]) => {
      const { formId, changedValues, initialValues } = editForm;
      const { selectedChannels = [] } = initialValues as InviteToChannelInitialValues;
      const { importedInvitees = [], emailBody } = changedValues as InviteToChannelChangedValues;
      const channelIds = selectedChannels.map((channel) => channel.id);
      const importedInviteesDTO = convertToInviteeDTO(importedInvitees);
      const payload: InvitePayload = {
        language: 'de',
        customMailText: emailBody,
        invitees: importedInviteesDTO,
        channels: channelIds
      };
      if (tenantId) {
        payload.tenantId = tenantId;
        payload.sendEmail = isInviteEmailEnabled;
      }
      const save$ = this.socketIoService.invites.create.acknowledgement$(payload).pipe(
        switchMap(() => {
          const contentTranslationKey = isInviteEmailEnabled ?
            'INVITE_CHANNEL_HAS_BEEN_SENT_INFO_MESSAGE' : 'INVITE_CHANNEL_HAS_BEEN_SENT_NO_EMAIL_INFO_MESSAGE';
          const data: CRMClosingDialogData = {
            image: '/assets/wen-widget/icons/success.svg',
            title: this.translateService.instant('INVITE_CHANNEL_HAS_BEEN_SENT_MESSAGE'),
            message: this.translateService.instant(contentTranslationKey),
            acceptLabel: this.translateService.instant('INVITE_HAS_BEEN_SENT_OK_LABEL')
          };
          this.overlayManager.dialog.openConfirmationDialog(
            CRMClosingDialogComponent,
            data as ConfirmationDialogData, { disableClose: true }
          );
          return [
            updateProgressState({ newState: { hasProgress: false } }),
            clearFormValues({ formId }),
          ];
        })
      );
      return merge(
        of(updateProgressState({ newState: { hasProgress: true, progressType: ProgressProcessType.FOREGROUND } })),
        save$
      );
    })
  ));

  private withTenantId() {
    return this.store.pipe(select(selectIsFromSmartDesign)).pipe(
      switchMap((isFromSmartDesign) => {
        if (isFromSmartDesign) {
          return this.smartDesignApi.getTenantId();
        }
        return of(null);
      }),
      first()
    );
  }

  constructor(
    private store: Store<RootState>,
    private socketIoService: SocketIoService,
    private overlayManager: OverlayManager,
    private formStoreMediator: FormStoreMediator,
    private smartDesignApi: SmartDesignApi,
    private translateService: TranslateService,
    private featureEnablementService: FeatureEnablementService,
  ) { }

}
