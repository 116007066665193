import { Component, HostBinding, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AvailableRoutes } from '../../../../core/services/navigation/available-routes';
import { RouteConfigInitializer } from '../../../../core/services/navigation/route-config-initializer';
import { WenNavigationHelper } from '../../../../core/services/navigation/types';
import { selectActiveChannelNotificationCount, selectActiveChatNotificationCount } from '../../../../core/store/notification/notification.selectors';
import { RootState } from '../../../../core/store/root/public-api';

@Component({
  selector: 'wen-nav-bar-item',
  templateUrl: './nav-bar-item.component.html',
  styleUrls: ['./nav-bar-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavBarItemComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  route: AvailableRoutes;
  iconName: string;
  iconNameActive: string;
  label: string;
  notificationCount$: Observable<number>;
  itemLink: any;

  @HostBinding('class.wen-nav-bar-item') className = true;

  /**
   * The route to display the item for
   * see {@link AvailableRoutes} enum for possible entries
   *
   * @public
   */
  @Input()
  set forRoute(route: AvailableRoutes) {
    if (this.route === route) {
      return;
    }
    this.setupDisplayData(route);
    this.route = route;
  }

  constructor(
    private translateService: TranslateService,
    private navigationHelper: WenNavigationHelper,
    private routeConfigInitializer: RouteConfigInitializer,
    private store: Store<RootState>
  ) { }

  ngOnInit() {
    this.routeConfigInitializer.onNavigatorChange$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      if (this.route) {
        this.setupDisplayData(this.route);
      }
    });
  }

  private setupDisplayData(route: AvailableRoutes) {
    switch (route) {
      case 'channel':
        this.iconName = 'channels_inactive';
        this.iconNameActive = 'channels_active';
        this.label = 'NAVBAR_LABEL_CHANNELS';
        this.notificationCount$ = this.store.pipe(select(selectActiveChannelNotificationCount));
        this.itemLink = this.navigationHelper.getNavigateToChannelListLink();
        return;
      case 'home':
        this.iconName = 'home_inactive';
        this.iconNameActive = 'home_active';
        this.label = 'NAVBAR_LABEL_HOME';
        this.itemLink = this.navigationHelper.getNavigateToHomeLink();
        return;
      case 'apps':
        this.iconName = 'marktplatz_inactive';
        this.iconNameActive = 'marktplatz_active';
        this.label = 'NAVBAR_LABEL_MARKTPLATZ';
        this.itemLink = this.navigationHelper.getNavigateToAppListLink();
        return;
      case 'event':
        this.iconName = 'events_inactive';
        this.iconNameActive = 'events_active';
        this.label = 'NAVBAR_LABEL_EVENTS';
        this.itemLink = this.navigationHelper.getNavigateToEventListLink();
        return;
      case 'chat':
        this.iconName = 'chats_inactive';
        this.iconNameActive = 'chats_active';
        this.label = 'NAVBAR_LABEL_CHATS';
        this.notificationCount$ = this.store.pipe(select(selectActiveChatNotificationCount));
        this.itemLink = this.navigationHelper.getNavigateToChatListLink();
        return;
      default:
        throw new Error(`Route ${route} is not supported`);
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
