/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { InvitePayload, SimpleUserDTO, SocketIoService } from '@portal/wen-backend-api';
import { ConfirmationDialogData, OverlayManager } from '@portal/wen-components';
import { catchError, combineLatest, distinctUntilChanged, filter, first, from, map, merge, of, switchMap, tap } from 'rxjs';
import { WenRouteId } from '../../../frame/routing/types';
import { maxNumberOfUsersForMassMessageSending } from '../../../views/chat/constants';
import { InviteChatCreationDialogOpener } from '../../../views/crm/chat/services/invite-chat-creation-dialog-opener';
import { CRMClosingDialogComponent, CRMClosingDialogData } from '../../../views/crm/crm-closing-dialog/crm-closing-dialog.component';
import { mapWithFirstFrom } from '../../common/operators/map-with-first-from';
import { convertToInviteeDTO } from '../../common/util/convert-to-invitee-dto';
import { FeatureEnablementService } from '../../services/configuration/feature-enablement';
import { DistributionDataImporter } from '../../services/smartdesign/chat-distribution/distribution-data-importer';
import { SelectionDataImporter } from '../../services/smartdesign/selection-data-importer';
import { SmartDesignApi } from '../../services/smartdesign/smartdesing-api';
import { WenOAuthService } from '../../services/user-management/wen-oauth.service';
import { actualizeGlobalDraftChat, addDraftChatMember, cancelChatMessagesDistribution, finishChatMessagesDistribution } from '../chat/chat.actions';
import { RootState } from '../root/public-api';
import { selectOutletIds } from '../root/root.selectors';
import { updateProgressState } from '../ui/ui.actions';
import { selectProgressState } from '../ui/ui.selectors';
import { ProgressProcessType } from '../ui/ui.state';
import { continueCRMChatFlow, haltCRMChatFlow, inviteToNetworkFromSmartdesign, loadImportedSelectionData, preloadWenUsersForCRM, upsertImportedSelectionData, upsertWenUsers } from './smartdesign.actions';
import { selectCreateChatSelection, selectIsFromSmartDesign, selectNetworkInviteSelection, } from './smartdesign.selectors';

@Injectable()
export class SmartDesignEffects {

  loadSelectionData$ = createEffect(() => this.actions$.pipe(
    ofType(loadImportedSelectionData),
    first(),
    switchMap(() => {
      const load$ = from(this.selectionDataImporter.preloadInviteData());
      return merge(
        of(updateProgressState({ newState: { hasProgress: true, progressType: ProgressProcessType.BACKGROUND } })),
        load$.pipe(
          switchMap((datas) => {
            const newEntities = datas.map((data) => data.toTransfarable());
            return [
              upsertImportedSelectionData({ newEntities }),
              updateProgressState({ newState: { hasProgress: false } }),
            ];
          })
        )
      );
    }),
  ));

  showLoadingIndicatorOnMessageSend$ = createEffect(() => this.actions$.pipe(
    ofType(actualizeGlobalDraftChat),
    switchMap(() => this.filterForSmartDesign()),
    map(() => updateProgressState({ newState: { hasProgress: true, progressType: ProgressProcessType.FOREGROUND } }))
  ));

  hideLoadingIndicatorAfterMessageSend$ = createEffect(() => this.actions$.pipe(
    ofType(continueCRMChatFlow),
    first(),
    switchMap(() => this.store.pipe(
      select(selectOutletIds),
      first(),
      filter(routeData => {
        const routeId = routeData?.dialogId || routeData?.primaryId;
        return routeId === WenRouteId.CHAT_CREATION;
      })
    )),
    switchMap(() => this.filterForSmartDesign()),
    switchMap(() => this.store.pipe(
      select(selectCreateChatSelection),
      map((selection) => selection.networkInviteableEntities),
      first()
    )),
    mapWithFirstFrom(() => this.featureEnablementService.featureConfigObs.enableCRMEmailInviteToNetwork$),
    tap(([networkInviteableEntities, isInviteEmailEnabled]) => {
      const needsInviteDialog = Boolean(networkInviteableEntities?.length) && isInviteEmailEnabled;
      if (needsInviteDialog) {
        this.inviteChatCreationDialogOpener.openInviteDialog();
      } else {
        this.inviteChatCreationDialogOpener.openSuccessDialog(isInviteEmailEnabled);
      }
    }),
    map(() => updateProgressState({ newState: { hasProgress: false } }))
  ));

  haltCRMChatFlow$ = createEffect(() => this.actions$.pipe(
    ofType(haltCRMChatFlow),
    switchMap(() => this.filterForSmartDesign()),
    map(() => updateProgressState({ newState: { hasProgress: false } }))
  ));

  preloadWenUsersForCRM$ = createEffect(() => this.actions$.pipe(
    ofType(preloadWenUsersForCRM),
    switchMap(() => this.filterForSmartDesign()),
    switchMap(() => this.store.pipe(
      select(selectProgressState),
      filter(state => !state.hasProgress),
      first()
    )),
    switchMap(() => {
      const save$ = this.store.pipe(
        select(selectCreateChatSelection),
        map((createChatSelection) => {
          const currentUserId = this.authService.getUserData().userId;
          return createChatSelection.chatCreatableEntities.filter(entity => entity.weNetworkUserId !== currentUserId);
        }),
        distinctUntilChanged((a, b) => a.length === b.length),
        switchMap(chatCreatableEntities => {
          if (!chatCreatableEntities?.length) {
            return of([] as SimpleUserDTO[]);
          }
          const ids = chatCreatableEntities.map(entity => entity.weNetworkUserId);
          return this.socketIoService.user.list.acknowledgement$({ ids }).pipe(
            map(result => result.users)
          );
        }),
        mapWithFirstFrom(() => this.store.pipe(select(selectNetworkInviteSelection))),
        switchMap(([users, networkInviteableEntities]) => {
          const progressStateAction = updateProgressState({ newState: { hasProgress: false } });
          if (!users || !Array.isArray(users) || !users.length) {
            if (networkInviteableEntities.length) {
              this.inviteChatCreationDialogOpener.openInviteDialog();
            }
            return [progressStateAction];
          }
          if (users.length > maxNumberOfUsersForMassMessageSending) {
            this.inviteChatCreationDialogOpener.openTooManyChatContactsDialog();
          }
          const draftChatUpdateActions = users.map(user => {
            const { userId: id, avatarUrl, displayName: title } = user;
            return addDraftChatMember({ user: { id, avatarUrl, title } });
          });
          return [
            upsertWenUsers({ users }),
            ...draftChatUpdateActions,
            progressStateAction
          ];
        })
      );
      return merge(
        of(updateProgressState({ newState: { hasProgress: true, progressType: ProgressProcessType.BACKGROUND } })),
        save$
      );
    })
  ));

  inviteToNetwork$ = createEffect(() => this.actions$.pipe(
    ofType(inviteToNetworkFromSmartdesign),
    mapWithFirstFrom(() => combineLatest([
      this.smartDesignApi.getTenantId(),
      this.featureEnablementService.featureConfigObs.enableCRMEmailInviteToNetwork$
    ])),
    switchMap(([{ emailBody, importedInvitees }, [tenantId, isInviteEmailEnabled]]) => {
      const importedInviteesDTO = convertToInviteeDTO(importedInvitees);
      const payload: InvitePayload = {
        language: 'de',
        customMailText: emailBody,
        invitees: [...importedInviteesDTO],
        tenantId,
        sendEmail: isInviteEmailEnabled
      };
      const save$ = this.socketIoService.invites.create.acknowledgement$(payload).pipe(
        switchMap(() => {
          const openSuccessDialog$ = this.overlayManager.dialog.closeConfirmationDialog('ok').pipe(
            tap(() => {
              this.inviteChatCreationDialogOpener.openInviteSuccessDialog();
            }),
            switchMap(() => [])
          );
          return merge(
            of(updateProgressState({ newState: { hasProgress: false } })),
            openSuccessDialog$
          );
        })
      );
      return merge(
        of(updateProgressState({ newState: { hasProgress: true, progressType: ProgressProcessType.FOREGROUND } })),
        save$
      );
    })
  ));

  cancelChatMessagesDistribution$ = createEffect(() => this.actions$.pipe(
    ofType(cancelChatMessagesDistribution),
    switchMap(() => this.distributionDataImporter.sendDistributeChatMessageSendResult({
      deliveredWeNetworkIds: [],
      failedWeNetworkIds: [],
      status: 'cancelled'
    })),
    tap(() => this.smartDesignApi.closeDialog())
  ), { dispatch: false });

  finishChatMessagesDistribution$ = createEffect(() => this.actions$.pipe(
    ofType(finishChatMessagesDistribution),
    switchMap(({ succeededRecipientIds, failedRecipientIds }) => {
      return this.distributionDataImporter.sendDistributeChatMessageSendResult({
        deliveredWeNetworkIds: succeededRecipientIds,
        failedWeNetworkIds: failedRecipientIds,
        status: 'finished'
      }).pipe(
        map(() => ({ total: succeededRecipientIds.length + failedRecipientIds.length, failed: failedRecipientIds.length })),
        catchError(() => of({ total: succeededRecipientIds.length + failedRecipientIds.length, failed: failedRecipientIds.length })),
      );
    }),
    tap(({ total, failed }) => {
      let title: string;
      let image = '/assets/wen-widget/icons/success.svg';
      let message = '';
      if (failed === 0) {
        title = this.translateService.instant('CRM_DISTRIBUTE_CHAT_SUCCESS_DIALOG_TITLE');
      } else if (failed === total) {
        title = this.translateService.instant('CRM_DISTRIBUTE_CHAT_FAILURE_DIALOG_TITLE');
        message = this.translateService.instant('CRM_DISTRIBUTE_CHAT_FAILURE_DIALOG_DESCRIPTION');
        image = '/assets/wen-widget/image/pic_404_channel.svg';
      } else if (failed === 1) {
        title = this.translateService.instant('CRM_DISTRIBUTE_CHAT_SEMI_SUCCESS_DIALOG_TITLE');
        message = this.translateService.instant('CRM_DISTRIBUTE_CHAT_SEMI_SUCCESS_DIALOG_DESCRIPTION.0');
      } else {
        title = this.translateService.instant('CRM_DISTRIBUTE_CHAT_SEMI_SUCCESS_DIALOG_TITLE');
        message = this.translateService.instant('CRM_DISTRIBUTE_CHAT_SEMI_SUCCESS_DIALOG_DESCRIPTION.1', {
          failedRecipientCount: failed
        });
      }
      const data: CRMClosingDialogData = {
        image,
        title,
        message,
        acceptLabel: this.translateService.instant('INVITE_HAS_BEEN_SENT_OK_LABEL')
      };
      this.overlayManager.dialog.closeConfirmationDialog('dismiss').subscribe(() => {
        this.overlayManager.dialog.openConfirmationDialog(CRMClosingDialogComponent, data as ConfirmationDialogData, { disableClose: true });
      });
    }),
    map(() => updateProgressState({ newState: { hasProgress: false } }))
  ));

  private filterForSmartDesign() {
    return this.store.pipe(
      select(selectIsFromSmartDesign),
      first(),
      filter((isFromSmartDesign) => isFromSmartDesign)
    );
  }

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly selectionDataImporter: SelectionDataImporter,
    private readonly distributionDataImporter: DistributionDataImporter,
    private readonly overlayManager: OverlayManager,
    private readonly smartDesignApi: SmartDesignApi,
    private readonly socketIoService: SocketIoService,
    private readonly inviteChatCreationDialogOpener: InviteChatCreationDialogOpener,
    private readonly authService: WenOAuthService,
    private readonly featureEnablementService: FeatureEnablementService,
    private readonly translateService: TranslateService,
  ) { }

}
