import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Paging } from '@portal/wen-backend-api';
import { WenComment } from '../../../shared/components/comments/types';
import { DraftMessageEntity } from '../common/models';

export interface CommentEntity {
  referenceId: string;
  commentList?: WenComment[];
  pagingInformation?: Paging;
  count?: number;
}

export interface CommentState {
  comments: EntityState<CommentEntity>;
  draftComment: DraftMessageEntity;
}

export const commentEntityAdapter: EntityAdapter<CommentEntity> = createEntityAdapter({
  selectId: (comment: CommentEntity) => comment?.referenceId || null
});
