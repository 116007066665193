import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { ChatMessagesViewModule } from '../../chat/common/components/chat-messages-view/chat-messages-view.module';
import { ChatPreviewComponent } from './components/chat-preview/chat-preview.component';
import { ChatPreviewMessageFeedDatasource } from './services/chat-preview-message-feed.datasource';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ChatMessagesViewModule,
  ],
  declarations: [
    ChatPreviewComponent,
  ],
  exports: [
    ChatPreviewComponent,
  ],
  providers: [
    ChatPreviewMessageFeedDatasource
  ]
})
export class ChatPreviewModule { }
