import { PortalFieldValueAdapter } from '@portal/ui-kit/field';
import { isNullOrUndefined } from '../../operators/null-check-util';
import { TrimFieldValueAdapter } from './trim-field-value-adapter';

export class CapitalizeFieldValueAdapter implements PortalFieldValueAdapter<string> {

  private trimmer = new TrimFieldValueAdapter();

  parse(rawValue: any): string {
    const parsed = rawValue?.toString() ?? '';
    return this.trimmer.parse(parsed);
  }

  format(parsedValue: string): string {
    if (isNullOrUndefined(parsedValue) || !Boolean(parsedValue.length)) {
      return '';
    }
    const formatted = `${parsedValue[0].toUpperCase()}${parsedValue.slice(1)}`;
    return this.trimmer.parse(formatted);
  }
}
