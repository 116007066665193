import { MediaType } from '@portal/wen-backend-api';

export const getMediaType = (file: File) => {
  let type: MediaType;
  if (file.type.includes('image/')) {
    type = MediaType.PICTURE;
  } else if (file.type.includes('video/')) {
    type = MediaType.VIDEO;
  } else if (file.type.includes('audio/')) {
    type = MediaType.AUDIO;
  } else {
    type = MediaType.DOCUMENT;
  }
  return type;
};
