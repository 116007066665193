import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EmbeddedLocation } from '../../../../../core/store/channel/channel.state';

@Component({
  selector: 'wen-location-list-item',
  templateUrl: './location-list-item.component.html',
  styleUrls: ['./location-list-item.component.scss']
})
export class LocationListItemComponent {

  @Input() model: EmbeddedLocation;
  @Input() searchTerm: string;
  @Output() prefillActionClicked = new EventEmitter<EmbeddedLocation>();

  onPrefillActionClicked(event: Event) {
    event.stopPropagation();
    this.prefillActionClicked.emit(this.model);
  }

}
