import { Component, OnInit } from '@angular/core';
import { RoomDTO } from '@portal/wen-backend-api';
import { Observable } from 'rxjs';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { ChatLoadResult } from '../../models/chat-load-result';
import { UserChatListDataSource } from './user-chat-list-datasource';

@Component({
  selector: 'wen-user-chat-list',
  templateUrl: './user-chat-list.component.html',
  styleUrls: ['./user-chat-list.component.scss'],
  providers: [
    UserChatListDataSource
  ]
})
export class UserChatListComponent implements OnInit {

  chatLoadResult$: Observable<ChatLoadResult>;
  markdownEnabled = true;

  constructor(
    private readonly dataSource: UserChatListDataSource,
    private readonly navigationHelper: WenNavigationHelper,
  ) {}

  ngOnInit() {
    this.chatLoadResult$ = this.dataSource.loadResult$;
  }

  onClick(chat: RoomDTO) {
    this.navigationHelper.navigateToChatView(chat.id);
  }

  navigateToDiscover() {
    this.navigationHelper.navigateToDialogChatCreation();
  }

  trackByFn(chat: RoomDTO) {
    return chat.id;
  }
}
