import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { NotificationData, NotificationType } from '@portal/wen-backend-api';
import { ColorPalette } from '../../../../../../../directives/colorize/palette-provider';

interface NotificationIconConfiguration {
  fillColor?: string;
  iconName?: string;
  color?: string;
}

@Component({
  selector: 'wen-notification-type',
  templateUrl: './notification-type.component.html',
  styleUrls: ['./notification-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationTypeComponent {

  iconConfiguration: NotificationIconConfiguration;

  @Input() set message(value: NotificationData) {
    this.iconConfiguration = {
      color: ColorPalette.WHITE,
      ...this.getIconConfiguration(value)
    };
  }

  private getIconConfiguration(message: NotificationData): Partial<NotificationIconConfiguration> {
    switch (message.notificationType) {
      case NotificationType.COMMENT:
        return {
          iconName: 'comment_inactive',
          fillColor: ColorPalette.DARK_BLUE
        };
      case NotificationType.REACTION:
        return {
          iconName: 'add_reaction',
          fillColor: ColorPalette.GREEN
        };
      case NotificationType.CHANNEL_INVITE_REQUEST:
        return {
          iconName: 'user_raise_hand',
          fillColor: ColorPalette.PURPLE
        };
      case NotificationType.PRIVILEGE_REVOKED:
        return {
          iconName: 'block',
          fillColor: ColorPalette.GOLD
        };
      case NotificationType.CONTACT_REQUEST:
        return {
          iconName: 'contact_add',
          fillColor: ColorPalette.OCEAN_BLUE
        };
      case NotificationType.CONTACT_DENY:
        return {
          iconName: 'contact_delete',
          fillColor: ColorPalette.OCEAN_BLUE
        };
      case NotificationType.CONTACT_ACCEPT:
        return {
          iconName: 'contact_connected',
          fillColor: ColorPalette.OCEAN_BLUE
        };
      default:
        return;
    }
  }

}
