import { Injectable } from '@angular/core';
import { RestrictionDTO, RestrictionResolveObjectType, RestrictionType } from '@portal/wen-backend-api';
import { SelectionOption } from '@portal/wen-components';
import { PrivilegeDTO } from '../../core/store/user/models/Privilege';

@Injectable()
export class PrivilegeService {

  convertSelectionToResolveObject(restrictions: SelectionOption<RestrictionType>[], password?: string): RestrictionResolveObjectType[] {
    const restrictionObjects: RestrictionResolveObjectType[] = [];

    for (const restriction of restrictions) {
      switch (restriction.value) {
        case RestrictionType.PasswordRequired:
          if (password) {
            restrictionObjects.push({ type: restriction.value, password });
          }
          break;
        case RestrictionType.PrivilegesRequired:
          restrictionObjects.push({ type: restriction.value, privilege: restriction.id });
          break;
        case RestrictionType.IsManaged:
        case RestrictionType.NoRestriction:
          break;
        default:
          restrictionObjects.push({ type: restriction.value });
          break;
      }
    }

    return restrictionObjects;
  }

  convertDTOToResolveObject(restrictions: RestrictionDTO[], privileges: PrivilegeDTO[]): RestrictionResolveObjectType[] {
    const restrictionObjects: RestrictionResolveObjectType[] = [];

    if (!restrictions?.length) {
      return restrictionObjects;
    }

    for (const restriction of restrictions) {
      switch (restriction.restrictionType) {
        case RestrictionType.PasswordRequired:
          restrictionObjects.push({ type: restriction.restrictionType, password: null });
          break;
        case RestrictionType.PrivilegesRequired:
          const privilege = privileges.find(p => p.id === restriction.id);
          restrictionObjects.push({ type: restriction.restrictionType, privilege: privilege.id });
          break;
        case RestrictionType.WalletCredentialRequired:
          restrictionObjects.push({ type: restriction.restrictionType });
          break;
        case RestrictionType.IsManaged:
        case RestrictionType.NoRestriction:
          break;
        default:
          restrictionObjects.push({ type: restriction.restrictionType });
          break;
      }
    }

    return restrictionObjects;
  }

}
