import { createReducer, on } from '@ngrx/store';
import { deleteReactions, setMessageReactions, updateReactionSummaryForMessage } from './reaction.actions';
import { reactionAdapter, ReactionsState } from './reaction.state';
import { reactionHelper } from './util/reaction-helper';


export const reactionInitialState: ReactionsState = {
  reactions: reactionAdapter.getInitialState({})
};

const reactionReducer = createReducer(
  reactionInitialState,
  on(setMessageReactions, (state, { response }) => {
    return {
      ...state,
      reactions: reactionHelper.setReactionSummaries(response, state)
    };
  }),
  on(updateReactionSummaryForMessage, (state, { reactionUpdate }) => {
    if (!reactionUpdate) {
      return state;
    }
    return {
      ...state,
      reactions: reactionHelper.updateReactionSummaryForMessage(reactionUpdate, state)
    };
  }),
  on(deleteReactions, (state, { referenceIds }) => {
    return {
      ...state,
      reactions: reactionHelper.deleteReactions(referenceIds, state.reactions)
    };
  }),
);

export function reactionReducerFactory(state, action) {
  return reactionReducer(state, action);
}
