import { Inject, Injectable, Optional } from '@angular/core';
import { DataProtectionConfig, DATA_PROTECTION_CONFIG, OnboardingConfig, ONBOARDING_CONFIG, ContactInfoConfig, CONTACT_INFO_CONFIG } from '../../../frame/api/tokens';
import { WenRoutingConfig, WEN_ROUTING_CONFIG } from '../../../frame/routing/tokens';
import { AvailableRoutes } from '../navigation/available-routes';

@Injectable()
export class AppConfigurationProvider {

  private readonly defaultOnboardingConfig: OnboardingConfig = {
    lottieAnimationUrl: '/assets/wen-widget/lottie/IntroTitelandPeopleLottie.json'
  };

  private readonly defaultDataProtectionConfig: DataProtectionConfig = {
    manifestEnabled: true,
  };

  private readonly defaultLicensesPage = './3rdpartylicenses.txt';

  private readonly defaultContactInfoConfig: ContactInfoConfig = {
    supportEmail: 'info@we.network'
  };

  public readonly startPage = this.routingConfig?.startPage || AvailableRoutes.HOME;

  constructor(
    @Optional() @Inject(WEN_ROUTING_CONFIG) private readonly routingConfig: WenRoutingConfig,
    @Optional() @Inject(ONBOARDING_CONFIG) private readonly externalOnboardingConfig: OnboardingConfig,
    @Optional() @Inject(DATA_PROTECTION_CONFIG) private readonly externalDataProtectionConfig: DataProtectionConfig,
    @Optional() @Inject(CONTACT_INFO_CONFIG) private externalContactInfoConfig: ContactInfoConfig,
  ) { }

  get onboardingConfig() {
    return this.externalOnboardingConfig || this.defaultOnboardingConfig;
  }

  get dataProtectionConfig() {
    return this.externalDataProtectionConfig || this.defaultDataProtectionConfig;
  }

  get contactInfoConfig() {
    return this.externalContactInfoConfig || this.defaultContactInfoConfig;
  }

  get licensesPage() {
    return this.defaultLicensesPage;
  }

}
