import { NgModule } from '@angular/core';
import { ComingSoonFeatureDirective } from './coming-soon/coming-soon-feature.directive';

@NgModule({
  declarations: [
    ComingSoonFeatureDirective
  ],
  exports: [
    ComingSoonFeatureDirective
  ]
})
export class FeaturesModule { }
