import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DialogLayoutModule } from '@portal/wen-components';
import { SearchBarModule } from '../../../shared/components/search-bar/search-bar.module';
import { FilterElementModule } from '../../../shared/directives/filter-element/filter-element.module';
import { SharedModule } from '../../../shared/shared.module';
import { HeaderModule } from '../header/header.module';
import { RouteDialogComponent } from './route-dialog.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    DialogLayoutModule,
    HeaderModule,
    SearchBarModule,
    FilterElementModule,
  ],
  declarations: [
    RouteDialogComponent
  ],
  exports: [
    RouteDialogComponent
  ]
})
export class RouteDialogModule { }
