import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { NotificationOptInOutPayload, SocketIoService } from '@portal/wen-backend-api';
import { combineLatest } from 'rxjs';
import { first, switchMapTo, tap } from 'rxjs/operators';
import { mapWithFirstFrom } from '../../../common/operators/map-with-first-from';
import { NativeNotificationHandler } from '../../../services/native-notification/native-notification-handler';
import { NativeNotificationDataTypes, NativeNotificationType, NewNativeChannelJoinRequestNotificationData, NewNativeChannelMessageNotificationData, NewNativeChatMessageNotificationData } from '@portal/wen-native-api';
import { DeepLinkNavigator } from '../../../services/navigation/deep-link/deep-link-navigator';
import { WenNavigationHelper } from '../../../services/navigation/types';
import { WenStorageService } from '../../../services/storage/wen-storage.service';
import { logout } from '../../auth/auth.actions';
import { selectCurrentUserData } from '../../auth/auth.selectors';
import { handleNativeNotificationRegistration, handleNewNativeNotification } from '../notification.actions';
import { selectNativeNotificationRegistrationToken } from '../notification.selectors';

export const createNativeNotificationRegistrationHandlerEffect = (
  actions$: Actions,
  store: Store,
  socketIoService: SocketIoService,
  storageService: WenStorageService
) => {
  return createEffect(() => actions$.pipe(
    ofType(handleNativeNotificationRegistration),
    mapWithFirstFrom(() => store.pipe(select(selectCurrentUserData))),
    tap(([{ payload }, { userId }]) => {
      if (!payload?.os || !payload?.token) {
        return;
      }
      const { os, token } = payload;
      const socketPayload: NotificationOptInOutPayload = {
        userId,
        platform: os,
        isEnabled: true,
        registrationId: token,
        deviceId: storageService.getDeviceId()
      };
      socketIoService.notification.optInOut.emit(socketPayload);
    })
  ), { dispatch: false });
};

export const createNativeNotificationUnRegistrationHandlerEffect = (
  actions$: Actions,
  store: Store,
  socketIoService: SocketIoService,
  storageService: WenStorageService,
  nativeNotificationHandler: NativeNotificationHandler,
) => {
  return createEffect(() => actions$.pipe(
    ofType(logout),
    switchMapTo(combineLatest([
      store.pipe(select(selectNativeNotificationRegistrationToken), first()),
      store.pipe(select(selectCurrentUserData), first()),
    ])),
    tap(([payload, { userId }]) => {
      if (!payload?.os || !payload?.token) {
        return;
      }
      const socketPayload: NotificationOptInOutPayload = {
        userId,
        platform: payload.os,
        isEnabled: false,
        registrationId: payload.token,
        deviceId: storageService.getDeviceId()
      };
      socketIoService.notification.optInOut.emit(socketPayload);
    }),
    tap(() => nativeNotificationHandler.unregisterFromNotifications())
  ), { dispatch: false });
};

export const createNewNativeNotificationHandlerEffect = (
  actions$: Actions,
  wenNavigationHelper: WenNavigationHelper,
  deepLinkNavigator: DeepLinkNavigator,
) => {
  return createEffect(() => actions$.pipe(
    ofType(handleNewNativeNotification),
    tap(({ notificationData }) => {
      let newMessageNotificationData: NativeNotificationDataTypes;
      switch (notificationData?.notificationType) {
        case NativeNotificationType.NEW_PERSONAL_MESSAGE:
        case NativeNotificationType.NEW_CHANNEL_MESSAGE:
        case NativeNotificationType.NEW_CHANNEL_INVITE:
        case NativeNotificationType.NEW_CHANNEL_JOIN:
          deepLinkNavigator.runGuarded(() => {
            newMessageNotificationData = notificationData as NewNativeChannelMessageNotificationData;
            wenNavigationHelper.navigateToChannelView(newMessageNotificationData.channelId, { state: { fromDeepLink: true } });
          });
          break;
        case NativeNotificationType.NEW_CHAT_MESSAGE:
          deepLinkNavigator.runGuarded(() => {
            newMessageNotificationData = notificationData as NewNativeChatMessageNotificationData;
            wenNavigationHelper.navigateToChatView(newMessageNotificationData.roomId, { state: { fromDeepLink: true } });
          });
          break;
        case NativeNotificationType.NEW_CHANNEL_JOIN_REQUEST:
          deepLinkNavigator.runGuarded(() => {
            newMessageNotificationData = notificationData as NewNativeChannelJoinRequestNotificationData;
            wenNavigationHelper.navigateToPendingJoinRequestsPage(newMessageNotificationData.channelId, { state: { fromDeepLink: true } });
          });
          break;
      }
    })
  ), { dispatch: false });
};
