import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChatMessageDeletedModule } from '../../../chat-message-deleted/chat-message-deleted.module';
import { ChatMessageErrorModule } from '../../../chat-message-error/chat-message-error.module';
import { ChatMessageContentComponent } from './chat-message-content.component';


@NgModule({
  imports: [ChatMessageErrorModule, CommonModule, ChatMessageDeletedModule],
  exports: [ChatMessageContentComponent],
  declarations: [ChatMessageContentComponent],
  providers: [],
})
export class ChatMessageContentModule { }
