import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AppConfigurationProvider } from '../../../../../../../core/services/configuration/app-confguration';
import { WenNavigationHelper } from '../../../../../../../core/services/navigation/types';
import { ColorPalette } from '../../../../../../../shared/directives/colorize/palette-provider';

@Component({
  selector: 'wen-hotline-view',
  templateUrl: './hotline-view.component.html',
  styleUrls: ['./hotline-view.component.scss']
})
export class HotlineViewComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();
  contactInformations = this.appConfig.contactInfoConfig.hotline;
  iconColor = ColorPalette.LIGHT_BLUE;
  constructor(
    private readonly appConfig: AppConfigurationProvider,
    private navigationHelper: WenNavigationHelper,
  ) { }

  ngOnInit(): void {
  }

  handleEntryClick(phonenumber: string) {
    this.navigationHelper.navigateToCallPhoneNumber(phonenumber);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
