import { Component, OnInit } from '@angular/core';
import { OverlayManager } from '@portal/wen-components';
import { AppConfigurationProvider } from '../../../../core/services/configuration/app-confguration';
import { WenNavigationHelper } from '../../../../core/services/navigation/types';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'wen-agb-data-protection-dialog',
  templateUrl: './agb-data-protection-dialog.component.html',
  styleUrls: ['./agb-data-protection-dialog.component.scss']
})
export class AgbDataProtectionDialogComponent implements OnInit {

  public selected = false;

  privacyPolicyLink = this.translateService.instant('PRIVACY_POLICY_URL');
  termsOfUseLink =this.translateService.instant('TERMS_AND_CONDITIONS_URL');

  constructor(
    public readonly config: AppConfigurationProvider,
    public readonly navigationHelper: WenNavigationHelper,
    public readonly overlayManager: OverlayManager,
    private readonly translateService: TranslateService,
  ) { }

  ngOnInit(): void {
  }

  onLinkClicked(event: Event, link: string) {
    event.preventDefault();
    this.navigationHelper.navigateToUrlWithFallback(link);
  }

  onAccept() {
    this.overlayManager.dialog.closeConfirmationDialog('ok');
  }
}
