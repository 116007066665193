import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { fetchEventDetail } from '../../../../../../core/store/events/event.actions';
import { RootState } from '../../../../../../core/store/root/public-api';

export const eventDetailDataResolverFn = (route: ActivatedRouteSnapshot) => {
  inject(EventDetailDataResolver).resolve(route);
};

@Injectable()
export class EventDetailDataResolver  {

  constructor(private store: Store<RootState>) {}

  resolve(route: ActivatedRouteSnapshot): Observable<void> | Promise<void> | void {
    const { eventId } = route.params;
    this.store.dispatch(fetchEventDetail({ eventId }));
  }
}
