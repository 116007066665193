import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SocketIoService } from '@portal/wen-backend-api';
import { filter, tap } from 'rxjs';
import { fetchCommentsForParentElements } from '../comments.actions';
import { COMMENT_LIST_SORTING } from '../constants/comment.constants';

export const createFetchCommentsEffect = (
  actions$: Actions,
  socketIoService: Pick<SocketIoService, 'comment'>
) => {
  return createEffect(() =>
    actions$.pipe(
      ofType(fetchCommentsForParentElements),
      filter(({ parentIds }) => parentIds.length > 0),
      tap(({ parentIds, size: pageSize, filter: filterString }) => {
        const requestObj = {
          parentId: parentIds[0],
          ...COMMENT_LIST_SORTING,
          ...!!pageSize && { size: pageSize },
          ...!!filterString && { filter: filterString }
        };
        socketIoService.comment.list.emit(requestObj);
      })
    ), { dispatch: false }
  );
};
