import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { map, of, switchMap, tap } from 'rxjs';
import { firstExisty } from '../../../../core/common/operators/first-existy';
import { WenNavigationHelper } from '../../../../core/services/navigation/types';
import { selectAppPermission, selectCurrentAppDetail } from '../../../../core/store/apps/apps.selectors';
import { RootState } from '../../../../core/store/root/public-api';

export const appEditGuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(AppEditGuard).canActivate(route, state);
};

@Injectable()
export class AppEditGuard  {
  constructor(
    private store: Store<RootState>,
    private navigationHelper: WenNavigationHelper,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(selectCurrentAppDetail),
      firstExisty(),
      switchMap((app) => {
        if (app.id) {
          return this.store.pipe(
            select(selectAppPermission),
            map((appPermissionFn) => appPermissionFn(app.id)),
            firstExisty(),
            map(permission => permission.canEdit),
            tap(canEdit => {
              if (!canEdit) {
                this.navigationHelper.navigateToAppDetail(app.id);
              }
            })
          );
        } else {
          return of(false);
        }
      })
    );
  }

}
