
import { Injectable } from '@angular/core';
import { SimpleUserDTO } from '@portal/wen-backend-api';
import { of } from 'rxjs';
import { FormValueConverter } from '../../../../../shared/form-store/form-store.providers';

interface ChatUsersFormModel {
  users: SimpleUserDTO[];
}

interface ChatUsersServerModel {
  users: SimpleUserDTO[];
}

@Injectable()
export class ChatUserFormValueConverter extends FormValueConverter<ChatUsersFormModel, ChatUsersServerModel>{

  convertToFormModel({ users }: ChatUsersServerModel) {
    return of({ users });
  }

  convertToServerModel(chatUsersFormModel: ChatUsersFormModel) {
    const users = chatUsersFormModel.users;
    return of({ users });
  }
}
