import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ActionMenuItem, WenSnackbarOpener } from '@portal/wen-components';
import { of } from 'rxjs';
import { toggleMuteChannel } from '../../../../../../../core/store/channel/channel.actions';
import { RootState } from '../../../../../../../core/store/root/public-api';
import { ChannelListItemModel } from '../models/list-item-model';

@Injectable()
export class ChannelMuteAction implements ActionMenuItem {

  private isMuted: boolean;

  label: string;
  icon: string;

  execute(listItemModel: ChannelListItemModel): void {
    this.store.dispatch(toggleMuteChannel({ channelId: listItemModel.id, mute: !listItemModel.muteIcon }));
    this.showSnackbarMessage();
  }

  isVisible$ = (listItemModel: ChannelListItemModel) => {
    this.isMuted = !!listItemModel?.muteIcon;
    this.icon = this.isMuted ? 'unmute' : 'mute';
    this.label = this.translateService.instant(this.isMuted ? 'UNMUTE_BUTTON_LABEL' : 'MUTE_BUTTON_LABEL');
    return of(!!listItemModel);
  };

  constructor(
    private store: Store<RootState>,
    private translateService: TranslateService,
    private wenSnackbarOpener: WenSnackbarOpener
  ) { }

  private showSnackbarMessage() {
    const notificationText = this.translateService.instant(this.isMuted ? 'UNMUTE_BUTTON_LABEL' : 'MUTE_BUTTON_LABEL');
    const notificationIcon = this.isMuted ? 'unmute' : 'mute';
    this.wenSnackbarOpener.openNotificationSnackbar({ notificationIcon, notificationText });
  }

}
