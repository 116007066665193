import { Injectable } from '@angular/core';
import { ConnectionMonitor } from './types';
import { SocketIoService } from '@portal/wen-backend-api';
import { map, shareReplay, startWith } from 'rxjs';
import { smartDistinctUntilChanged } from '@portal/wen-components';


@Injectable()
export class HealthStatusMonitor implements ConnectionMonitor {
    constructor(private readonly socketIoService: SocketIoService) { }

    public readonly isOnline$ = this.socketIoService.system.healthStatus.listen.pipe(
        map(value => value.systemCoreUp),
        smartDistinctUntilChanged(),
        startWith(true),
        shareReplay(1)
    );
}
