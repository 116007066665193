import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { SelectionOption } from '@portal/wen-components';
import { distinctUntilChanged, map, Observable, shareReplay } from 'rxjs';
import { ChannelEntity } from '../../../../../core/store/channel/channel.state';
import { RootState } from '../../../../../core/store/root/public-api';
import { selectChannelInvitableSelection } from '../../../../../core/store/smartdesign/smartdesign.selectors';
import { ImportedSelectionEntity } from '../../../../../core/store/smartdesign/smartdesign.state';
import { selectProgressState } from '../../../../../core/store/ui/ui.selectors';
import { ProgressState } from '../../../../../core/store/ui/ui.state';
import { FormStoreService } from '../../../../../shared/form-store/form-store.service';

@Component({
  selector: 'wen-invite-to-channel',
  templateUrl: './invite-to-channel.component.html',
  styleUrls: ['./invite-to-channel.component.scss']
})
export class InviteToChannelComponent implements OnInit {

  hasProgress$: Observable<ProgressState>;

  hasNetworkInviteEntity$: Observable<boolean>;
  importedInviteeData$: Observable<ImportedSelectionEntity[]>;
  selectedChannels: SelectionOption<ChannelEntity>[];
  channelsSelectionModel: SelectionModel<SelectionOption<ChannelEntity>>;

  inviteFormGroup = new FormGroup({
    importedInvitees: new FormControl([]),
    emailBody: new FormControl(''),
    selectedChannels: new FormControl({ value: '', disabled: true }),
  });

  constructor(
    private store: Store<RootState>,
    private formStoreService: FormStoreService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    const data$ = this.store.pipe(
      select(selectChannelInvitableSelection),
      shareReplay(1)
    );
    this.hasNetworkInviteEntity$ = data$.pipe(
      map(data => {
        return Boolean(data?.networkInviteableEntities?.length);
      })
    );
    this.importedInviteeData$ = data$.pipe(
      map((data) => {
        if (!data) {
          return [];
        }
        return [
          ...data.channelInviteableEntities,
          ...data.networkInviteableEntities
        ];
      })
    );
    this.channelsSelectionModel = new SelectionModel(true);
    this.selectedChannels = this.activatedRoute.snapshot.data.selectedChannels;
    this.hasProgress$ = this.store.pipe(
      select(selectProgressState),
      distinctUntilChanged(),
    );
    this.formStoreService.initializeForm({
      selectedChannels: this.selectedChannels
    });
  }

}
