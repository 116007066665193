import { EmbedDTOTypes } from '../embed/embed';

export interface ForwardContent {
  content?: string;
  embeds?: EmbedDTOTypes[];
}

export interface ForwardContentLink {
  link: string;
}

interface ForwardDataBase<T extends ForwardContentLink | ForwardContent> {
  /** The id of the target item which is forwarded */
  id: string;
  /** The context which the target item is in */
  context: ForwardContext;
  /** The details of the target item */
  data: T;
}

export type ForwardData2 = ForwardDataBase<ForwardContent> | ForwardDataBase<ForwardContentLink>;
export type ForwardData = ForwardDataBase<ForwardContent>;

export enum ForwardContext {
  CHANNEL_MESSAGE = 'CHANNEL_MESSAGE',
  EVENT = 'EVENT',
  CHAT_MESSAGE = 'CHAT_MESSAGE',
  CHANNEL = 'CHANNEL',
  TEXTVIEW = 'TEXTVIEW'
}

export const isLinkForwardData = (forward: ForwardData): forward is ForwardDataBase<ForwardContent> => {
  const content = forward.data as ForwardContentLink;
  return Boolean(content?.link);
};
