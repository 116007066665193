import { Injectable } from '@angular/core';
import { generateDeterministicId } from '@portal/wen-backend-api';
import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';
import { DateUtil } from '../../../core/common/date/date-util';
import { Filterable } from '../../../shared/components/filter-view/models/filter-item';

export enum DateCategory {
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  THIS_WEEKEND = 'this_weekend',
  THIS_WEEK = 'this_week',
  THIS_MONTH = 'this_month',
  NEXT_WEEK = 'next_week',
  NEXT_MONTH = 'next_month'
}

@Injectable()
export class EventDatesLoader {

  constructor() {
    this.loadEventDateFilters();
  }

  public loadedDateFilters: Filterable<DateTime[]>[] = [];
  public readonly loadedDateFilters$ = new BehaviorSubject(this.loadedDateFilters);

  loadEventDateFilters() {
    const dateFilters = [
      { name: 'EVENT_FILTER_DATES_TODAY', dateRange: () => DateUtil.getTodayAsDateRange() },
      { name: 'EVENT_FILTER_DATES_TOMORROW', dateRange: () => DateUtil.getTomorrowAsDateRange() },
      { name: 'EVENT_FILTER_DATES_THIS_WEEKEND', dateRange: () => DateUtil.getThisWeekendRange() },
      { name: 'EVENT_FILTER_DATES_THIS_WEEK', dateRange: () => DateUtil.getThisWeekRange() },
      { name: 'EVENT_FILTER_DATES_THIS_MONTH', dateRange: () => DateUtil.getThisMonthRange() },
      { name: 'EVENT_FILTER_DATES_NEXT_WEEK', dateRange: () => DateUtil.getNextWeekRange() },
      { name: 'EVENT_FILTER_DATES_NEXT_MONTH', dateRange: () => DateUtil.getNextMonthRange(), }
    ];
    const baseDateFilters = this.loadedDateFilters.length > 0 ? this.loadedDateFilters : dateFilters;
    this.loadedDateFilters = baseDateFilters.map(dF => {
      return {
        ...dF,
        data: dF.dateRange(),
        id: dF?.id || generateDeterministicId(dF.name)
      };
    });
  }

}
