import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { handleNewNativeNotification } from '../../store/notification/notification.actions';
import { WenNativeApi } from '@portal/wen-native-api';
import { NativeNotificationPayload, NativeNotificationRegistrationPayload } from '@portal/wen-native-api';

@Injectable()
export class NativeNotificationHandler {

  constructor(
    private wenNativeApi: WenNativeApi,
    private store: Store,
    private ngZone: NgZone
  ) { }

  handleNotification(payload: NativeNotificationPayload, checkUserInteraction = true) {
    if (!payload || !payload.data || (checkUserInteraction && !payload.userInteraction)) {
      return;
    }

    this.ngZone.run(() => this.store.dispatch(handleNewNativeNotification({ notificationData: payload.data })));
  }

  getInitialNotificationData(): Observable<NativeNotificationPayload> {
    return this.wenNativeApi.getInitialNotificationData();
  }

  unregisterFromNotifications() {
    this.wenNativeApi.unregisterFromNotifications();
  }

  registerToNotifications(): Observable<NativeNotificationRegistrationPayload> {
    return this.wenNativeApi.registerToNotifications();
  }
}
