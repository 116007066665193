import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ActionMenuItem, PollCreationComponent, OverlayManager } from '@portal/wen-components';
import { distinctUntilChanged, map } from 'rxjs';
import { selectCurrentChannelDraftMessage } from '../../../../core/store/channel/channel.selectors';
import { RootState } from '../../../../core/store/root/public-api';

@Injectable()
export class PollCreationAction implements ActionMenuItem {

  label = '';
  icon = 'chart';

  isVisible$ = () => this.store.pipe(
    select(selectCurrentChannelDraftMessage),
    map(currentDraft => Boolean(currentDraft?.id)),
    distinctUntilChanged(),
    map(isEditingMessage => !isEditingMessage),
  );

  constructor(
    private store: Store<RootState>,
    private overlayManager: OverlayManager,
    private translateService: TranslateService,
  ) {
    this.label = this.translateService.instant('CONTEXT_MENU_ACTION_POLL_LABEL');
  }

  execute() {
    this.overlayManager.dialog.openInputDialog(PollCreationComponent, null, { autoFocus: false });
  }
}
