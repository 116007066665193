import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SocketIoService } from '@portal/wen-backend-api';
import { SelectionOption } from '@portal/wen-components';
import { first, map, Observable, shareReplay } from 'rxjs';
import { ChannelEntity } from '../../../../../core/store/channel/channel.state';
import { AppHeaderController } from '../../../../../frame/components/header/components/app-header/app-header-controller';
import { FormStoreService } from '../../../../../shared/form-store/form-store.service';

@Component({
  selector: 'wen-invite-to-channel-selector',
  templateUrl: './invite-to-channel-selector.component.html',
  styleUrls: ['./invite-to-channel-selector.component.scss']
})
export class InviteToChannelSelectorComponent implements OnInit, OnDestroy {

  channelSelectorFormGroup = new FormGroup({
    selectedChannels: new FormControl('', Validators.required),
  });

  isLoaded = false;
  availableChannels$: Observable<SelectionOption<ChannelEntity>[]>;
  channelsSelectionModel: SelectionModel<SelectionOption<ChannelEntity>>;

  get hasError() {
    const hasValue = this.channelSelectorFormGroup.controls?.selectedChannels?.value?.length;
    const isTouched = this.channelSelectorFormGroup.controls?.selectedChannels?.touched;
    return !hasValue && isTouched;
  }

  constructor(
    private socketIoService: SocketIoService,
    private formStoreService: FormStoreService,
    private appHeaderController: AppHeaderController,
  ) { }

  ngOnInit(): void {
    this.availableChannels$ = this.socketIoService.channel.listInvitable.acknowledgement$({}).pipe(
      first(),
      map((channels) => {
        if (!channels.length) {
          return null;
        }
        return this.toSelectionOption(channels);
      }),
      shareReplay(1)
    );
    this.availableChannels$.pipe(
      first()
    ).subscribe((availableChannels) => {
      if (!availableChannels?.length) {
        this.appHeaderController.setRightCommandEnablement(false);
      }
      this.isLoaded = true;
    });
    this.formStoreService.initializeForm();
    this.channelsSelectionModel = new SelectionModel(true);
  }

  private toSelectionOption(channels: ChannelEntity[]): Partial<SelectionOption<ChannelEntity>[]> {
    return channels.map(channel => {
      return {
        id: channel.id,
        text: channel.title,
        value: channel,
        icon: channel.icon
      };
    });
  }

  ngOnDestroy(): void {
    this.appHeaderController.setRightCommandEnablement(true);
  }

}
