import { Component, Input, forwardRef } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'wen-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DateFieldComponent),
    multi: true
  }]
})
export class DateFieldComponent implements ControlValueAccessor {
  private disabled: boolean;
  private value: Date;
  onChange: (value: Date) => void = (_: Date) => {};
  onTouched: () => void = () => {};

  @Input() placeholder: string;
  @Input() min: Date;
  @Input() max: Date;
  @Input() formControl: FormControl;
  @Input() showIcon = true;
  @Input() iconName = 'events_inactive';
  @Input() iconColor = '#0000008a';

  writeValue(value: string): void {
    this.value = new Date(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onDateChange(dateChangeEvent: MatDatepickerInputEvent<Date>) {
    this.onChange(dateChangeEvent.value);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
