import { inject, Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { fetchDiscoveryEvents } from '../../../../core/store/events/event.actions';
import { removeFilter, replaceActiveFilter } from '../../../../core/store/filter/filter.actions';
import { FilterEntityType, FilterType } from '../../../../core/store/filter/models/filter';
import { RootState } from '../../../../core/store/root/public-api';
import { SectionProps } from '../../../../core/common/util/section.model';
import { FILTER_QUERY_PARAM } from '../../../../core/services/navigation/query-params';
import { Filterable } from '../../../../shared/components/filter-view/models/filter-item';

export const discoveryEventListDataResolverFn = () => {
  inject(DiscoveryEventListDataResolver).resolve();
};

@Injectable()
export class DiscoveryEventListDataResolver  {

  constructor(private store: Store<RootState>, private router: Router) { }

  resolve(): void {
    const queryParams: Params = this.router.getCurrentNavigation().extras.queryParams;
    if (!!queryParams && queryParams[FILTER_QUERY_PARAM] === 'clearFilter') {
      this.store.dispatch(removeFilter({ filterEntityType: FilterEntityType.EVENT_LISTS }));
      this.store.dispatch(fetchDiscoveryEvents({}));
    } else if (!!queryParams && queryParams[FILTER_QUERY_PARAM]) {
      const props: SectionProps = queryParams[FILTER_QUERY_PARAM];
      this.store.dispatch(replaceActiveFilter({
        filterEntityType: props.filterEntityType,
        filter: {
          filterId: props.filterId,
          filterValue: props.filterRef as Filterable,
          filterType: FilterType.SELECT
        }
      }));
    } else {
      this.store.dispatch(fetchDiscoveryEvents({}));
    }
  }
}
