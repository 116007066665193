import { Component } from '@angular/core';

@Component({
  selector: 'wen-chat-view-desktop',
  templateUrl: './chat-view-desktop.component.html',
  styleUrls: ['./chat-view-desktop.component.scss']
})
export class ChatViewDesktopComponent {

  constructor() { }

}
