import { Injectable, inject } from '@angular/core';
import { EncryptedMessageEventResponses, MessageEvent, MessageEventResponses } from '@portal/wen-backend-api';
import { Observable, forkJoin, of, map } from 'rxjs';
import { isNullOrUndefined } from '../../../common/operators/null-check-util';
import { ChatMessageEntity } from '../../../store/chat/chat.state';
import { isScheduledEvent } from '../message-event/message-event-helper';
import { DecryptedMessageResultWrapper } from './decryption-result-base';
import { EncryptedEventModifier } from './encrypted-event-modifier';
import { MessageDecryptor } from './message-decryptor';
import { ScheduledMessageDecryptor } from './scheduled-message-decryptor';

@Injectable()
export class MessageEventDecryptor<TENTITY extends ChatMessageEntity = ChatMessageEntity> {

  private messageDecryptor = inject(MessageDecryptor);
  private scheduledMessageDecryptor = inject(ScheduledMessageDecryptor);
  private encryptedEventModifier = inject(EncryptedEventModifier);

  decryptMessageEvents(events: MessageEvent<MessageEventResponses>[]): Observable<DecryptedMessageResultWrapper<TENTITY>[]> {
    const transformedEvents = this.encryptedEventModifier.transformEncryptedEvents(events);
    const decryptRequests$ = transformedEvents.map(event => {
      if (isScheduledEvent(event)) {
        return this.scheduledMessageDecryptor.decryptScheduledEvent(event);
      }
      return this.messageDecryptor.decryptRoomEventWithRetry(event as MessageEvent<EncryptedMessageEventResponses>);
    });
    const result$: Observable<DecryptedMessageResultWrapper<TENTITY>[]> = decryptRequests$?.length ? forkJoin(decryptRequests$) : of([]);
    const filteredResult$ = result$.pipe(
      map((decryptions) => decryptions.filter(decryption => !isNullOrUndefined(decryption))),
    );
    return filteredResult$;
  }

}
