import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PortalFieldModule } from '@portal/ui-kit/field';
import { HeaderModule } from '../../../../../frame/components/header/header.module';
import { SharedModule } from '../../../../../shared/shared.module';
import { ChannelInfoEditComponent } from './channel-info-edit.component';

@NgModule({
  imports: [
    SharedModule,
    HeaderModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    PortalFieldModule,
    MatSlideToggleModule,
  ],
  declarations: [
    ChannelInfoEditComponent,
  ],
  exports: [
    ChannelInfoEditComponent,
  ]
})
export class ChannelInfoEditModule { }
