import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { EmbedDTOType } from '@portal/wen-backend-api';
import { Subject, first, map, switchMap, takeUntil } from 'rxjs';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { FileSelectorHandler } from '../../../../../core/services/util/file-selector-handler';
import { MediaValidator } from '../../../../../core/services/util/media-validator';
import { selectCurrentChannel } from '../../../../../core/store/channel/channel.selectors';
import { PendingEmbedType } from '../../../../../core/store/common/models';
import { ChannelPostEditorChangedValues, ChannelPostEditorFormChangedValues } from '../../../../../core/store/form/types/channel-post-editor.types';
import { RootState } from '../../../../../core/store/root/public-api';
import { ExtendedTextEditorComponent, ExtendedTextEditorModel } from '../../../../../shared/components/extended-text-editor/extended-text-editor.component';
import { FileDropOverlayComponent } from '../../../../../shared/components/file-drop-overlay/file-drop-overlay.component';
import { getMediaType } from '../../../../../shared/components/message-box/components/embeds/embed-media/media-utils';
import { FORM_INVALID_ERROR } from '../../../../../shared/directives/form-controlled-header/form-controlled-header.directive';
import { FormStoreService } from '../../../../../shared/form-store/form-store.service';
import { ChannelPostEditorResolveData } from '../../guards/channel-post-editor-edit.resolver';

const initialValue: ExtendedTextEditorModel = {
  textContent: '',
  mediaEmbed: null,
};

export const formValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const postContent = (control.value as ChannelPostEditorFormChangedValues)?.postContent;
    if (!postContent) {
      return FORM_INVALID_ERROR;
    }
    const { embedLocation, linkEmbed, textContent, mediaEmbed } = postContent;
    const hasLocation = embedLocation;
    const hasValidValue = textContent || linkEmbed || mediaEmbed;
    if (!hasLocation || !hasValidValue) {
      return FORM_INVALID_ERROR;
    }
    return null;
  };
};

@Component({
  selector: 'wen-channel-post-editor',
  templateUrl: './channel-post-editor.component.html',
  styleUrls: ['./channel-post-editor.component.scss']
})
export class ChannelPostEditorComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  @ViewChild(ExtendedTextEditorComponent) extendedTextEditor: ExtendedTextEditorComponent;

  overlayContent = FileDropOverlayComponent;
  private addMediaFile = new Subject<File>();

  channelMessageFormGroup = new FormGroup({
    postContent: new FormControl(initialValue),
  }, {
    validators: [formValidator()]
  });

  get postContentControl() {
    return this.channelMessageFormGroup.controls.postContent;
  }

  get postContentControlValue() {
    return this.postContentControl?.value as ExtendedTextEditorModel;
  }

  get hasMedia() {
    return Boolean(this.postContentControlValue?.mediaEmbed);
  }

  get hasLocation() {
    return Boolean(this.postContentControlValue?.embedLocation);
  }

  private readonly channelId$ = this.store.pipe(
    select(selectCurrentChannel),
    map((channel) => channel.id)
  );

  constructor(
    private store: Store<RootState>,
    private formStoreService: FormStoreService<ChannelPostEditorChangedValues>,
    private fileSelector: FileSelectorHandler,
    private mediaValidator: MediaValidator,
    private cdr: ChangeDetectorRef,
    private navigationHelper: WenNavigationHelper,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    const resolveData = this.activatedRoute.snapshot.data as ChannelPostEditorResolveData;
    this.formStoreService.initializeForm(resolveData.initialValue);

    this.addMediaFile.pipe(
      switchMap(file => this.mediaValidator.validateMedia(file)),
      takeUntil(this.onDestroy$)
    ).subscribe((file: File) => {
      if (this.postContentControl.value.mediaEmbed) {
        this.extendedTextEditor.handleMediaDismiss();
      }
      const currentValue = this.postContentControl.value;
      const newValue: ExtendedTextEditorModel = {
        ...currentValue,
        mediaEmbed: {
          id: null,
          type: EmbedDTOType.MEDIA,
          subType: getMediaType(file),
          file,
          pendingType: PendingEmbedType.UNSAFE
        }
      };
      this.patchFormValue(newValue);
    });
  }

  uploadMedia() {
    if (this.hasMedia) {
      return;
    }
    this.fileSelector.openFileSelector(this.mediaValidator.allowedMediaExtensions).subscribe(file => {
      this.addMediaFile.next(file);
    });
  }

  onFilesDropped(files: FileList) {
    this.addMediaFile.next(files[0]);
  }

  private patchFormValue(newValue: ExtendedTextEditorModel) {
    this.postContentControl.markAsTouched();
    this.postContentControl.markAsDirty();
    this.channelMessageFormGroup.patchValue({
      postContent: newValue
    });
    this.cdr.detectChanges();
  }

  addLocation() {
    if (this.hasLocation) {
      return;
    }
    this.channelId$.pipe(
      first()
    ).subscribe(channelId => {
      this.navigationHelper.navigateToChannelPostLocationSelector(channelId);
    });
  }

  formClicked() {
    this.extendedTextEditor.handleFocus();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
