import { ChannelState, FeaturedEntity } from '../channel.state';

export const featuredDataHelper = {
  updateOne: (
    featuredData: Partial<FeaturedEntity>,
    channelState: ChannelState
  ) => {
    const existingFeaturedData = channelState.featuredData;
    return { ...existingFeaturedData, ...featuredData };
  }
};
