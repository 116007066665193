import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { Observable, switchMap } from 'rxjs';
import { RootState } from '../../root/public-api';
import { fetchEventDetail, setEventSubscriptions, updateEventDetails } from '../event.actions';
import { extractEntitiesFromEventList } from '../utils/event-utils';

export const createEventDetailEffect = (
  store: Store<RootState>,
  actions$: Actions,
  eventDetail$: Observable<any>,
  socketIoService: Pick<SocketIoService, 'events'>
) => {
  return createEffect(() => actions$.pipe(
    ofType(fetchEventDetail),
    switchMap(({ eventId }) => {
      socketIoService.events.detail.emit(eventId);
      return eventDetail$.pipe(
        switchMap(eventDetail => {
          const extractedData = extractEntitiesFromEventList([eventDetail]);
          const events = extractedData.map(d => d.event);
          const subscriptions = extractedData.map(d => d.subscription);
          return [
            setEventSubscriptions({ subscriptions }),
            updateEventDetails({ events })
          ];
        })
      );
    })
  ));
};
