import { NgModule } from '@angular/core';
import { ChannelInfoEditModule } from './components/channel-info-edit/channel-info-edit.module';
import { ChannelInteractionSettingsModule } from './components/channel-interaction-settings/channel-interaction-settings.module';
import { ChannelRestrictionSettingsModule } from './components/channel-restriction-settings/channel-restriction-settings.module';
import { ChannelVisibilitySettingsModule } from './components/channel-visibility-settings/channel-visibility-settings.module';

@NgModule({
  imports: [
    ChannelInfoEditModule,
    ChannelVisibilitySettingsModule,
    ChannelInteractionSettingsModule,
    ChannelRestrictionSettingsModule,
  ],
})
export class ChannelEditModule { }
