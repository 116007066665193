

import { Injectable } from '@angular/core';
import { UserProfileSettings, UserUpdatePayload } from '@portal/wen-backend-api';
import { of } from 'rxjs';
import { FormValueConverter } from '../../../../../shared/form-store/form-store.providers';

interface UserSettingsFormModel {
  visibility: UserProfileSettings;
}

@Injectable()
export class UserSettingsFormValueConverter extends FormValueConverter<UserSettingsFormModel, Partial<UserUpdatePayload>> {

  convertToFormModel(value) {
    return of({ visibility: { ...value } });
  }

  convertToServerModel(userSettings: UserSettingsFormModel) {
    const payload: Partial<UserUpdatePayload> = {
      ...userSettings.visibility
    };
    return of(payload);
  }

}
