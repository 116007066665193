import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, distinctUntilChanged, first, map, scan, startWith } from 'rxjs';
import { CrmChatMessageSender } from '../../../../../core/services/chat/message-sender/crm-chat-message-sender';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { DistributionDataImporter } from '../../../../../core/services/smartdesign/chat-distribution/distribution-data-importer';
import { RootState } from '../../../../../core/store/root/public-api';
import { selectProgressState } from '../../../../../core/store/ui/ui.selectors';
import { ProgressState } from '../../../../../core/store/ui/ui.state';

@Component({
  selector: 'wen-distribute-chat',
  templateUrl: './distribute-chat.component.html',
  styleUrls: ['./distribute-chat.component.scss']
})
export class DistributeChatComponent implements OnInit {
  progressState$: Observable<ProgressState>;
  recipientCount$: Observable<number>;
  processedRecipients$: Observable<number>;
  imageSize = 88;

  constructor(
    private store: Store<RootState>,
    private navigationHelper: WenNavigationHelper,
    private crmChatMessageSender: CrmChatMessageSender,
    private distributionDataImporter: DistributionDataImporter,
  ) { }

  ngOnInit() {
    this.progressState$ = this.store.pipe(
      select(selectProgressState),
      distinctUntilChanged(),
    );
  }

  sendMessage() {
    const { singleMessageSendFinished$ } = this.crmChatMessageSender.distributeCRMChatMessages();
    const asd = singleMessageSendFinished$.pipe(
      map(() => 1)
    );
    this.processedRecipients$ = asd.pipe(
      scan((acc, val) => {
        return acc + val;
      }, 0),
      startWith(0)
    );
    this.recipientCount$ = this.distributionDataImporter.loadDistributionChatMessage().pipe(
      first(),
      map(({ recipientCount }) => recipientCount)
    );
  }

  navigateToCrmProfileReadOnlyView() {
    this.navigationHelper.navigateToCrmProfileReadOnlyView();
  }

}
