import { Component, HostBinding, Input, ElementRef, Renderer2, OnInit } from '@angular/core';
import { MediaType } from '@portal/wen-backend-api';
import { filter, first, map, Observable, ReplaySubject, shareReplay } from 'rxjs';
import { isNullOrUndefined } from '../../../../../../../../core/common/operators/null-check-util';
import { LazyLoadParams } from '../../../../../../../directives/lazy-load/lazy-load.directive';
import { ThumbnailProvider } from '../../directives/thumbnail-evaluator.directive';
import { MediaViewParams } from '../../models/models';

@Component({
  selector: 'wen-embed-upload',
  templateUrl: './embed-upload.component.html',
  styleUrls: ['./embed-upload.component.scss']
})
export class EmbedUploadComponent implements OnInit {

  mediaType$ = new ReplaySubject<MediaType>(1);
  localThumbnail$: Observable<{ hasImage: boolean; params: LazyLoadParams } | void>;
  thumbnailParams$: Observable<LazyLoadParams>;
  processing: boolean;

  @HostBinding('class.wen-local-embed') className = true;

  @Input() set config(params: MediaViewParams) {
    this.mediaType$.next(params.mediaType);
    this.processing = params.embedType === MediaType.PROCESS;
  }

  constructor(private thumbnailProvider: ThumbnailProvider, private renderer: Renderer2, private elementRef: ElementRef<HTMLElement>) { }

  ngOnInit() {
    this.handleThumbnail();
  }

  private handleThumbnail() {
    const arrivingThumbnail$ = this.thumbnailProvider.thumbnail$.pipe(
      shareReplay(1)
    );
    this.thumbnailParams$ = arrivingThumbnail$.pipe(
      filter(({ url, imageElement }) => isNullOrUndefined(imageElement) && !!url),
      map(({ url, scaledDimensions: { width, height } }) => {
        return {
          src: url,
          originalWidth: width,
          originalHeight: height
        };
      })
    );

    arrivingThumbnail$.pipe(
      filter(({ imageElement }) => !isNullOrUndefined(imageElement)),
      first()
    ).subscribe(({ imageElement, scaledDimensions, usedDimensions, horizontalOffset }) => {
      const canvasEl: HTMLCanvasElement = this.renderer.createElement('canvas');
      canvasEl.width = scaledDimensions.width;
      canvasEl.height = scaledDimensions.height;

      canvasEl.getContext('2d').drawImage(
        imageElement,
        horizontalOffset, 0,
        usedDimensions.width, usedDimensions.height,
        0, 0,
        scaledDimensions.width, scaledDimensions.height);
      this.renderer.appendChild(this.elementRef.nativeElement, canvasEl);
    });
  }

}
