import { Injectable } from '@angular/core';
import { BackNavigationBehavior, BackNavigationBehaviorResult } from './back-navigation-behavior';
import { WenBreakpointObserver } from '@portal/wen-components';
import { WenNavigationHelper } from '../../types';

@Injectable()
export class ProfileSubMenuBackNavigationBehavior implements BackNavigationBehavior {

  constructor(
    private breakpointObserver: WenBreakpointObserver,
    private navigationHelper: WenNavigationHelper) {
  }

  execute(): BackNavigationBehaviorResult {
    if (this.breakpointObserver.isDesktopStyleDevice) {
      this.navigationHelper.navigateToUserProfile({ replaceUrl: true });
      return { canHandle: true };
    } else {
      return { canHandle: false };
    }
  }
}
