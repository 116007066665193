import { Injectable } from '@angular/core';
import { SocketConnectionState, SocketIoService } from '@portal/wen-backend-api';
import { ConnectionMonitor } from './types';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap } from 'rxjs';
import { isAppInBackground } from './utils';

@Injectable()
export class SocketConnectionMonitor implements ConnectionMonitor {
  public readonly isOnline$ = this.socketIoService.isConnected$.pipe(
    distinctUntilChanged(),
    switchMap(() => {
      return isAppInBackground.pipe(
        filter(isInbackground => !isInbackground),
      );
    }),
    switchMap(() => this.socketIoService.waitForAutoReconnect(3)),
    map(reconnectResult => reconnectResult.socketState === SocketConnectionState.CONNECTED),
    startWith(true),
    shareReplay(1),
  );
  constructor(private readonly socketIoService: SocketIoService) { }
}
