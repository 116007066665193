import { Injectable } from '@angular/core';
import { DataContextHelper } from '../../../services/util/data-context-helper';
import { createEffect } from '@ngrx/effects';
import { DataContext } from '../../../common/types/data-context';
import { updateAppExploreSectionLoadingState, updateChannelExploreSectionLoadingState, updateEventExploreSectionLoadingState } from '../sections.actions';
import { LoadingState } from '../../../common/types/store-loading-state';
import { EMPTY, mergeMap } from 'rxjs';

@Injectable()
export class SectionLoadingStateEffects {

  setLoadingState$ = createEffect(() => this.dataContextHelper.onDataContextChanges().pipe(
    mergeMap(({ fromContext }) => {
      switch(fromContext) {
        case DataContext.CHANNEL:
          return [updateChannelExploreSectionLoadingState({ loadingState: LoadingState.NOT_LOADED })];
        case DataContext.APP:
          return [updateAppExploreSectionLoadingState({ loadingState: LoadingState.NOT_LOADED})];
        case DataContext.EVENT:
          return [updateEventExploreSectionLoadingState({ loadingState: LoadingState.NOT_LOADED })];
        case DataContext.HOME:
          return [
            updateChannelExploreSectionLoadingState({ loadingState: LoadingState.NOT_LOADED }),
            updateAppExploreSectionLoadingState({ loadingState: LoadingState.NOT_LOADED}),
            updateEventExploreSectionLoadingState({ loadingState: LoadingState.NOT_LOADED }),
          ];
        default:
          return EMPTY;
      }
    })
  ));

  constructor(
    private readonly dataContextHelper: DataContextHelper
  ) { }
}
