import { InjectionToken } from '@angular/core';
import { TranslateModuleConfig } from '@ngx-translate/core';
import { WenLanguage } from '@portal/wen-backend-api';

export interface WenTranslationConfig extends TranslateModuleConfig {
  /**
   * project prefix for loaded files. The prefix is required in order to avoid conflicts in the Passolo translations
   **/
  projectPrefix: string;
  /**
   * The supportedLanguages tells an app how should it manage the language related items. When nothing is defined, ONLY the german
   * language is supported by an app.
   **/
  supportedLanguages: WenLanguage[];
}

export const TRANSLATION_CONFIG = new InjectionToken<WenTranslationConfig>('TRANSLATION_CONFIG');
