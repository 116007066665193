import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SocketIoService } from '@portal/wen-backend-api';
import { filter, map, tap } from 'rxjs';
import { commentDeleteResponse, deleteComment } from '../comments.actions';

export const createDeleteCommentEffect = (
  actions$: Actions,
  socketIoService: Pick<SocketIoService, 'comment'>
) => {
  return createEffect(() =>
    actions$.pipe(
      ofType(deleteComment),
      tap(({ commentId }) => {
        socketIoService.comment.delete.emit({ id: commentId });
      })
    ), { dispatch: false }
  );
};

export const createCommentDeleteResponseEffect = (
  socketIoService: Pick<SocketIoService, 'comment'>
) => {
  return createEffect(() =>
    socketIoService.comment.delete.listen.pipe(
      filter((response) => response.success),
      map((response) => {
        return commentDeleteResponse(response);
      })
    )
  );
};
