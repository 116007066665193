import { animate, style, transition, trigger } from '@angular/animations';

export const fadeInOutAnimation = (
    enterSpeed: string,
    enterEasing: string,
    leaveSpeed = enterSpeed,
    leaveEasing = enterEasing,
    enterOpacity = 1,
    leaveOpacity = 0
  ) => {
  return trigger('fadeInOut', [
    transition(':enter', [
      style({ opacity: leaveOpacity }),
      animate(`${enterSpeed} ${enterEasing}`, style({ opacity: enterOpacity })),
    ]),
    transition(':leave', [
      style({ opacity: enterOpacity }),
      animate(`${leaveSpeed} ${leaveEasing}`, style({ opacity: leaveOpacity })),
    ])
  ]);
};
