import { createFeature, createReducer, on } from '@ngrx/store';
import { contactsFeatureKey } from './constants';
import { updateContactsOpenRequestCount } from './contacts.actions';
import { ContactsState } from './contacts.state';

export const contactsInitialState: ContactsState = {
  openRequestCount: 0
};

export const contactsFeature = createFeature({
  name: contactsFeatureKey,
  reducer: createReducer(
    contactsInitialState,
    on(updateContactsOpenRequestCount, (state, { openRequestCount }) => {
      return {
        ...state,
        openRequestCount
      };
    }),
  )
});
