import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MediaContextTypes, SocketIoService } from '@portal/wen-backend-api';
import { first, Observable, of, switchMap } from 'rxjs';
import { selectorWithParam } from '../../common/util/selector-with-param';
import { EmbeddedMedia } from '../../store/channel/channel.state';
import { selectFileEmbed } from '../../store/chat/chat.selectors';
import { RootState } from '../../store/root/public-api';

@Injectable()
export class MediaRetriever {

  constructor(
    private socketIoService: SocketIoService,
    private store: Store<RootState>,
  ) { }

  getMedia(media: EmbeddedMedia, contextId: string): Observable<EmbeddedMedia & { errorMessage?: string }> {
    return this.store.pipe(
      selectorWithParam(selectFileEmbed, media?.uploadId),
      first(),
      switchMap(fileEmbed => {
        if (!!fileEmbed) {
          return of({ ...media, thumbnailUrl: fileEmbed, uploaded: false });
        }
        if (!media?.uploadId) {
          return of(media);
        }
        return this.getBy(media.uploadId, contextId);
      })
    );
  }

  private getBy(uploadId: string, contextId: string): Observable<EmbeddedMedia & { errorMessage?: string }> {
    return this.socketIoService.media.get.acknowledgement$({
      uploadId,
      contextId,
      contextType: MediaContextTypes.CHAT_MESSAGE
    });
  }

}
