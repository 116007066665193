import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EmbeddedLinkDTO, EmbeddedLocationDTO, EmbeddedMediaDTO } from '@portal/wen-backend-api';
import { PendingEmbedType } from '../../../core/store/common/models';

export type ExtendedTextEditorMediaModel = EmbeddedMediaDTO & { pendingType?: PendingEmbedType };

export interface ExtendedTextEditorModel {
  textContent?: string;
  mediaEmbed?: ExtendedTextEditorMediaModel;
  linkEmbed?: EmbeddedLinkDTO;
  embedLocation?: EmbeddedLocationDTO;
}

@Component({
  selector: 'wen-extended-text-editor',
  templateUrl: './extended-text-editor.component.html',
  styleUrls: ['./extended-text-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ExtendedTextEditorComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExtendedTextEditorComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @Input() inputPlaceholder: string;

  @ViewChild('textArea') textAreaElement: ElementRef<HTMLTextAreaElement>;

  private onChange: (value: ExtendedTextEditorModel) => void = (_: ExtendedTextEditorModel) => { };
  private onTouched: () => void = () => { };

  private isDirty = false;
  private modelValue: ExtendedTextEditorModel;

  get textContent() {
    return this.modelValue?.textContent;
  }

  get locationData() {
    return this.modelValue?.embedLocation?.locationData;
  }

  get isLinkPreviewDisabled() {
    return Boolean(this.mediaData);
  }

  get mediaData() {
    return this.modelValue?.mediaEmbed;
  }

  get linkData() {
    if (!this.isDirty) {
      return this.modelValue?.linkEmbed;
    }
    return this.textContent;
  }

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const textAreaElement = this.textAreaElement?.nativeElement;
    if (textAreaElement) {
      setTimeout(() => {
        textAreaElement.focus();
      }, 0);
    }
  }

  private updateValueFromView(newValue: ExtendedTextEditorModel) {
    this.isDirty = true;
    this.modelValue = newValue;
    this.onChange(newValue);
  }

  writeValue(newValue: ExtendedTextEditorModel): void {
    this.modelValue = newValue;
    const fileData = this.modelValue?.mediaEmbed;
    if (fileData) {
      this.cdr.detectChanges();
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      throw new Error('Disabling ExtendedTextEditorComponent is not supported!');
    }
  }

  registerOnChange(fn: (value: any) => void): void { this.onChange = fn; }
  registerOnTouched(fn: () => void): void { this.onTouched = fn; }

  onTextContentInput() {
    const newTextValue = this.textAreaElement.nativeElement.value;
    const newValue: ExtendedTextEditorModel = {
      ...this.modelValue,
      textContent: newTextValue
    };
    this.updateValueFromView(newValue);
  }

  handleMediaDismiss() {
    const newValue: ExtendedTextEditorModel = {
      ...this.modelValue,
      mediaEmbed: null
    };
    this.updateValueFromView(newValue);
  }

  handleLinkEmbedChange(embed: EmbeddedLinkDTO) {
    const newValue: ExtendedTextEditorModel = {
      ...this.modelValue,
      linkEmbed: embed
    };
    this.updateValueFromView(newValue);
  }

  handleLocationDismiss() {
    const newValue: ExtendedTextEditorModel = {
      ...this.modelValue,
      embedLocation: null
    };
    this.updateValueFromView(newValue);
  }

  handleFocus() {
    const textArea: HTMLTextAreaElement = this.textAreaElement.nativeElement;
    textArea.focus();
  }

}
