import { AfterViewInit, Component, HostBinding, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, first, map, Observable, Subject, takeUntil } from 'rxjs';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { selectCurrentChannel } from '../../../../../core/store/channel/channel.selectors';
import { RootState } from '../../../../../core/store/root/public-api';
import { MessageSendFieldDataSource } from '../../providers/message-send-field-data-source';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { fetchScheduledMessagesCount } from '../../../../../core/store/channel/channel.actions';

@Component({
  selector: 'wen-message-scheduler',
  templateUrl: './message-scheduler.component.html',
  styleUrls: ['./message-scheduler.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MessageSchedulerComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class.wen-message-scheduler') className = true;

  private onDestroy$ = new Subject<void>();

  @ViewChild('scheduleDatePicker') datePicker: TimePickerComponent;

  @Input() contextId: string;
  @Input() canClearDate = true;

  scheduledMessagesCount$: Observable<number> = this.store.pipe(
    select(selectCurrentChannel),
    map(channel => {
      return channel?.scheduledMessagesCount;
    })
  );

  disabled$: Observable<boolean>;

  constructor(
    private readonly dataSource: MessageSendFieldDataSource,
    private readonly store: Store<RootState>,
    private readonly navigationHelper: WenNavigationHelper,
  ) { }

  ngOnInit(): void {
    this.dataSource.draftMessage$.pipe(
      filter(message => !Boolean(message)),
      takeUntil(this.onDestroy$),
    ).subscribe(() => {
      this.datePicker.clearDate();
    });

    this.disabled$ = this.dataSource.draftMessage$.pipe(
      map(message => message?.id && !message?.scheduled),
    );
  }

  ngAfterViewInit(): void {
    this.store.dispatch(fetchScheduledMessagesCount({ channelId: this.contextId }));
    this.dataSource.draftMessage$.pipe(
      distinctUntilChanged((m1, m2) => m1?.id === m2?.id),
      takeUntil(this.onDestroy$),
    ).subscribe((message) => {
      if (message?.timestamp) {
        this.datePicker.onDateChange(new Date(message.timestamp));
      } else {
        this.datePicker.clearDate();
      }
    });
  }

  scheduleDateChange(date: Date | null) {
    this.dataSource.draftMessage$.pipe(
      first()
    ).subscribe(draftMessage => {
      const isEdit = Boolean(draftMessage?.id);
      let timestamp = null;
      if (date) {
        date.setSeconds(0);
        date.setMilliseconds(0);
        timestamp = date.toISOString();
      }
      if (isEdit) {
        this.dataSource.updateDraftMessage({ timestamp });
      } else {
        this.dataSource.updateDraftMessage({ timestamp, scheduled: Boolean(timestamp) });
      }
    });
  }

  navigateToScheduledMessages() {
    this.navigationHelper.navigateToChannelScheduledMessages(this.contextId);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
