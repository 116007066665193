import { Injectable } from '@angular/core';
import { FeatureEnablementService } from '../configuration/feature-enablement';
import { combineLatest, debounceTime, filter, map, switchMap } from 'rxjs';
import { WenNavigationHelper } from '../navigation/types';
import { isAppInBackground } from '../connection-monitors/utils';
import { ConnectionMonitorService } from '../connection-monitors/connection-monitor.service';

export const CONNECTION_ERROR_GRACE_PERIOD = 1500;

@Injectable()
export class ConnectionErrorHandler {

  initialize() {
    const { isOnline$ } = this.connectionMonitorService.attach();
    this.featureEnablement.appConfigObs.enableDisconnectErrorPage$.pipe(
      filter(enableDisconnectErrorPage => enableDisconnectErrorPage),
      switchMap(() => combineLatest([isAppInBackground, isOnline$])),
      map(([isInBackground, isOnline]) => {
        return !isOnline && !isInBackground;
      }),
      debounceTime(CONNECTION_ERROR_GRACE_PERIOD),
      filter(Boolean)
    ).subscribe(() => {
      this.navigationHelper.navigateToErrorPage();
    });
  }

  constructor(
    private readonly featureEnablement: FeatureEnablementService,
    private readonly navigationHelper: WenNavigationHelper,
    private readonly connectionMonitorService: ConnectionMonitorService
  ) { }
}
