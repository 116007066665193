<wen-dialog-layout [headerGravity]="'top'" [wen-test-id]="'category-selector'">
  <div wen-dialog-layout-static-header class="wen-selector-header" portal-title>{{ mainLabel }}</div>
  <form
    dialogLayoutContent
    class="wen-selector-form"
    [formGroup]="formGroup"
    wenIosKeyboardSticky>
    <div class="wen-selector-form-group">
      <wen-selection-list
        [formControlName]="'selectedElements'"
        [selectionItems]="possibleItems"
        [selectionModel]="selectionModel"
        [selectionOptionPosition]="'postfix'"
        [selectionOptionBorderType]="'normal'">
      </wen-selection-list>
    </div>
  </form>
  <div wen-dialog-layout-static-footer>
    <mat-dialog-actions class="wen-dialog-actions">
      <button class="wen-dialog-cancel-button" wen-primary-color (click)="onSubmit()">{{ "APPLY_BUTTON" | translate }}</button>
    </mat-dialog-actions>
  </div>
</wen-dialog-layout>