import { Injectable } from '@angular/core';
import { StatusBarAnimationConfig, StatusBarStyle } from '@portal/react-native-api';
import { LightboxImageData, OverlayManager } from '@portal/wen-components';
import { first } from 'rxjs';
import { FeatureEnablementService } from '../../../../../../../core/services/configuration/feature-enablement';
import { WenNativeApi } from '@portal/wen-native-api';
import { WenColorService } from '../../../../../../../core/services/util/wen-color.service';

@Injectable()
export class LightboxService {

  private statusBarColor = '#000';


  constructor(
    private featureEnablementService: FeatureEnablementService,
    private overlayManager: OverlayManager,
    private nativeApi: WenNativeApi,
    private colorService: WenColorService
  ) { }

  openLightbox(images: LightboxImageData[]) {
    const dialogReference = this.overlayManager.dialog.openLightboxDialog({
      images,
      isFileDownloadEnabled: this.featureEnablementService.featureFlagMethods.isEnableFileDownload()
    });
    this.setNativeStyling({
      color: this.statusBarColor,
      animationDuration: 150,
      animationEasing: 'linear'
    }, 'light-content', this.statusBarColor);
    return dialogReference.beforeClosed().pipe(
      first()
    );
  }

  updateNativeStyling() {
    this.setNativeStyling({
      color: this.colorService.headerBackgroundColor,
      animationDuration: 0,
      animationEasing: 'step0'
    }, 'dark-content', this.colorService.headerBackgroundColor);
  }

  private setNativeStyling(
    statusBarAnimation: StatusBarAnimationConfig,
    statusBarStyle: StatusBarStyle,
    navBarColor: string
  ) {
    this.nativeApi.setStatusBarColor(statusBarAnimation);
    this.nativeApi.setStatusBarStyle(statusBarStyle);
    this.nativeApi.setNavbarColor(navBarColor);
  }

}
