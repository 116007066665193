import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SchemaProcessorModule } from '../../../../../core/services/schema/schema-processor.module';
import { FormStoreModule } from '../../../../../shared/form-store/form-store.module';
import { ChannelSettingsComponent } from './channel-settings.component';
import { PersonalWeatherModule } from './components/personal-weather/personal-weather.module';

@NgModule({
  imports: [
    CommonModule,
    FormStoreModule,
    PersonalWeatherModule,
    ReactiveFormsModule,
    SchemaProcessorModule.forRoot()
  ],
  declarations: [ChannelSettingsComponent]
})
export class ChannelSettingsModule { }
