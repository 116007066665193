import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AUTH_SERVER_REDIRECT_URL_QUERY_PARAM, AUTH_SERVER_SUGGESTED_USERNAME_QUERY_PARAM } from '../../../../core/services/navigation/query-params';
import { LoginParams } from '../../../../core/services/user-management/wen-oauth.service';
import { authenticate } from '../../../../core/store/auth/auth.actions';
import { RootState } from '../../../../core/store/root/public-api';
import { emailverificationUtil } from '../../../../shared/feature-specific/email-verification/email-verification-util';

@Component({
  selector: 'wen-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private store: Store<RootState>,
  ) { }

  ngOnInit(): void {
    const loginParams: LoginParams = {};
    const suggested_username = this.route.snapshot.queryParamMap.get(AUTH_SERVER_SUGGESTED_USERNAME_QUERY_PARAM);
    const redirectAfterLogin = this.route.snapshot.queryParamMap.get(AUTH_SERVER_REDIRECT_URL_QUERY_PARAM);

    if (suggested_username) {
      loginParams.suggested_username = suggested_username;
    }
    if (redirectAfterLogin) {
      loginParams.redirectAfterLogin = redirectAfterLogin;
      if (emailverificationUtil.getCode(redirectAfterLogin)) {
        loginParams.ui_context = 'emailverification';
      }
    }
    this.store.dispatch(authenticate(loginParams));
  }

}
