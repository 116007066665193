import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getRootUrl, isInternalUrl, isRelativeUrl } from '@portal/wen-common';
import { LinkOpener } from '@portal/wen-components';
import { DeepLinkableUtils } from '../navigation/deep-link/deep-linkable';
import { WenNavigationHelper } from '../navigation/types';

@Injectable()
export class OpenUrlWithNavigationHelperService extends LinkOpener {

  private deepLinkableUtils = inject(DeepLinkableUtils);

  constructor(
    private navigationHelper: WenNavigationHelper,
    private router: Router
  ) {
    super();
  }

  openUrl(url: string, newTab: boolean = true): void {
    const isInternalLink = isInternalUrl(url);
    if (isInternalLink || isRelativeUrl(url)) {
      const routeLink = url.replace(getRootUrl(), '');
      this.router.navigateByUrl(routeLink);
    } else {
      this.navigationHelper.navigateToUrlWithFallback(url, { nativeUri: url }, newTab);
    }
  }

  evaluateOpenerLabel(url: string): string {
    if (!isInternalUrl(url)) {
      return 'EMBED_OPEN_EXTERNAL_LINK';
    }
    return this.deepLinkableUtils.getUrlOpenerTranslation(url);
  }
}
