import { Component, Input } from '@angular/core';
import { GeoWeatherHourly } from '@portal/wen-backend-api';
import { getHourlyForecast, HourlyForecastData } from '@portal/wen-components';

type HourlyOtherViewProps = {
  rainTotal: number;
  wind: number;
};

const EMBED_WEATHER_ICONS_PATH = '/assets/wen-widget/image/weather/embed';

@Component({
  selector: 'wen-detailed-forecast',
  templateUrl: './detailed-forecast.component.html',
  styleUrls: ['./detailed-forecast.component.scss']
})
export class DetailedForecastComponent {

  hourlyViewModel: HourlyForecastData;
  hourlyOtherViewProps: HourlyOtherViewProps;

  @Input() set model(value: GeoWeatherHourly) {
    this.hourlyViewModel = getHourlyForecast(value, EMBED_WEATHER_ICONS_PATH);
    this.hourlyOtherViewProps = {
      rainTotal: Math.round(value.rainTotal),
      wind: Math.round(value.wind10Meters)
    };
  }

}
