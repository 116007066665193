import { inject, Injectable } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { fetchConfigurationForChannel } from '../../../../../../core/store/channel/channel.actions';
import { RootState } from '../../../../../../core/store/root/public-api';

export const channelConfigurationResolver: ResolveFn<void> = () => {
  return inject(ChannelConfigurationResolver).resolve();
};

@Injectable()
export class ChannelConfigurationResolver {

  constructor(
    private readonly store: Store<RootState>,
  ) { }

  resolve() {
    this.store.dispatch(fetchConfigurationForChannel());
  }

}
