import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { combineLatest } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { updateAppDetail } from '../../../../../../core/store/apps/apps.actions';
import { RootState } from '../../../../../../core/store/root/public-api';
import { selectRouteParam } from '../../../../../../core/store/root/root.selectors';
import { appDetailIdentifier, appViewIdentifier } from '../../../../tokens';

@Injectable()
export class AppDetailLoadedGuard  {

  constructor(
    private store: Store<RootState>,
    private socketIoService: SocketIoService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return combineLatest([
      this.store.pipe(select(selectRouteParam(appDetailIdentifier)), first()),
      this.store.pipe(select(selectRouteParam(appViewIdentifier)), first()),
    ]).pipe(
      map(([appDetailId, appViewId]) => appDetailId ?? appViewId),
      switchMap((appId) => {
        return this.socketIoService.app.details.acknowledgement$({ id: appId });
      }),
      map((app) => {
        this.store.dispatch(updateAppDetail({ app }));
        return true;
      }),
    );
  }
}
