import { Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'wen-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavigationBarComponent implements OnInit {

  @HostBinding('class.wen-nav-bar') className = true;

  constructor() { }

  ngOnInit(): void {
  }

}
