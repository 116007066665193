import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PhoneAreaInfo } from '../../../types/form-types';

export interface PhoneNumberAreaPickerData {
  selected: PhoneAreaInfo;
  availableCountries: PhoneAreaInfo[];
}

export interface PhoneNumberAreaPickerResult {
  selected: PhoneAreaInfo;
}

@Component({
  selector: 'wen-phone-number-area-picker',
  templateUrl: './phone-number-area-picker.component.html',
  styleUrls: ['./phone-number-area-picker.component.scss']
})
export class PhoneNumberAreaPickerComponent implements OnInit {

  @HostBinding('class.wen-phone-number-area-picker') className = true;

  get selected() {
    return this.dialogData.selected;
  }

  get availableCountries() {
    return this.dialogData.availableCountries;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: PhoneNumberAreaPickerData,
    private matDialogRef: MatDialogRef<PhoneNumberAreaPickerComponent, PhoneNumberAreaPickerResult>,
  ) { }

  ngOnInit(): void {
  }

  selectCountry(selected: PhoneAreaInfo) {
    this.matDialogRef.close({
      selected
    });
  }

  onDismiss() {
    this.matDialogRef.close();
  }

}
