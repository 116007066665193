import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EventCategoryDTO, EventDTO, EventListItemEntity, EventPaging, EventSubscription } from '@portal/wen-backend-api';
import { DateUtil } from '../../common/date/date-util';
import { LoadingState } from '../../common/types/store-loading-state';
import { WithId } from '../common/entity-state-helpers/state-adapter-utils';

export type EventEntity = Omit<EventDTO, keyof EventSubscription>;

export type WithDateRange = {
  start: string;
  end?: string;
};

export type WithPriority = {
  priority: number;
};

export interface SubscriptionEntity extends EventSubscription {
  id: string;
}

export type OccurenceEntity = Omit<EventListItemEntity, 'id'> & EventSubscription & WithId & { eventId: string };


export interface EventState {
  events: EntityState<Partial<EventEntity>>;
  subscriptions: EntityState<SubscriptionEntity>;
  discoveryListOccurences: EntityState<OccurenceEntity>;
  userListOccurences: EntityState<OccurenceEntity>;
  userEventsPaging: EventPaging;
  discoverEventsPaging: EventPaging;
  userEventsLoading: LoadingState;
  discoverEventsLoading: LoadingState;
  categories: EventCategoryDTO[];
}

export const eventAdapter: EntityAdapter<Partial<EventEntity>> = createEntityAdapter<Partial<EventEntity>>({
  selectId: (event: EventEntity) => event.id,
  sortComparer: (event1, event2) => {
    const startDateComparison = DateUtil.compare(event2.start, event1.start);

    return startDateComparison !== 0
      ? startDateComparison : event1.id < event2.id
        ? 1 : -1;
  }
});

export const subscriptionAdapter: EntityAdapter<SubscriptionEntity> = createEntityAdapter<SubscriptionEntity>({
  selectId: (subscription: SubscriptionEntity) => subscription.id
});

export const occurenceAdapter: EntityAdapter<OccurenceEntity> = createEntityAdapter<OccurenceEntity>({
  selectId: (entity) => entity.id,
});
