import { isNullOrUndefined } from '../../../../../../../core/common/operators/null-check-util';
import { Injectable } from '@angular/core';
import { MediaType, WeLocalImageHelper } from '@portal/wen-backend-api';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EmbeddedMedia } from '../../../../../../../core/store/channel/channel.state';
import { MediaOverlayParams, MediaViewParams } from '../models/models';

export type ProcessedMedia = { viewParams: MediaViewParams; overlayParams: MediaOverlayParams };

@Injectable()
export class MediaProcessor {

  private processedMedia: BehaviorSubject<ProcessedMedia>;
  processedMedia$: Observable<ProcessedMedia>;

  mediaOverlayParams = new Subject<MediaOverlayParams>();

  constructor(private weLocalImageHelper: WeLocalImageHelper) {
    this.processedMedia = new BehaviorSubject<ProcessedMedia>(null);
    this.processedMedia$ = this.processedMedia.asObservable();
  }

  attach(mediaFile: EmbeddedMedia) {
    let isPlayable = mediaFile.playable;
    if (mediaFile.id && isNullOrUndefined(isPlayable)) {
      isPlayable = true;
    }
    const processor = isPlayable
      ? new ExternalMediaProcessor(this.weLocalImageHelper) : new LocalMediaProcessor();
    const viewParams = processor.getViewParams(mediaFile);
    const overlayParams = processor.getOverlayParams(mediaFile);
    this.processedMedia.next({
      viewParams,
      overlayParams
    });
  }

}

export interface MediaProcesses {
  getViewParams(mediaFile: EmbeddedMedia): MediaViewParams;
  getOverlayParams(mediaFile: EmbeddedMedia): MediaOverlayParams;
}

export class LocalMediaProcessor implements MediaProcesses {

  getViewParams(mediaFile: EmbeddedMedia): MediaViewParams {
    return {
      id: mediaFile.id,
      embedType: (!!mediaFile.error && !mediaFile.playable) ? MediaType.ERROR : !mediaFile?.uploaded ? MediaType.UPLOAD : MediaType.PROCESS,
      mediaType: mediaFile.subType,
      thumbnailUrl: MediaType.PICTURE === mediaFile.subType ? mediaFile?.thumbnailUrl ||  mediaFile?.rawUrl : null,
      sourceUrl: MediaType.VIDEO === mediaFile.subType ? mediaFile?.thumbnailUrl ||  mediaFile?.rawUrl : null,
      dimensions: {
        width: mediaFile.width,
        height: mediaFile.height
      },
      title: mediaFile.title,
      sizeInBytes: mediaFile.sizeInBytes,
      ...mediaFile.error && { errorCode: mediaFile.errorCode }
    };
  }

  getOverlayParams(mediaFile: EmbeddedMedia): MediaOverlayParams {
    return null;
  }

}

export class ExternalMediaProcessor implements MediaProcesses {

  constructor(private weLocalImageHelper: WeLocalImageHelper) { }

  getViewParams(mediaFile: EmbeddedMedia): MediaViewParams {
    const sourceUrl = mediaFile?.rawUrl || mediaFile?.fullUrl || this.weLocalImageHelper.getGenericUrl(mediaFile.id);
    return {
      id: mediaFile.id,
      thumbnailUrl: mediaFile?.thumbnailUrl || (MediaType.PICTURE === mediaFile.subType ? sourceUrl : null),
      sourceUrl,
      embedType: (!!mediaFile.error && !mediaFile.playable) ? MediaType.ERROR : mediaFile.subType,
      mediaType: mediaFile.subType,
      ...[MediaType.AUDIO, MediaType.VIDEO].includes(mediaFile.subType) && { isWeLocalPlayerNeeded: !mediaFile?.rawUrl },
      dimensions: {
        width: mediaFile.width,
        height: mediaFile.height
      },
      title: mediaFile.title,
      sizeInBytes: mediaFile.sizeInBytes,
      mimeType: mediaFile.mimeType,
      ...mediaFile.error && { errorCode: mediaFile.errorCode }
    };
  }

  getOverlayParams(mediaFile: EmbeddedMedia): MediaOverlayParams {
    return {
      id: mediaFile.id,
      fileType: mediaFile.subType,
      mimeType: mediaFile.mimeType,
      placeholderUrl: mediaFile.thumbnailUrl,
      srcUrl: mediaFile?.rawUrl || mediaFile?.fullUrl || this.weLocalImageHelper.getGenericUrl(mediaFile.id),
      width: mediaFile.subType === MediaType.VIDEO ? 0 : mediaFile.width,
      height: mediaFile.subType === MediaType.VIDEO ? 0 : mediaFile.height,
      fileName: mediaFile.title
    };
  }

}
