import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReadCategoriesFieldComponent } from './read-categories-field.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ReadCategoriesFieldComponent
  ],
  exports: [
    ReadCategoriesFieldComponent
  ]
})
export class ReadCategoriesFieldModule { }
