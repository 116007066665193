import { createAction, props } from '@ngrx/store';
import { ListEventRequestParameters } from '@portal/wen-backend-api';
import { SectionEntity } from './sections.state';
import { LoadingState } from '../../common/types/store-loading-state';
import type { DataContext } from '../../common/types/data-context';

export const fetchSectionContent = createAction(
  '[Section] Load section content',
  props<{ params: ListEventRequestParameters; sectionId: string; dataContext: DataContext }>()
);

export const updateSection = createAction(
  '[Section] Update section',
  props<{ sections: SectionEntity[] }>()
);

export const updateSectionContent = createAction(
  '[Section] Update content for a section',
  props<{ data: any[]; sectionId: string }>()
);

export const updateChannelExploreSectionLoadingState = createAction(
  '[Section] Update channel explore section loading state',
  props<{ loadingState: LoadingState }>()
);

export const updateEventExploreSectionLoadingState = createAction(
  '[Section] Update event explore section loading state',
  props<{ loadingState: LoadingState }>()
);

export const updateAppExploreSectionLoadingState = createAction(
  '[Section] Update app explore section loading state',
  props<{ loadingState: LoadingState }>()
);
