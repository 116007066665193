import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EmbedDTOType, ForwardContent, ForwardContext, SocketIoService } from '@portal/wen-backend-api';
import { Observable, combineLatest, filter, first, map, of, throwError } from 'rxjs';
import { ForbiddenForAnonymousUserDialogService } from '../../../shared/services/forbidden-for-anonymous-user-dialog.service';
import { DateUtil } from '../../common/date/date-util';
import { updateDraftChatMessage } from '../../store/chat/chat.actions';
import { RootState } from '../../store/root/public-api';
import { WenNavigationHelper } from '../navigation/types';

@Injectable()
export class ForwardService {

  constructor(
    private store: Store<RootState>,
    private navigationHelper: WenNavigationHelper,
    private socketService: SocketIoService,
    private forbiddenForAnonymousUserDialogService: ForbiddenForAnonymousUserDialogService
  ) { }

  initiateForward(forwardedContentOrDeeplink: ForwardContent | string, context: ForwardContext, id: string) {
    const forwardDataProcessor$ = this.prepareForwardEmbedContent(forwardedContentOrDeeplink, context);

    combineLatest([
      (this.forbiddenForAnonymousUserDialogService.openChatCreationForbiddenWhenAnonymous()),
      forwardDataProcessor$
    ]).pipe(
      filter(([dialog, _]) => !dialog),
      first(),
    ).subscribe(
      ([_, data]) => {
        this.store.dispatch(updateDraftChatMessage({
          message: {
            forwardEmbed: {
              forwardData: { context, id, data },
              type: EmbedDTOType.FORWARD
            },
            contextId: null
          }
        }));
        this.navigationHelper.navigateToDialogChatCreationFromForward();
      }
    );
  }

  private prepareForwardEmbedContent(
    forwardableContent: ForwardContent | string,
    context: ForwardContext,
  ): Observable<ForwardContent> {
    if (typeof forwardableContent === 'string') {
      if (context === ForwardContext.CHAT_MESSAGE) {
        return throwError(() => 'Forwarding links in chat context is not supported!');
      }
      if (context === ForwardContext.TEXTVIEW) {
        return this.socketService.reading.textViewPreview.acknowledgement$(
          { content: forwardableContent }
        ).pipe(
          map((preview) => {
            if (preview.embed) {
              return { embeds: [preview.embed] };
            }
            return { content: forwardableContent };
          })
        );
      }
      return this.socketService.channel.messagePreview.acknowledgement$(
        { timestamp: DateUtil.currentDateString(), content: forwardableContent }
      ).pipe(
        map((preview) => {
          if (preview.embed) {
            return { embeds: [preview.embed] };
          }
          return { content: forwardableContent };
        })
      );
    }
    return of(forwardableContent);
  }

}
