import { Modifier, Options } from '@popperjs/core';

export const createPopperActiveClassModifier = (
  className: string
): Modifier<string, Options> => ({
  name: 'addClassToPopper',
  enabled: true,
  phase: 'main',
  effect: ({ state }) => {
    state.elements.popper.classList.add(className);
    return () => {
      state.elements.popper.classList.remove(className);
    };
  },
  fn: () => { }
});
