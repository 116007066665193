import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { RootState } from '../../../../core/store/root/public-api';

export const exploreEventDataResolverFn = () => {
  inject(ExploreEventDataResolver).resolve();
};

@Injectable()
export class ExploreEventDataResolver  {

  constructor(private store: Store<RootState>) { }

  resolve(): void {
    // TODO: Dispatch fetching of data for explore page
  }
}
