import { DateTime } from 'luxon';
import { and, Comparison, comparison, eq } from 'rsql-builder';
import { Filter, FilterType } from '../models/filter';

const mapFilterValueToRSQLString = (filter: Filter): string | Comparison => {
  let rsqlComparison: string | Comparison;

  if (filter.filterValue === null) {
    return null;
  }

  switch (filter.filterType) {
    case FilterType.SEARCH: {
      rsqlComparison = comparison(filter.propertyName, eq(`*${filter.filterValue}*`));
      break;
    }
    case FilterType.SELECT: {
      rsqlComparison = filter.filterValue.filterString;
      break;
    }
  }
  return rsqlComparison;
};

export const createRSQLFilterString = (activeFilters: Filter[]): string => {
  const rsqlFilters = activeFilters.map(filter => mapFilterValueToRSQLString(filter));

  return rsqlFilters.filter(f => !!f).reduce<string>((combinedFilter: string, currentFilter: string | Comparison) => {
    if (!combinedFilter) {
      /**
       * The and() is responsible for creating the final string.
       * As the first ';' (and) or ',' (or) is not wanted, we skip that separator from the result
       */
      combinedFilter = and(combinedFilter, currentFilter).substring(1);
    } else {
      combinedFilter = and(combinedFilter, currentFilter);
    }
    return combinedFilter;
  }, '');
};

export const filterByJoinedObjectIds = (propertyName: string, ...args: string[]): string => {
  return args.map(id => `${propertyName}=join=(id, '==', ${id})`).join(',');
};

export const createFilterStringFromDateRange = (dateRange: DateTime[]) =>  {
  const [start, end] = dateRange;
  return `start<='${end.toSQL()}' and end>='${start.toSQL()}'`;
};
