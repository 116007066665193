import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { selectCurrentAppDetail, selectCurrentAppView } from '../../../../../../core/store/apps/apps.selectors';
import { RootState } from '../../../../../../core/store/root/public-api';
import { DeletedAppHelper } from '../providers/deleted-app-helper';

export const appDeletedGuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(AppDeletedGuard).canActivate(route, state);
};

@Injectable()
export class AppDeletedGuard  {
  constructor(
    private store: Store<RootState>,
    private deletedAppHelper: DeletedAppHelper
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return combineLatest([
      this.store.pipe(select(selectCurrentAppDetail), first()),
      this.store.pipe(select(selectCurrentAppView), first()),
    ]).pipe(
      map(([appDetail, appView]) => appDetail ?? appView),
      switchMap((app) => {
        return this.deletedAppHelper.isDeleted(app).pipe(map(deleted => !deleted));
      })
    );
  }

}
