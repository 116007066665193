
import { Directive, Input, Renderer2, ElementRef, AfterViewInit } from '@angular/core';

@Directive({ selector: 'wen-circle-image-overlay, [wen-circle-image-overlay]' })
export class CircleImageOverlayDirective implements AfterViewInit {

  @Input() position: 'top' | 'bottom' = 'top';
  @Input() positionOffset = 0;
  @Input() rightOffset = 0;
  @Input() zIndex = 2;

  constructor(private hostEl: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    this.renderer.setStyle(this.hostEl.nativeElement, 'position', 'absolute');
    this.renderer.setStyle(this.hostEl.nativeElement, `${this.position}`, `${this.positionOffset}`);
    this.renderer.setStyle(this.hostEl.nativeElement, 'right', `${this.rightOffset}`);
    this.renderer.setStyle(this.hostEl.nativeElement, 'z-index', `${this.zIndex}`);
  }

}
