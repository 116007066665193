
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MemberState } from '@portal/wen-backend-api';
import { ActionMenuItem } from '@portal/wen-components';
import { map } from 'rxjs';
import { selectCurrentUserData } from '../../../../core/store/auth/auth.selectors';
import { removeAdminStatusFromRoomMember } from '../../../../core/store/chat/actions/chat-member.actions';
import { ChatUserDTO } from '../../../../core/store/chat/chat.state';
import { RootState } from '../../../../core/store/root/public-api';

@Injectable()
export class RemoveAdminStatusGroupMemberAction implements ActionMenuItem<string, ChatUserDTO, void> {

  label: string;
  icon = 'user_shield_off';
  isVisible$ = (context: ChatUserDTO) => this.store.pipe(select(selectCurrentUserData)).pipe(
    map((currentUserData) => currentUserData.userId !== context.userId && context.state === MemberState.OWNER)
  );

  constructor(
    private translateService: TranslateService,
    private store: Store<RootState>
  ) {
    this.label = this.translateService.instant('CHAT_REMOVE_GROUP_MEMBER_ADMIN_STATUS');
  }

  execute(userId: string) {
    this.store.dispatch(removeAdminStatusFromRoomMember({ userId }));
  }

}
