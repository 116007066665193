import { ComponentType } from '@angular/cdk/portal';
import { InjectionToken } from '@angular/core';
import { DataObjectType } from '../../common/data-objects';

export enum HeaderCommandType {
  BACK_NAVIGATION = 'BACK_NAVIGATION',
  CLOSE_DIALOG = 'CLOSE_DIALOG',
  CANCEL_BACK_NAVIGATION = 'CANCEL_BACK_NAVIGATION',
  EXIT_EMBEDDED_APP_BACK_NAVIGATION = 'EXIT_EMBEDDED_APP_BACK_NAVIGATION',
  OPEN_CHANNEL_DETAIL = 'OPEN_CHANNEL_DETAIL',
  OPEN_USER_PROFILE = 'OPEN_USER_PROFILE',
  APP_ADD_WELCOME = 'APP_ADD_WELCOME',
  CHANNEL_ADD_WELCOME = 'CHANNEL_ADD_WELCOME',
  FORWARD_IN_CREATE = 'FORWARD_IN_CREATE',
  FINISH_CREATE = 'FINISH_CREATE',
  CLOSE_BACK_NAVIGATION = 'CLOSE_BACK_NAVIGATION',
  CLOSE_PROFILE_NAVIGATION = 'CLOSE_PROFILE_NAVIGATION',
  SAVE_BACK_NAVIGATION = 'SAVE_BACK_NAVIGATION',
  CHAT_ADD_NEW = 'CHAT_ADD_NEW',
  OPEN_ROOM_DETAIL = 'OPEN_ROOM_DETAIL',
  INVITE = 'INVITE',
  CHAT_MENU = 'CHAT_MENU_CREATE',
  FINISH_ADD_MEMBERS_TO_CHAT = 'FINISH_ADD_MEMBERS_TO_CHAT',
  BACK_TO_PROFILE = 'BACK_TO_PROFILE',
  CRM_PROFILE_READ_ONLY_VIEW = 'CRM_PROFILE_READ_ONLY_VIEW',
  CRM_PAUSE_MASS_CHAT_SENDING = 'CRM_PAUSE_MASS_CHAT_SENDING'
}

export type HeaderCommand = HeaderCommandIcon
  | HeaderCommandText
  | HeaderCommandComponent
  | SaveHeaderCommand<HeaderCommandType.SAVE_BACK_NAVIGATION>
  | SaveHeaderCommand<HeaderCommandType.FINISH_CREATE>
  | SaveHeaderCommand<HeaderCommandType.INVITE>
  ;

export interface BaseHeaderCommand {
  type: HeaderCommandType;
  comingSoonFeature?: boolean;
}

export interface HeaderCommandIcon extends BaseHeaderCommand {
  icon: string;
  title?: string;
}

export interface HeaderCommandText extends BaseHeaderCommand {
  title: string;
}

export interface HeaderCommandComponentType extends ComponentType<any> {
  disabled?: boolean;
}

export interface HeaderCommandComponent extends BaseHeaderCommand {
  componentToken: InjectionToken<HeaderCommandComponentType>;
}

export interface SaveHeaderCommand<T extends HeaderCommandType> extends HeaderCommandText {
  type: T;
  dataObjectType: DataObjectType;
  title: string;
}

export const isTextCommand = (commandData: HeaderCommand): commandData is HeaderCommandText => {
  if (!commandData) {
    return false;
  }
  return (commandData as any).title && !(commandData as any).icon;
};

export const isIconCommand = (commandData: HeaderCommand): commandData is HeaderCommandIcon => {
  if (!commandData) {
    return false;
  }
  return (commandData as any).icon;
};

export const isComponentCommand = (commandData: HeaderCommand): commandData is HeaderCommandComponent => {
  if (!commandData) {
    return false;
  }
  return (commandData as any).componentToken;
};
