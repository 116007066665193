import { createSelector } from '@ngrx/store';
import { filterFeature } from './filter.reducers';
import { filterEntityAdapter } from './filter.state';
import { AllFilterIds, FilterEntityType, FilterType, SearchFilter } from './models/filter';

export const {
  selectFilterState,
} = filterFeature;

const {
  selectEntities: selectFilterEntities
} = filterEntityAdapter.getSelectors();

export const selectAllFilterEntities = createSelector(
  selectFilterState,
  (state) => state.filterEntities.entities
);

export const selectActiveFiltersByEntityType = createSelector(
  selectFilterState,
  (state) => (filterEntityType: FilterEntityType) => {
    const filterEntities = selectFilterEntities(state.filterEntities);
    const { activeFilters } = filterEntities[filterEntityType] || {};
    return Object.values(activeFilters || {});
  }
);

export const selectActiveFilterById = createSelector(
  selectFilterState,
  (state) => (filterEntityType: FilterEntityType, filterId: AllFilterIds) => {
    const filterEntities = selectFilterEntities(state.filterEntities);
    const { activeFilters } = filterEntities[filterEntityType] || {};
    if (!activeFilters) {
      return null;
    }
    const result = activeFilters[filterId];
    return result;
  }
);

export const selectSearchTerm = createSelector(
  selectFilterState,
  (state) => (filterEntityType: FilterEntityType) => {
    const filterEntities = selectFilterEntities(state.filterEntities);
    const { activeFilters } = filterEntities[filterEntityType] || {};
    const filters = Object.values(activeFilters || {});
    const filter = filters.find(f => f.filterType === FilterType.SEARCH) as SearchFilter;
    return filter?.filterValue;
  }
);
