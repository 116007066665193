import { Attribute, ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'wen-rounded-button',
  templateUrl: './rounded-button.component.html',
  styleUrls: ['./rounded-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoundedButtonComponent {

  @HostBinding('class.wen-rounded-button') className = true;
  @HostBinding('class.wen-rounded-button-transparent') transparentClassName = false;
  @HostBinding('class.wen-rounded-button-background') backgroundClassName = false;
  @HostBinding('class.wen-rounded-button-border') borderClassName = false;
  @HostBinding('class.wen-rounded-button-disabled') @Input() disabled = false;

  constructor(
    @Attribute('transparent') public transparent: string,
    @Attribute('background') public background: string,
    @Attribute('border') public border: string,
  ) {
    this.transparentClassName = !Boolean(transparent ?? true);
    this.backgroundClassName = !Boolean(background ?? true);
    this.borderClassName = !Boolean(border ?? true);
  }

}
