import { WenRoutes } from '../../../frame/routing/types';
import {
  addChatInfoRoute,
  addChatUsersRoute,
  chatCreationDesktopRoute,
  chatInfoEditRoute,
  chatGroupDetailRoute, chatListRoute,
  chatViewDesktopRoute,
  chatGroupAddMembers,
  addChatAutoReplyEditRoute,
  chatSchedulesDesktopRoute
} from './chat.routes';

export const sidebarOutletChatRoutes: WenRoutes = [
  chatListRoute,
  {
    path: '**',
    redirectTo: 'list'
  }
];

export const primaryOutletChatRoutes: WenRoutes = [
  chatViewDesktopRoute,
  chatCreationDesktopRoute,
  chatSchedulesDesktopRoute,
  {
    path: '**',
    redirectTo: '/home/dashboard(sidebar:chat/list)'
  }
];

export const dialogOutletChatRoutes: WenRoutes = [
  chatGroupDetailRoute,
  addChatUsersRoute,
  chatGroupAddMembers,
  addChatInfoRoute,
  chatInfoEditRoute,
  addChatAutoReplyEditRoute
];
