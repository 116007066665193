import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavBarItemComponent } from './nav-bar-item/nav-bar-item.component';
import { NavigationBarComponent } from './navigation-bar.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../../../shared/shared.module';
import { navigationBarFeatureKey } from '../../../core/store/navigation-bar/constants';
import { NavigationBarEffects } from '../../../core/store/navigation-bar/navigation-bar.effects';
import { navigationBarReducerFactory } from '../../../core/store/navigation-bar/navigation-bar.reducers';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    StoreModule.forFeature(navigationBarFeatureKey, navigationBarReducerFactory),
    EffectsModule.forFeature([NavigationBarEffects])
  ],
  declarations: [
    NavBarItemComponent,
    NavigationBarComponent
  ],
  exports: [
    NavigationBarComponent,
    NavBarItemComponent
  ]
})
export class NavigationBarModule { }
