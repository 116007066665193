import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ContentChildren, ElementRef, Input, NgZone, OnChanges, OnDestroy, QueryList, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { Dimensions2D, ResizeHandlerProvider } from '@portal/wen-components';
import { BehaviorSubject, Subject, debounceTime, distinctUntilChanged, filter, map, startWith, takeUntil } from 'rxjs';
import { upsertHeaderData } from '../../../core/store/header/header.actions';
import { HeaderData } from '../../../core/store/header/models/HeaderData';
import { RootState } from '../../../core/store/root/public-api';
import { DetailHeaderScroller } from './detail-header-scroller.service';
import { DetailHeaderAdditionalContentDirective, DetailHeaderQuickActionsDirective } from './detail-header.directives';

export interface DetailHeaderModel {
  imageUrl?: string;
  title: string;
  prefixTitleIcon?: string;
  description?: string;
  pendingImage?: boolean;
}

@Component({
  selector: 'wen-detail-header',
  templateUrl: './detail-header.component.html',
  styleUrls: ['./detail-header.component.scss'],
  providers: [
    ResizeHandlerProvider,
    DetailHeaderScroller
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DetailHeaderComponent implements AfterViewInit, AfterContentInit, OnChanges, OnDestroy {

  // The width of the cover image size to be loaded
  coverImageLoadedSize$ = new BehaviorSubject<Dimensions2D>({ width: 100 });

  @ViewChild('summaryWrapper', { read: ElementRef }) summaryWrapper: ElementRef<HTMLDivElement>;
  @ContentChildren(DetailHeaderQuickActionsDirective) private headerActions: QueryList<DetailHeaderQuickActionsDirective>;
  @ContentChild(DetailHeaderAdditionalContentDirective) public headerAdditionalContent: DetailHeaderAdditionalContentDirective;

  @Input() model: DetailHeaderModel;
  @Input() shouldAnimate = true;
  @Input() allowLoadingIndicator = false;
  @Input() coverImageSize: number;
  @Input()
  get coverImageStyle() { return this.coverImageShape; }
  set coverImageStyle(value: 'circle' | 'square') { this.coverImageShape = value; }
  private coverImageShape: 'circle' | 'square' = 'circle';
  @Input()
  get coverImageFitStyle() { return this.coverImageFitType; }
  set coverImageFitStyle(value: 'contain' | 'cover') { this.coverImageFitType = value; }
  private coverImageFitType: 'contain' | 'cover' = 'cover';

  private reInitializeLayout$ = new Subject<void>();
  private onDestroy$ = new Subject<void>();

  private headerChanged$ = new Subject<void>();
  private imageError = false;

  constructor(
    private elementRef: ElementRef,
    private store: Store<RootState>,
    private resizeHandlerProvider: ResizeHandlerProvider,
    private zone: NgZone,
    private detailHeaderScroller: DetailHeaderScroller,
    private cdr: ChangeDetectorRef
  ) { }

  get showImage() {
    return !!(this.model?.imageUrl && !this.imageError);
  }

  ngAfterViewInit() {
    this.initHeaderBehavior();
    this.initResizeListener();
    this.initProperImageSizeListener();
    if (this.model && !this.model.imageUrl) {
      this.reInitializeLayout$.next();
    }
  }

  ngAfterContentInit() {
    this.headerActions.changes.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.headerChanged$.next();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const modelChange = changes.model;
    if (modelChange && !modelChange.firstChange) {
      this.imageError = false;
      this.reInitializeLayout$.next();
    }
  }

  onImageLoaded() {
    this.imageError = false;
  }

  onImageError() {
    this.imageError = true;
  }

  private initResizeListener() {
    const resizeHandler = this.resizeHandlerProvider.create(this.detailHeaderScroller.scrollElement);
    resizeHandler.onResize$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.reInitializeLayout$.next();
    });
  }

  private initHeaderBehavior() {
    this.detailHeaderScroller.scrollSub$.pipe(
      debounceTime(100),
      map(() => {
        const summaryWrapperEl = this.summaryWrapper.nativeElement;
        const scrollTop = this.detailHeaderScroller.getScrollOffset('top');
        return summaryWrapperEl.offsetTop - 10 <= scrollTop;
      }),
      distinctUntilChanged(),
      startWith(null),
      takeUntil(this.onDestroy$)
    ).subscribe((needsHeaderText) => {
      let headerData: Partial<HeaderData>;
      if (needsHeaderText) {
        headerData = {
          title: this.model.title,
          subTitle: this.model.description,
          prefixTitleIcon: this.model.prefixTitleIcon
        };
      }
      this.zone.run(() => {
        this.store.dispatch(upsertHeaderData({ headerData }));
      });
    });
  }

  private initProperImageSizeListener() {
    this.reInitializeLayout$.pipe(
      debounceTime(200),
      map(() => this.elementRef.nativeElement.getBoundingClientRect()?.width),
      filter(width => !!width),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$)
    ).subscribe(
      width => {
        this.coverImageLoadedSize$.next({ width });
        this.cdr.detectChanges();
      }
    );
  }

  ngOnDestroy() {
    this.resizeHandlerProvider.detach();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
