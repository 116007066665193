import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { PollModel } from '@portal/wen-backend-api';
import { WenSelectionListComponent } from '../../../../wen-selection-list/wen-selection-list.component';
import { PollManager } from '../../../providers/poll-manager';

@Component({
  selector: 'wen-poll-representation',
  templateUrl: './poll-representation.component.html',
  styleUrls: ['./poll-representation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PollRepresentationComponent {

  @Input() set model(value: PollModel) {
    this.pollModel = value;
    this.userAlreadyChose = this.pollModel.userAlreadyChose;
    if (!this.userAlreadyChose && !this.selectionModel) {
      this.selectionModel = new SelectionModel<WenSelectionListComponent<null>>(
        this.pollModel.multiSelect
      );
    }
  }

  private hasActiveSelection = false;
  private activeSelections: string[] = [];
  userAlreadyChose = false;
  pollModel: PollModel;
  selectionModel: SelectionModel<any>;

  @HostBinding('class.wen-embed-poll') className = true;
  @HostBinding('class.wen-poll-disabled') get disabledClassName() {
    return !this.hasActiveSelection;
  }

  constructor(private pollManager: PollManager) { }

  onSelectionChange(latestSelectedIds: string[]) {
    this.activeSelections = [...latestSelectedIds];
    this.hasActiveSelection = this.activeSelections.length > 0;
  }

  sendPollSelection() {
    if (this.hasActiveSelection) {
      const { id: pollId } = this.pollModel;
      const optionForPoll = {
        pollId,
        optionIds: this.activeSelections
      };
      this.pollManager.chooseOption(optionForPoll);
    }
  }
}
