import { Component, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { RestrictionDTO, RestrictionType, SocketIoService } from '@portal/wen-backend-api';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { firstExisty } from '../../../../core/common/operators/first-existy';
import { selectorWithParam } from '../../../../core/common/util/selector-with-param';
import { selectCurrentUserData } from '../../../../core/store/auth/auth.selectors';
import { resolveSubscriptionRestriction, withdrawJoinRequestForTheCurrentChannel } from '../../../../core/store/channel/channel.actions';
import { selectCurrentChannel, selectIsJoinRequestChannel } from '../../../../core/store/channel/channel.selectors';
import { RootState } from '../../../../core/store/root/public-api';
import { ChannelSubscribedToSource } from '../../../../views/channel/common/providers/channel-subscribed-to-source';
import { SubscribedToSource } from '../../../directives/subscibed-to/subscribed-to-source';

@Component({
  selector: 'wen-channel-subscribe-button',
  templateUrl: './channel-subscribe-button.component.html',
  styleUrls: ['./channel-subscribe-button.component.scss'],
  viewProviders: [
    { provide: SubscribedToSource, useClass: ChannelSubscribedToSource }
  ]
})
export class ChannelSubscribeButtonComponent {

  @Input() channelId: string;
  @Input() restrictions: RestrictionDTO[] = [];

  get requestOnly(): boolean {
    return Boolean(this.restrictions?.find(restriction => {
      return restriction.restrictionType === RestrictionType.RequestOnly && !restriction.fulfilled;
    }));
  }

  pendingRequest$: Observable<boolean> = this.store.pipe(
    select(selectCurrentChannel),
    filter(channel => Boolean(channel)),
    switchMap(({ id }) => this.store.pipe(selectorWithParam(selectIsJoinRequestChannel, id)))
  );

  constructor(
    private readonly store: Store<RootState>,
    private readonly socketIoService: SocketIoService
  ) { }

  subscribeToChannel() {
    this.store.dispatch(resolveSubscriptionRestriction({ channelId: this.channelId, autoAcknowledge: true }));
  }

  requestAccessToChannel() {
    this.store.pipe(
      select(selectCurrentUserData),
      firstExisty(),
    ).subscribe(({ userId }) => this.socketIoService.channelInvitation.sendJoinRequest.emit({ userId, channelId: this.channelId }));
  }

  withdrawJoinRequest() {
    this.store.dispatch(withdrawJoinRequestForTheCurrentChannel());
  }

}
