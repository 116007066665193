import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { ConfirmationDialogComponent, ConfirmationDialogResult } from '@portal/wen-components';
import { first } from 'rxjs';
import { SmartDesignApi } from '../../../core/services/smartdesign/smartdesing-api';
import { RootState } from '../../../core/store/root/public-api';
import { selectIsFromSmartDesign } from '../../../core/store/smartdesign/smartdesign.selectors';

export interface CRMClosingDialogData {
  image?: string;
  title: string;
  acceptLabel: string;
  message?: string;
}

@Component({
  selector: 'wen-crm-closing-dialog',
  templateUrl: './crm-closing-dialog.component.html',
  styleUrls: ['./crm-closing-dialog.component.scss']
})
export class CRMClosingDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly dialogData: CRMClosingDialogData,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent, ConfirmationDialogResult>,
    private store: Store<RootState>,
    private smartDesignApi: SmartDesignApi,
  ) { }

  onAction() {
    this.store.pipe(
      select(selectIsFromSmartDesign),
      first()
    ).subscribe((isFromSmartDesign) => {
      if (isFromSmartDesign) {
        this.smartDesignApi.closeDialog();
      } else {
        this.dialogRef.close({
          result: 'ok'
        });
      }
    });
  }
}
