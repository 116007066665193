import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WenNativeApi } from '@portal/wen-native-api';
import { EXCLUSIVE_INVITE_CONFIG, ExclusiveInviteConfig } from '../../../frame/api/tokens';
import { NativeInviteHandler } from './types';

@Injectable()
export class NativeInviteHandlerWithoutCode extends NativeInviteHandler {

  constructor(
    protected readonly nativeApi: WenNativeApi,
    protected readonly translateService: TranslateService,
    @Inject(EXCLUSIVE_INVITE_CONFIG) protected readonly inviteConfig: ExclusiveInviteConfig,
  ) {
    super(nativeApi);
  }

  initInviteFlow() {
    const emailSubject = this.translateService.instant('INVITE_NATIVE_SHARE_EMAIL_SUBJECT');
    const emailContent = this.translateService.instant('INVITE_NATIVE_SHARE_MESSAGE_WITHOUT_CODE', {
      inviteUrl: this.inviteConfig.inviteUrl
    });
    this.initNativeEmailShare(emailSubject, emailContent);
  }
}
