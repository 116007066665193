import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CREDENTIAL_TYPE } from '../../../../../core/services/navigation/query-params';
import { WalletCredentialType } from '@portal/wen-backend-api';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../../core/store/root/public-api';
import { upsertHeaderData } from '../../../../../core/store/header/header.actions';
import { CredentialDetailsUnitedKioskFlatComponent } from './credential-details-united-kiosk-flat/credential-details-united-kiosk-flat.component';
import { SharedModule } from '../../../../../shared/shared.module';

@Component({
  selector: 'wen-credential-details',
  templateUrl: './credential-details.component.html',
  imports: [
    SharedModule,
    CredentialDetailsUnitedKioskFlatComponent
  ],
  standalone: true
})
export class CredentialDetailsComponent implements OnInit {

  public credentialType: WalletCredentialType;
  public readonly walletCredentialType = WalletCredentialType;

  constructor(private route: ActivatedRoute, private store: Store<RootState>) {
  }

  ngOnInit(): void {
    this.credentialType = WalletCredentialType[this.route.snapshot.queryParamMap.get(CREDENTIAL_TYPE)];

    if (this.credentialType) {
      this.setUpHeader(this.credentialType);
    }
  }

  private setUpHeader(credentialType: WalletCredentialType) {
    switch(credentialType) {
      case WalletCredentialType.UNITED_KIOSK_NEWS_FLAT:
        this.store.dispatch(upsertHeaderData({ headerData: { showLogo: false, title: 'CREDENTIAL_DETAILS_UNITED_KIOSK_FLAT_HEADER' } }));
        return;
      default:
        return;
    }
  }
}
