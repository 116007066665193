import { NgModule } from '@angular/core';
import { ActionMenuModule, ACTION_MENU_ITEMS, ContextMenuComponent, ContextMenuTriggerDirective } from '@portal/wen-components';
import { SharedModule } from '../../../shared/shared.module';
import { ChannelAdminListComponent } from './components/channel-admin-list/channel-admin-list.component';
import { CHANNEL_ADMIN_CONTEXT_MENU_ITEMS } from './components/channel-admin-list/providers/action-menu/channel-admin-list-actions';

@NgModule({
  declarations: [ChannelAdminListComponent],
  imports: [
    SharedModule,
    ActionMenuModule,
    ContextMenuTriggerDirective,
    ContextMenuComponent
  ],
  providers: [
    { provide: ACTION_MENU_ITEMS, useValue: CHANNEL_ADMIN_CONTEXT_MENU_ITEMS, multi: true }
  ],
  exports: [ChannelAdminListComponent]
})
export class ChannelAdminListModule { }
