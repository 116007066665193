/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { PortalFieldValueAdapter } from '@portal/ui-kit/field';
import { DateTime } from 'luxon';
import { dateFormats, DateUtil } from '../../../../../../core/common/date/date-util';

@Injectable()
export class DateRangeAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
    if (!value) {
      return null;
    }

    const parsedValue = super.parse(value);
    return this.isValid(parsedValue) ? parsedValue : null;
  }

  format(date: Date, displayFormat: any): string {
    if (displayFormat === 'input' && this.isValid(date)) {
      return DateTime.fromJSDate(date).toFormat(dateFormats.DATE_ONLY);
    } else {
      return super.format(date, displayFormat);
    }
  }

  getFirstDayOfWeek(): number {
    /**
     * Used to show monday as the starting day (expects value 0)
     * We need to override this because the native date adapter cant detect the first day.
     * TODO: Can be removed when we switch to LuxonDateAdapter (requires angular v14 at least)
     */
    return DateUtil.getLocalFirstDayOfWeek();
  }

  getDayOfWeekNames(style: 'narrow' | 'short' | 'long'): string[] {
    // TODO: in some cases narrow should be completely fine. Currently it is transformed into short style.
    if (style === 'narrow') {
      style = 'short';
    }
    // As Luxon returns the days always starting from Monday, therefore the native provided days are being used.
    const weekDays = super.getDayOfWeekNames(style);
    return weekDays;
  }

  getMonthNames(): string[] {
    return DateUtil.getLocalMonthNames();
  }

  setLocale(locale: string): void {
    return super.setLocale(locale);
  }
}

@Injectable()
export class DateRangeValueAdapter extends PortalFieldValueAdapter<Date> {
  constructor(public readonly dateAdapter: DateRangeAdapter) {
    super();
  }

  parse(rawValue: any): Date {
    if (!rawValue) {
      return null;
    }
    if (rawValue instanceof Date && this.dateAdapter.isValid(rawValue)) {
      return rawValue;
    }
    const parsedDate: Date = this.dateAdapter.parse(rawValue);
    if (!parsedDate || isNaN(parsedDate.getTime())) {
      throw new Error('Invalid Date');
    }
    return parsedDate;
  }

  format(parsedValue: Date): string {
    let formattedValue = '';
    try {
      formattedValue = this.dateAdapter.format(parsedValue, 'input');
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return formattedValue;
    }
  }

  setLocale(locale: string) {
    return this.dateAdapter.setLocale(locale);
  }

}
