import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { isErrorVisible } from '@portal/ui-kit/core';
import { SelectionOption } from '@portal/wen-components';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { firstExisty } from '../../../../../core/common/operators/first-existy';
import { ChannelEntity } from '../../../../../core/store/channel/channel.state';
import { FormValueConverter } from '../../../../../shared/form-store/form-store.providers';
import { FormStoreService } from '../../../../../shared/form-store/form-store.service';
import { PrivilegeService } from '../../../../../shared/services/privilege.service';
import { ChannelFormDataProvider, CHANNEL_EDIT_FORM_DATA_PROVIDER } from '../../../common/providers/channel-form-data-provider';
import { ChannelVisibilitySettingFormValueConverter } from './services/channel-visibility-settings-form-value-converter';

@Component({
  selector: 'wen-visibility-channel-settings',
  templateUrl: './channel-visibility-settings.component.html',
  providers: [
    PrivilegeService, ChannelVisibilitySettingFormValueConverter, CHANNEL_EDIT_FORM_DATA_PROVIDER,
    {
      provide: FormValueConverter,
      useClass: ChannelVisibilitySettingFormValueConverter
    }
  ]
})
export class ChannelVisibilitySettingsComponent implements OnInit, OnDestroy {
  readonly networkAccessEnabled = false; // TODO Remove this flag when "global" channels are implemented

  private onDestroy$ = new Subject<void>();

  channelVisibilitySettingsFormGroup = new FormGroup({
    visibility: new FormControl('', [Validators.required]),
    networkAccess: new FormControl('', [Validators.required])
  });

  channel$: Observable<ChannelEntity>;

  visibilityPossibleValues: SelectionOption<boolean>[];
  netWorkAccessPossibleValues: SelectionOption<boolean>[];

  visibilitySelectionModel: SelectionModel<any> = new SelectionModel();
  networkAccessSelectionModel: SelectionModel<any> = new SelectionModel();

  constructor(
    readonly formValueConverter: ChannelVisibilitySettingFormValueConverter,
    readonly translateService: TranslateService,
    readonly formStoreService: FormStoreService,
    private readonly channelFormDataProvider: ChannelFormDataProvider,
  ) {
    this.visibilityPossibleValues = formValueConverter.visibilityPossibleValues;
    this.netWorkAccessPossibleValues = formValueConverter.netWorkAccessPossibleValues;
  }

  ngOnInit() {
    this.channel$ = this.channelFormDataProvider.channel$;

    this.channel$.pipe(
      firstExisty(),
      takeUntil(this.onDestroy$)
    ).subscribe((data) => {
      this.formStoreService.initializeForm({
        id: data.id,
        discoverable: data.discoverable
      });
    });
  }

  isRequiredError(controlName: string): boolean {
    const control = this.channelVisibilitySettingsFormGroup.get(controlName);
    return isErrorVisible(control, 'required');
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
