import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SOCKET_EVENTS, SocketIoService } from '@portal/wen-backend-api';
import { logout } from '../../store/auth/auth.actions';

@Injectable()
export class SocketErrorHandler {

  constructor(
    private readonly store: Store,
    private readonly socketIoService: SocketIoService,
  ) { }

  initialize() {
    this.socketIoService.system.error.listen.subscribe((errorData) => {
      if (!errorData) {
        return;
      }
      if (errorData.topic === SOCKET_EVENTS.AUTH_WELCOME && errorData.errorType === 'Unauthorized') {
        this.store.dispatch(logout());
      }
    });
  }
}
