import { createAction, props } from '@ngrx/store';
import { WalletCredential } from '@portal/wen-backend-api';

export const fetchWalletCredentials = createAction(
  '[wallet] Get Wallet credentials for user'
);

export const walletCredentialsRetrieved = createAction(
  '[wallet] Retrieved Wallet credentials',
  props<{ newState: WalletCredential[] }>()
);
