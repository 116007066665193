import { ChannelMuteAction } from './channel-mute.action';
import { ActionMenuConfig } from '@portal/wen-components';
import { ChannelPinAction } from './channel-pin.action';

export const channelListItemContextMenuId = 'channel-list-item-context-menu-id';

export const CHANNEL_LIST_ITEM_CONTEXT_MENU_ITEMS: ActionMenuConfig = {
  menuId: channelListItemContextMenuId,
  menuItems: [ChannelPinAction, ChannelMuteAction]
};
