import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ActionMenuItem } from '@portal/wen-components';
import { distinctUntilChanged, first, map, switchMap } from 'rxjs';
import { selectorWithParam } from '../../../../../core/common/util/selector-with-param';
import { addAdminForCurrentChannel } from '../../../../../core/store/channel/channel.actions';
import { selectChannelPermission, selectCurrentChannel } from '../../../../../core/store/channel/channel.selectors';
import { RootState } from '../../../../../core/store/root/public-api';

@Injectable()
export class PromoteToAdminAction implements ActionMenuItem<string, void> {

  label: string;
  icon = 'user_shield_on';
  isVisible$ = () => this.store.pipe(
    select(selectCurrentChannel),
    first(),
    switchMap((channel) => this.store.pipe(selectorWithParam(selectChannelPermission, channel.id))),
    map(permission => permission.canAddAdmins),
    distinctUntilChanged()
  );

  constructor(
    private translateService: TranslateService,
    private store: Store<RootState>
  ) {
    this.label = this.translateService.instant('CHANNEL_CONTENT_PROVIDER_PROMOTE_TO_ADMIN_LABEL');
  }

  execute(userId: string) {
    this.store.dispatch(addAdminForCurrentChannel({ userId }));
  }
}
