import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { first, map, ReplaySubject, Subject, switchMap, takeUntil } from 'rxjs';
import { selectCurrentUserData } from '../../../core/store/auth/auth.selectors';
import { RootState } from '../../../core/store/root/public-api';

@Directive({ selector: '[isLoggedInUser]' })
export class IsLoggedInUserDirective implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();
  private targetUserId = new ReplaySubject<string>();
  private elseTemplateRef: TemplateRef<any>;

  @Input('isLoggedInUser') set userId(targetUserId: string) {
    this.targetUserId.next(targetUserId);
  }

  @Input() set isLoggedInUserElse(templateRef: TemplateRef<any>) {
    this.elseTemplateRef = templateRef;
  }

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private store: Store<RootState>
  ) { }

  ngOnInit() {
    this.targetUserId.pipe(
      switchMap((targetUserId) => {
        return this.store.pipe(
          select(selectCurrentUserData),
          first(),
          map(({ userId }) => userId === targetUserId)
        );
      }),
      takeUntil(this.onDestroy$)
    ).subscribe(isVisible => {
      this.updateView(isVisible);
    });
  }

  private updateView(isVisible: boolean) {
    if (isVisible) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
      if (this.elseTemplateRef) {
        this.viewContainer.createEmbeddedView(this.elseTemplateRef);
      }
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
