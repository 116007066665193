import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatCheckboxDefaultOptions, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';
import { SelectionOption, SelectionOptionBorder, SelectionOptionPosition } from '../types';

@Component({
  selector: 'wen-selection-list-item',
  templateUrl: './selection-list-item.component.html',
  styleUrls: ['./selection-list-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: {
        clickAction: 'noop'
      } as MatCheckboxDefaultOptions
    },
    {
      provide: ANIMATION_MODULE_TYPE,
      useValue: 'NoopAnimations'
    }
  ]
})
export class SelectionListItemComponent implements OnInit {

  isMultiSelect: boolean;
  private innerSelectionOption: SelectionOption;
  selectionOptionPrefixIcon: string;

  @HostBinding('class.wen-selection-list-item') className = true;
  @HostBinding('class.wen-selection-list-postfix-item') get prefixClassName(): boolean {
    return this.selectionOptionPosition === 'postfix';
  }

  @HostListener('click')
  @HostListener('press')
  onClick() {
    if (this.allowSameSelection || !this.selectionModel.selected.includes(this.innerSelectionOption)) {
      this.selected = !this.selected;
    }
  }

  @Input() selectionOptionPosition: SelectionOptionPosition;
  @Input() selectionOptionBorderType: SelectionOptionBorder;
  @Input() selectionModel: SelectionModel<SelectionOption>;
  @Input() allowSameSelection = true;
  @Input() @HostBinding('class.wen-selection-list-item-disabled') isDisabled = false;
  @Input() set selectionOption(option: SelectionOption) {
    this.innerSelectionOption = option;
    this.selectionOptionPrefixIcon = option.prefixIcon;
  }
  get selectionOption() {
    return this.innerSelectionOption;
  }

  public get selected() {
    return this.selectionModel.isSelected(this.innerSelectionOption);
  }
  public set selected(isSelected: boolean) {
    this.setSelection(isSelected);
  }

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.isMultiSelect = this.selectionModel.isMultipleSelection();
  }

  setSelection(isSelected: boolean) {
    if (this.isDisabled) {
      return;
    }
    if (isSelected) {
      this.selectionModel.select(this.innerSelectionOption);
    } else {
      this.selectionModel.deselect(this.innerSelectionOption);
    }
  }

  refresh(): void {
    this.cdr.markForCheck();
  }
}
