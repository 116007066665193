import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RoomDTO } from '@portal/wen-backend-api';
import { ChatMuteService } from '../../providers/chat-mute.service';

@Component({
  selector: 'wen-mute-unmute-chat-button',
  templateUrl: './mute-unmute-chat-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MuteUnmuteChatButtonComponent {

  @Input() chat: RoomDTO;

  constructor(private chatMuteService: ChatMuteService) { }

  muteChat() {
    this.chatMuteService.setMuteForCurrentChat(this.chat.id, true, true);
  }

  unmuteChat() {
    this.chatMuteService.setMuteForCurrentChat(this.chat.id, false, true);
  }
}
