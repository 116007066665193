import { Injectable } from '@angular/core';
import { ActionMenuItem, ConfirmationDialogComponent, OverlayManager, WenSnackbarOpener } from '@portal/wen-components';
import { TranslateService } from '@ngx-translate/core';
import { of, switchMap } from 'rxjs';
import { ChallengeData } from '../challenge-context-menu/challenge-user-data-info';
import { filter, first } from 'rxjs/operators';
import { SocketIoService } from '@portal/wen-backend-api';

@Injectable()
export class ChallengeReportViolationAction implements ActionMenuItem {
  label: string;
  icon = 'report_violation';

  constructor(
    private translateService: TranslateService,
    private overlayManager: OverlayManager,
    private snackbarOpener: WenSnackbarOpener,
    private socketIoService: SocketIoService
  ) {
    this.label = this.translateService.instant('CHALLENGE_REPORT_VIOLATION_TEXT');
  }

  isVisible$ = () => of(true);

  execute(challengeData: ChallengeData): void {

    const dialog = this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, {
      header: this.translateService.instant('CHALLENGE_REPORT_VIOLATION_TEXT'),
      content: this.translateService.instant('CHALLENGE_REPORT_VIOLATION_DESCRIPTION'),
      reasonFieldLabel: this.translateService.instant('CHALLENGE_REPORT_VIOLATION_TEXT_AREA_PLACEHOLDER'),
      okLabel: this.translateService.instant('SEND_BUTTON_LABEL'),
      dismissLabel: this.translateService.instant('CANCEL_BUTTON_LABEL'),
      largeReasonField: true
    });

    dialog.afterClosed().pipe(
      first(),
      filter(dialogData => dialogData?.result === 'ok'),
      switchMap(dialogData => {
        return this.socketIoService.channel.reportViolation.acknowledgement$(
          {
            messageId: challengeData.messageId,
            authorName: challengeData.authorName,
            channelName: challengeData.channelName,
            notice: dialogData.reason
          });
      })
    ).subscribe(response => {
      if (response.ok) {
        this.snackbarOpener.openNotificationSnackbar({
          notificationText: this.translateService.instant('CHALLENGE_REPORT_VIOLATION_SNACKBAR'),
          notificationIcon: 'success'
        });
      } else {
        console.error(response.message);
      }
    });
  }
}
