import { NgModule } from '@angular/core';
import { MiddleEllipsizeDirective } from './middle-ellipsize.directive';

@NgModule({
  providers: [
  ],
  declarations: [
    MiddleEllipsizeDirective
  ],
  exports: [
    MiddleEllipsizeDirective
  ]
})
export class MiddleEllipsizeModule { }
