import { merge, ReplaySubject, map, distinctUntilChanged, Observable, tap } from 'rxjs';
import { Component, forwardRef, Input, ViewEncapsulation, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { SliderHelper } from './providers/slider-helper';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'wen-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  standalone: true,
  imports: [MatSliderModule, CommonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => WenSliderComponent),
    },
    SliderHelper
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WenSliderComponent implements ControlValueAccessor {

  private innerRangeValues: number[];
  private outerValue$ = new ReplaySubject<number>(1);
  private sliderIndex$ = new ReplaySubject<number>(1);

  min = 0;
  max: number;
  matchingIndex$: Observable<number> = merge(
    this.outerValue$,
    this.sliderIndex$.pipe(
      distinctUntilChanged(),
      map((sliderIndex) => {
        const matchingValue = this.sliderHelper.calculateMatchingValueFor(sliderIndex, this.innerRangeValues);
        return matchingValue;
      }),
      tap(newRadiusValue => this.onChange(newRadiusValue))
    )
  ).pipe(
    map((newRadiusValue) => this.sliderHelper.calculateMatchingIndexFor(newRadiusValue, this.innerRangeValues))
  );

  @HostBinding('class.wen-slider') className = true;

  @Input()
  set rangeValues(values: number[]) {
    this.innerRangeValues = values;
    this.max = values.length - 1;
  }

  @Input()
  set value(newValue: number) {
    this.outerValue$.next(newValue);
  }

  constructor(private sliderHelper: SliderHelper) { }

  onValueChange(newIndex: number) {
    this.sliderIndex$.next(newIndex);
  }

  onDragStart() {
    this.onTouched();
  }

  formatLabel = (index: number) => {
    const newLocal = this.sliderHelper.calculateMatchingValueFor(index, this.innerRangeValues);
    return `${newLocal}`;
  };

  //ControlValueAccessor implementation:
  onChange = (value) => { };
  onTouched = () => { };



  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void { }

}
