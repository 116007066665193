import { AsyncPipe, NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScrollToButtonComponent } from '../scroll-to-button/scroll-to-button.component';

import { WenSectionComponent } from './section.component';
import { SectionContentDirective, SectionHeaderActionDirective, SectionHeaderTitleDirective } from './section.directive';
import { WenTestIdModule } from '../../directives/public-api';

@NgModule({
  imports: [
    NgIf,
    AsyncPipe,
    ScrollToButtonComponent,
    WenTestIdModule
  ],
  declarations: [
    WenSectionComponent,
    SectionHeaderTitleDirective,
    SectionHeaderActionDirective,
    SectionContentDirective
  ],
  exports: [
    WenSectionComponent,
    SectionHeaderTitleDirective,
    SectionHeaderActionDirective,
    SectionContentDirective
  ],
})
export class WenSectionModule { }
