import { Injectable } from '@angular/core';
import { ListEventRequestParameters, ListEventRequestProps } from '@portal/wen-backend-api';
import { DateTime } from 'luxon';

@Injectable({ providedIn: 'root' })
export class DateRangeConverter {

  convertRangeToRequestParameters(range: DateTime[]): ListEventRequestParameters {
    return range.reduce((finalDateProps, currentDate, currentIndex) => {
      currentDate = this.ensureDateTimeType(currentDate);

      const dateRangeKeys = ['startDate', 'endDate'];
      const isoDate = currentDate.toUTC().toISO();
      return { ...finalDateProps, [dateRangeKeys[currentIndex]]: isoDate };
    }, {} as ListEventRequestProps);
  }

  private ensureDateTimeType(date: any): DateTime {
    const isNotDateTime = !(date instanceof DateTime);
    const isCompatibleWithDateTime = DateTime.isDateTime(date);
    if (isNotDateTime && isCompatibleWithDateTime) {
      return DateTime.local(
        date.year,
        date.month,
        date.day,
        date.hour,
        date.minute,
        date.second,
        date.millisecond
        );
    }

    return date;
  }
}
