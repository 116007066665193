<wen-confirmation-dialog>
  <div wen-confirmation-dialog-custom-content>
    <mat-checkbox [(ngModel)]="selected" [disableRipple]="'true'">
      {{ "AGB_DATA_PROTECTION_DIALOG_TEXT.0" | translate }}
      <a [href]="termsOfUseLink" (click)="onLinkClicked($event, termsOfUseLink)">{{ "AGB_DATA_PROTECTION_DIALOG_TEXT.1"
        | translate }}</a>
    </mat-checkbox>
    <div>
      <p>
        {{ "AGB_DATA_PROTECTION_DIALOG_TEXT.2" | translate }}
        <a [href]="privacyPolicyLink" (click)="onLinkClicked($event, privacyPolicyLink)">{{
          "AGB_DATA_PROTECTION_DIALOG_TEXT.3" | translate }}</a>
      </p>
    </div>
  </div>
  <button class="wen-confirmation-dialog-wide-action" wen-confirmation-dialog-ok-action [disabled]="!selected" (click)="onAccept()">{{
    "DATA_PROTECTION_DIALOG_ACCEPT_BUTTON_LABEL" | translate }}</button>
</wen-confirmation-dialog>