import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild, ViewEncapsulation, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wen-poll-new-option',
  templateUrl: './poll-new-option.component.html',
  styleUrls: ['./poll-new-option.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PollNewOptionComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild('textArea') textAreaRef: ElementRef<HTMLTextAreaElement>;
  @Input() control: FormControl;
  @Input() index: number;
  @Input() removable: boolean;

  @Output() remove = new EventEmitter<number>();
  @Output() createNewOption = new EventEmitter<void>();

  private onDestroy$ = new Subject<void>();

  readonly CHARACTER_LIMIT = 250;
  readonly SHOW_CHARACTER_COUNT = Math.ceil(this.CHARACTER_LIMIT * 0.8);
  characterCount$: Observable<number | boolean>;

  get placeholder() {
    return `Option ${this.index + 1}`;
  }

  ngOnInit(): void {
    this.characterCount$ = this.control.valueChanges.pipe(
      map(value => value.length >= this.SHOW_CHARACTER_COUNT ? value.length : false)
    );
  }

  ngAfterViewInit(): void {
    this.textAreaRef.nativeElement.focus();

    fromEvent(this.textAreaRef.nativeElement, 'focusout').pipe(
      debounceTime(150),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      if (!this.textAreaRef.nativeElement.value) {
        this.removeOption();
      }
    });
  }

  removeOption() {
    this.remove.emit(this.index);
  }

  onenter(event: KeyboardEvent) {
    event.preventDefault();
    this.createNewOption.emit();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
