import { EncryptedMessageEventResponses, MessageEvent, MessageModificationState, RedactionDTO, RedactMessageEventDTO, SendMessageContent } from '@portal/wen-backend-api';
import { ChatMessageEntity } from '../../../../store/chat/chat.state';
import { ChatNotificationEventEntity } from '../../../../store/notification/notification.state';
import { isRealtimeRedactEvent } from '../message-event-helper';

export const redactMessage = (event: MessageEvent): SendMessageContent<false> => {
  return {
    message: {
      id: null,
      timestamp: event.insertTimestamp
    }
  };
};

export const asEncryptedRedactEvent = (event: MessageEvent<RedactMessageEventDTO>): MessageEvent<EncryptedMessageEventResponses> => {
  const originalEventId = event.payload.redacted.eventId;
  const redactMessageEvent: MessageEvent<RedactMessageEventDTO> = {
    eventId: originalEventId,
    insertTimestamp: event.insertTimestamp,
    insertUser: event.insertUser,
    new: event.new,
    payload: event.payload,
    roomId: event.roomId,
    relations: null
  };
  return redactMessageEvent;
};


export const toRedactChatMessageEntity = (redactEvent: MessageEvent<RedactionDTO>) => {
  const messageEntity: ChatMessageEntity = {
    id: redactEvent.payload.redacts,
    new: redactEvent.new,
    eventId: redactEvent.eventId,
    encryptionData: {
      roomId: redactEvent.roomId,
      encryptedMessage: null,
      originalEvent: null
    },
    insertUser: redactEvent.insertUser,
    messageContent: null,
    insertTimestamp: redactEvent.payload.redactedEventInsertTimestamp,
    decryptionError: null,
    state: MessageModificationState.DELETED
  };
  return messageEntity;
};

export const toRedactChatNotificationEntity = (redactEvent: MessageEvent<RedactionDTO>): ChatNotificationEventEntity => {
  const asRedactMessage = redactMessage(redactEvent);
  if (isRealtimeRedactEvent(redactEvent)) {
    asRedactMessage.message.timestamp = redactEvent.payload.redactedEventInsertTimestamp;
  }
  const messageEntity: ChatNotificationEventEntity = {
    id: redactEvent.payload.redacts,
    originalEvent: redactEvent,
    redacted: true,
    decryptedEvent: asRedactMessage,
    insertUser: redactEvent.insertUser,
    decryptionError: null,
  };
  return messageEntity;
};
