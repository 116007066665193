import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectionOption } from '@portal/wen-components';
import { WenLanguage } from '@portal/wen-backend-api';
import { UserProfile } from '../../../../../../core/store/user/models/UserProfile';
import { WenLanguageService } from '@portal/wen-translation';

@Injectable()
export class UserLanguageService {

  getLanguageSettings(userProfile: UserProfile) {
    const language = this.languageService.getLanguage(userProfile);
    return { primaryLanguage: language };
  }

  getLanguageSettingItems(): SelectionOption<WenLanguage>[] {
    const supportedLanguages = this.languageService.getSupportedLanguages();
    const wenLanguages = Object.entries(WenLanguage);
    return supportedLanguages.reduce((allSelectionItems, currentLanguage) => {
      const [matchingLanguageKey, matchingLanguageValue] = wenLanguages.filter(([_, language]) => currentLanguage === language)[0];
      const currentSelectionOption: SelectionOption<WenLanguage> = {
        id: currentLanguage,
        value: matchingLanguageValue,
        text: this.translateService.instant(matchingLanguageKey)
      };
      allSelectionItems.push(currentSelectionOption);
      return allSelectionItems;
    }, []);
  }

constructor(
  private translateService: TranslateService,
  private languageService: WenLanguageService
) { }

}
