<portal-expandable-container [maxHeight]="maxHeight">
  <ng-content></ng-content>
  <div open-button-content>
    <div class="fade"></div>
    <div class="button">
      <wen-icon iconName="chevron_down"></wen-icon>
      {{ 'EXPANDABLE_CONTAINER_SHOW_MORE_BUTTON_LABEL' | translate }}
    </div>
  </div>
  <div close-button-content class="button">
    <wen-icon iconName="chevron_up"></wen-icon>
    {{ 'EXPANDABLE_CONTAINER_SHOW_LESS_BUTTON_LABEL' | translate }}
  </div>
</portal-expandable-container>