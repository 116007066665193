import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { upsertHeaderData } from '../../../../core/store/header/header.actions';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { WenNavigationHelper } from '../../../../core/services/navigation/types';
import { RootState } from '../../../../core/store/root/public-api';
import { WenBreakpointObserver } from '@portal/wen-components';
import { ShareService } from '../../../../shared/channel-specific/providers/share.service';
import { TextViewDeepLinkable } from '../../../../core/services/navigation/deep-link/deep-linkables/text-view-deep-linkable';
import { textViewIdentifier } from '../../tokens';
import { ForwardContext, MediaType, ReadingError, ReadingTextViewResponse, SocketIoService, TextViewDTO, WalletCredentialType } from '@portal/wen-backend-api';
import { filter, first, map, Observable, of, timeout } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RestrictionModel } from '../../../channel/channel-view/components/channel-content-curtain/channel-restriction/channel-restriction.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { WalletService } from '../../../wallet/providers/wallet.service';
import { FeatureEnablementService } from '../../../../core/services/configuration/feature-enablement';
import { ContentErrorModel } from '../../../error-page/components/content-error/content-error.component';

interface AdjustedTextView extends TextViewDTO {
  safeContentHtml: SafeHtml;
  previewImage?: HTMLImageElement;
}

@Component({
  selector: 'wen-text-view',
  templateUrl: './text-view.component.html',
  styleUrls: ['./text-view.component.scss']
})
export class TextViewComponent implements OnInit {

  readonly isDesktopStyleDevice = this.breakpointObserver.isDesktopStyleDevice;
  textView$: Observable<AdjustedTextView>;
  restrictionModel?: RestrictionModel;
  contentErrorModel?: ContentErrorModel;
  loadingIndicatorVariant?: MediaType = MediaType.DOCUMENT;
  forwardContext = ForwardContext.TEXTVIEW;
  canForward$: Observable<boolean>;

  private articleId: string;

  get textViewDeepLink() {
    return this.deepLinkProvider.getDeepLink({ articleId: this.articleId });
  }

  constructor(
    private socketIoService: SocketIoService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private store: Store<RootState>,
    private navigationHelper: WenNavigationHelper,
    private breakpointObserver: WenBreakpointObserver,
    private readonly walletService: WalletService,
    private readonly shareService: ShareService,
    private sanitized: DomSanitizer,
    private readonly deepLinkProvider: TextViewDeepLinkable,
    private featureEnablementService: FeatureEnablementService
  ) {
  }

  ngOnInit() {
    const timeoutDeltaInMinutes = 0.5;

    this.articleId = this.route.snapshot.params[textViewIdentifier];
    this.textView$ = this.socketIoService.reading.textView.listen.pipe(
      timeout({
        first: timeoutDeltaInMinutes * 60 * 1000,
        with: () => of(
          {
            ok: false,
            errorCode: ReadingError.NO_RESPONSE_RECEIVED,
            textView: null
          } as ReadingTextViewResponse
        )
      }),
      first((response) => response != null),
      tap((response: ReadingTextViewResponse) => {
        this.loadingIndicatorVariant = null;
        if (!response.ok) {
          this.handleError(response.errorCode);
        }
      }),
      filter((response: ReadingTextViewResponse) => response.ok),
      map((response: ReadingTextViewResponse) => this.adjustContent(response.textView)),
      tap(textView => this.updateHeader(textView))
    );
    this.socketIoService.reading.textView.emit({
      articleId: this.articleId,
    });

    this.canForward$ = this.featureEnablementService.appConfigObs.enableChat$.pipe(
      map((isChatEnabled) => isChatEnabled && !!this.textViewDeepLink)
    );
  }

  private adjustContent(textView: TextViewDTO): AdjustedTextView {
    const parser = new DOMParser();
    let contentHtml = parser.parseFromString(textView.contentText, 'text/html');
    contentHtml = this.handleImageLazyLoading(contentHtml);
    contentHtml = this.handleLinks(contentHtml);
    const previewImage = this.getPreviewImage(contentHtml);
    const newContent = contentHtml?.querySelector('.reader-textview-content-text')?.innerHTML;
    const safeContentHtml = this.sanitized.bypassSecurityTrustHtml(newContent ?? '');
    return {
      ...textView,
      safeContentHtml,
      previewImage
    };
  }

  private handleImageLazyLoading(document: Document): Document {
    const images = document.querySelectorAll('img');

    if (images) {
      images.forEach(image => {
        image.setAttribute('loading', 'lazy');
      });
    }

    return document;
  }

  private handleLinks(document: Document): Document {
    const linkElements: HTMLAnchorElement[] = Array.from(document.getElementsByTagName('a'));

    if (linkElements) {
      linkElements.forEach(element => {
        element.onclick = (event) => {
          event.preventDefault();
          this.navigationHelper.navigateToExternalUrl(element.href);
        };
      });
    }

    return document;
  }

  private getPreviewImage(document: Document): HTMLImageElement {
    const images = Array.from(document.querySelectorAll('img'));
    return images[1] || images[0] || null;
  }

  private updateHeader(textViewData: AdjustedTextView) {
    const headerTitle = this.translateService.instant('TEXTVIEW_HEADER_TITLE', {
      issueNumber: textViewData.issueNumber,
      issueYear: textViewData.issueYear
    });
    this.store.dispatch(upsertHeaderData({
      headerData: {
        showLogo: false, title: headerTitle,
        subTitle: textViewData.magazineTitle
      }
    }));
  }

  private handleError(errorCase: ReadingError) {
    switch (errorCase) {
      case ReadingError.CONTENT_NOT_FOUND:
        this.contentErrorModel = {
          titleTranslationKey: 'CONTENT_ERROR_NO_RESULT_TITLE',
          descriptionTranslationKey: 'CONTENT_ERROR_NO_RESULT_DESCRIPTION',
          imagePath: '/assets/wen-widget/error-page/error-no-search-result.svg',
        } as ContentErrorModel;
        break;
      case ReadingError.NOT_AUTHORIZED_FOR_CONTENT:
        this.restrictionModel = {
          titleTranslationKey: 'PREMIUM_READING_CHANNEL_RESTRICTION_TITLE',
          descriptionTranslationKey: 'PREMIUM_READING_CHANNEL_RESTRICTION_DESCRIPTION',
          imagePath: '/assets/wen-widget/image/wallet-united-kiosk-restriction.png',
          layoutType: 'buttonLayout',
          action: {
            translationKey: 'PREMIUM_READING_CHANNEL_RESTRICTION_BUTTON',
            handler: () => this.walletService.handleWalletRestrictionButton(WalletCredentialType.UNITED_KIOSK_NEWS_FLAT)
          },
          premiumReadingChannel: true
        } as RestrictionModel;
        break;
      case ReadingError.INVALID_RESPONSE_RECEIVED:
      case ReadingError.NO_RESPONSE_RECEIVED:
      case ReadingError.UNKNOWN_ERROR:
      default:
        this.contentErrorModel = {
          titleTranslationKey: 'CONTENT_ERROR_BACKEND_UNAVAILABLE_TITLE',
          descriptionTranslationKey: 'CONTENT_ERROR_BACKEND_UNAVAILABLE_DESCRIPTION',
          lottiePath: '/assets/wen-widget/error-page/InfoPageAnimation.json',
        } as ContentErrorModel;
        break;
    }
  }

  navigateToXReader(textViewData: TextViewDTO) {
    this.navigationHelper.navigateToReaderView({docId: textViewData.docId, startPage: textViewData.pageNumber});
  }

  shareDeepLink() {
    const deepLink = this.deepLinkProvider.getDeepLink({ articleId: this.articleId });
    this.shareService.shareDeepLink(deepLink);
  }
}
