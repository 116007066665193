import { NavigationBarState } from './navigation-bar.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RootState } from '../root/public-api';
import { navigationBarFeatureKey } from './constants';

export interface WithFeatureState extends RootState {
  [navigationBarFeatureKey]: NavigationBarState;
}

export const selectNavigationBarState = createFeatureSelector<NavigationBarState>(
  navigationBarFeatureKey
);

export const selectIsNavBarVisible = createSelector(
  selectNavigationBarState,
  (state) => {
    return state.isNavBarVisible;
  }
);
