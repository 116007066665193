import { PlatformLocation } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject, takeUntil } from 'rxjs';
import { LocationSelectorModel } from '../../../../../shared/components/location-selector/location-selector.component';
import { FormStoreService } from '../../../../../shared/form-store/form-store.service';

@Component({
  selector: 'wen-channel-post-location-selector',
  templateUrl: './channel-post-location-selector.component.html',
  styleUrls: ['./channel-post-location-selector.component.scss']
})
export class ChannelPostLocationSelectorComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  locationSelectorForm = new FormGroup({
    postContent: new FormGroup({
      embedLocation: new FormControl()
    }),
  });

  get embedControl() {
    const postContent = this.locationSelectorForm.controls.postContent as FormGroup;
    return postContent.controls.embedLocation;
  }

  constructor(
    private formStoreService: FormStoreService,
    private location: PlatformLocation,
  ) { }

  ngOnInit(): void {
    this.formStoreService.initializeForm();
    const selectionChanges$: Observable<LocationSelectorModel> = this.embedControl.valueChanges;
    selectionChanges$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((newValue) => {
      if (newValue) {
        this.location.back();
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
