import { TranslateService } from '@ngx-translate/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { OverlayManager, WenSnackbarOpener } from '@portal/wen-components';
import { WenNativeApi } from '@portal/wen-native-api';
import { BrowserLocationService } from './browser-location.service';
import { NativeLocationService } from './native-location.service';
import { FeatureEnablementService } from '../configuration/feature-enablement';

export const locationServiceFactory = (
  nativeApi: WenNativeApi,
  socketIOService: SocketIoService,
  overlayManager: OverlayManager,
  snackBarOpener: WenSnackbarOpener,
  translateService: TranslateService,
  featureEnablementService: FeatureEnablementService,
) => {
  if (nativeApi.isReactNativeApp()) {
    return new NativeLocationService(
      socketIOService, snackBarOpener, translateService, featureEnablementService, overlayManager, nativeApi
    );
  }
  return new BrowserLocationService(socketIOService, snackBarOpener, translateService, featureEnablementService, overlayManager);
};
