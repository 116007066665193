import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'wen-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusBarComponent implements AfterViewInit {

  @ViewChild('statusBar') statusBar: ElementRef;

  private value$ = new ReplaySubject<number>(1);

  @Input() set value(value: number) {
    this.value$.next(value);
  }

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.value$.pipe(
      tap((value) => this.renderer.setStyle(this.statusBar.nativeElement, 'width', `${value}%`))
    ).subscribe();
  }

}
