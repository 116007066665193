import { MatInput } from '@angular/material/input';
import { Directive, Input } from '@angular/core';
import { PortalFieldValueAdapter, PortalNoopFieldValueAdapter } from '@portal/ui-kit/field';

@Directive({ selector: '[matInput]', standalone: true })
export class MaterialInputExtensionDirective<D = number> extends MatInput {

  private inputValueAdapter: PortalFieldValueAdapter<D> = new PortalNoopFieldValueAdapter();

  @Input()
  set valueAdapter(valueAdapter: PortalFieldValueAdapter<D>) {
    if (!valueAdapter) {
      valueAdapter = new PortalNoopFieldValueAdapter();
    }
    this.inputValueAdapter = valueAdapter;
  }
  get valueAdapter() {
    return this.inputValueAdapter;
  }

  @Input()
  set value(value: string) {
    if (super.value !== value) {
      const formattedValue = this.inputValueAdapter.parse(value).toString();
      super.value = formattedValue;
    }
  }

}
