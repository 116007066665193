import { Injectable } from '@angular/core';
import { AutoReplyMessageEventDTO, MessageEvent, RedactionDTO, ToRoomEventType } from '@portal/wen-backend-api';
import { isNullOrUndefined } from '../../../common/operators/null-check-util';
import { ChatMessageEntity } from '../../../store/chat/chat.state';
import { ChatNotificationEventEntity } from '../../../store/notification/notification.state';
import { toAutoReplyChatMessageEntity } from '../message-event/modifiers/auto-reply';
import { toRedactChatMessageEntity, toRedactChatNotificationEntity } from '../message-event/modifiers/redact';
import { DecryptedMessageResultWrapper } from './decryption-result-base';
import { DecryptedMessageResult } from './message-decryptor';

@Injectable()
export class DecryptedEventModifier {

  toMessageEntities(
    messages: (DecryptedMessageResultWrapper<ChatMessageEntity> | MessageEvent)[]
  ): ChatMessageEntity[] {
    return messages.map(message => {
      if (isDecryptedMessage(message)) {
        return message.asChatStoreEntity();
      } else {
        return this.handleMessageEventConversionToMessageEntity(message);
      }
    })
      .filter(message => !isNullOrUndefined(message));
  }

  toNotificationEntities(
    messages: (DecryptedMessageResultWrapper<ChatMessageEntity> | MessageEvent)[]
  ): ChatNotificationEventEntity[] {
    return messages.map(message => {
      if (isDecryptedMessage(message)) {
        return (message as DecryptedMessageResult).asChatNotificationEntity();
      }
      return this.handleMessageEventConversionToNotificationEntity(message);
    })
      .filter(message => !isNullOrUndefined(message));
  }

  private handleMessageEventConversionToMessageEntity(message: MessageEvent): ChatMessageEntity {
    switch (message.payload.eventType) {
      case ToRoomEventType.AUTO_REPLY:
        return toAutoReplyChatMessageEntity(message as MessageEvent<AutoReplyMessageEventDTO>);
      case ToRoomEventType.REDACTION:
        return toRedactChatMessageEntity(message as MessageEvent<RedactionDTO>);
      default:
        return;
    }
  }

  private handleMessageEventConversionToNotificationEntity(message: MessageEvent): ChatNotificationEventEntity {
    switch (message.payload.eventType) {
      case ToRoomEventType.REDACTION:
        return toRedactChatNotificationEntity(message as MessageEvent<RedactionDTO>);
      default:
        return;
    }
  }
}

const isDecryptedMessage = (message: DecryptedMessageResultWrapper<any> | MessageEvent): message is DecryptedMessageResultWrapper<any> => {
  return !!(message as DecryptedMessageResultWrapper<any>)?.props?.eventId;
};
