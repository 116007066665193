import { ActionMenuConfig } from '@portal/wen-components';
import { ChallengeAskAuthorAction } from './challenge-ask-author.action';
import { ChallengeReportViolationAction } from './challenge-report-violation.action';

export const challengeContextMenuId = 'challenge-context-menu-id';

export const CHALLENGE_CONTEXT_MENU_ITEMS: ActionMenuConfig = {
  menuId: challengeContextMenuId,
  menuItems: [ChallengeAskAuthorAction, ChallengeReportViolationAction]
};
