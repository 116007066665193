import { createAction, props } from '@ngrx/store';
import { CommentDeleteResponse, Paging } from '@portal/wen-backend-api';
import { WenComment } from '../../../shared/components/comments/types';
import { DraftMessageEntity } from '../common/models';

export interface ParentId {
  parentId: string;
}

export const setParentIdForComment = createAction(
  '[Comments] Set parent id',
  props<{ parentId: string }>()
);

export const fetchCommentsForParentElements = createAction(
  '[Comments] Fetch comments for parent elements',
  props<{ parentIds: string[]; size?: number; filter?: string }>()
);

export const updateComments = createAction(
  '[Comments] Update loaded comments',
  props<{ comments: WenComment[]; paging: Paging } & ParentId>()
);

export const sendComment = createAction(
  '[Comments] Send comment'
);

export const loadNextChunkOfComments = createAction(
  '[Comments] Load next chunk of comments',
  props<ParentId>()
);

export const updateTimestampOfPageVisit = createAction(
  '[Comments] Update timestamp of visiting a page',
  props<{ timestamp: string }>()
);

export const deleteComment = createAction(
  '[Comments] Delete a comment',
  props<{ commentId: string }>()
);

export const commentDeleteResponse = createAction(
  '[Comments] Successful comment deletion',
  props<CommentDeleteResponse>()
);

export const fetchCommentCountForMessages = createAction(
  '[Comments] Fetch comment count for messages',
  props<{ messageIds: string[] }>()
);

export const fetchCommentCountForParentId = createAction(
  '[Comments] Fetch comment count for parent id',
  props<{ parentId: string }>()
);

export const updateCommentCount = createAction(
  '[Comments] Update comment count',
  props<{ commentCountByParentId: { [key: string]: number } }>()
);

export const deleteComments = createAction(
  '[Comments] Delete comments from store',
  props<{ messageIds: string[] }>()
);

export const removeDraftComment = createAction(
  '[Comments] Remove draft comment'
);

export const updateDraftComment = createAction(
  '[Comments] Update draft comment',
  props<{ comment: Partial<DraftMessageEntity> }>()
);

