import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SocketIoService, UserProfileResponse } from '@portal/wen-backend-api';
import { WenSnackbarOpener } from '@portal/wen-components';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, switchMapTo, takeUntil, throttleTime } from 'rxjs/operators';
import { FeatureEnablementService } from '../../../../../core/services/configuration/feature-enablement';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { selectCurrentUserData } from '../../../../../core/store/auth/auth.selectors';
import { RootState } from '../../../../../core/store/root/public-api';
import { selectUserProfile } from '../../../../../core/store/user/user.selectors';

const EMAIL_VERIFICATION_COOLDOWN_MILLISEC = 60000;

@Component({
  selector: 'wen-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.scss']
})
export class EmailSettingsComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  private verificationSubject = new Subject<void>();

  userProfile$: Observable<UserProfileResponse>;
  changeEmailAddressButtonLabel$: Observable<string>;

  constructor(
    private readonly store: Store<RootState>,
    private readonly navigationHelper: WenNavigationHelper,
    private readonly socketIoService: SocketIoService,
    private readonly translateService: TranslateService,
    private readonly wenSnackbar: WenSnackbarOpener,
    public readonly featureEnablementService: FeatureEnablementService,
  ) { }

  ngOnInit(): void {
    this.userProfile$ = this.store.pipe(
      select(selectUserProfile),
      shareReplay(1),
    );

    this.changeEmailAddressButtonLabel$ = this.userProfile$.pipe(
      map(userProfile => {
        const translationKey = userProfile?.email ?
          'USER_PROFILE_EMAIL_SETTINGS_CHANGE_EMAIL_BUTTON_LABEL' :
          'USER_PROFILE_EMAIL_SETTINGS_SET_EMAIL_BUTTON_LABEL';
        return this.translateService.instant(translationKey);
      })
    );

    this.verificationSubject.pipe(
      throttleTime(EMAIL_VERIFICATION_COOLDOWN_MILLISEC),
      switchMapTo(this.store.select(selectCurrentUserData)),
      takeUntil(this.onDestroy$)
    ).subscribe(({ userId }) => {
      this.socketIoService.user.requestVerifyEmail.emit({ userId });
      this.wenSnackbar.openNotificationSnackbar({
        notificationIcon: 'icon_mail',
        notificationText: this.translateService.instant('SNACKBAR_LABEL_VERIFICATION_EMAIL_IS_SENT')
      });
    });
  }

  verifyEmailAddress(userProfile: UserProfileResponse) {
    if (userProfile && userProfile.email && !userProfile.emailVerified) {
      this.verificationSubject.next();
    }
  }

  changeEmailAddress() {
    this.navigationHelper.navigateToEmailEditPage();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
