import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Renderer2, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DelayedOperationScheduler } from '@portal/wen-components';
import { BehaviorSubject, EMPTY, Subject } from 'rxjs';
import { distinctUntilChanged, first, map, takeUntil } from 'rxjs/operators';
import { DataContextHelper } from '../../../core/services/util/data-context-helper';
import { FilterType } from '../../../core/store/filter/models/filter';
import { updateSearchbarActive } from '../../../core/store/header/header.actions';
import { selectSearchbarActive } from '../../../core/store/header/header.selectors';
import { SearchFilterId } from '../../../core/store/header/models/HeaderData';
import { reloadNavBarVisibility, updateNavBarVisibility } from '../../../core/store/navigation-bar/navigation-bar.actions';
import { RootState } from '../../../core/store/root/public-api';
import { FilterElementDirective } from '../../directives/filter-element/filter-element.directive';
import { SearchEventsHandlerDirective } from '../../directives/search-config/search-events-handler.directive';

@Component({
  selector: 'wen-search-bar',
  templateUrl: 'search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy, AfterViewInit {

  private inputWidthScheduler = new DelayedOperationScheduler();

  @HostBinding('class.wen-search-bar') className = true;
  @HostBinding('class.wen-search-bar-active') isActive = false;

  searchInputElement: ElementRef<HTMLElement>;
  @ViewChild('searchInput') set setSearchInputElement(value: ElementRef<HTMLElement>) {
    this.searchInputElement = value;
    this.inputWidthScheduler.setReady();
    this.inputWidthScheduler.flush();
  }

  translatedSearchBarPlaceholder = '';

  @Input() set disabled(value: boolean) {
    if (value) {
      this.onCancel();
    }
  }

  @Input() set searchBarPlaceholder(newValue: string) {
    this.translatedSearchBarPlaceholder = this.translateService.instant(newValue);
    const inputWidth = this.isActive ? '100%' : null;
    this.updateSearchBarInputWidth('none', inputWidth);
  }

  get searchBarPlaceholder(): string {
    return this.translatedSearchBarPlaceholder;
  }

  get clearButtonVisibility() {
    return !this.inputValue ? 'none' : 'block';
  }

  inputValue: string;
  onDestroy$ = new Subject<void>();
  input$ = new Subject<string>();
  focused$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly store: Store<RootState>,
    private readonly translateService: TranslateService,
    private readonly renderer: Renderer2,
    private readonly dataContextHelper: DataContextHelper,
    @Optional() private readonly filterElementDirective: FilterElementDirective,
    @Optional() private readonly searchEventsHandlerDirective: SearchEventsHandlerDirective,
  ) { }

  ngOnInit() {
    this.input$.pipe(
      map(searchTerm => searchTerm?.trim()),
      takeUntil(this.onDestroy$)
    ).subscribe((searchTerm) => {
      this.inputValue = searchTerm;
      this.filterElementDirective.updateFilter(searchTerm, FilterType.SEARCH);
    });
    this.resetSearchBarOnContextChange();
  }

  ngAfterViewInit() {
    (this?.filterElementDirective?.activeFilter$ || EMPTY).pipe(
      first(),
    ).subscribe((activeFilter => {
      if (activeFilter?.filterType === FilterType.SEARCH) {
        const searchTerm = activeFilter.filterValue;
        this.inputValue = searchTerm;
        this.updateSearchBarInputWidth(null, searchTerm ? '100%' : null);
      }
    }));

    this.store.pipe(
      select(selectSearchbarActive),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$)
    ).subscribe((isActive => {
      this.isActive = isActive;
      if (isActive) {
        this.onActivate();
      }
    }));

  }

  onChange(searchTerm: string) {
    this.input$.next(searchTerm);
  }

  onCancel() {
    this.onClear();
    this.onDeactivate();
  }

  resetView() {
    this.inputValue = null;
    this.onDeactivate();
  }

  onClear() {
    this.input$.next(null);
  }

  onFocus() {
    if (!this.searchInputElement) {
      return;
    }
    if (this.searchEventsHandlerDirective) {
      this.searchEventsHandlerDirective.onSearchnputFocused();
    }

    this.input$.next(this.inputValue);
    this.searchInputElement.nativeElement.focus();
    this.store.dispatch(updateSearchbarActive({ active: true }));
  }

  private onActivate() {
    const transition = this.inputValue ? 'none' : 'width 200ms ease-in-out';
    this.updateSearchBarInputWidth(transition, '100%');
    if (![SearchFilterId.ADMIN_SEARCH, SearchFilterId.USER_SEARCH].includes(this.filterElementDirective?.filterId as SearchFilterId)) {
      this.store.dispatch(updateNavBarVisibility({ isNavBarVisible: false }));
    }
  }

  private onDeactivate() {
    this.searchInputElement?.nativeElement.blur();
    this.updateSearchBarInputWidth();
    this.store.dispatch(updateSearchbarActive({ active: false }));
    this.store.dispatch(reloadNavBarVisibility());
  }

  private updateSearchBarInputWidth(transition: string = null, widthOverride: string = null) {
    this.inputWidthScheduler.scheduleOrRun(() => {
      if (this.searchInputElement) {
        const matFormFieldInfixElement = this.searchInputElement.nativeElement.parentElement;
        const width = widthOverride || `${this.translatedSearchBarPlaceholder.length * 10}px`;
        this.renderer.setStyle(matFormFieldInfixElement, 'width', width);
        if (transition) {
          this.renderer.setStyle(matFormFieldInfixElement, 'transition', transition);
        }
      }
    });
  }

  private resetSearchBarOnContextChange() {
    this.dataContextHelper.onDataContextChanges().pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.resetView();
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }

}
