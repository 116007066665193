import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { WenHistoryState } from '@portal/wen-components';
import { CHAT_CREATE_FROM_FORWARD_QUERY_PARAM, CHAT_CREATE_FROM_SHARE_QUERY_PARAM } from '../../core/services/navigation/query-params';
import { upsertHeaderData } from '../../core/store/header/header.actions';
import { RootState } from '../../core/store/root/public-api';

@Injectable()
export class ChatCreateHeaderDataUpdater {

  constructor(
    private readonly store: Store<RootState>,
    private readonly activatedRoute: ActivatedRoute,
    private readonly locationStrategy: LocationStrategy
  ) { }

  initialize() {
    let headerTitle = 'CHAT_CREATION_TITLE';
    const locationState: WenHistoryState = this.locationStrategy.getState();
    if (locationState.fromDeepLink) {
      return;
    }
    if (this.activatedRoute.snapshot.queryParams[CHAT_CREATE_FROM_SHARE_QUERY_PARAM]) {
      headerTitle = 'CHAT_CREATION_TITLE_FOR_SHARE';
    }
    if (this.activatedRoute.snapshot.queryParams[CHAT_CREATE_FROM_FORWARD_QUERY_PARAM]) {
      headerTitle = 'FORWARD_TITLE';
    }
    this.store.dispatch(upsertHeaderData({ headerData: { title: headerTitle } }));
  }

}
