import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EmbedDTOType } from '@portal/wen-backend-api';
import { EmbeddedExpandable } from '../../../../../core/store/channel/channel.state';

@Component({
  selector: 'wen-manifest-view',
  templateUrl: './manifest-view.component.html',
  styleUrls: ['./manifest-view.component.scss']
})
export class ManifestViewComponent implements OnInit {

  embeds = new Array(12).fill(null).map((_, index) => {
    const embed: EmbeddedExpandable = {
      type: EmbedDTOType.EXPANDABLE,
      title: this.translateService.instant(`MANIFEST_P${index + 1}_TIITLE`),
      description: this.translateService.instant(`MANIFEST_P${index + 1}_DESCRIPTION`),
    };
    return embed;
  });

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

}
