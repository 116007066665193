
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { filter, first, map, switchMap } from 'rxjs';
import { selectorWithParam } from '../../common/util/selector-with-param';
import { selectActiveNetwork } from '../network/network.selectors';
import { RootState } from '../root/public-api';
import { addPinItem, pinItem, removePinnedItem, unPinItem, updatePinEntities } from './pin.actions';
import { selectPinItem } from './pin.selectors';

@Injectable()
export class PinEffects {

  onPinSummary$ = createEffect(() => this.socketIoService.pin.summary.listen.pipe(
    first(),
    map((response) => updatePinEntities({ entities: response }))
  ));

  pin$ = createEffect(() => this.actions$.pipe(
    ofType(pinItem),
    switchMap(({ contextId, contextType }) => this.store.pipe(
      select(selectActiveNetwork),
      filter(network => !!network),
      switchMap((activeNetwork) => {
        this.socketIoService.pin.pin.emit({
          contextId,
          contextType,
          network: activeNetwork.id
        });
        return this.socketIoService.pin.pin.listen.pipe(
          filter(response => !!response?.id),
          map((response) => addPinItem({ pinEntity: response }))
        );
      })
    ))
  ));

  unpin$ = createEffect(() => this.actions$.pipe(
    ofType(unPinItem),
    switchMap(({ id }) => this.store.pipe(
      selectorWithParam(selectPinItem, id),
      first()
    )),
    switchMap((pinEntity) => {
      this.socketIoService.pin.unpin.emit({ id: pinEntity.id });
      return this.socketIoService.pin.unpin.listen.pipe(
        filter(response => response?.success),
        map(response => removePinnedItem({ id: response.id })),
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private socketIoService: SocketIoService
  ) { }

}
