import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';
import { LoadResultMode } from '../../../../../core/common/types/misc';
import { selectorWithParam } from '../../../../../core/common/util/selector-with-param';
import { selectUserApps, selectUserAppsLoaded } from '../../../../../core/store/apps/apps.selectors';
import { AppEntity } from '../../../../../core/store/apps/apps.state';
import { selectSearchTerm } from '../../../../../core/store/filter/filter.selectors';
import { FilterEntityType } from '../../../../../core/store/filter/models/filter';
import { AppListLoadResult } from '../../models/AppLoadResult';

@Injectable()
export class UserAppListDataSource {

  public readonly loadResult$: Observable<AppListLoadResult>;

  constructor(
    private readonly store: Store,
  ) {
    const filteredApps$ = this.store.pipe(
      select(selectUserApps),
      switchMap(apps => this.store.pipe(
        selectorWithParam(selectSearchTerm, FilterEntityType.APP_LISTS),
        distinctUntilChanged(),
        map(searchTerm => this.filterApps(apps, searchTerm)),
        map(filteredApps =>
          filteredApps.map((app) => {
            return {
              ...app,
              isSubscribed: true
            };
          })
        )
      )),
      shareReplay(1)
    );

    this.loadResult$ = combineLatest([
      this.store.pipe(
        selectorWithParam(selectSearchTerm, FilterEntityType.APP_LISTS),
      ),
      this.store.pipe(select(selectUserAppsLoaded))
    ]).pipe(
      switchMap(([searchTerm, loaded]) => {
        if (!loaded) {
          return of({ mode: LoadResultMode.NORMAL, data: null });
        }
        return filteredApps$.pipe(
          map(apps => ({ mode: searchTerm?.length ? LoadResultMode.SEARCH : LoadResultMode.NORMAL, data: apps }))
        );
      })
    );
  }

  private filterApps(apps: AppEntity[], searchTerm: string): AppEntity[] {
    if (!searchTerm) {
      return apps;
    }
    return apps.filter(app => app.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
  }

}
