import { ChangeDetectionStrategy, Component, HostBinding, Input, Optional, ViewEncapsulation } from '@angular/core';
import { MediaViewParams } from '../../models/models';
import { DownloaderService, getFileExtension, getIconForSupportedFileExtension } from '@portal/wen-components';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'wen-embed-file',
  templateUrl: './embed-file.component.html',
  styleUrls: ['./embed-file.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbedFileComponent {
  @HostBinding('class.wen-file-embed') className = true;
  params: MediaViewParams;
  fileSize: number;
  fileIcon: string;
  isDownloading$ = new Observable<boolean>();

  constructor(
    @Optional() private downloadService: DownloaderService
  ) {
    this.isDownloading$ = this.downloadService?.ongoingDownloads$.pipe(
      map(ongoingDownloads =>
        ongoingDownloads.has(this.params.sourceUrl)
      )
    );
  }

  @Input() set config(params: MediaViewParams) {
    this.params = params;
    this.fileIcon = this.getIconForFile(params);
    this.fileSize = this.params.sizeInBytes;
  }

  onMediaClick() {
    this.downloadService.downloadInBackground(
      this.params.sourceUrl,
      this.params.mimeType,
      this.params.mediaType,
      this.params.title
    );
  }

  private getIconForFile(params: MediaViewParams): string {
    const fileExtension = getFileExtension(params.title);
    return getIconForSupportedFileExtension(fileExtension);
  }
}

