import { MinimizeNativeAppBackNavigation } from '../../../core/services/navigation/back-navigation/back-navigation-behaviors/minimize-native-app-behavior';
import { SuppressBackNavigationOrMinimizeApp } from '../../../core/services/navigation/back-navigation/back-navigation-behaviors/suppress-back-navigation-or-minimize-app-behavior';
import { WenRoute, WenRouteId } from '../../../frame/routing/types';
import { AppLogoComponent } from '../../../shared/components/app-logo/app-logo.component';
import { HomeComponent } from '../components/home/home.component';
import { DataContext } from '../../../core/common/types/data-context';

export const homePageRoute: WenRoute = {
  path: '',
  component: HomeComponent,
  data: {
    routeId: WenRouteId.HOME,
    routeTransitionEnabled: true,
    backNavigationBehavior: SuppressBackNavigationOrMinimizeApp,
    dataContext: DataContext.HOME,
  }
};

export const dashboardRoute: WenRoute = {
  path: 'dashboard',
  component: AppLogoComponent,
  data: {
    routeId: WenRouteId.DASHBOARD,
    backNavigationBehavior: MinimizeNativeAppBackNavigation,
  }
};
