import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { WenSelectionListModule } from '@portal/wen-components';
import { SharedModule } from '../../../../../shared/shared.module';
import { UserVisibilitySelectionListComponent } from './components/user-visibility-selection-list/user-visibility-selection-list.component';

import { UserVisibilityComponent } from './user-visibility.component';

@NgModule({
  imports: [
    SharedModule,
    MatSlideToggleModule,
    TranslateModule.forChild(),
    WenSelectionListModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    CommonModule,
  ],
  declarations: [
    UserVisibilityComponent,
    UserVisibilitySelectionListComponent
  ],
  exports: [UserVisibilityComponent]
})
export class UserVisibilityModule { }
