import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { DialogLayoutModule, DateFieldModule } from '@portal/wen-components';
import { DateRangeAdapter } from './date-range-value-adapter';
import { DateRangePickerComponent } from './date-range-picker.component';

@NgModule({
  imports: [
    CommonModule,
    DateFieldModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatDialogModule,
    DialogLayoutModule,
    TranslateModule.forChild(),
    MatNativeDateModule
  ],
  providers: [DateRangeAdapter],
  exports: [DateRangePickerComponent],
  declarations: [DateRangePickerComponent],
})
export class DateRangePickerModule { }
