import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'wen-file-drop-overlay',
  templateUrl: './file-drop-overlay.component.html',
  styleUrls: ['./file-drop-overlay.component.scss']
})
export class FileDropOverlayComponent implements OnInit {

  overlayLabel = this.translateService.instant('FILE_DROP_TARGET_OVERLAY_LABEL');

  constructor(private translateService: TranslateService) { }

  ngOnInit(): void {
  }

}
