import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { firstExisty } from './first-existy';

export const switchMapFirst = <T, R>(projector: (val: T) => Observable<R>) => (source: Observable<T>): Observable<R> => {
  return source.pipe(
    switchMap(outerVal =>
      projector(outerVal).pipe(
        first()
      )
    )
  );
};

export const switchMapFirstExisty = <T, R>(projector: (val: T) => Observable<R>) => (source: Observable<T>): Observable<R> => {
  return source.pipe(
    switchMap(outerVal =>
      projector(outerVal).pipe(
        firstExisty()
      )
    )
  );
};
