import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectionOption } from '../../../wen-selection-list/types';
import { SelectorDialogData } from '../../models/dialog-data';

export interface SelectorDialogResult {
  result?: SelectionOption<string>[];
}

@Component({
  selector: 'wen-selector-dialog',
  templateUrl: './selector-dialog.component.html',
  styleUrls: ['./selector-dialog.component.scss']
})
export class SelectorDialogComponent implements AfterViewInit {

  selectionModel = new SelectionModel(true);
  possibleItems: SelectionOption<string>[] = this.dialogData.possibleValues;

  formGroup = new FormGroup({
    selectedElements: new FormControl()
  });

  get mainLabel() {
    return this.dialogData.mainLabel;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: SelectorDialogData,
    private dialogRef: MatDialogRef<SelectorDialogComponent, SelectorDialogResult>,
    private cdr: ChangeDetectorRef,
  ) { }

  ngAfterViewInit(): void {
    this.formGroup.controls.selectedElements.setValue(this.dialogData.activeValues);
    this.cdr.detectChanges();
  }

  onSubmit() {
    this.dialogRef.close({
      result: this.formGroup.controls.selectedElements.value
    });
  }

}
