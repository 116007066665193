import { inject, Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { combineLatest, filter, first, of, switchMap } from 'rxjs';
import { FormStoreMediator } from '../../../../shared/form-store/form-store-mediator';
import { selectCurrentUserData } from '../../auth/auth.selectors';
import { UserData } from '../../auth/models/UserData';
import { DataObjectType } from '../../common/data-objects';
import { doBackNavigation } from '../../header/header.actions';
import { RootState } from '../../root/public-api';
import { upsertUserMetaDataSettings } from '../../user/user.actions';
import { clearFormValues } from '../form.actions';
import { selectEditFormById } from '../form.selectors';
import { EditFormEntity } from '../form.state';
import { PrivacySettingsChangedValues } from '../types/privacy-settings.types';

@Injectable()
export class PrivacySettingsFormEffects {

  private socketIoService = inject(SocketIoService);
  private formStoreMediator = inject(FormStoreMediator);
  private store = inject(Store<RootState>);

  savePrivacySettings$ = this.formStoreMediator.createSaveEditFormEffect((saveAction) => of(saveAction).pipe(
    filter(action => action.dataObjectType === DataObjectType.PRIVACY_SETTINGS),
    switchMap(({ formId }) => combineLatest([
      this.store.pipe(select(selectEditFormById(formId))),
      this.store.pipe(select(selectCurrentUserData))
    ]).pipe(first())),
    switchMap(([editForm, userData]: [EditFormEntity<PrivacySettingsChangedValues>, UserData]) => {
      const { formId, changedValues } = editForm;
      const changedSettings = changedValues;
      const actions: Action[] = [];
      actions.push(doBackNavigation());
      if (!changedSettings || Object.keys(changedSettings).length < 1) {
        return actions;
      }
      const { traceReportsEnabled } = changedSettings;
      const { userId } = userData;
      this.socketIoService.user.update.emit({ userId, loggingAllowed: traceReportsEnabled });
      actions.push(upsertUserMetaDataSettings({ newSettings: { traceReportsEnabled } }));
      actions.push(clearFormValues({ formId }));
      return actions;
    }),
  ));

}
