import { createFeature, createReducer, on } from '@ngrx/store';
import { addRouteAsLocated } from './christmas.actions';
import { ChristmasState } from './christmas.state';
import { christmasFeatureKey } from './constants';

export const christmasInitialState: ChristmasState = {
  locatedPages: []
};


export const christmasFeature = createFeature({
  name: christmasFeatureKey,
  reducer: createReducer(
    christmasInitialState,
    on(addRouteAsLocated, (state, { routeId }) => {
      return {
        ...state,
        locatedPages: [...state.locatedPages, routeId]
      };
    })
  )
});
