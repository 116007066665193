import { EmbeddedMediaDTO, EmbedDTOTypes, EncryptedMessageEventResponses, ForwardData, isEncryptedMessageEventResponse, isPlainMessageEventResponse, MediaUploadResponse, MemberState, MessageEvent, MessageModificationState, RoomDTO, RoomMembers, RoomType } from '@portal/wen-backend-api';
import { partitionArray } from '@portal/wen-common';
import { mergeArrays } from '../../../common/operators/array-utils';
import { isNullOrUndefined } from '../../../common/operators/null-check-util';
import { UserData } from '../../auth/models/UserData';
import { EmbeddedMedia } from '../../channel/channel.state';
import { isForwardEmbed, isMediaEmbed } from '../../channel/util/embed-type-helper';
import { ChatMessageEntity, ChatRoomEntity, RoomDetails } from '../chat.state';

export const toChatRoomEntity = (room: RoomDTO): ChatRoomEntity => {
  return {
    id: room.id,
    details: toRoomDetails(room),
    members: [toRoomMembers(room)]
  };
};

export const toRoomDetails = (room: RoomDTO): RoomDetails => {
  return {
    description: room.description,
    icon: room.customIcon ?? room.icon,
    title: room.customTitle ?? room.title,
    type: room.type,
    version: room.version,
    timestamp: room.memberSince,
    isMuted: room.isMuted
  };
};

const toRoomMembers = (room: RoomDTO): RoomMembers => {
  return {
    userId: null,
    memberSince: room.memberSince,
    state: room.memberState,
  };
};

export const isCurrentUserAnOwner = (members: RoomMembers[], currentUser: UserData) => {
  return members.some(member => member.userId === currentUser.userId && member.state === MemberState.OWNER);
};

export const isGroupChatRoom = (roomEntity: ChatRoomEntity) => {
  return roomEntity?.details?.type === RoomType.GROUP;
};

/** To identify which event is encrypted or plain */
export const filterRoomEvents = (roomEvents: MessageEvent[]) => {
  return partitionArray(roomEvents, (event: MessageEvent<EncryptedMessageEventResponses>) => {
    return isEncryptedMessageEventResponse(event.payload) || isPlainMessageEventResponse(event.payload);
  });
};

export const updateMessageMediaEmbedByUploadStatus = <T extends ChatMessageEntity>(messageEntity: T, status: MediaUploadResponse) => {
  if (messageEntity.state === MessageModificationState.DELETED) {
    return messageEntity;
  }

  const incomingMediaInfo: EmbeddedMediaDTO = {
    ...status.dbMediaMetadata,
    contextId: status.contexts[0].compositeId
  };

  return updateMessageMediaEmbed(messageEntity, incomingMediaInfo);
};

export const updateMessageMediaEmbed = <T extends ChatMessageEntity>(messageEntity: T, mediaEmbed: EmbeddedMedia) => {
  const { embeds } = messageEntity.messageContent || {};
  const newEmbeds = embeds?.map((embed: EmbedDTOTypes) => {
    if (isMediaEmbed(embed)) {
      if (embed.uploadId === mediaEmbed.uploadId) {
        return {
          ...embed,
          ...mediaEmbed
        };
      }
    }
    if (isForwardEmbed(embed)) {
      const forwardData = embed.forwardData;
      if (Boolean(forwardData?.data?.embeds)) {
        const updatedMediaEmbeds = mergeArrays(forwardData.data.embeds, [mediaEmbed], 'uploadId');
        const updatedForwardData: ForwardData = {
          ...forwardData,
          data: {
            ...forwardData.data,
            embeds: updatedMediaEmbeds
          }
        };
        return {
          ...embed,
          forwardData: updatedForwardData
        };
      }
    }
    return embed;
  });

  const newMessageEntity: T = {
    ...messageEntity,
    messageContent: {
      ...messageEntity.messageContent,
      embeds: newEmbeds
    }
  };
  return newMessageEntity;
};

export const cleanupFileEmbedCacheForRoom = <T extends ChatRoomEntity>(roomEntity: T, status: MediaUploadResponse) => {
  if (isNullOrUndefined(roomEntity.fileEmbedCache) || Object.keys(roomEntity.fileEmbedCache).length === 0) {
    return roomEntity;
  }
  const { [status.uploadId]: _, ...updatedCache } = roomEntity.fileEmbedCache;
  return {
    ...roomEntity,
    fileEmbedCache: updatedCache
  };
};
