import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateWithIconModule } from '@portal/wen-components';
import { HeaderModule } from '../../../../../frame/components/header/header.module';
import { SharedModule } from '../../../../../shared/shared.module';
import { ChannelRestrictionSettingsComponent } from './channel-restriction-settings.component';

@NgModule({
  imports: [
    SharedModule,
    HeaderModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateWithIconModule
  ],
  declarations: [
    ChannelRestrictionSettingsComponent,
  ],
  exports: [
    ChannelRestrictionSettingsComponent,
  ]
})
export class ChannelRestrictionSettingsModule { }
