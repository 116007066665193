import { WenRoute, WenRouteId } from '../../frame/routing/types';
import { ReaderComponent } from './components/reader/reader.component';
import { TextViewComponent } from './components/text-view/text-view.component';
import { textViewIdentifier } from './tokens';

export const readerRoute: WenRoute = {
  path: '',
  component: ReaderComponent,
  data: {
    routeId: WenRouteId.READER_VIEW
  }
};

export const textViewRoute: WenRoute = {
  path: `textview/:${textViewIdentifier}`,
  component: TextViewComponent,
  data: {
    routeId: WenRouteId.TEXT_VIEW
  }
};
