import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, first, map } from 'rxjs';
import { PermissionLevel } from '../../../../core/services/user-management/permission-level';
import { selectCurrentUserData } from '../../../../core/store/auth/auth.selectors';
import { RootState } from '../../../../core/store/root/public-api';
import { ForbiddenForAnonymousUserDialogService } from '../../../services/forbidden-for-anonymous-user-dialog.service';
import { MessageSendFieldDataSource } from '../../message-send-field/providers/message-send-field-data-source';

@Component({
  selector: 'wen-comment-message-send-field',
  templateUrl: './comment-message-send-field.component.html',
  styleUrls: ['./comment-message-send-field.component.scss'],
})
export class CommentMessageSendFieldComponent implements OnChanges {

  @Input() parentId: string;

  isAnonymousUser$: Observable<boolean>;

  constructor(
    private readonly dataSource: MessageSendFieldDataSource,
    private readonly anonymousDialogService: ForbiddenForAnonymousUserDialogService,
    private readonly store: Store<RootState>
  ) {
    this.isAnonymousUser$ = store.pipe(
      select(selectCurrentUserData),
      map(user => user.permissionLevel === PermissionLevel.ANONYMOUS),
      first()
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.parentId && changes.parentId.currentValue) {
      this.dataSource.initialize(changes.parentId.currentValue);
    }
  }

  onMessageSent() {
    this.dataSource.sendMessage();
  }

  onClick() {
    this.anonymousDialogService.openCommentCreationForbiddenWhenAnonymous().subscribe();
  }
}
