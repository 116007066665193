import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, catchError, first, map, of, switchMap } from 'rxjs';
import { FeatureEnablementService } from '../configuration/feature-enablement';
import { WenNavigationHelper } from '../navigation/types';
import { NetworkLoadedGuard } from '../network/network-loaded-guard';
import { WenNativeApi } from '@portal/wen-native-api';
import { WenStorageService } from '../storage/wen-storage.service';
import { ExclusiveInviteHelper } from '../util/exclusive-invite-helper';
import { AuthStateValidator } from './auth-state-validator.service';
import { WenOAuthService } from './wen-oauth.service';

export const onboardingAnonymousAutoLoginGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(OnboardingAnonymousAutoLoginGuard).canActivate(route, state);
};

@Injectable()
export class OnboardingAnonymousAutoLoginGuard {

  constructor(
    private router: Router,
    private storageService: WenStorageService,
    private nativeApi: WenNativeApi,
    private authStateValidator: AuthStateValidator,
    private wenOAuthService: WenOAuthService,
    private featureConfiguration: FeatureEnablementService,
    private navigationHelper: WenNavigationHelper,
    private exclusiveInviteHelper: ExclusiveInviteHelper,
    private networkLoadedGuard: NetworkLoadedGuard,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    if (!this.storageService.isOnboardingDone() && this.nativeApi.isReactNativeApp() &&
      !this.featureConfiguration.featureFlagMethods.isEnableSkipOnboarding()) {
      return of(this.router.parseUrl('/onboarding'));
    }
    return this.authStateValidator.validateAuthState().pipe(
      switchMap((userData) => {
        const currentUrl = state.url;
        const isCodeDialogNeeded = this.exclusiveInviteHelper.isCodeDialogNeeded();
        if (isCodeDialogNeeded) {
          this.navigationHelper.navigateToExclusiveInvite(currentUrl);
          return of(false);
        }
        return this.networkLoadedGuard.canActivateChild(route, state).pipe(
          first(),
          map(() => Boolean(userData))
        );
      }),
      catchError(() => {
        return this.wenOAuthService.initCodeFlow({
          redirectAfterLogin: state.url,
          anonymousAutoLogin: true,
          needsDataProtectionDialog: true
        }).pipe(
          map(() => false),
          catchError(() => {
            this.navigationHelper.navigateToErrorPage();
            return of(false);
          })
        );
      })
    );
  }

}
