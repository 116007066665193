import { Injectable } from '@angular/core';
import { ChannelConfiguration } from '@portal/wen-backend-api';
import { of } from 'rxjs';
import { FormValueConverter } from '../../../../../shared/form-store/form-store.providers';

type ChannelSettingsFormModel = {
  [key in string]: ChannelConfiguration;
};

@Injectable()
export class ChannelSettingsFormValueConverter extends FormValueConverter<ChannelSettingsFormModel, Partial<ChannelConfiguration>> {

  convertToFormModel(channelSettings: ChannelSettingsFormModel) {
    return of(channelSettings);
  }

  convertToServerModel(channelSettings: ChannelSettingsFormModel) {
    const newConfiguration = Object.keys(channelSettings).reduce((acc, formKey) => {
      const formValues = channelSettings[formKey];
      return { ...acc, ...formValues };
    }, {});
    return of(newConfiguration);
  }

}
