import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, shareReplay, Subject, takeUntil } from 'rxjs';
import { removeDraftComment, sendComment, updateDraftComment } from '../../../../core/store/comments/comments.actions';
import { selectDraftComment } from '../../../../core/store/comments/comments.selectors';
import { DraftMessageEntity } from '../../../../core/store/common/models';
import { RootState } from '../../../../core/store/root/public-api';
import { MessageSendFieldDataSource } from '../../message-send-field/providers/message-send-field-data-source';

@Injectable()
export class CommentMessageSendFieldDataSource extends MessageSendFieldDataSource implements OnDestroy {

  private parentId$ = new BehaviorSubject<string>(null);
  private onDestroy$ = new Subject<void>();

  draftMessage$: Observable<DraftMessageEntity> = this.store.pipe(
    select(selectDraftComment),
    shareReplay(1),
    takeUntil(this.onDestroy$),
  );

  constructor(
    private store: Store<RootState>
  ) {
    super();
  }

  initialize(parentId: string) {
    this.parentId$.next(parentId);
  }

  updateDraftMessage(message: Partial<DraftMessageEntity>) {
    this.store.dispatch(updateDraftComment({ comment: { ...message, contextId: this.parentId$.getValue() } }));
  }

  discardDraftMessage() {
    this.store.dispatch(removeDraftComment());
  }

  sendMessage() {
    this.store.dispatch(sendComment());
  }

  ngOnDestroy() {
    this.store.dispatch(removeDraftComment());

    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
