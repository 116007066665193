import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { DialogLayoutModule } from '../dialog-layout/dialog-layout.module';
import { WenIconModule } from '../wen-icon/wen-icon.module';
import { WenSelectionListModule } from '../wen-selection-list/wen-selection-list.module';
import { SelectComponent } from './components/select/select.component';
import { SelectorDialogComponent } from './components/selector-dialog/selector-dialog.component';
import { WenTestIdModule } from '../../directives/public-api';


@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    WenIconModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    TranslateModule,
    DialogLayoutModule,
    MatDialogModule,
    WenSelectionListModule,
    WenTestIdModule
  ],
  declarations: [
    SelectComponent,
    SelectorDialogComponent
  ],
  exports: [
    SelectComponent,
    SelectorDialogComponent
  ]
})
export class SelectModule { }
