import { Component, HostBinding, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MemberState } from '@portal/wen-backend-api';
import { ConfirmationDialogComponent, OverlayManager } from '@portal/wen-components';
import { combineLatest, distinctUntilChanged, filter, first, map, Observable, shareReplay, switchMap, withLatestFrom } from 'rxjs';
import { WenNavigationHelper } from '../../../core/services/navigation/types';
import { selectCurrentUserData } from '../../../core/store/auth/auth.selectors';
import { leaveRoom } from '../../../core/store/chat/actions/chat-member.actions';
import { selectCurrentRoom } from '../../../core/store/chat/chat.selectors';
import { ChatRoomEntity } from '../../../core/store/chat/chat.state';
import { isCurrentUserAnOwner } from '../../../core/store/chat/utils/chat-room-utils';
import { RootState } from '../../../core/store/root/public-api';
import { ChatHeaderDataUpdater } from '../../../shared/chat-specific/chat-view-header-data-updater';
import { DetailHeaderModel } from '../../../shared/components/detail-header/detail-header.component';
import { maxNumberOfUsersForChatGroup } from '../constants';

interface ChatDetailContent {
  description: string;
  id: string;
  memberCount: number;
  isMuted: boolean;
}

@Component({
  selector: 'wen-chat-detail',
  templateUrl: './chat-detail.component.html',
  styleUrls: ['./chat-detail.component.scss'],
  providers: [ChatHeaderDataUpdater]
})
export class ChatDetailComponent implements OnInit {
  headerData$: Observable<DetailHeaderModel>;
  roomDetails$: Observable<ChatDetailContent>;
  isAdmin$: Observable<boolean>;
  maxNumberOfUsers = maxNumberOfUsersForChatGroup;
  isMuted$: Observable<boolean>;
  private currentUser$ = this.store.pipe(select(selectCurrentUserData));
  private currentRoom$: Observable<ChatRoomEntity>;

  @HostBinding('class.wen-chat-detail') className = true;

  constructor(
    private store: Store<RootState>,
    private chatHeaderDataUpdater: ChatHeaderDataUpdater,
    private navigationHelper: WenNavigationHelper,
    private overlayManager: OverlayManager,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.chatHeaderDataUpdater.initialize();
    this.currentRoom$ = this.store.pipe(
      select(selectCurrentRoom),
      filter((roomEntity) => !!roomEntity),
      shareReplay(1)
    );
    this.roomDetails$ = this.currentRoom$.pipe(
      map(room => {
        return {
          description: room.details.description,
          id: room.id,
          isMuted: room.details.isMuted,
          memberCount: room.members.length
        };
      })
    );
    this.headerData$ = combineLatest([
      this.chatHeaderDataUpdater.headerData$,
      this.currentRoom$.pipe(
        filter(room => Boolean(room))
      )
    ]).pipe(
      map(([headerData, room]) => {
        const { title, subTitle } = headerData;
        const detailHeaderModel: DetailHeaderModel = {
          title,
          description: subTitle,
          imageUrl: room.details.icon
        };
        return detailHeaderModel;
      })
    );

    this.isAdmin$ = this.currentRoom$.pipe(
      withLatestFrom(this.currentUser$),
      map(([{ members }, currentUser]) => {
        return isCurrentUserAnOwner(members, currentUser);
      }),
      distinctUntilChanged()
    );
  }

  editRoom(chatId: string) {
    this.navigationHelper.navigateToChatEdit(chatId);
  }

  addMembers(chatId: string) {
    this.navigationHelper.navigateToGroupChatAddMembers(chatId);
  }

  leaveRoom(chatId: string) {
    if (!chatId) {
      return;
    }
    this.currentRoom$.pipe(
      first(),
      withLatestFrom(this.isAdmin$),
      switchMap(([room, currentUserIsAdmin]) => {
        const roomAdmins = room.members.filter(member => member.state === MemberState.OWNER);
        const isLastAdmin = currentUserIsAdmin && roomAdmins.length === 1;
        let dialogContent;
        if (isLastAdmin) {
          dialogContent = {
            header: this.translateService.instant('GROUP_CHAT_LEAVE_LAST_ADMIN_CONFIRM_DIALOG_TITLE'),
            content: this.translateService.instant('GROUP_CHAT_LEAVE_LAST_ADMIN_CONFIRM_DIALOG_TEXT'),
            okLabel: this.translateService.instant('EVERYTHING_IS_CLEAR_BUTTON_LABEL')
          };
        } else {
          dialogContent = {
            header: this.translateService.instant('GROUP_CHAT_LEAVE_CONFIRM_DIALOG_TITLE'),
            content: this.translateService.instant('GROUP_CHAT_LEAVE_CONFIRM_DIALOG_TEXT'),
            okLabel: this.translateService.instant('GROUP_CHAT_LEAVE_BUTTON_LABEL'),
            dismissLabel: this.translateService.instant('CANCEL_BUTTON_LABEL'),
          };
        }
        const dialog = this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, {
          ...dialogContent
        });
        return dialog.afterClosed().pipe(
          first(),
          filter(data => data?.result === 'ok' && !isLastAdmin)
        );
      })
    ).subscribe(() => {
      this.store.dispatch(leaveRoom());
    });
  }
}
