import { Component, inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { filter, map } from 'rxjs';
import { SchemaHandler } from '../../../../../core/services/schema/schema-handler';
import { selectCurrentChannelConfiguration } from '../../../../../core/store/channel/channel.selectors';
import { RootState } from '../../../../../core/store/root/public-api';
import { FormValueConverter } from '../../../../../shared/form-store/form-store.providers';
import { FormStoreService } from '../../../../../shared/form-store/form-store.service';
import { ChannelSettingsFormValueConverter } from './channel-settings-form-value.converter';

@Component({
  selector: 'wen-channel-settings',
  templateUrl: './channel-settings.component.html',
  styleUrls: ['./channel-settings.component.scss'],
  providers: [{
    provide: FormValueConverter,
    useClass: ChannelSettingsFormValueConverter
  }]
})
export class ChannelSettingsComponent implements OnInit {

  private schemaHandler: SchemaHandler = inject(SchemaHandler);
  channelSettingsFormGroup: FormGroup = new FormGroup({});

  settingsSchema$ = this.store.pipe(
    select(selectCurrentChannelConfiguration),
    filter(configuration => !!configuration),
    smartDistinctUntilChanged(),
    map((configuration) => {
      const { schema, config } = configuration;
      this.formStoreService.initializeForm(config);
      this.schemaHandler.initializeFormValue(config);
      return this.schemaHandler.processSchema(schema);
    })
  );

  constructor(
    private formStoreService: FormStoreService,
    private store: Store<RootState>
  ) { }

  ngOnInit() {
    this.formStoreService.clearFormData();
  }
}
