import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WenTestIdModule } from '../../directives/test-id/test-id.module';
import { WenSelectionListComponent } from './wen-selection-list.component';
import { SelectionListItemComponent } from './selection-list-item/selection-list-item.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { WenIconModule } from '../wen-icon/wen-icon.module';
import { TranslateModule } from '@ngx-translate/core';
import { CircleImageViewModule } from '../circle-image-view/circle-image-view.module';

@NgModule({
  declarations: [WenSelectionListComponent, SelectionListItemComponent],
  imports: [
    CommonModule,
    MatCheckboxModule,
    FormsModule,
    WenIconModule,
    TranslateModule.forChild(),
    CircleImageViewModule,
    WenTestIdModule
  ],
  exports: [WenSelectionListComponent]
})
export class WenSelectionListModule { }
