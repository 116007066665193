import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SocketIoService, ChannelDTO } from '@portal/wen-backend-api';
import { distinctUntilKeyChanged, filter, first, map, mergeMap, switchMap, withLatestFrom } from 'rxjs';
import { DataContext } from '../../../common/types/data-context';
import { fetchSectionContent, updateChannelExploreSectionLoadingState, updateSectionContent } from '../../sections/sections.actions';
import { updateChannels } from '../channel.actions';
import { Store, select } from '@ngrx/store';
import { RootState } from '../../root/public-api';
import { selectChannelExploreIsLoaded } from '../../sections/sections.selectors';
import { LoadingState } from '../../../common/types/store-loading-state';
import { NOTIFICATION_CHANNEL_ID } from '../../../../views/channel/channel-detail/components/channel-detail/channel-detail.component';

@Injectable()
export class ChannelSectionEffects {
  private readonly channelSections$ = createEffect(() => this.actions$.pipe(
    ofType(fetchSectionContent),
    distinctUntilKeyChanged('sectionId'),
    filter(({ dataContext }) => dataContext === DataContext.CHANNEL),
    withLatestFrom(this.store.pipe(select(selectChannelExploreIsLoaded))),
    filter(([_, isLoaded]) => !isLoaded),
    mergeMap(([{ params, sectionId }]) => {
      const requestParams = { filter: params.filter, size: params.limit, page: 0 };
      return this.socketIoService.channel.listForDiscover.acknowledgement$(requestParams).pipe(
        first(),
        map(({ content }) => content.filter(channel => channel.id !== NOTIFICATION_CHANNEL_ID)),
        switchMap(content => {
          return [
            updateChannels({ channels: content as ChannelDTO[] }),
            updateSectionContent({ data: content, sectionId }),
            updateChannelExploreSectionLoadingState({ loadingState: LoadingState.LOADED })
          ];
        })
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private socketIoService: SocketIoService,
    private store: Store<RootState>
  ) { }

}
