import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OccurenceEntity } from '../../../../../../../core/store/events/event.state';
import { EventListDataSource } from '../../../../providers/event-list-datasource';

@Component({
  selector: 'wen-user-event-list-item-after',
  templateUrl: './user-event-list-item-after.component.html',
  styleUrls: ['./user-event-list-item-after.component.scss']
})
export class UserEventListItemAfterComponent {

  eventSubscriptionCount$: Observable<number>;

  @Input() set event(ev: OccurenceEntity) {
    if (ev.eventId) {
      this.eventSubscriptionCount$ = this.eventListDataSource.selectSubscriptionFor(ev.eventId).pipe(
        map(sub => sub.subscriptionCount),
      );
    }
  }

  constructor(private eventListDataSource: EventListDataSource) { }

}
