import { distinctUntilChanged, fromEvent, map, merge, shareReplay, startWith } from 'rxjs';
import { ConnectionMonitor } from './types';
import { Injectable } from '@angular/core';

@Injectable()
export class BrowserConnectionMonitor implements ConnectionMonitor {
  public readonly isOnline$ = merge(
    fromEvent(window, 'online').pipe(map(() => true)),
    fromEvent(window, 'offline').pipe(map(() => false)),
  ).pipe(
    startWith(window.navigator.onLine),
    distinctUntilChanged(),
    shareReplay(1),
  );
}
