import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs';
import { switchMapFirst } from '../../../common/operators/switch-map-first';
import { RootState } from '../../root/public-api';
import { listChannelSubscribers, removeUserFromCurrentChannel, subscribeToChannelRoleUpdates, updateSubscriberList } from '../channel.actions';
import { selectCurrentChannel } from '../channel.selectors';

@Injectable()
export class ChannelSubscriberEffects {

  listChannelSubscribers$ = createEffect(() => this.actions$.pipe(
    ofType(listChannelSubscribers),
    switchMapFirst(() => this.store.pipe(select(selectCurrentChannel))),
    filter(channel => channel.permission?.canListSubscribers),
    tap(({ id: channelId }) => this.socketIoService.system.listSubscribers.emit({ channelId }))
  ), { dispatch: false });

  updateSubscriberList$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeToChannelRoleUpdates),
    switchMap(() => this.socketIoService.system.listSubscribers.listen.pipe(
      filter(({ users }) => Boolean(users?.length)),
      map(({ channelId, users, anonymousCount }) => {
        const subscribers = {
          subscribedUsers: users,
          anonymousUsers: anonymousCount
        };

        return updateSubscriberList({ channelId, users: subscribers });
      })
    ))
  ));

  removeSubscriberUserFromCurrentChannel$ = createEffect(() => this.actions$.pipe(
    ofType(removeUserFromCurrentChannel),
    filter(action => Boolean(action.userIdToRemove)),
    withLatestFrom(this.store.pipe(select(selectCurrentChannel))),
    tap(([{ userIdToRemove }, { id: channelId }]) => {
      this.socketIoService.channel.removeSubscriber.emit({ userId: userIdToRemove, channelId });
    })
  ), { dispatch: false });

  removeAnonymousSubscriberFromCurrentChannel$ = createEffect(() => this.actions$.pipe(
    ofType(removeUserFromCurrentChannel),
    filter(action => !Boolean(action.userIdToRemove)),
    switchMapFirst(() => this.store.pipe(select(selectCurrentChannel))),
    tap(({ id: channelId }) => {
      this.socketIoService.channel.removeSubscriber.emit({ userId: 'ANONYMOUS_USERS', channelId });
    }),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private socketIoService: SocketIoService,
    private store: Store<RootState>,
  ) { }
}
