import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, shareReplay } from 'rxjs';
import { AppConfigurationProvider } from '../../../../../../core/services/configuration/app-confguration';
import { selectCurrentChannel } from '../../../../../../core/store/channel/channel.selectors';
import { RootState } from '../../../../../../core/store/root/public-api';

@Injectable()
export class ChannelSelector {

  currentChannel$ = this.store.pipe(
    select(selectCurrentChannel),
    distinctUntilChanged((a, b) => a?.id === b?.id),
    shareReplay(1)
  );

  constructor(
    private store: Store<RootState>,
    private appConfiguration: AppConfigurationProvider,
    private router: Router
  ) { }

}
