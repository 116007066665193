import { Component, OnInit } from '@angular/core';
import { distinctUntilChanged, map, Observable } from 'rxjs';
import { MessageSendFieldDataSource } from '../../providers/message-send-field-data-source';

@Component({
  selector: 'wen-message-discard-edit-button',
  templateUrl: './message-discard-edit-button.component.html',
  styleUrls: ['./message-discard-edit-button.component.scss']
})
export class MessageDiscardEditButtonComponent implements OnInit {

  constructor(private readonly dataSource: MessageSendFieldDataSource) { }

  isEdit$ = new Observable<boolean>();

  ngOnInit(): void {
    this.isEdit$ = this.dataSource.draftMessage$.pipe(
      map(message => Boolean(message?.id)),
      distinctUntilChanged(),
    );
  }

  stopEditingMessage() {
    this.dataSource.discardDraftMessage();
  }

}
