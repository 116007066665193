import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinct, filter, map, mergeMap } from 'rxjs/operators';
import { LoadResultMode } from '../../../../core/common/types/misc';
import { selectorWithParam } from '../../../../core/common/util/selector-with-param';
import { selectDiscoveryEventsIsLoaded, selectDiscoveryListOccerences, selectSubscriptionForEvent, selectUserEventsIsLoaded, selectUserListOccerences } from '../../../../core/store/events/event.selectors';
import { SubscriptionEntity } from '../../../../core/store/events/event.state';
import { selectActiveFiltersByEntityType } from '../../../../core/store/filter/filter.selectors';
import { Filter, FilterEntityType, FilterType } from '../../../../core/store/filter/models/filter';
import { RootState } from '../../../../core/store/root/public-api';
import { mapToEventLoadResult } from '../../operators/map-to-event-load-result';
import { OccurenceLoadResult } from '../models/event-load-result';

@Injectable()
export class EventListDataSource {
  private loadMode$ = (filterSpecificLoadResultFn: (filters: Filter[]) => boolean) => this.store.pipe(
    selectorWithParam(selectActiveFiltersByEntityType, FilterEntityType.EVENT_LISTS),
    map((filters) => filterSpecificLoadResultFn(filters) ? LoadResultMode.SEARCH : LoadResultMode.NORMAL)
  );

  discoveryEventLoadResult$: Observable<OccurenceLoadResult> = this.store.pipe(
    select(selectDiscoveryEventsIsLoaded),
    filter(Boolean),
    mergeMap(() => this.store.pipe(
      select(selectDiscoveryListOccerences),
      mapToEventLoadResult(this.loadMode$((filters: Filter[]) => {
        return filters.length && filters.some(f => !!f.filterValue);
      }))
    )),
    distinct()
  );

  userEventLoadResult$: Observable<OccurenceLoadResult> = this.store.pipe(
    select(selectUserEventsIsLoaded),
    filter(Boolean),
    mergeMap(() => this.store.pipe(
      select(selectUserListOccerences),
      mapToEventLoadResult(this.loadMode$((filters: Filter[]) => {
        if (!filters.length) {
          return false;
        }
        const activeFilters = filters
          .filter(f => f.filterType !== FilterType.SELECT);

        return activeFilters.length && activeFilters.every(f => !!f.filterValue);
      })
      ),
    )),
    distinct()
  );

  constructor(
    private store: Store<RootState>
  ) { }

  selectSubscriptionFor(id: string): Observable<SubscriptionEntity> {
    return this.store.pipe(selectorWithParam(selectSubscriptionForEvent,id));
  }
}
