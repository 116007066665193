import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { ConfirmationDialogComponent, OverlayManager } from '@portal/wen-components';
import { filter, first, mergeMap, tap } from 'rxjs/operators';
import { deleteChannelMessage } from '../channel.actions';

export const createDeleteChannelMessageEffect = (
  actions$: Actions,
  socketIoService: Pick<SocketIoService, 'channel'>,
  translateService: TranslateService,
  overlayManager: OverlayManager
) => {
  return createEffect(() =>
    actions$.pipe(
      ofType(deleteChannelMessage),
      mergeMap(({ messageId }) => {
        const dialog = overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, {
          header: translateService.instant('CHANNEL_MESSAGE_DELETE_CONFIRM_DIALOG_TITLE'),
          content: translateService.instant('CHANNEL_MESSAGE_DELETE_CONFIRM_DIALOG_TEXT'),
          reasonFieldLabel: translateService.instant('CHANNEL_MESSAGE_DELETE_REASON_FIELD_LABEL'),
          okLabel: translateService.instant('DELETE_BUTTON_LABEL'),
          dismissLabel: translateService.instant('CANCEL_BUTTON_LABEL'),
        });
        return dialog.afterClosed().pipe(
          first(),
          filter(data => data?.result === 'ok'),
          tap(data => {
            socketIoService.channel.deleteMessage.emit({ id: messageId, reason: data.reason });
          })
        );
      })
    ), { dispatch: false });
};
