import { ActionMenuConfig } from '@portal/wen-components';
import { RemoveUserFromChannelAction } from '../../../../../common/action-menu/remove-user-from-channel-action';
import { RemoveAdminRightAction } from './remove-admin-right.action';

export const channelAdminContextMenuId = 'channel-context-menu-id';

export const CHANNEL_ADMIN_CONTEXT_MENU_ITEMS: ActionMenuConfig = {
  menuId: channelAdminContextMenuId,
  menuItems: [RemoveAdminRightAction, RemoveUserFromChannelAction]
};
