import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DraftMessageEntity } from '../../../../core/store/common/models';

@Injectable()
export abstract class MessageSendFieldDataSource {
  abstract draftMessage$: Observable<DraftMessageEntity>;
  abstract updateDraftMessage(message: Omit<DraftMessageEntity, 'contextId'>);
  abstract sendMessage();
  abstract discardDraftMessage();
  abstract initialize(contextId: string);
  contextId$: Observable<string>;
  messageTypeSelectorId?: string;
}
