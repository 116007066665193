import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, retry, throwError, timer } from 'rxjs';
import { AUTH_CLIENT_CONFIG, AuthClientConfig } from './tokens';

const retryableStatuses = [
  504 // Gateway timeout
];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    @Inject(AUTH_CLIENT_CONFIG) private authConfig: AuthClientConfig,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes(this.authConfig.issuer)) {
      return next.handle(req);
    }
    return next.handle(req).pipe(
      retry({
        count: 3,
        resetOnSuccess: false,
        delay: (error, retryCount) => {
          if (retryableStatuses.includes(error.status)) {
            return timer(retryCount * 200);
          }
          return throwError(() => error);
        }
      })
    );
  }

}
