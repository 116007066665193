import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { ChannelMessageAcknowledgeResponse, ChannelRelevantMessagesResponse, SocketIoService } from '@portal/wen-backend-api';
import { Observable, combineLatest, merge } from 'rxjs';
import { debounceTime, filter, first, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { DateUtil } from '../../../common/date/date-util';
import { selectorWithParam } from '../../../common/util/selector-with-param';
import { UserData } from '../../auth/models/UserData';
import { removeChannelMessagesNotification, updateChannelMessageNotifications } from '../../notification/notification.actions';
import { RootState } from '../../root/public-api';
import { bulkAction } from '../../root/root.actions';
import { acknowledgeChannelMessagesLocally, clearBatchChannelMessagesNewState, clearChannelMessagesNewState, requestChannelMessageAcknowledgeBroadcast, subscribeToUserChannelUpdates, updateLastReadTimestamp } from '../channel.actions';
import { selectChannelMessages, selectCurrentAutoAcknowledgeChannelId, selectLastReadTimestamp } from '../channel.selectors';

export const createLastReadTimestampUpdateEffect = (
  actions$: Actions,
  channelRelevantMessages$: Observable<ChannelRelevantMessagesResponse[]>,
  channelMessagesAcknowledge$: Observable<ChannelMessageAcknowledgeResponse>,
) => {
  return createEffect(() => actions$.pipe(
    ofType(subscribeToUserChannelUpdates),
    first(),
    switchMap(() => merge(
      channelMessagesAcknowledge$.pipe(
        map(response => {
          return updateLastReadTimestamp({ channelId: response.channelId, lastReadTimestamp: response.lastAckTimestamp });
        })
      ),
      channelRelevantMessages$.pipe(
        map((responses) => {
          const targetActions = responses.reduce((acc, response) => {
            if (!response.messages?.length) {
              return acc;
            }
            const messages = response.messages;
            const readMessages = messages.filter((message) => !message.new);
            if (!readMessages.length) {
              return acc;
            }
            const action = updateLastReadTimestamp({
              channelId: response.channelId,
              lastReadTimestamp: readMessages[readMessages.length - 1].timestamp
            });
            return [...acc, action];
          }, [] as Action[]);
          return bulkAction({ targetActions });
        }),
        filter((action) => Boolean(action.targetActions.length))
      )
    ))
  ));
};

export const createAcknowledgeMessagesOnCurrentDeviceEffect = (
  actions$: Actions,
  store: Store<RootState>,
) => {
  return createEffect(() => actions$.pipe(
    ofType(acknowledgeChannelMessagesLocally),
    switchMap(({ messages }) =>
      store.pipe(
        selectorWithParam(selectLastReadTimestamp, messages[0].channelId),
        first(),
        map(lastReadTimestamp => messages.filter(message => DateUtil.compare(message.timestamp, lastReadTimestamp) < 1))
      ),
    ),
    filter(messages => Boolean(messages.length)),
    switchMap((messages) => {
      return [
        removeChannelMessagesNotification({ messages }),
        clearBatchChannelMessagesNewState({ messages })
      ];
    })
  ));
};

export const createNotificationUpdaterEffect = (
  actions$: Actions,
  channelMessagesAcknowledge$: Observable<ChannelMessageAcknowledgeResponse>,
) => {
  return createEffect(() => actions$.pipe(
    ofType(subscribeToUserChannelUpdates),
    first(),
    switchMap(() => channelMessagesAcknowledge$),
    switchMap(({ channelId, unreadCountDiff, lastAckTimestamp }) => {
      return [
        updateChannelMessageNotifications({ channelId, unreadCountDiff, lastAckTimestamp }),
        clearChannelMessagesNewState({ channelId, lastReadTimestamp: lastAckTimestamp })
      ];
    })
  ));
};

export const createBroadcastCurrentAcknowledgeStateEffect = (
  actions$: Actions,
  store: Store<RootState>,
  currentUserData$: Observable<UserData>,
  socketIoService: Pick<SocketIoService, 'channel'>
) => {
  return createEffect(() => actions$.pipe(
    ofType(requestChannelMessageAcknowledgeBroadcast),
    switchMap(({ channelId }) => store.pipe(
      select(selectCurrentAutoAcknowledgeChannelId),
      filter(currentAutoAcknowledgeChannelId => !Boolean(currentAutoAcknowledgeChannelId)),
      map(() => channelId)
    )),
    debounceTime(1000),
    switchMap(channelId => combineLatest([
      store.pipe(selectorWithParam(selectChannelMessages, channelId), first()),
      store.pipe(selectorWithParam(selectLastReadTimestamp, channelId), first())
    ])),
    map(([messages, lastReadTimestamp]) => {
      const recentlyReadMessages = messages.filter(message => !message.new && DateUtil.compare(message.timestamp, lastReadTimestamp) < 1);
      const lastReadMessage = recentlyReadMessages.length ? recentlyReadMessages[recentlyReadMessages.length - 1] : null;
      if (!lastReadMessage) {
        return { lastReadMessage };
      }
      const alreadyCountedOffset = DateUtil.compare(recentlyReadMessages[0].timestamp, lastReadTimestamp) === 0 ? 1 : 0;
      const unreadCountDiff = recentlyReadMessages.length - alreadyCountedOffset;
      return { lastReadMessage, unreadCountDiff };
    }),
    filter(({ lastReadMessage }) => Boolean(lastReadMessage)),
    withLatestFrom(currentUserData$),
    map(([{ lastReadMessage: { channelId, timestamp }, unreadCountDiff }, { userId }]) => {
      socketIoService.channel.messagesAcknowledge.emit({ channelId, userId, lastAckTimestamp: timestamp, unreadCountDiff });
      return updateLastReadTimestamp({ channelId, lastReadTimestamp: timestamp });
    })
  ));
};

export const createBroadcastCurrentAcknowledgeStateForAutoAcknowledgeEffect = (
  actions$: Actions,
  store: Store<RootState>,
  currentUserData$: Observable<UserData>,
  socketIoService: Pick<SocketIoService, 'channel'>
) => {
  return createEffect(() => actions$.pipe(
    ofType(requestChannelMessageAcknowledgeBroadcast),
    switchMap(({ channelId }) => store.pipe(
      select(selectCurrentAutoAcknowledgeChannelId),
      filter(currentAutoAcknowledgeChannelId => currentAutoAcknowledgeChannelId === channelId),
      map(() => channelId)
    )),
    switchMap((channelId) => store.pipe(selectorWithParam(selectChannelMessages, channelId), first())),
    map(messages => messages.length ? messages[messages.length - 1] : null),
    filter(lastReadMessage => Boolean(lastReadMessage)),
    withLatestFrom(currentUserData$),
    switchMap(([{ channelId, timestamp }, { userId }]) => {
      socketIoService.channel.messagesAcknowledge.emit({ channelId, userId, lastAckTimestamp: timestamp });
      return [
        updateLastReadTimestamp({ channelId, lastReadTimestamp: timestamp }),
        updateChannelMessageNotifications({ channelId }),
        clearChannelMessagesNewState({ channelId })
      ];
    })
  ));
};
