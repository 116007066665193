import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'locationDistanceFormatter'
})
export class LocationDistanceFormatterPipe implements PipeTransform {

  transform(distance: number): string {
    if(distance === undefined || distance === null){
      return '';
    }

    return distance >= 1.0 ? distance.toFixed(1) + ' km' : distance * 1000 + ' m';
  }
}
