import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandFormat'
})

export class ThousandFormatPipe implements PipeTransform {
  transform(value: number, ...args: any[]): any {
    return value >= 1e3 ? `${+(value / 1e3).toFixed(1)}K` : value;
  }
}
