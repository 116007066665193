import { createSelector } from '@ngrx/store';
import { commentsFeature } from './comments.reducers';
import { CommentEntity } from './comments.state';

export const {
  selectComments,
  selectDraftComment
} = commentsFeature;

export const selectCommentsForMessage = createSelector(
  selectComments,
  (commentEntityState) => (messageId: string): CommentEntity | any[] => {
    return commentEntityState?.entities[messageId] ?? [];
  }
);
