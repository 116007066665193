import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subject, catchError, combineLatest, filter, of, takeUntil, Observable, BehaviorSubject } from 'rxjs';
import { TextToSpeechService } from '../../../../core/services/text-to-speech/text-to-speech.service';
import { DateUtil } from '../../../../core/common/date/date-util';
import { PastMessageSeparatorDateFormatter } from '../../../directives/directives/message-separator-formatters';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

enum TextToSpeechPlayerStatus {
  STOPPED = 'STOPPED',
  LOADING = 'LOADING',
  META_PLAYING = 'META_PLAYING',
  CONTENT_PLAYING = 'CONTENT_PLAYING',
  META_PAUSED = 'META_PAUSED',
  CONTENT_PAUSED = 'CONTENT_PAUSED',
  ERROR = 'ERROR',
}

export enum TextToSpeechContext {
  MESSAGE = 'MESSAGE'
}

export interface TextToSpeechPayload {
  contextId: string;
  content?: string;
  contentSrc?: string;
  metadata?: {
    context: TextToSpeechContext;
    timestamp: string;
    author: string;
  };
}

@Component({
  selector: 'wen-text-to-speech-button',
  templateUrl: './text-to-speech-button.component.html',
  styleUrls: ['./text-to-speech-button.component.scss']
})
export class TextToSpeechButtonComponent implements AfterViewInit, OnDestroy {

  @ViewChild('metaPlayer') metaPlayer: ElementRef<HTMLAudioElement>;
  @ViewChild('contentPlayer') contentPlayer: ElementRef<HTMLAudioElement>;

  @Input() payload: TextToSpeechPayload;
  @Input() enableOnTheFlyContent = false;

  private onDestroy$ = new Subject<void>();
  private audioTriggered = new EventEmitter<string>();
  private icons: { [key in TextToSpeechPlayerStatus]: string } = {
    [TextToSpeechPlayerStatus.STOPPED]: 'volume_on',
    [TextToSpeechPlayerStatus.LOADING]: 'loop',
    [TextToSpeechPlayerStatus.META_PAUSED]: 'play',
    [TextToSpeechPlayerStatus.META_PLAYING]: 'pause',
    [TextToSpeechPlayerStatus.CONTENT_PAUSED]: 'play',
    [TextToSpeechPlayerStatus.CONTENT_PLAYING]: 'pause',
    [TextToSpeechPlayerStatus.ERROR]: 'report_problem'
  };

  iconName$ = new BehaviorSubject<string>(this.icons[TextToSpeechPlayerStatus.STOPPED]);
  audioState = TextToSpeechPlayerStatus.STOPPED;

  constructor(
    private textToSpeechService: TextToSpeechService,
    private messageDateFormatter: PastMessageSeparatorDateFormatter,
    private translateService: TranslateService
  ) {
  }

  ngAfterViewInit(): void {
    this.audioTriggered.pipe(
      filter(id => id !== this.payload.contextId),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
        this.metaPlayer.nativeElement.pause();
        this.contentPlayer.nativeElement.pause();
        this.updateState(TextToSpeechPlayerStatus.STOPPED);
      }
    );
  }

  handleRead() {
    this.audioTriggered.emit(this.payload.contextId);

    switch (this.audioState) {
      case TextToSpeechPlayerStatus.ERROR:
      case TextToSpeechPlayerStatus.STOPPED: {
        this.getAudio();
        break;
      }
      case TextToSpeechPlayerStatus.LOADING: {
        break;
      }
      case TextToSpeechPlayerStatus.META_PLAYING: {
        this.metaPlayer.nativeElement.pause();
        this.updateState(TextToSpeechPlayerStatus.META_PAUSED);
        break;
      }
      case TextToSpeechPlayerStatus.META_PAUSED: {
        this.metaPlayer.nativeElement.play();
        this.updateState(TextToSpeechPlayerStatus.META_PLAYING);
        break;
      }
      case TextToSpeechPlayerStatus.CONTENT_PLAYING: {
        this.contentPlayer.nativeElement.pause();
        this.updateState(TextToSpeechPlayerStatus.CONTENT_PAUSED);
        break;
      }
      case TextToSpeechPlayerStatus.CONTENT_PAUSED: {
        this.contentPlayer.nativeElement.play();
        this.updateState(TextToSpeechPlayerStatus.CONTENT_PLAYING);
        break;
      }
    }
  }

  private getAudio() {
    this.updateState(TextToSpeechPlayerStatus.LOADING);

    const metaText = this.getMetaText();
    const meta = metaText ? this.textToSpeechService.getAudio(metaText) : of(null);

    let content: Observable<string> = of(null);
    if (this.payload.contentSrc) {
      content = of(this.payload.contentSrc);
    } else if (this.enableOnTheFlyContent) {
      content = this.payload.content ? this.textToSpeechService.getAudio(this.payload.content) : of(null);
    }

    combineLatest([meta, content]).pipe(
      catchError((err: HttpErrorResponse) => {
        console.error('Failed to load text-to-speech: ' + err.status);
        this.updateState(TextToSpeechPlayerStatus.ERROR);
        return of([null, null]);
      }),
      filter(() => this.audioState !== TextToSpeechPlayerStatus.STOPPED && this.audioState !== TextToSpeechPlayerStatus.ERROR)
    ).subscribe(([metaSrc, contentSrc]) => {
      if (!!metaSrc && !!contentSrc) {
        this.metaPlayer.nativeElement.src = metaSrc;
        this.contentPlayer.nativeElement.src = contentSrc;
        this.metaPlayer.nativeElement.play();
        this.updateState(TextToSpeechPlayerStatus.META_PLAYING);
      } else if (!!contentSrc) {
        this.contentPlayer.nativeElement.src = contentSrc;
        this.contentPlayer.nativeElement.play();
        this.updateState(TextToSpeechPlayerStatus.CONTENT_PLAYING);
      } else {
        this.updateState(TextToSpeechPlayerStatus.STOPPED);
      }
    });
  }

  private updateState(state: TextToSpeechPlayerStatus) {
    this.audioState = state;
    this.iconName$.next(this.icons[state]);
  }

  private getMetaText(): string {
    if (!this.payload.metadata) {
      return null;
    }

    switch (this.payload.metadata.context) {
      case TextToSpeechContext.MESSAGE: {
        if (!this.payload.metadata.author) {
          return '';
        }
        return this.translateService.instant(
          'TEXT_TO_SPEECH_MESSAGE_META',
          {author: this.payload.metadata.author, time: this.getTimeFormat(this.payload.metadata.timestamp)}
        );
      }
    }
  }

  private getTimeFormat(timestamp: string): string {
    if (!timestamp) {
      return '';
    }
    const dateTime = DateUtil.fromIsoString(timestamp);
    const day = this.messageDateFormatter.formatSeparatorDate(dateTime);
    const hour = DateUtil.toTimeClientFormat(dateTime);
    if (DateUtil.isToday(dateTime)) {
      return this.translateService.instant('TEXT_TO_SPEECH_MESSAGE_TIME_TODAY', {hour});
    } else if (DateUtil.isYesterday(dateTime)) {
      return this.translateService.instant('TEXT_TO_SPEECH_MESSAGE_TIME_YESTERDAY', {hour});
    } else {
      return this.translateService.instant('TEXT_TO_SPEECH_MESSAGE_TIME', {day, hour});
    }
  }

  onMetaEnded() {
    this.updateState(TextToSpeechPlayerStatus.CONTENT_PLAYING);
    this.contentPlayer.nativeElement.play();
  }

  onContentEnded() {
    this.updateState(TextToSpeechPlayerStatus.STOPPED);
  }

  ngOnDestroy(): void {
    this.updateState(TextToSpeechPlayerStatus.STOPPED);
    this.metaPlayer.nativeElement.pause();
    this.contentPlayer.nativeElement.pause();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
