import { ChangeDetectionStrategy, Component, HostBinding, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { combineLatest, EMPTY, Observable, tap , of } from 'rxjs';
import { catchError, distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';
import { WenBreakpointObserver } from '../../../services/resize/wen-breakpoint-observer';
import { whitespaceValidator } from '../../../util/whitespace-validator';
import { PollManager } from '../providers/poll-manager';
import { ErrorResponse } from '@portal/wen-backend-api';
import { OverlayManager } from '../../../services/overlays/overlay-manager';

const VALIDATORS = [Validators.required, whitespaceValidator];

@Component({
  selector: 'wen-poll-creation',
  templateUrl: './poll-creation.component.html',
  styleUrls: ['./poll-creation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class PollCreationComponent implements OnInit {
  readonly CHARACTER_LIMIT = 250;
  readonly SHOW_CHARACTER_COUNT = Math.ceil(this.CHARACTER_LIMIT * 0.8);
  characterCount$: Observable<number | boolean>;
  pollOptions$: Observable<number>;

  readonly MAX_OPTIONS = 15;

  pollFormGroup: FormGroup = this.formBuilder.group({
    question: this.formBuilder.control('', VALIDATORS),
    options: this.formBuilder.array([this.formBuilder.control('', VALIDATORS)]),
    isMultiSelect: false
  });

  savingPollDisabled$: Observable<boolean>;
  addingNewOptionDisabled$: Observable<boolean>;
  optionRemovable$: Observable<boolean>;
  headerGravity = this.breakpointObserver.isDesktopStyleDevice ? 'bottom' : 'top';

  @HostBinding('class.wen-poll-creation') className = true;

  constructor(
    private formBuilder: FormBuilder,
    @Optional() private dialogRef: MatDialogRef<PollCreationComponent>,
    private pollManager: PollManager,
    private breakpointObserver: WenBreakpointObserver,
    private overlayManager: OverlayManager,
  ) { }

  ngOnInit() {
    this.savingPollDisabled$ = this.getIsFormInvalidObservable(this.pollFormGroup);

    this.addingNewOptionDisabled$ = combineLatest([
      this.getIsFormInvalidObservable(this.getOptionArray()),
      this.getOptionArray().valueChanges.pipe(
        tap((options: string[]) =>{
          this.pollOptions$ = of(options.length);
        }),
        map((options: string[]) => options.length >= this.MAX_OPTIONS
        ),
        distinctUntilChanged(),
        startWith(true),
      )
    ]).pipe(
      map(([formValidation, maxLength]) => formValidation || maxLength)
    );

    this.optionRemovable$ = this.getOptionArray().valueChanges.pipe(
      shareReplay(1),
      map((values: string[]) => values.length > 1),
      distinctUntilChanged()
    );

    this.characterCount$ = this.pollFormGroup.controls.question.valueChanges.pipe(
      map(value => value.length >= this.SHOW_CHARACTER_COUNT ? value.length : false),
    );
  }

  private getIsFormInvalidObservable(form: FormGroup | FormArray): Observable<boolean> {
    return form.statusChanges.pipe(
      distinctUntilChanged(),
      map(formStatus => 'INVALID' === formStatus),
      startWith(true),
      shareReplay(1)
    );
  }

  createNewOption() {
    const optionArrayControl = this.getOptionArray();
    if (optionArrayControl.status === 'VALID' && optionArrayControl.length < this.MAX_OPTIONS) {
      optionArrayControl.push(this.formBuilder.control('', VALIDATORS));
    }
  }

  getOptionArray(): FormArray {
    return this.pollFormGroup.controls.options as FormArray;
  }

  getOptionArrayControls(): AbstractControl[] {
    return (this.pollFormGroup.controls.options as FormArray).controls;
  }

  removeOption(index: number) {
    if (this.getOptionArrayControls().length > 1) {
      this.getOptionArrayControls().splice(index, 1);
    }
    this.getOptionArray().updateValueAndValidity();
  }

  cancel() {
    this.closeDialog();
  }

  publish() {
    const { question, options, isMultiSelect } = this.pollFormGroup.value;
    const optionsWithPriority = options.map((option: string, index: number) => ({ text: option, priority: index }));
    const pollRequest = {
      question,
      options: [...optionsWithPriority],
      multiSelect: isMultiSelect
    };
    this.pollManager.createPoll(pollRequest).pipe(
      catchError((e: ErrorResponse) => {
        this.showErrorMessage(e);
        return EMPTY;
      })
    ).subscribe(() => {
      this.closeDialog();
    });
  }

  private closeDialog() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  private showErrorMessage(error: ErrorResponse) {
    this.overlayManager.dialog.openErrorDialog({ errorMessageLabel: 'ERROR_GENERIC_DIALOG_MESSAGE' });
  }
}
