import { MediaValidator } from '../../../../core/services/util/media-validator';
import { Provider } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FeatureEnablementService } from '../../../../core/services/configuration/feature-enablement';
import { ChannelMediaPreviewHandler } from '../../../../core/services/util/channel-media-preview-handler';
import { ChatMediaPreviewHandler } from '../../../../core/services/util/chat-media-preview-handler';
import { DraftChatMediaPreviewHandler } from '../../../../core/services/util/draft-chat-media-preview-handler';
import { FileSelectorHandler } from '../../../../core/services/util/file-selector-handler';
import { MediaUploadAction, mediaUploadActionFactory } from './media-upload.action';
import { PollCreationAction } from './poll-creation.action';
import { DocumentUploadAction, documentUploadActionFactory } from './document-upload.action';

export const CHANNEL_MESSAGE_TYPE_ACTIONS: Provider[] = [
  PollCreationAction,
  {
    provide: MediaUploadAction,
    useFactory: mediaUploadActionFactory,
    deps: [ChannelMediaPreviewHandler, TranslateService, FeatureEnablementService, FileSelectorHandler, MediaValidator]
  },
  {
    provide: DocumentUploadAction ,
    useFactory: documentUploadActionFactory,
    deps: [ChannelMediaPreviewHandler, TranslateService, FeatureEnablementService, FileSelectorHandler]
  }
];

export const CHAT_MESSAGE_TYPE_ACTIONS: Provider[] = [
  {
    provide: MediaUploadAction,
    useFactory: mediaUploadActionFactory,
    deps: [ChatMediaPreviewHandler, TranslateService, FeatureEnablementService, FileSelectorHandler, MediaValidator]
  },
  {
    provide: DocumentUploadAction ,
    useFactory: documentUploadActionFactory,
    deps: [ChatMediaPreviewHandler, TranslateService, FeatureEnablementService, FileSelectorHandler,]
  }
];

export const CHAT_CREATION_MESSAGE_TYPE_ACTIONS: Provider[] = [
  {
    provide: MediaUploadAction,
    useFactory: mediaUploadActionFactory,
    deps: [DraftChatMediaPreviewHandler, TranslateService, FeatureEnablementService, FileSelectorHandler, MediaValidator]
  },
  {
    provide: DocumentUploadAction ,
    useFactory: documentUploadActionFactory,
    deps: [DraftChatMediaPreviewHandler, TranslateService, FeatureEnablementService, FileSelectorHandler]
  }
];
