import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OverlayManager, smartDistinctUntilChanged } from '@portal/wen-components';
import { Subject, filter, first, map, takeUntil } from 'rxjs';
import { FilterId } from '../../../../../../core/common/types/filter-id';
import { selectorWithParam } from '../../../../../../core/common/util/selector-with-param';
import { selectActiveFilterById } from '../../../../../../core/store/filter/filter.selectors';
import { FilterEntityType, GeoFilter } from '../../../../../../core/store/filter/models/filter';
import { RootState } from '../../../../../../core/store/root/public-api';
import { GeoFilterSelectComponent, GeoFilterSelectDialogData, GeoFilterSelectDialogResponse } from '../../../../../../shared/components/filter-view/components/geo-filter-select/geo-filter-select.component';
import { Filterable } from '../../../../../../shared/components/filter-view/models/filter-item';
import { GeoFilterValueModel } from '../../../../../channel/channel-view/components/channel-view/filter-sources/geo-location-filter-datasource';

@Injectable()
export class EventGeoLocationFilterDataSource implements OnDestroy {

  private onDestroy$ = new Subject<void>();

  currentActiveEventGeoLocationFilter$ = this.store.pipe(
    selectorWithParam(selectActiveFilterById, FilterEntityType.EVENT_LISTS, FilterId.EVENT_GEO_LOCATION),
    smartDistinctUntilChanged(),
    map((activeFilter: GeoFilter) => {
      const hasGeoJson = Boolean(activeFilter?.filterValue.data.geoJson);
      return hasGeoJson ? activeFilter : null;
    }),
    takeUntil(this.onDestroy$)
  );

  get defaultGeoFilter(): Filterable {
    return {
      id: null,
      name: this.translateService.instant('GEO_SELECTOR_OPTION_FALLBACK')
    };
  }

  constructor(
    private store: Store<RootState>,
    private overlayManager: OverlayManager,
    private translateService: TranslateService
  ) { }

  openFilterSelector(activeGeoFilter: GeoFilterValueModel) {
    const dialogData: GeoFilterSelectDialogData = {
      activeGeoFilter: activeGeoFilter?.data
    };
    const dialogRef = this.overlayManager.dialog.openFilterSelectorDialog<
      GeoFilterSelectComponent, GeoFilterSelectDialogData, GeoFilterSelectDialogResponse
    >(GeoFilterSelectComponent, dialogData);
    return dialogRef.beforeClosed().pipe(
      first(),
      filter(dialogResult => Boolean(dialogResult))
    );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
