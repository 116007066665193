import { NgxMatDatepickerBase } from '@angular-material-components/datetime-picker';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxDatePickerUtils, WenBreakpointObserver } from '@portal/wen-components';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, distinctUntilChanged, map, tap } from 'rxjs';
import { DateUtil } from '../../../../../../core/common/date/date-util';

@Component({
  selector: 'wen-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimePickerComponent implements OnInit {
  disablePast = false;

  private selectedDateModelValue = new BehaviorSubject<Date>(null);
  selectedDateViewValue$ = this.selectedDateModelValue.pipe(
    map((date) => {
      if (!date) {
        return date;
      }
      const clonedDate = new Date(date.getTime());
      clonedDate?.setSeconds(0);
      clonedDate?.setMilliseconds(0);
      return clonedDate;
    })
  );

  defaultTime: number[];
  minDate: Date;
  isClearVisible$: Observable<boolean>;
  isSaveDisabled$: Observable<boolean>;

  disabled$ = new ReplaySubject<boolean>(1);
  @Input() set disabled(value: boolean) {
    this.disabled$.next(value);
  }
  @Input() label: string;
  @Input() placeholder: string;
  @Input() set onlyFuture(value: boolean) {
    this.disablePast = value;
    this.calculateMinDateAndTime();
  }
  @Input() isClearable: boolean;
  @Output() dateChange = new EventEmitter<Date | null>();

  isMobileView = !this.breakPointObserver.isDesktopStyleDevice;

  constructor(
    private breakPointObserver: WenBreakpointObserver,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.isClearVisible$ = combineLatest([
      this.selectedDateModelValue,
      this.disabled$
    ]).pipe(
      map(([value, disabled]) => {
        return this.isClearable ? Boolean(value) && !disabled : false;
      }),
      distinctUntilChanged(),
      tap(() => this.cdr.detectChanges())
    );
  }

  onPickerOpen(datePickerComponent: NgxMatDatepickerBase<any, any, any>) {
    const pickerUtils = new NgxDatePickerUtils(datePickerComponent);
    if (!this.selectedDateModelValue.getValue()) {
      // TODO: remove workaround once [defaultTime] binding is fixed for detapicker lib
      pickerUtils.forceSeletion(this.createMinDate());
    }
    this.isSaveDisabled$ = pickerUtils.selectedDate$.pipe(
      map((selectedDate) => {
        const currentDate = new Date();
        currentDate.setSeconds(0);
        currentDate.setMilliseconds(0);
        const comp = DateUtil.compareNullSafeDates(currentDate, selectedDate);
        return comp !== 1;
      })
    );
  }

  onDateChange(dateValue: Date) {
    this.selectedDateModelValue.next(dateValue);
    this.dateChange.emit(dateValue);
  }

  clearDate() {
    this.onDateChange(null);
  }

  private createMinDate() {
    const minDate = new Date();
    minDate.setSeconds(0);
    minDate.setMilliseconds(0);
    minDate.setMinutes(minDate.getMinutes() + 1);
    return minDate;
  }

  calculateMinDateAndTime() {
    if (this.disablePast) {
      this.minDate = this.createMinDate();
      this.defaultTime = [this.minDate.getHours(), this.minDate.getMinutes(), this.minDate.getSeconds()];
    }
  }
}
