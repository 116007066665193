import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { PortalButtonModule } from '@portal/ui-kit/button';
import { FocusFirstInputModule, WenComponentsModule } from '@portal/wen-components';
import { AdaptiveBackgroundModule } from '../../shared/components/adaptive-background/adaptive-background.module';
import { FormStoreModule } from '../../shared/form-store/form-store.module';
import { IosUtilsModule } from '../../shared/ios-util/ios-util.module';
import { ExclusiveInviteComponent } from './components/exclusive-invite/exclusive-invite.component';
import { ExclusiveInviteGuard } from './guards/exclusive-invite.guard';

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    WenComponentsModule,
    MatButtonModule,
    PortalButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MatDialogModule,
    FocusFirstInputModule,
    IosUtilsModule,
    AdaptiveBackgroundModule,
    FormStoreModule,
  ],
  declarations: [
    ExclusiveInviteComponent
  ],
  exports: [
    ExclusiveInviteComponent
  ],
  providers: [
    ExclusiveInviteGuard
  ]
})
export class ExclusiveInviteModule { }
