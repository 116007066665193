import { Component, HostListener, Input, ChangeDetectionStrategy } from '@angular/core';
import { NotificationData, NotificationType } from '@portal/wen-backend-api';
import { EmbeddedNotification } from '../../../../../../core/store/channel/channel.state';
import { NotificationNavigator } from './providers/embed-notification-navigator.service';

@Component({
  selector: 'wen-embed-notification',
  templateUrl: './embed-notification.component.html',
  styleUrls: ['./embed-notification.component.scss'],
  providers: [NotificationNavigator],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbedNotificationComponent {

  @HostListener('click')
  onClick() {
    this.initiateNavigation();
  }

  @Input() set model(value: EmbeddedNotification) {
    this.notificationViewModel = value.notificationData;
  }
  notificationViewModel: NotificationData;
  notificationType = NotificationType;

  constructor(private notificationNavigator: NotificationNavigator) {}

  private initiateNavigation() {
    const { contextType, contextId, triggerUser } = this.notificationViewModel;
    this.notificationNavigator.navigateBy(contextType, contextId, triggerUser);
  }

}
