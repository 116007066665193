import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { first, map, tap } from 'rxjs/operators';
import { RootState } from '../store/root/public-api';
import { updatePrivileges } from '../store/user/user.actions';

export const systemPrivilegesLoadedGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(SystemPrivilegesLoadedGuard).canActivate();
};

@Injectable()
export class SystemPrivilegesLoadedGuard {

  constructor(
    private store: Store<RootState>,
    private socketIoService: SocketIoService,
  ) { }

  canActivate() {
    return this.socketIoService.system.listPrivileges.acknowledgement$().pipe(
      first(),
      tap((result) => {
        if (Array.isArray(result)) {
          this.store.dispatch(updatePrivileges({ privileges: result }));
        }
      }),
      map(() => true)
    );
  }
}
