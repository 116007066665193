import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
})
export class ContentHighlighterPipe implements PipeTransform {

  transform(content: string, searchTerm: string): string {
    if (!searchTerm) {
      return content;
    }
    searchTerm = searchTerm.match(/([^, ]+)/ig).join('|');
    const regex = new RegExp(`(${searchTerm})`, 'ig');
    content = content.replace(regex, '<span class="wen-highlighted">$1</span>');
    return content;
  }
}
