import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatDetailComponent } from './chat-detail.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SharedModule } from '../../../shared/shared.module';
import { ChatDetailUserListComponent } from './components/chat-detail-user-list/chat-detail-user-list.component';
import { UserRoleModule } from '../../../shared/components/user-role/user-role.module';
import { CHAT_DETAIL_MEMBERS_MENU } from './action-menu/constants';
import { ActionMenuModule, ACTION_MENU_ITEMS, ContextMenuComponent, ContextMenuTriggerDirective } from '@portal/wen-components';

@NgModule({
  declarations: [
    ChatDetailComponent,
    ChatDetailUserListComponent,
  ],
  imports: [
    SharedModule,
    ScrollingModule,
    CommonModule,
    UserRoleModule,
    ActionMenuModule,
    ContextMenuTriggerDirective,
    ContextMenuComponent
  ],
  providers: [
    { provide: ACTION_MENU_ITEMS, useValue: CHAT_DETAIL_MEMBERS_MENU, multi: true }
  ]
})
export class ChatDetailModule { }
