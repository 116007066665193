import { createAction, props } from '@ngrx/store';
import { BroadcastInformation, GetReactionsResponse, ReactionContext, ReactionUpdateResponse } from '@portal/wen-backend-api';
import { AncestorReference } from '@portal/wen-components';

export const initReactionListenerAction = createAction(
  '[Reactions] Init listener'
);

export const subscribeToGetMessageReactions = createAction(
  '[Reactions] Get message reactions'
);

export const setMessageReactions = createAction(
  '[Reactions] Set reaction summary for message',
  props<{ response: GetReactionsResponse }>()
);

export const sendReactionAction = createAction(
  '[Reactions] Send new reaction',
  props<{
    emojiId: number;
    parentId: string;
    parentType: ReactionContext;
    broadcastInformation?: BroadcastInformation;
    possibleBlockingAncestor?: AncestorReference;
  }>()
);

export const fetchReactionsForMessages = createAction(
  '[Reactions] Fetch reactions for messages',
  props<{ messageIds?: string[]; parentIds?: string[] }>()
);

export const updateReactionSummaryForMessage = createAction(
  '[Reactions] Update reactions for message',
  props<{ reactionUpdate: ReactionUpdateResponse }>()
);

export const deleteReactions = createAction(
  '[Reactions] Delete reactions from the store',
  props<{ referenceIds: string[] }>()
);
