import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RootState } from '../root/public-api';
import { userFeatureKey } from './constants';
import { UserState } from './user.state';

export interface WithFeatureState extends RootState {
  [userFeatureKey]: UserState;
}

export const selectUserState = createFeatureSelector<UserState>(
  userFeatureKey
);

export const selectUserProfile = createSelector(
  selectUserState,
  (state) => state.userProfile
);

export const selectPrivileges = createSelector(
  selectUserState,
  (state) => state.privileges
);

export const selectUserChannelPermission = createSelector(
  selectUserState,
  (state) => state.channelPermission
);

export const selectUserAppPermission = createSelector(
  selectUserState,
  (state) => state.appPermission
);

export const selectProfileImageState = createSelector(
  selectUserState,
  (state) => state.profileImageState
);

export const selectUserMetaDataSettings = createSelector(
  selectUserState,
  (state) => state.userMetaDataSettings
);

export const selectUserPrimaryLanguage = createSelector(
  selectUserState,
  (state) => state.userProfile?.primaryLanguage
);
