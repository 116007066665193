import { TranslateService } from '@ngx-translate/core';
import { ActionMenuItem } from '@portal/wen-components';
import { Observable } from 'rxjs';
import { FeatureEnablementService } from '../../../../core/services/configuration/feature-enablement';
import { FileSelectorHandler } from '../../../../core/services/util/file-selector-handler';
import { MediaPreviewHandler } from '../../../../core/services/util/media-preview-handler';

export class DocumentUploadAction implements ActionMenuItem {

  label: string;
  icon = 'document';
  isVisible$ = () => this.featureEnablementService.featureConfigObs.enableDocumentUpload$;

  constructor(
    private mediaPreviewHandler: MediaPreviewHandler,
    protected translateService: TranslateService,
    protected featureEnablementService: FeatureEnablementService,
    protected fileSelector: FileSelectorHandler
  ) {
    this.label = this.translateService.instant(
      'CONTEXT_MENU_ACTION_DOCUMENT_UPLOAD_LABEL'
    );
  }

  prepareFile(): Observable<File> {
    return this.fileSelector.openFileSelector();
  }

  execute() {
    this.prepareFile().subscribe(file => {
      this.mediaPreviewHandler.handleMediaPreview(file);
    });
  }
}

export const documentUploadActionFactory = (
  mediaPreviewHandler: MediaPreviewHandler,
  translateService: TranslateService,
  featureEnablementService: FeatureEnablementService,
  fileSelector: FileSelectorHandler,
) => new DocumentUploadAction(mediaPreviewHandler, translateService, featureEnablementService, fileSelector);
