import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { firstExisty } from '../../../../core/common/operators/first-existy';
import { selectCurrentUserData } from '../../../../core/store/auth/auth.selectors';
import { RootState } from '../../../../core/store/root/public-api';

@Injectable()
export class AppPermissionService {

  constructor(
    private readonly store: Store<RootState>,
    private readonly socketIoService: SocketIoService
  ) { }

  checkPermissionToApp(appId: string) {
    if (!appId) {
      return;
    }

    this.store.pipe(
      select(selectCurrentUserData),
      firstExisty()
    ).subscribe(userData => this.socketIoService.system.appPolicy.emit({ userId: userData.userId, appId }));
  }

}
