import { FactoryProvider, Injector, Provider, StaticProvider, Type } from '@angular/core';
import { ActionMenuItem } from '../actions/model';

export class ActionMenuItemCreator {

  static createActions<T, C, R>(actions: Provider[], injector: Injector): ActionMenuItem<T, C, R>[] {
    return actions
      .filter(action => !!action)
      .map(action => {
        const providers: StaticProvider = [action];
        const newInjector = Injector.create({
          providers,
          parent: injector
        });
        const isFactoryProv = Boolean((action as FactoryProvider)?.useFactory);
        const token: Type<ActionMenuItem<T, C, R>> = isFactoryProv ? (action as FactoryProvider).provide : action;
        return newInjector.get(token, null);
      });
  }

}
