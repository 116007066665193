import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { ConfirmationDialogResult } from '@portal/wen-components';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { setSubscriptionToCurrentChannel } from '../../../../../core/store/channel/channel.actions';
import { RootState } from '../../../../../core/store/root/public-api';

@Component({
  selector: 'wen-password-protected-subscription-dialog',
  templateUrl: './password-protected-subscription-dialog.component.html',
  styleUrls: ['./password-protected-subscription-dialog.component.scss']
})
export class PasswordProtectedSubscriptionDialogComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  confirmationFormGroup = new FormGroup({ password: new FormControl('') });
  errorState$: Observable<boolean>;

  constructor(
    private dialogRef: MatDialogRef<PasswordProtectedSubscriptionDialogComponent, ConfirmationDialogResult>,
    private readonly socketIoService: SocketIoService,
    private readonly store: Store<RootState>,

  ) { }

  ngOnInit(): void {
    this.errorState$ = this.socketIoService.channel.subscribe.listen.pipe(
      map(response => !response.result.allow),
      takeUntil(this.onDestroy$),
      shareReplay(1)
    );

    this.errorState$.subscribe(invalid => {
      if (invalid) {
        this.confirmationFormGroup.controls.password.setErrors({ invalid });
      } else {
        this.dialogRef.close({ result: 'ok' });
      }
    });
  }

  onAccept() {
    this.store.dispatch(setSubscriptionToCurrentChannel({
      setSubscribed: true, restriction: { password: this.confirmationFormGroup.controls.password.value }
    }));
  }

  onDismiss() {
    this.dialogRef.close({
      result: 'dismiss'
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
