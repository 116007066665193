import { Injectable, InjectionToken, inject } from '@angular/core';
import { ChannelConfiguration, ConfigSchema, ContentSchema } from '@portal/wen-backend-api';
import { isNullOrUndefined } from '../../common/operators/null-check-util';
import { FormValueManager } from './form-handling/form-value-manager';
import { ProcessedGroupProps } from './models/model';

export const SCHEMA_HANDLERS = new InjectionToken<SchemaHandlerRecognizer[]>('SCHEMA_HANDLERS');

export interface SchemaHandlerRecognizer {
  processSchema(schema: ConfigSchema): ProcessedGroupProps<ContentSchema[]>[];
}


@Injectable()
export class SchemaHandler {

  private schemaHandlers = inject(SCHEMA_HANDLERS, { optional: true });
  private formValueManager = inject(FormValueManager);

  processSchema(schema: ConfigSchema): ProcessedGroupProps<ContentSchema[]>[] {
    if (isNullOrUndefined(schema)) {
      return [];
    }
    if (!this.schemaHandlers.length) {
      throw new Error(`Schema can't be processed`);
    }
    return this.schemaHandlers.reduce((acc, curr) => {
      const processed: ProcessedGroupProps<ContentSchema[]>[] = curr.processSchema(schema).filter(content => !!content);
      acc = processed;
      return acc;
    }, [] as ProcessedGroupProps<ContentSchema[]>[]);
  }

  initializeFormValue(formValue: ChannelConfiguration['config']) {
    this.formValueManager.setFormValue(formValue);
  }
}
