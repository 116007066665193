import { TranslateService } from '@ngx-translate/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { WenSnackbarOpener } from '@portal/wen-components';
import { FeatureEnablementService } from '../../../core/services/configuration/feature-enablement';
import { WenNavigationHelper } from '../../../core/services/navigation/types';
import { WenNativeApi } from '@portal/wen-native-api';
import { ExclusiveInviteConfig } from '../../../frame/api/tokens';
import { BrowserInviteHandler } from './browser-invite';
import { NativeInviteHandlerWithCode } from './native-invite-with-code';
import { NativeInviteHandlerWithoutCode } from './native-invite-without-code';

export const inviteHandlerFactory = (
  featureEnablementService: FeatureEnablementService,
  nativeApi: WenNativeApi,
  navigationHelper: WenNavigationHelper,
  translateService: TranslateService,
  socketIoService: SocketIoService,
  snackBarOpener: WenSnackbarOpener,
  inviteConfig: ExclusiveInviteConfig
) => {
  if (nativeApi.isReactNativeApp() && featureEnablementService.featureFlagMethods.isEnableNativeShareInvite()) {
    return featureEnablementService.isInviteOnlyNetworkEnabled() ?
      new NativeInviteHandlerWithCode(nativeApi, translateService, inviteConfig, socketIoService, snackBarOpener) :
      new NativeInviteHandlerWithoutCode(nativeApi, translateService, inviteConfig);
  }
  return new BrowserInviteHandler(navigationHelper);
};
