import { Observable } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { LoadResultMode } from '../../../core/common/types/misc';
import { OccurenceEntity } from '../../../core/store/events/event.state';

export const mapToEventLoadResult = (loadMode$: Observable<LoadResultMode>) =>
  (source$: Observable<Partial<OccurenceEntity[]>>): Observable<any> => {
    return source$.pipe(
      withLatestFrom(loadMode$, (events, loadResultMode) => {
        return {
          data: events,
          mode: loadResultMode
        };
      })
    );
  };
