import { FactoryProvider, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of, shareReplay } from 'rxjs';
import { selectCurrentRoom } from '../../../../../../core/store/chat/chat.selectors';
import { ChatRoomEntity } from '../../../../../../core/store/chat/chat.state';
import { RootState } from '../../../../../../core/store/root/public-api';

@Injectable()
export abstract class ChatFormDataProvider {
  chat$: Observable<ChatRoomEntity>;
}

@Injectable()
export class CreateChatFormDataProvider extends ChatFormDataProvider {
  chat$ = of({} as ChatRoomEntity);
}

@Injectable()
export class EditChatFormDataProvider extends ChatFormDataProvider {
  chat$ = this.store.pipe(select(selectCurrentRoom), shareReplay(1));
  constructor(private store: Store<RootState>) {
    super();
  }
}



export function chatEditFormDataProviderFactoryFn(
  route: ActivatedRoute,
  store: Store<RootState>
): ChatFormDataProvider {
  return route.snapshot.params.chatId ? new EditChatFormDataProvider(store) : new CreateChatFormDataProvider();
}

export const CHAT_EDIT_FORM_DATA_PROVIDER: FactoryProvider = {
  provide: ChatFormDataProvider,
  useFactory: chatEditFormDataProviderFactoryFn,
  deps: [ActivatedRoute, Store]
};
