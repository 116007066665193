import { Component, ViewEncapsulation, HostBinding, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'wen-embed-forward',
  templateUrl: './embed-forward.component.html',
  styleUrls: ['./embed-forward.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmbedForwardComponent {

  @HostBinding('class.wen-embed-forward') className = true;

  @ViewChild('forwardContentHost', { read: ViewContainerRef }) contentContainerRef: ViewContainerRef;

}
