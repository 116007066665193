import { Inject, Injectable } from '@angular/core';
import { NativeConfig, NATIVE_CONFIG } from '../../../frame/api/tokens';
import { WenNavigationHelper } from './types';

@Injectable()
export class StoreNavigator {

  constructor(
    private readonly navigationHelper: WenNavigationHelper,
    @Inject(NATIVE_CONFIG) private readonly nativeConfig: NativeConfig,
  ) { }

  goToPlayStore() {
    const androidPlayStoreId = this.nativeConfig?.storeInfos?.androidPlayStoreId;
    if (!androidPlayStoreId) {
      return;
    }
    this.navigationHelper.navigateToAppStore({ androidPlayStoreId }, true);
  }

  goToAppStore() {
    const iosAppName = this.nativeConfig?.storeInfos?.iosAppName;
    const iosAppStoreId = this.nativeConfig?.storeInfos?.iosAppStoreId;
    const iosTestFlightLink = this.nativeConfig?.storeInfos?.iosTestFlightLink;
    if (!iosAppName || !iosAppStoreId) {
      if (iosTestFlightLink) {
        this.navigationHelper.navigateToUrlWithFallback(iosTestFlightLink);
      }
      return;
    }
    this.navigationHelper.navigateToAppStore({
      iosAppName, iosAppStoreId
    }, false);
  }

  isPlayStoreConfigValid() {
    return Boolean(this.nativeConfig?.storeInfos?.androidPlayStoreId);
  }

  isAppStoreConfigValid() {
    return (Boolean(this.nativeConfig?.storeInfos?.iosAppName) && Boolean(this.nativeConfig?.storeInfos?.iosAppStoreId)) ||
      Boolean(this.nativeConfig?.storeInfos?.iosTestFlightLink);
  }

}
