import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

type CardDirection = 'row' | 'column';

@Component({
  selector: 'wen-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent {

  @HostBinding('class.wen-card') className = true;

  @HostBinding('style.flex-direction')
  @Input() direction: CardDirection = 'row';

  @HostBinding('class.wen-card-elevation')
  @Input() elevate = false;

}
