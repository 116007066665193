import { Directive, Input } from '@angular/core';
import { isNullOrUndefined } from '../../../core/common/operators/null-check-util';
import { SearchConfig } from '../../../core/store/header/models/HeaderData';

@Directive({
  selector: '[searchEventsHandler]'
})
export class SearchEventsHandlerDirective {

  private searchConfig: SearchConfig;

  @Input() set searchEventsHandler(config: SearchConfig) {
    if (config?.filterConfig && !isNullOrUndefined(config?.navigateToFn)) {
      throw new Error('Filter config and navigation can not work together');
    }
    this.searchConfig = config;
  }

  onSearchnputFocused() {
    if (!isNullOrUndefined(this.searchConfig?.navigateToFn)) {
      this.searchConfig.navigateToFn();
    }
  }

}
