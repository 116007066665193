import { WenRoutes } from '../../../frame/routing/types';
import { eventDetailRoute, eventDiscoveryRoute, eventExploreRoute, eventListRoute } from './events.routes';

export const sidebarOutletEventRoutes: WenRoutes = [
  eventExploreRoute,
  eventDiscoveryRoute,
  eventListRoute,
  {
    path: '**',
    redirectTo: 'explore'
  }
];

export const primaryOutletEventRoutes: WenRoutes = [
  eventDetailRoute,
  {
    path: '**',
    redirectTo: '/home/dashboard(sidebar:event/explore)'
  }
];
