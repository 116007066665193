import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SocketIoService } from '@portal/wen-backend-api';
import { fetchWalletCredentials, walletCredentialsRetrieved } from './wallet.actions';
import { map, switchMap } from 'rxjs';

@Injectable()
export class WalletEffects {

  constructor(
    private actions$: Actions,
    private socketIoService: SocketIoService,
  ) { }

  initializeWalletCredentials$ = createEffect(() => this.actions$.pipe(
    ofType(fetchWalletCredentials),
    switchMap(() => {
      return this.socketIoService.wallet.credentialDetails.acknowledgement$();
    }),
    map(newState => {
      return walletCredentialsRetrieved({newState});
    })
  ));

}
