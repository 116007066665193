import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export abstract class MessagesDatasource<T = any> {

  abstract lastUnreadMessage$: Observable<T>;
  abstract messages$: Observable<T[]>;
  hasMessages$: Observable<boolean>;
  hasUnreadMessages$: Observable<boolean>;

  initialize() {
    this.hasMessages$ = this.messages$.pipe(
      map(messages => Boolean(messages?.length)),
      distinctUntilChanged(),
    );
  }
}
