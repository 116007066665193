import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';
import { MarkdownService } from '../../services/markdown/markdown.service';

@Pipe({
  name: 'removeMarkdown',
})
export class RemoveMarkdownPipe implements PipeTransform {
  constructor(
    private markdownService: MarkdownService,
    private sanitizer: DomSanitizer
  ) {}

  transform(value: string, markdownEnabled = true): SafeHtml | string {
    if (value) {
      const markdownStrippedHtml = this.markdownService.removeMarkdown(value, markdownEnabled);
      return this.sanitizer.bypassSecurityTrustHtml(markdownStrippedHtml);
    }

    return '';
  }
}
