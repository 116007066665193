<ng-container *ngTemplateOutlet="notificationViewModel.notificationType === notificationType.SYSTEM_MESSAGE ? defaultContent : notificationContent; context: { content: notificationViewModel, user: notificationViewModel.triggerUser }"></ng-container>


<ng-template #notificationContent let-content="content" let-user="user">
  <wen-horizontal-card>
    <wen-circle-image-view [imageUrl]="(user?.userId | toAvatarUrl)" wen-horizontal-card-image wen-list-item-icon
                           shape="circle" [placeholderText]="user?.displayName">
      <wen-notification-type wen-circle-image-overlay position="bottom" positionOffset="-8px" rightOffset="-6px"
                             [message]="content"></wen-notification-type>
    </wen-circle-image-view>
    <div wen-horizontal-card-title portal-title>{{ content.header }}</div>
    <wen-markdown-text wen-horizontal-card-description [content]="content.message"></wen-markdown-text>
  </wen-horizontal-card>
</ng-template>

<ng-template #defaultContent let-content="content">
  <wen-markdown-text [content]="content.message"></wen-markdown-text>
</ng-template>
