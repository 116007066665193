import { inject } from '@angular/core';
import { WenLanguage } from '@portal/wen-backend-api';
import { TRANSLATION_CONFIG } from '../tokens/translation.token';

export abstract class WenLanguageService {

  private translationConfig = inject(TRANSLATION_CONFIG);

  abstract initLanguage(): void;

  abstract setLanguage(languageCode: string): void;

  abstract getLanguage(userProfile: any): WenLanguage;

  getSupportedLanguages(): WenLanguage[] {
    return this.translationConfig.supportedLanguages;
  }
}

