
import { RRule } from 'rrule';
import { Language } from 'rrule/dist/esm/nlp/i18n';

export const toGermanString = (rrule: string) => RRule.fromString(rrule).toText(getGermanText, GERMAN, germanDateFormat);

const germanTokens = {
  every: 'Jede(n)',
  until: 'bis zum',
  day: 'Tag',
  days: 'Tage',
  week: 'Woche',
  weeks: 'Wochen',
  on: 'am',
  in: 'in',
  'on the': 'am',
  for: 'für',
  and: 'und',
  or: 'oder',
  at: 'bei',
  last: 'zuletzt',
  st: '.,',
  nd: '.,',
  rd: '.,',
  th: '.,',
  '(~ approximate)': '(~ ungefähr)',
  times: 'Zeiten',
  time: 'Zeit',
  minutes: 'Minuten',
  hours: 'Stunden',
  /**
   * Since for weekday it can be unclear in german
   * what days are included we use specifically the day range
   */
  weekdays: 'Montags bis Freitags',
  weekday: 'Montag bis Freitag',
  months: 'Monate',
  month: 'Monat',
  years: 'Jahre',
  year: 'Jahr'
};

const GERMAN: Language = {
  dayNames: [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag'
  ],
  monthNames: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
  ],
  tokens: {},
};
const  germanDateFormat = (year, month, day) => `${day}. ${month} ${year}`;

const getGermanText = (id) => germanTokens[id] || id;
