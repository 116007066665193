import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { DateUtil } from '../../../../../core/common/date/date-util';
import { LoadResultMode } from '../../../../../core/common/types/misc';
import { selectorWithParam } from '../../../../../core/common/util/selector-with-param';
import { selectUserChannelIds, selectUserChannelIsLoaded } from '../../../../../core/store/channel/channel.selectors';
import { selectSearchTerm } from '../../../../../core/store/filter/filter.selectors';
import { FilterEntityType } from '../../../../../core/store/filter/models/filter';
import { ChannelLoadResult } from '../channel-list/models/channel-load-result';
import { ChannelListItemModel } from '../channel-list/channel-list-item/models/list-item-model';
import { filterBy } from '../../../../../core/common/operators/fitler-by';
import { ChannelListItemFactory } from '../channel-list/channel-list-item/providers/channel-list-item-factory';
import { ChannelListItemDataSource } from '../channel-list/channel-list-item/providers/channel-list-item-data-source';
import { smartDistinctUntilChanged } from '@portal/wen-components';

@Injectable()
export class UserChannelListDataSource {

  public readonly loadResult$: Observable<ChannelLoadResult>;

  constructor(
    private readonly store: Store,
    private readonly itemDataSource: ChannelListItemDataSource,
    private readonly itemFactory: ChannelListItemFactory,
  ) {

    const isUserChannelLoaded$ = this.store.pipe(
      select(selectUserChannelIsLoaded),
      distinctUntilChanged()
    );

    const currentSearchTerm$ = this.store.pipe(
      selectorWithParam(selectSearchTerm, FilterEntityType.CHANNEL_LISTS),
      distinctUntilChanged(),
    );

    const userChannelIds$ = this.store.pipe(
      select(selectUserChannelIds),
      filterBy(() => isUserChannelLoaded$),
      startWith([] as string[]),
      smartDistinctUntilChanged(),
      shareReplay(1),
    );

    const allChannelModels$ = userChannelIds$.pipe(
      switchMap(channelIds => {
        const models$ = channelIds.map(id => this.itemDataSource.getChannelListItemProps(id).pipe(
          map(properties => {
            if (!Boolean(properties)) {
              return null;
            }
            if (properties.channel.invite) {
              return this.itemFactory.createInvitationListItem(properties, false);
            }
            return this.itemFactory.createSubscribedListItem(properties);
          }),
          smartDistinctUntilChanged()
        ));
        return combineLatest(models$);
      }),
      shareReplay(1)
    );

    const filteredChannelModels$ = allChannelModels$.pipe(
      map(channels => channels.filter(Boolean)),
      switchMap(channels => currentSearchTerm$.pipe(
        map(searchTerm => this.filterChannels(channels, searchTerm))
      ))
    );

    const filteredAndSortedChannelModels$ = filteredChannelModels$.pipe(
      map((filtered) => {
        return filtered.sort((channelA, channelB) => {
          if (Boolean(channelA.pinTimestamp) !== Boolean(channelB.pinTimestamp)) {
            return Boolean(channelB.pinTimestamp) ? 1 : -1;
          }
          return DateUtil.compareNullSafe(
            channelA.pinTimestamp || channelA.timestamp,
            channelB.pinTimestamp || channelB.timestamp
          );
        });
      }),
      startWith([] as ChannelListItemModel[])
    );

    const loadResultMode$ = currentSearchTerm$.pipe(
      map(searchTerm => searchTerm?.length ? LoadResultMode.SEARCH : LoadResultMode.NORMAL),
      distinctUntilChanged()
    );

    this.loadResult$ = isUserChannelLoaded$.pipe(
      switchMap((loaded) => {
        if (!loaded) {
          return of({ mode: LoadResultMode.NORMAL, data: null });
        }
        return filteredAndSortedChannelModels$.pipe(
          withLatestFrom(loadResultMode$),
          map(([data, mode]) => ({ mode, data }))
        );
      }),
      smartDistinctUntilChanged()
    );
  }

  private filterChannels(channels: ChannelListItemModel[], searchTerm: string) {
    if (!searchTerm) {
      return channels;
    }
    return channels.filter(channel => channel.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
  }

}
