import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { RoomType } from '@portal/wen-backend-api';
import { filter, first, map, of, switchMap } from 'rxjs';
import { FormStoreMediator } from '../../../../shared/form-store/form-store-mediator';
import { hasProperty } from '../../../common/operators/null-check-util';
import { deepMerge } from '../../../common/util/deepmerge';
import { roomCreateRequest, roomUpdateRequest, uploadImageForRoom } from '../../chat/chat.actions';
import { DataObjectType } from '../../common/data-objects';
import { doBackNavigation } from '../../header/header.actions';
import { RootState } from '../../root/public-api';
import { clearAllFormValues, clearFormValues } from '../form.actions';
import { selectAllEditForms, selectEditFormById } from '../form.selectors';
import { EditFormEntity } from '../form.state';

@Injectable()
export class ChatFormEffect {

  saveGroupChatCreation$ = this.formStoreMediator.createSaveEditFormEffect((saveAction) => of(saveAction).pipe(
    filter(action => action.dataObjectType === DataObjectType.CHAT),
    switchMap(() => this.store.pipe(
      select(selectAllEditForms),
      first()
    )),
    map(editForms => {
      let chat = {} as EditFormEntity;
      Object.keys(editForms).forEach(key => {
        chat = deepMerge(chat, editForms[key]);
      });
      return chat;
    }),
    filter((editForm) => !Boolean(editForm?.initialValues?.id)),
    switchMap(({ changedValues }) => {
      const { icon, users, ...otherChangedValues } = changedValues;
      const targetUserIds: string[] = users?.map(user => user.id);
      const actions: Action[] = [];
      const roomCreateAction = roomCreateRequest({
        roomType: RoomType.GROUP,
        targetUserIds: targetUserIds ?? [],
        ...otherChangedValues,
        icon,
        fromCreateMode: true
      });
      actions.push(roomCreateAction);
      actions.push(clearAllFormValues());
      return actions;
    })
  ));

  saveGroupChatEdit$ = this.formStoreMediator.createSaveEditFormEffect((saveAction) => of(saveAction).pipe(
    filter(action => action.dataObjectType === DataObjectType.CHAT),
    switchMap(({ formId }) => this.store.pipe(
      select(selectEditFormById(formId)),
      first()
    )),
    filter((editForm) => Boolean(editForm?.initialValues?.id)),
    switchMap(({ changedValues, initialValues, formId }) => {
      const { icon, ...otherChangedValues } = changedValues;
      const iconRemoved = hasProperty(changedValues, 'icon') && !icon;
      const { id } = initialValues;
      const actions: Action[] = [clearFormValues({ formId }),];
      if (iconRemoved || Object.keys(otherChangedValues).length > 0) {
        const roomUpdateAction = roomUpdateRequest({
          room: {
            ...otherChangedValues,
            roomId: id,
            ...iconRemoved && { icon: null }
          }
        });
        actions.push(roomUpdateAction);
      }
      if (icon) {
        const iconUpdate = uploadImageForRoom({
          image: icon,
          roomId: id
        });
        actions.push(iconUpdate);
      }
      actions.push(doBackNavigation());
      return actions;
    })
  )
  );

  constructor(
    private store: Store<RootState>,
    private formStoreMediator: FormStoreMediator,
  ) { }

}
