
import { EntityState, Update } from '@ngrx/entity';
import { channelAdapter, ChannelEntity, ChannelMessageEntity, channelMessagesAdapter } from '../channel.state';
import { findChannelById } from './state-adapter-utils';

export const channelScheduledMessagesHelper = {

  upsertMany: (
    messages: ChannelMessageEntity[],
    channelId: string,
    channelEntities: EntityState<ChannelEntity>,
    hasMore: boolean
  ) => {
    let newChannels = channelEntities;
    let targetChannel = findChannelById(channelId, newChannels);
    if (!targetChannel?.scheduledMessages) {
      targetChannel = { ...targetChannel, scheduledMessages: channelMessagesAdapter.getInitialState() };
    }

    const newChannelMessages = channelMessagesAdapter.upsertMany(messages, targetChannel.scheduledMessages);
    const scheduledMessages = { ...targetChannel.scheduledMessages, ...newChannelMessages };

    const updates: Update<ChannelEntity> = {
      id: channelId,
      changes: {
        scheduledMessages,
        scheduledMessagesHasMore: hasMore
      }
    };
    newChannels = channelAdapter.updateOne(updates, channelEntities);
    return newChannels;
  },

  upsertOne: (
    message: ChannelMessageEntity,
    channelEntities: EntityState<ChannelEntity>
  ) => {
    let newChannels = channelEntities;
    let targetChannel = findChannelById(message.channelId, newChannels);
    if (!targetChannel?.scheduledMessages) {
      targetChannel = { ...targetChannel, scheduledMessages: channelMessagesAdapter.getInitialState() };
    }

    const newScheduledMessagesEntityState = channelMessagesAdapter.upsertOne(message, targetChannel.scheduledMessages);

    const updates: Update<ChannelEntity> = {
      id: message.channelId,
      changes: {
        scheduledMessages: newScheduledMessagesEntityState
      }
    };
    newChannels = channelAdapter.updateOne(updates, channelEntities);
    return newChannels;
  },

  removeOne: (
    message: ChannelMessageEntity,
    channelEntities: EntityState<ChannelEntity>
  ) => {
    let newChannels = channelEntities;
    let targetChannel = findChannelById(message.channelId, newChannels);
    if (!targetChannel.scheduledMessages) {
      targetChannel = { ...targetChannel, scheduledMessages: channelMessagesAdapter.getInitialState() };
    }

    const newScheduledMessagesEntityState = channelAdapter.removeOne(message.id, targetChannel.scheduledMessages);
    const updates: Update<ChannelEntity> = {
      id: message.channelId,
      changes: {
        scheduledMessages: newScheduledMessagesEntityState
      }
    };
    newChannels = channelAdapter.updateOne(updates, channelEntities);
    return newChannels;
  },

  removeMany: (
    channelId: string,
    messages: ChannelMessageEntity[],
    channelEntities: EntityState<ChannelEntity>
  ) => {
    let newChannels = channelEntities;
    let targetChannel = findChannelById(channelId, newChannels);
    if (!targetChannel?.scheduledMessages) {
      targetChannel = { ...targetChannel, scheduledMessages: channelMessagesAdapter.getInitialState() };
    }

    const messageIds = messages.map(message => message.id);
    const newScheduledMessagesEntityState = channelAdapter.removeMany(messageIds, targetChannel.scheduledMessages);
    const updates: Update<ChannelEntity> = {
      id: channelId,
      changes: {
        scheduledMessages: newScheduledMessagesEntityState
      }
    };
    newChannels = channelAdapter.updateOne(updates, channelEntities);
    return newChannels;
  },

  setCount: (
    count: number,
    channelId: string,
    channelEntities: EntityState<ChannelEntity>
  ) => {
    let newChannels = channelEntities;
    const updates: Update<ChannelEntity> = {
      id: channelId,
      changes: {
        scheduledMessagesCount: count
      }
    };
    newChannels = channelAdapter.updateOne(updates, channelEntities);
    return newChannels;
  }
};
