import { Injectable} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent, ConfirmationDialogResult, OverlayManager } from '@portal/wen-components';
import { Observable, first, map, of, tap } from 'rxjs';
import { WenNavigationHelper } from '../../core/services/navigation/types';
import { PermissionLevel } from '../../core/services/user-management/permission-level';
import { WenOAuthService } from '../../core/services/user-management/wen-oauth.service';
import { logout } from '../../core/store/auth/auth.actions';
import { RootState } from '../../core/store/root/public-api';

@Injectable()
export class ForbiddenForAnonymousUserDialogService {

  get isAnonymous() {
    return this.oAuthService.getUserData()?.permissionLevel === PermissionLevel.ANONYMOUS;
  }

  constructor(
    private translateService: TranslateService,
    private overlayManager: OverlayManager,
    private navigationHelper: WenNavigationHelper,
    private store: Store<RootState>,
    private oAuthService: WenOAuthService,
  ) { }

  openCommentCreationForbiddenWhenAnonymous(): Observable<ConfirmationDialogResult> {
    return this.open('COMMENT_FORBIDDEN_FOR_ANONYMOUS_DIALOG_HEADER', 'COMMENT_FORBIDDEN_FOR_ANONYMOUS_DIALOG_CONTENT');
  }

  openChatCreationForbiddenWhenAnonymous(): Observable<ConfirmationDialogResult> {
    return this.open('CHAT_FORBIDDEN_FOR_ANONYMOUS_DIALOG_HEADER', 'CHAT_FORBIDDEN_FOR_ANONYMOUS_DIALOG_CONTENT');
  }

  openChannelCreationForbiddenWhenAnonymous(): Observable<ConfirmationDialogResult> {
    return this.open('CHANNEL_ADD_POP_UP_HEADER', 'CHANNEL_ADD_ANONYM_USER_POP_UP_TEXT');
  }

  openProfileInteractionForbiddenWhenAnonymous(): Observable<ConfirmationDialogResult> {
    return this.open('PROFILE_FORBIDDEN_FOR_ANONYMOUS_DIALOG_HEADER', 'PROFILE_FORBIDDEN_FOR_ANONYMOUS_DIALOG_CONTENT');
  }

  openWalletCredentialCreationForbiddenWhenAnonymous(): Observable<ConfirmationDialogResult> {
    return this.open('WALLET_FORBIDDEN_FOR_ANONYMOUS_DIALOG_HEADER', 'WALLET_FORBIDDEN_FOR_ANONYMOUS_DIALOG_CONTENT');
  }

  private open(headerLabel: string, contentLabel: string): Observable<ConfirmationDialogResult> {
    if (!this.isAnonymous) {
      return of(null);
    }

    const dialog = this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, {
      header: this.translateService.instant(headerLabel),
      content: this.translateService.instant(contentLabel),
      okLabel: this.translateService.instant('FORBIDDEN_FOR_ANONYMOUS_DIALOG_REGISTER'),
      neutralLabel: this.translateService.instant('FORBIDDEN_FOR_ANONYMOUS_DIALOG_LOGIN'),
      dismissLabel: this.translateService.instant('FORBIDDEN_FOR_ANONYMOUS_DIALOG_CANCEL')
    }, { closeOnNavigation: true });

    return dialog.afterClosed().pipe(
      first(),
      map(data => data ?? { result: 'dismiss' } as ConfirmationDialogResult),
      tap(data => {
        if (data?.result === 'ok') {
          this.navigationHelper.navigateToRegistrationStart(true);
        } else if (data?.result === 'neutral') {
          this.store.dispatch(logout());
        }
      })
    );
  }

}
