import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { AcknowledgeType, ChatMessageAcknowledgeDTO, SocketIoService } from '@portal/wen-backend-api';
import { bufferCount, filter, first, from, map, merge, mergeMap, pairwise, switchMap, tap } from 'rxjs';
import { WenRouteId } from '../../../../frame/routing/types';
import { chatViewIdentifier } from '../../../../views/chat/tokens';
import { DateUtil } from '../../../common/date/date-util';
import { lastItem } from '../../../common/operators/array-utils';
import { selectorWithParam } from '../../../common/util/selector-with-param';
import { ChatEventOrchestrator } from '../../../services/socket-io/helpers/chat-event-orchestrator';
import { WenOAuthService } from '../../../services/user-management/wen-oauth.service';
import { RootState } from '../../root/public-api';
import { selectOutletIds, selectRouteParam } from '../../root/root.selectors';
import { acknowledgeChatMessage, enableAutoReadAcknowledgeForCurrentRoom, fetchAcknowledgeTimestamps, subscribeChatMessageAcknowledgeUpdates, updateAutoReadAcknowledgeRoomId, updateChatMessageAcknowledgeStatusInRoom } from '../chat.actions';
import { selectCurrentAutoReadAcknowledgeRoomId, selectRoomById } from '../chat.selectors';
import { ChatMessageEntity } from '../chat.state';
import { selectCurrentRoomMessagesHistory, selectRoomMessagesHistoryById } from '../selectors/chat-message-selectors';

@Injectable()
export class ChatMessageAcknowledgeEffects {

  get userId() {
    return this.oAuthService.getUserData()?.userId;
  }

  messageAcknowledgeEffect$ = createEffect(() => this.actions$.pipe(
    ofType(acknowledgeChatMessage),
    filter(({ senderUserId }) => senderUserId !== this.userId),
    mergeMap(({ payload }) => this.store.pipe(
      selectorWithParam(selectRoomById, payload.roomId),
      filter(room => {
        return Boolean(room && room.lastReadTimestamp && room.lastReceivedTimestamp);
      }),
      first(),
      filter(room => {
        const isNewerRead = DateUtil.compare(room.lastReadTimestamp, payload.lastAckTimestamp) > 0;
        if (payload.type === AcknowledgeType.RECEIVED) {
          const isNewerReceived = DateUtil.compare(room.lastReceivedTimestamp, payload.lastAckTimestamp) > 0;
          return isNewerReceived && isNewerRead;
        } else if (payload.type === AcknowledgeType.READ) {
          return isNewerRead;
        }
      }),
      map(() => payload)
    )),
    tap((payload) => this.socketIoService.chat.room.messagesAcknowledge.emit(payload))
  ), { dispatch: false });

  chatMessageAcknowledgeListenerEffect$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeChatMessageAcknowledgeUpdates),
    mergeMap(() => {
      return this.socketIoService.chat.room.messagesAcknowledgeReplay.listen.pipe(
        map(({ roomId, minReceivedTs: lastReceivedTimestamp, minReadTs: lastReadTimestamp }) => {
          return updateChatMessageAcknowledgeStatusInRoom({ roomId, lastReceivedTimestamp, lastReadTimestamp });
        })
      );
    })
  ));

  receivedMessageAcknowledgeEffect$ = createEffect(() =>
    merge(
      this.socketIoService.chat.room.messages.listen,
      this.socketIoService.chat.room.messagesReplay.listen.pipe(
        filter(payload => Boolean(payload?.events?.length)),
        map(payload => lastItem(payload.events)),
      ),
      this.chatEventOrchestrator.bufferedSummary$.pipe(
        map(responses => {
          return responses
            .filter((response) => Boolean(response?.latestEvents?.length))
            .map(response => lastItem(response.latestEvents));
        })
      )
    ).pipe(switchMap((messages) => {
      const messageArr = Array.isArray(messages) ? messages : [messages];
      const targetActions = messageArr.map(message => {
        const payload: ChatMessageAcknowledgeDTO = {
          eventId: message.eventId,
          roomId: message.roomId,
          lastAckTimestamp: message.insertTimestamp,
          type: AcknowledgeType.RECEIVED,
          userId: this.userId,
        };
        return acknowledgeChatMessage({ payload, senderUserId: message.insertUser.id });
      });

      return targetActions;
    })
    )
  );

  autoReadAcknowledgeEffect$ = createEffect(() => this.socketIoService.chat.room.messages.listen.pipe(
    switchMap((message => this.store.pipe(
      select(selectCurrentAutoReadAcknowledgeRoomId),
      first(),
      filter(roomId => message.roomId === roomId),
      map(() => message)
    ))),
    map(message => {
      const payload: ChatMessageAcknowledgeDTO = {
        eventId: message.eventId,
        roomId: message.roomId,
        lastAckTimestamp: message.insertTimestamp,
        userId: this.userId,
        type: AcknowledgeType.READ,
      };

      return acknowledgeChatMessage({ payload, senderUserId: message.insertUser.id });
    })
  ));

  acknowledgeOnAutoReadAcknowledgeEnablementEffect$ = createEffect(() => this.actions$.pipe(
    ofType(enableAutoReadAcknowledgeForCurrentRoom),
    switchMap(() => {
      return this.store.pipe(
        select(selectCurrentRoomMessagesHistory),
        first(),
        map(messages => this.createAcknowledgePayload(messages)),
        filter(acknowledge => Boolean(acknowledge)),
        map(acknowledge => acknowledgeChatMessage(acknowledge)),
      );
    })
  ));

  updateAutoReadAcknowledgeRoomIdEffect$ = createEffect(() => this.actions$.pipe(
    ofType(enableAutoReadAcknowledgeForCurrentRoom),
    switchMap(() => {
      return this.store.pipe(
        select(selectRouteParam(chatViewIdentifier)),
        first(),
        map(roomId => updateAutoReadAcknowledgeRoomId({ roomId })),
      );
    })
  ));

  disableAutoReadAcknowledgeEffect$ = createEffect(() => this.store.pipe(
    select(selectOutletIds),
    pairwise(),
    filter(([fromRouteData]) => {
      const fromRouteId = fromRouteData?.dialogId || fromRouteData?.primaryId;
      return fromRouteId === WenRouteId.CHAT_VIEW;
    }),
    map(() => updateAutoReadAcknowledgeRoomId({ roomId: null })),
  ));

  acknowledgeRoomsLatestMessageWhenLeaving$ = createEffect(() =>
    this.store.pipe(
      select(selectRouteParam(chatViewIdentifier)),
      pairwise(),
      filter(([fromRoomId, toRoomId]) => Boolean(fromRoomId) && fromRoomId !== toRoomId),
      switchMap(([fromRoomId]) => {
        return this.store.pipe(
          selectorWithParam(selectRoomMessagesHistoryById, fromRoomId),
          first(),
          map(messages => this.createAcknowledgePayload(messages)),
          filter(acknowledge => Boolean(acknowledge)),
          map(acknowledge => acknowledgeChatMessage(acknowledge)),
        );
      })
    ));

  fetchAcknowledgeTimestampsEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchAcknowledgeTimestamps),
      mergeMap(({ roomIds }) =>
        from(roomIds).pipe(
          bufferCount(100),
          tap((roomIdsBatch) => {
            this.socketIoService.chat.room.bulkMessagesAckowledgeReplay.emit({
              roomIds: roomIdsBatch,
            });
          })
        )
      )
    );
  }, { dispatch: false });

  private createAcknowledgePayload(messages: ChatMessageEntity[]): { payload: ChatMessageAcknowledgeDTO; senderUserId: string } {
    if (!messages?.length) {
      return null;
    }

    const latestNewMessage = messages
      .sort(((a, b) => DateUtil.compare(a.insertTimestamp, b.insertTimestamp)))
      .find(message => message.new);

    if (!latestNewMessage) {
      return null;
    }

    const payload: ChatMessageAcknowledgeDTO = {
      eventId: latestNewMessage.eventId,
      roomId: latestNewMessage.encryptionData.roomId,
      lastAckTimestamp: latestNewMessage.insertTimestamp,
      userId: this.userId,
      type: AcknowledgeType.READ
    };
    return { payload, senderUserId: latestNewMessage.insertUser.id };
  }

  constructor(
    private store: Store<RootState>,
    private actions$: Actions,
    private socketIoService: SocketIoService,
    private oAuthService: WenOAuthService,
    private chatEventOrchestrator: ChatEventOrchestrator,
  ) { }

}
