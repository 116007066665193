import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { WenSnackbarNotificationData, WenSnackbarOpener } from '@portal/wen-components';
import { Subscription } from 'rxjs';
import { filter, first, shareReplay, switchMapTo, tap, withLatestFrom } from 'rxjs/operators';
import { selectCurrentUserData } from '../../../core/store/auth/auth.selectors';
import { RootState } from '../../../core/store/root/public-api';
import { selectUserProfile } from '../../../core/store/user/user.selectors';

@Injectable()
export class EmailVerificationService {

  private get successNotificationData() {
    return {
      notificationIcon: 'success',
      notificationText: this.translateService.instant('EMAIL_VERIFICATION_SNACKBAR_SUCCESS')
    };
  }

  private get errorNotificationData() {
    return {
      notificationIcon: 'error_robot',
      notificationText: this.translateService.instant('EMAIL_VERIFICATION_SNACKBAR_ERROR')
    };
  }

  private verifyEmailSub = new Subscription();
  private userData$ = this.store.pipe(
    select(selectCurrentUserData),
    filter(data => !!data),
    shareReplay(1)
  );

  constructor(
    private store: Store<RootState>,
    private socketIoService: SocketIoService,
    private wenSnackbar: WenSnackbarOpener,
    private translateService: TranslateService,
  ) { }

  verifyCode(verificationCode: string) {
    this.verifyEmailSub.unsubscribe();
    if (verificationCode) {
      this.verifyEmailSub = this.store.pipe(
        select(selectUserProfile),
        filter(profile => !!profile),
        first(),
        filter((profile) => !profile.emailVerified),
        switchMapTo(this.userData$.pipe(
          first(),
          tap(({ userId }) => {
            this.socketIoService.user.verifyEmail.emit({ userId, code: verificationCode });
          })
        )),
        switchMapTo(this.socketIoService.user.verifyEmail.listen.pipe(
          filter(({ code }) => code === verificationCode),
          first(),
          withLatestFrom(this.userData$.pipe(first()))
        ))
      ).subscribe(([response, userData]) => {
        const { valid } = response;
        let notificationData: WenSnackbarNotificationData;
        if (valid) {
          notificationData = this.successNotificationData;
          this.socketIoService.system.channelPolicy.emit({ userId: userData.userId });
          this.socketIoService.user.profile.emit({ userId: userData.userId });
        } else {
          notificationData = this.errorNotificationData;
        }
        this.wenSnackbar.openNotificationSnackbar(notificationData);
      });
    }
  }
}
