import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { AllFilterIds, Filter, FilterEntityType } from './models/filter';

export type ActiveFilterMap = Record<AllFilterIds, Filter>;

export interface FilterEntity {
  filterEntityType: FilterEntityType;
  activeFilters: ActiveFilterMap;
}

export interface FilterState {
  filterEntities: EntityState<FilterEntity>;
}

export const filterEntityAdapter = createEntityAdapter<FilterEntity>({
  selectId: (entity) => entity.filterEntityType
});
