import { Injectable, inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { filter, first } from 'rxjs';
import { fetchScheduledMessagesForCurrentChannel } from '../../../../core/store/channel/channel.actions';
import { selectCurrentChannel } from '../../../../core/store/channel/channel.selectors';
import { RootState } from '../../../../core/store/root/public-api';

export const scheduledMessageResolver: ResolveFn<void> = () => {
  return inject(ScheduledMessageResolver).resolve();
};

@Injectable()
export class ScheduledMessageResolver {

  constructor(private store: Store<RootState>) { }

  resolve(): void {
    this.store.pipe(
      select(selectCurrentChannel),
      filter(channel => !!channel?.permission),
      first(),
      filter(channel => {
        return Boolean(channel.id && channel.permission?.canSend);
      })
    ).subscribe(({ id: channelId }) => {
      this.store.dispatch(fetchScheduledMessagesForCurrentChannel({
        channelId,
        timestamp: null,
        direction: null
      }));
    });
  }
}
