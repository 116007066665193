import { Injectable } from '@angular/core';
import { finalize, first, from, fromEvent, map, Observable, switchMap } from 'rxjs';
import { MEDIA_PERMISSIONS } from '@portal/wen-native-api';
import { WenNativeApi } from '@portal/wen-native-api';

const FILE_INPUT_ID = 'wen-mobile-upload-selector';

@Injectable()
export class FileSelectorHandler {
  constructor(
    private wenNativeAPI: WenNativeApi,
  ) { }

  openFileSelector(allowedExtensions?: string[]): Observable<File> {
    if (this.wenNativeAPI.isIOS() || !this.wenNativeAPI.isReactNativeApp()) {
      return this.getFileFromSelector(allowedExtensions);
    }

    return from(this.wenNativeAPI.requestMultiplePermissions(MEDIA_PERMISSIONS)).pipe(
      switchMap(() => this.getFileFromSelector(allowedExtensions))
    );
  }

  /**
   * Copying the file is necessary because of a bug with the Chromium when getting image from Google Drive
   * https://bugs.chromium.org/p/chromium/issues/detail?id=1063576
   */
  private async getBuffer(file: File): Promise<File> {
    const buffer = await file.arrayBuffer();
    return new File([buffer], file.name, { type: file.type });
  }

  private getFileFromSelector(allowedExtensions?: string[]): Observable<File> {
    let input = document.getElementById(FILE_INPUT_ID) as HTMLInputElement;
    if (!input) {
      input = document.createElement('input');
      input.setAttribute('id', FILE_INPUT_ID);
    }
    input.type = 'file';
    input.accept = allowedExtensions?.join(',');
    document.body.appendChild(input);
    const selectedFile$ = fromEvent(input, 'change').pipe(
      map(({ target }: Event) => (target as HTMLInputElement).files.item(0)),
      switchMap((value) => from(this.getBuffer(value))),
      first(),
      finalize(() => document.body.removeChild(input))
    );
    input.click();
    return selectedFile$;
  }
}
