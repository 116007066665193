import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { firstExisty } from '../../../../../../core/common/operators/first-existy';
import { WenNavigationHelper } from '../../../../../../core/services/navigation/types';
import { selectChannelPermission, selectCurrentChannel } from '../../../../../../core/store/channel/channel.selectors';
import { RootState } from '../../../../../../core/store/root/public-api';

export const channelAddAdminsGuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(ChannelAddAdminsGuard).canActivate(route, state);
};

@Injectable()
export class ChannelAddAdminsGuard  {
  constructor(
    private store: Store<RootState>,
    private navigationHelper: WenNavigationHelper,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(selectCurrentChannel),
      firstExisty(),
      switchMap((channel) => {
        if (channel.id) {
          return this.store.pipe(
            select(selectChannelPermission),
            map((channelPermissionFn) => channelPermissionFn(channel.id)),
            firstExisty(),
            map(permission => permission.canAddAdmins),
            tap(canAddAdmins => {
              if (!canAddAdmins) {
                this.navigationHelper.navigateToChannelDetail(channel.id);
              }
            })
          );
        } else {
          return of(false);
        }
      })
    );
  }

}
