import { Injectable } from '@angular/core';
import { ErrorDialogData, OverlayManager } from '@portal/wen-components';
import { first, map, Observable, of } from 'rxjs';
import { MediaSettingsService } from '../media/media-settings.service';
import { FeatureEnablementService } from '../configuration/feature-enablement';

const oneMiBInBytes = Math.pow(1024, 2);

@Injectable()
export class MediaValidator {

  constructor(
    private mediaSettingsService: MediaSettingsService,
    private overlayManager: OverlayManager,
    private featureEnablementService: FeatureEnablementService
  ) { }

  readonly allowedMediaExtensions = ['image/*', 'video/*', 'audio/*'];

  private isFileTypeAccepted(file: File, onlyMediaAllowed = false): boolean {
    if (
      this.featureEnablementService.featureFlagMethods.isEnableDocumentUpload()
      && !onlyMediaAllowed
    ) {
      return true;
    }

    return this.isFileTypeAcceptedAsMedia(file);
  }

  private isFileTypeAcceptedAsMedia(file: File): boolean {
    return this.allowedMediaExtensions.some(acceptedType => {
      const mainMimeType = acceptedType.split('/*').shift();
      return file.type.includes(mainMimeType);
    });
  }

  private isFileSizeAccepted(file: File): boolean {
    return this.mediaSettingsService.getMaxFileSizeInMiB() >= file.size / oneMiBInBytes;
  }

  private wrongFileFormatErrorDialogData(): ErrorDialogData {
    return {
      errorMessageLabel: 'ERROR_MEDIA_UPLOAD_WRONG_FILE_FORMAT',
    };
  }

  private tooBigFileErrorDialogData(): ErrorDialogData {
    return {
      errorMessageLabel: 'ERROR_MEDIA_UPLOAD_SIZE_TOO_BIG',
      messageParams: { maxFileSize: this.mediaSettingsService.getMaxFileSizeInMiB().toString() },
    };
  }

  validateMedia(file: File): Observable<File> {
    return this.validate(file, true);
  }

  validate(file: File, onlyMediaAllowed = false): Observable<File> {
    if (!this.isFileTypeAccepted(file, onlyMediaAllowed)) {
      return this.overlayManager.dialog.openErrorDialog(
        this.wrongFileFormatErrorDialogData(), 'ERROR_MEDIA_UPLOAD_TITLE'
      ).afterClosed().pipe(
        first(),
        map(() => null)
      );
    }
    if (!this.isFileSizeAccepted(file)) {
      return this.overlayManager.dialog.openErrorDialog(
        this.tooBigFileErrorDialogData(),'ERROR_MEDIA_UPLOAD_TITLE'
      ).afterClosed().pipe(
        first(),
        map(() => null)
      );
    }
    return of(file);
  }
}
