
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { fetchCommentCountForMessages } from '../../../../core/store/comments/comments.actions';
import { fetchReactionsForMessages } from '../../../../core/store/reactions/reaction.actions';
import { RootState } from '../../../../core/store/root/public-api';
import { FeedLayoutHooks } from '../../../../shared/components/feed/providers/feed-layout-hooks';

@Injectable()
export class MessageExtensionDataLoader implements FeedLayoutHooks, OnDestroy {

  private previouslyFetchedMessageIds: string[] = [];

  constructor(private store: Store<RootState>) { }

  beforeMessagesRendered(messageIds: string[]) {
    if (!messageIds?.length) {
      return;
    }
    const newMessageIds = this.previouslyFetchedMessageIds.length > 0 ? this.extractNewMessageIds(messageIds) : messageIds;
    this.previouslyFetchedMessageIds = [...this.previouslyFetchedMessageIds, ...newMessageIds];
    this.store.dispatch(fetchReactionsForMessages({ messageIds: newMessageIds, parentIds: newMessageIds }));
    this.store.dispatch(fetchCommentCountForMessages({ messageIds: newMessageIds }));
  }

  private extractNewMessageIds(newMessageIds: string[]) {
    return newMessageIds.filter(messageId => !this.previouslyFetchedMessageIds.includes(messageId));
  }

  ngOnDestroy(): void {
    this.previouslyFetchedMessageIds = [];
  }

}
