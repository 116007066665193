import { inject, Injectable } from '@angular/core';
import { mergeError } from '@portal/wen-common';
import { Tracer } from '@portal/wen-tracer';
import { catchError, first, of } from 'rxjs';
import { MegolmSessionNotFoundError } from '../../error-types';
import { CryptoStorage } from '../../persistence/crypto-storage';
import { convertHintsToObject, ShareKeyHint } from './chat-tracer-helpers';

/**
 * @returns True if the date is within a reasonable time period for processing
 */
const isRelevantDate = (date: Date) => {
  const days = 7;
  return date > new Date(Date.now() - (1000 * 60 * 60 * 24 * days));
};

@Injectable()
export class ChatTracer {

  private tracer = inject(Tracer);
  private dataStore = inject(CryptoStorage);

  private hints: ShareKeyHint[] = [];

  captureExceptionForRoom(
    error: Error,
    eventTimestamp: string,
    roomId: string,
  ) {
    if (!isRelevantDate(new Date(eventTimestamp))) {
      return;
    }
    if (error instanceof MegolmSessionNotFoundError) {
      this.captureMegolmSessionNotFoundError(error, roomId);
    } else {
      this.captureErrorWithContext(error, { roomId });
    }
  }

  captureExceptionForEvent(
    error: Error,
    eventTimestamp: string,
    eventId: string,
  ) {
    if (!isRelevantDate(new Date(eventTimestamp))) {
      return;
    }
    if (this.hints.length) {
      const relevantHints = this.hints.filter(hint => hint.eventId === eventId);
      this.tracer.addBreadcrumb({
        category: 'chat.share_key_events',
        level: 'log',
        data: convertHintsToObject(relevantHints)
      });
    }
    this.captureErrorWithContext(error, { eventId });
  }

  addShareKeyHint(data: ShareKeyHint) {
    this.hints.push({
      processedTimestamp: new Date().toISOString(),
      ...data
    });
  }

  private captureMegolmSessionNotFoundError(
    error: MegolmSessionNotFoundError,
    roomId: string
  ) {
    this.dataStore.getAllInboundGroupSessions().pipe(
      first(),
      catchError(() => of(null))
    ).subscribe((allInboundGroupSessions) => {
      if (!allInboundGroupSessions) {
        return;
      }
      const hasKeyForRoom = allInboundGroupSessions.some(inboundGroupSession => {
        return inboundGroupSession.roomId === roomId;
      });
      if (hasKeyForRoom) {
        /**
         * In order to avoid false positive errors here because eg. the device sync is missing
         *  only capture the error if the user has a known session history in the target room
         */
        this.tracer.captureException(mergeError(error, { roomId }, true));
      }
    });
  }

  private captureErrorWithContext(
    error: Error,
    context: Record<string, string>,
  ) {
    this.tracer.captureException(mergeError(error, context, true));
  }

}
