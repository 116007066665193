import { Directive, Input, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { Observable, map } from 'rxjs';
import { upsertActiveFilter } from '../../../core/store/filter/filter.actions';
import { selectAllFilterEntities } from '../../../core/store/filter/filter.selectors';
import { AllFilterIds, Filter, FilterEntityType, FilterType, FilterValueType } from '../../../core/store/filter/models/filter';
import { SearchConfig } from '../../../core/store/header/models/HeaderData';
import { RootState } from '../../../core/store/root/public-api';

@Directive({
  selector: '[filter-element]'
})
export class FilterElementDirective implements OnInit {

  hasFilterConfig = false;
  @Input('filter-element') set config(config: SearchConfig) {
    this.hasFilterConfig = !!config;
  }
  @Input() formControlName: AllFilterIds;
  @Input() filterId: AllFilterIds;
  @Input() filterEntityType: FilterEntityType;

  activeFilter$: Observable<Filter>;

  get storeFilterId() {
    return this.formControlName || this.filterId;
  }

  constructor(
    private store: Store<RootState>
  ) { }

  ngOnInit() {
    if (this.hasFilterConfig && (!this.storeFilterId || !this.filterEntityType)) {
      throw new Error('Missing configs for FilterElementDirective!');
    }
    this.activeFilter$ = this.store.pipe(
      select(selectAllFilterEntities),
      map((entities) => {
        const byEntityType = entities[this.filterEntityType];
        const byFilterId = byEntityType?.activeFilters[this.storeFilterId];
        return byFilterId;
      }),
      smartDistinctUntilChanged()
    );
  }

  updateFilter(filterValue: FilterValueType, filterType: FilterType) {
    const filterUpdate = this.buildFilterValue(filterValue, filterType);
    this.store.dispatch(upsertActiveFilter({ filterEntityType: this.filterEntityType, filter: filterUpdate }));
  }

  private buildFilterValue(filterValue: FilterValueType, filterType: FilterType) {
    switch (filterType) {
      case FilterType.SEARCH:
        return {
          filterType,
          filterValue: filterValue as string ?? null,
          filterId: this.storeFilterId,
          propertyName: 'search',
        };
      default:
        return {
          filterType,
          filterValue: filterValue ?? null,
          filterId: this.storeFilterId,
        } as Filter;
    }
  }

}
