import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OccurenceEntity } from '../../../../../../../core/store/events/event.state';
import { EventListDataSource } from '../../../../providers/event-list-datasource';

@Component({
  selector: 'wen-discovery-event-list-item-after',
  templateUrl: './discovery-event-list-item-after.component.html',
  styleUrls: ['./discovery-event-list-item-after.component.scss']
})
export class DiscoveryEventListItemAfterComponent {

  hasEventSubscription$: Observable<boolean>;

  @Input() set event(ev: OccurenceEntity) {
    if (ev.eventId) {
      this.hasEventSubscription$ = this.eventListDataSource.selectSubscriptionFor(ev.eventId).pipe(
        map(sub => sub.subscribedByUser)
      );
    }
  }

  constructor(private eventListDataSource: EventListDataSource) { }

}
