<wen-message-discard-edit-button></wen-message-discard-edit-button>
<wen-link-embed-preview [previewContent]="linkPreviewContent$ | async" [disabled]="hasMediaEmbed$ | async" (previewChanged)="updateDraftMessageLinkEmbed($event)"></wen-link-embed-preview>
<wen-media-embed-preview></wen-media-embed-preview>
<wen-message-send-field
  [inputPlaceholder]="'SEND_MESSAGE_INPUT_PLACEHOLDER' | translate"
  [contextId]="channelId$ | async"
  (messageSent)="onMessageSent()">
  <wen-message-type-selector
    wen-message-type-selector
    [menuId]="messageSelectorId"
    [disabled]="hasMediaEmbed$ | async">
  </wen-message-type-selector>
  <wen-message-scheduler [canClearDate]="false" wen-message-bottom-action [contextId]="channelId$ | async"></wen-message-scheduler>
</wen-message-send-field>