import { NgxMatDateAdapter, NgxMatDateFormats, NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CoreComponentsModule, WenIconModule } from '@portal/wen-components';
import { dateFormats } from '../../../../../../core/common/date/date-util';
import { LuxonFormattingDateAdapter } from './luxon-formatting-date-adapter';
import { TimePickerComponent } from './time-picker.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: dateFormats.FULL_DATE_AND_TIME
  },
  display: {
    dateInput: dateFormats.FULL_DATE_AND_TIME,
    monthYearLabel: dateFormats.YEAR_WITH_MONTH_NAME,
    dateA11yLabel: dateFormats.FULL_DATE_AND_TIME,
    monthYearA11yLabel: dateFormats.YEAR_WITH_MONTH_NAME
  }
};

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    CoreComponentsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatDatetimePickerModule,
    MatDatepickerModule,
    WenIconModule
  ],
  declarations: [
    TimePickerComponent
  ],
  providers: [
    { provide: NgxMatDateAdapter, useClass: LuxonFormattingDateAdapter },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
  ],
  exports: [
    TimePickerComponent,
  ]
})
export class TimePickerModule { }
