import { NgModule } from '@angular/core';
import { ActionMenuModule, ACTION_MENU_ITEMS, ContextMenuComponent, ContextMenuTriggerDirective } from '@portal/wen-components';
import { UserRoleModule } from '../../../shared/components/user-role/user-role.module';
import { SharedModule } from '../../../shared/shared.module';
import { CHANNEL_ANONYMOUS_SUBSCRIBERS_ACTION_MENU, CHANNEL_SUBSCRIBERS_ACTION_MENU } from './action-menu/constants';
import { ChannelSubscriberListComponent } from './channel-subscriber-list.component';


@NgModule({
  imports: [SharedModule, ActionMenuModule, UserRoleModule, ContextMenuTriggerDirective, ContextMenuComponent],
  exports: [ChannelSubscriberListComponent],
  declarations: [ChannelSubscriberListComponent],
  providers: [
    { provide: ACTION_MENU_ITEMS, useValue: CHANNEL_SUBSCRIBERS_ACTION_MENU, multi: true },
    { provide: ACTION_MENU_ITEMS, useValue: CHANNEL_ANONYMOUS_SUBSCRIBERS_ACTION_MENU, multi: true },
  ],
})
export class ChannelSubscriberListModule { }
