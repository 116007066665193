import { NgModule } from '@angular/core';
import { PollCreationModule } from './creation/poll-creation.module';
import { PollRepresentationModule } from './representation/poll-representation.module';

@NgModule({
  imports: [
    PollRepresentationModule,
    PollCreationModule
  ],
  exports: [PollRepresentationModule],
})
export class PollingModule { }
