import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GeoPosition } from '@portal/react-native-api';
import { GeoReverseResponse, SocketIoService } from '@portal/wen-backend-api';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { WenSnackbarOpener } from '@portal/wen-components';
import { FeatureEnablementService } from '../configuration/feature-enablement';

export interface DeviceLocationData {
  reverseGeoData: GeoReverseResponse;
  devicePosition: GeoPosition;
}

@Injectable()
export abstract class LocationService {

  constructor(
    private socketIoService: SocketIoService,
    private snackBarOpener: WenSnackbarOpener,
    private translateService: TranslateService,
    private featureEnablementService: FeatureEnablementService,
  ) { }

  abstract getLocation(): Observable<GeoPosition>;

  abstract getPermission(): Observable<string>;

  requestLocation(): Observable<DeviceLocationData> {
    return this.getLocation().pipe(
      filter(devicePosition => Boolean(devicePosition)),
      switchMap(devicePosition => {
        const coordinates: [number, number] = [devicePosition.coords.longitude, devicePosition.coords.latitude];
        return this.socketIoService.geo.reverse.acknowledgement$({
          coordinates
        }).pipe(
          map((response) => {
            const data: DeviceLocationData = {
              reverseGeoData: response,
              devicePosition
            };
            return data;
          })
        );
      }),
      catchError(() => {
        this.snackBarOpener.openNotificationSnackbar({
          notificationIcon: 'error',
          notificationText: this.translateService.instant('CHANNEL_GEO_LOCATION_GENERIC_ERROR')
        });
        return of(null);
      })
    );
  }

  checkLocationPermission(): Observable<boolean> {
    return this.getPermission().pipe(
      map(permissionData => {
        return Boolean(permissionData) && permissionData === 'granted';
      }),
      catchError(() => {
        this.snackBarOpener.openNotificationSnackbar({
          notificationIcon: 'error',
          notificationText: this.translateService.instant('CHANNEL_GEO_LOCATION_GENERIC_ERROR')
        });
        return of(null);
      })
    );
  }
}
