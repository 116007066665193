import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RootState } from '../root/public-api';
import { headerFeatureKey } from './constants';
import { HeaderState } from './header.state';

export interface WithFeatureState extends RootState {
  [headerFeatureKey]: HeaderState;
}

export const selectHeaderState = createFeatureSelector<HeaderState>(
  headerFeatureKey
);

export const selectCurrentHeaderData = createSelector(
  selectHeaderState,
  (state) => state.headerData
);

export const selectSearchbarActive = createSelector(
  selectHeaderState,
  (state) => state.searchbarActive
);
