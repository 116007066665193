<wen-dialog-layout wen-adaptive-background-content [headerGravity]="'top'">
  <wen-dialog-layout-header>
    <button headerLeft portal-button inlined (click)="onCancel()" tabindex="-1">
      <span portal-body-2>{{ "CANCEL_BUTTON_LABEL" | translate }}</span>
    </button>
    <div headerTitle portal-title>
      {{ "GEO_LOCATION_FILTER_SELECTOR_DIALOG_TITLE" | translate }}
    </div>
    <button headerRight portal-button inlined tabindex="-1">
      <span portal-body-2>{{ "CANCEL_BUTTON_LABEL" | translate }}</span>
    </button>
  </wen-dialog-layout-header>
  <div class="wen-geo-filter-location-selector-content" dialogLayoutContent>
    <form class="wen-form wen-geo-filter-location-selector-form" [formGroup]="locationSelectorForm">
      <wen-location-selector [formControl]="embedControl" [wen-test-id]="'geo-filter-location-selector'" [customEndpoint]="customEndpoint"></wen-location-selector>
    </form>
  </div>
</wen-dialog-layout>