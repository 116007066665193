import { ConnectionErrorHandler } from '../../../core/services/error-handlers/connection-error-handler';
import { SocketErrorHandler } from '../../../core/services/error-handlers/socket-error-handler';

export function socketErrorHandlerInitializer(socketErrorHandler: SocketErrorHandler) {
  socketErrorHandler.initialize();
  return () => Promise.resolve();
}

export function connectionErrorHandlerInitializer(connectionErrorHandler: ConnectionErrorHandler) {
  connectionErrorHandler.initialize();
  return () => Promise.resolve();
}
