/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GeoAutocompleteFeature, GeoFeatureProperties } from '@portal/wen-backend-api';
import { isNullOrUndefined } from '../../core/common/operators/null-check-util';

export type LocationFormat = 'multiline' | 'singleline' | 'singlelineWithPlaceholder';
export type LocationFormatMultiline = { line1: string; line2: string };
export type LocationFormatResult = string | LocationFormatMultiline;

// Prevent undefined strings in template literals
const f = (input: string) => isNullOrUndefined(input) ? '' : input;

const formatLocation = (location: GeoAutocompleteFeature): LocationFormatMultiline => {
  const { properties } = location;
  // Make sure not to include property if it has defined null value from the api
  const normalizedProps = Object.keys(properties).reduce((acc, key) => {
    const val = properties[key];
    return {
      ...acc,
      [key]: isNullOrUndefined(val) ? '' : val
    };
  }, {} as GeoFeatureProperties);
  const {
    poi, street, houseNo, zip, city, country, countryLongName, displayValue, district, state, county
  } = normalizedProps;
  const countryName = countryLongName || country;
  // The area parts are sorted by size from city to state and we choose the smallest (which is the most accurate).
  const areaName = city || county || district || state;

  const line1Arr: string[] = [];
  const line2Arr: string[] = [];
  if (poi) {
    line1Arr.push(poi);
    line2Arr.push(`${f(street)} ${f(houseNo)}`);
    line2Arr.push(`${f(zip)} ${f(city)}`);
    line2Arr.push(countryName);
  } else if (street) {
    line1Arr.push(`${f(street)} ${f(houseNo)}`);
    line2Arr.push(`${f(zip)} ${f(city)}`);
    line2Arr.push(countryName);
  } else if (displayValue) {
    line1Arr.push(`${f(displayValue)}`);
  } else {
    line1Arr.push(`${f(areaName)}`);
    line1Arr.push(`${f(countryName)}`);
  }
  const mergeArray = (arr: string[]) => {
    return arr
      .map(d => d.trim())
      .filter(d => Boolean(d))
      .join(', ');
  };
  const line1 = mergeArray(line1Arr);
  const line2 = mergeArray(line2Arr);
  return { line1, line2 };
};

@Injectable()
export class LocationFormatter {

  constructor(
    private translateService: TranslateService
  ) { }

  format(location: GeoAutocompleteFeature, format: LocationFormat = 'singleline'): LocationFormatResult {
    const properties = location?.properties;
    const isSingleLIne = format === 'singleline' || format === 'singlelineWithPlaceholder';
    const hasProperties = Object.keys(properties || {}).filter((key) => {
      return !isNullOrUndefined(properties[key]);
    }).length;
    if (!hasProperties) {
      const coordinatesCopy = [...location?.geometry?.coordinates || []];
      let coords = coordinatesCopy?.reverse().join(', ') || '';
      if (format === 'singlelineWithPlaceholder') {
        coords = `${this.translateService.instant('CHANNEL_GEO_COORDS_ONLY_PLACEHOLDER')} (${coords})`;
      }
      if (isSingleLIne) {
        return coords;
      }
      return {
        line1: coords,
        line2: ''
      };
    }
    const { line1 = '', line2 = '' } = formatLocation(location);
    if (isSingleLIne) {
      return [line1, line2]
        .filter(data => Boolean(data))
        .join(', ');
    }
    if (!line1) {
      return {
        line1: line2,
        line2: ''
      };
    }
    return { line1, line2 };
  }

}
