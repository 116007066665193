
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ActionMenuItem, ConfirmationDialogComponent, OverlayManager } from '@portal/wen-components';
import { first, Observable, of } from 'rxjs';
import { setSubscriptionToApp } from '../../../../../core/store/apps/apps.actions';
import { AppEntity } from '../../../../../core/store/apps/apps.state';
import { RootState } from '../../../../../core/store/root/public-api';

@Injectable()
export class AppDeleteAction implements ActionMenuItem {

  label: string;
  icon = 'delete';

  constructor(
    private translateService: TranslateService,
    private overlayManager: OverlayManager,
    private store: Store<RootState>
  ) {
    this.label = this.translateService.instant('REMOVE_APP_LABEL');
  }

  isVisible$: (context?: any) => Observable<boolean> = () => of(true);

  execute(app: AppEntity) {
    this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, {
      header: this.translateService.instant('REMOVE_APP_CONFIRM_DIALOG_TITLE'),
      content: this.translateService.instant('REMOVE_APP_CONFIRM_DIALOG_TEXT'),
      okLabel: this.translateService.instant('REMOVE_APP_LABEL'),
      dismissLabel: this.translateService.instant('CANCEL_BUTTON_LABEL')
    }).afterClosed().pipe(
      first(),
    ).subscribe((result) => {
      if (result?.result === 'ok') {
        this.store.dispatch(setSubscriptionToApp({ id: app.id, setSubscribed: false }));
      }
    });
  }
}
