import { Injectable } from '@angular/core';
import { HomeCoverImageConfig } from '../../../frame/api/tokens';
import { WenBreakpointObserver } from '@portal/wen-components';
import { map } from 'rxjs';
import { FeaturedSectionConfig, WeatherCustomizationConfig } from '@portal/wen-backend-api';

@Injectable()
export class NetworkClientConfigService {

  homeCoverImageConfig: HomeCoverImageConfig;

  weather: WeatherCustomizationConfig;

  featuredSections?: FeaturedSectionConfig[];

  buttonSectionLink?: string;

  homeCoverImage$ = this.wenBreakpointObserver.orientation$.pipe(
    map((orientation) => {
      if (orientation === 'landscape') {
        return this.homeCoverImageConfig.landscape;
      } else {
        return this.homeCoverImageConfig.portrait;
      }
    }));

  setHomeCoverImage(homeCoverImageConfig: HomeCoverImageConfig) {
    this.homeCoverImageConfig = homeCoverImageConfig;
  }

  setWeather(weather: WeatherCustomizationConfig) {
    this.weather = weather;
  }

  setHomeFeaturedSection(featuredSections?: FeaturedSectionConfig[]): void {
    this.featuredSections = featuredSections;
  }

  setHomeButtonSectionLink(buttonSectionLink?: string): void {
    this.buttonSectionLink = buttonSectionLink;
  }

  constructor(
    private readonly wenBreakpointObserver: WenBreakpointObserver
  ) { }
}
