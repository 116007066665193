import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: 'img[wenImgFallback]'
})
export class ImgFallbackDirective implements OnInit {

  constructor(private element: ElementRef) { }

  @Input('wenImgFallback') fallback: string;

  ngOnInit(): void {
    if (!this.fallback) {
      this.fallback = '/assets/wen-widget/image/we-logo.png';
    }
  }

  @HostListener('error')
  displayFallbackImg() {
    if (!this.element.nativeElement.src.endsWith(this.fallback)) {
      this.element.nativeElement.src = this.fallback;
    }
  }

}
