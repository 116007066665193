import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { EmbedDTOType } from '@portal/wen-backend-api';
import { combineLatestWith, filter, first } from 'rxjs';
import { getMediaType } from '../../../shared/components/message-box/components/embeds/embed-media/media-utils';
import { channelViewIdentifier } from '../../../views/channel/tokens';
import { updateDraftChannelMessage } from '../../store/channel/channel.actions';
import { PendingEmbedType } from '../../store/common/models';
import { selectRouteParam } from '../../store/root/root.selectors';
import { MediaPreviewHandler } from './media-preview-handler';
import { MediaValidator } from './media-validator';

@Injectable()
export class ChannelMediaPreviewHandler extends MediaPreviewHandler {
  constructor(
    private store: Store,
    mediaValidator: MediaValidator
  ) {
    super(mediaValidator);
  }

  handleMediaPreview(file: File): void {
    this.mediaValidator.validate(file).pipe(
      first(),
      filter(result => Boolean(result)),
      combineLatestWith(this.store.pipe(
        select(selectRouteParam(channelViewIdentifier)),
        first()
      )),
    ).subscribe(([result, contextId]) => {
      this.store.dispatch(updateDraftChannelMessage({
        message: {
          contextId,
          fileEmbed: {
            id: null,
            type: EmbedDTOType.MEDIA,
            subType: getMediaType(file),
            file: result,
            pendingType: PendingEmbedType.UNSAFE
          }
        }
      }));
    });
  }
}
