import { Injectable } from '@angular/core';
import { Observable, from, shareReplay, switchMap } from 'rxjs';
import { GWDataObject } from '../providers/gw/gw-data-object';
import { OpenDataObject } from '../providers/open/open-data-object';
import { SelectionProvider } from '../providers/selection-provider';
import { SmartDesignApi } from '../smartdesing-api';
import { DistributionMessageDTO, DistributionRecipientDTO, DistributionResultDTO } from './distribution-types';

@Injectable()
export class DistributionDataImporter {

  private readonly PAGE_SIZE = 1000;

  private inviteSelectionProvider: SelectionProvider<OpenDataObject | GWDataObject>;
  private primaryDataObject$: Observable<OpenDataObject | GWDataObject>;
  private distributionChatMessage$: Observable<DistributionMessageDTO>;

  constructor(
    private wenSdApi: SmartDesignApi,
  ) { }

  loadDistributionChatMessage(): Observable<DistributionMessageDTO> {
    this.initialize();
    return this.distributionChatMessage$;
  }

  loadDistributionChatRecipients(page: number): Observable<DistributionRecipientDTO[]> {
    this.initialize();
    return this.primaryDataObject$.pipe(
      switchMap(dataObject => this.wenSdApi.loadDistributionMessageRecipients(dataObject.gguid, page, this.PAGE_SIZE)),
    );
  }

  loadDistributionChatRecipient(wenetworkId: string): Observable<DistributionRecipientDTO> {
    this.initialize();
    return this.primaryDataObject$.pipe(
      switchMap(dataObject => this.wenSdApi.loadDistributionMessageRecipient(dataObject.gguid, wenetworkId)),
    );
  }

  loadDistributionMessageAttachment(attachmentGUID: string): Observable<{ blob: Blob; extension: string }> {
    this.initialize();
    return this.primaryDataObject$.pipe(
      switchMap(dataObject => this.wenSdApi.loadDistributionMessageAttachment(dataObject.gguid, attachmentGUID))
    );
  }

  sendDistributeChatMessageSendResult(result: DistributionResultDTO) {
    this.initialize();
    return this.primaryDataObject$.pipe(
      switchMap(dataObject => this.wenSdApi.sendDistributeChatMessageSendResult(dataObject.gguid, result)),
    );
  }

  private initialize() {
    if (!this.inviteSelectionProvider) {
      this.inviteSelectionProvider = this.wenSdApi.createSelectionProvider();
    }
    if (!this.primaryDataObject$) {
      this.primaryDataObject$ = from(this.inviteSelectionProvider.loadPrimaryDataObject()).pipe(shareReplay(1));
    }
    if (!this.distributionChatMessage$) {
      this.distributionChatMessage$ = this.primaryDataObject$.pipe(
        switchMap(dataObject => this.wenSdApi.loadDistributionMessage(dataObject.gguid)),
        shareReplay(1)
      );
    }
  }

}
