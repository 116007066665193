import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '../../../../../shared/shared.module';
import { UserLanguageSettingComponent } from './user-language-setting.component';

@NgModule({
  declarations: [
    UserLanguageSettingComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule,
    MatInputModule,
    MatFormFieldModule
  ],
  exports: [
    UserLanguageSettingComponent
  ]
})
export class UserLanguageSettingModule { }
