export enum AcknowledgeType {
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
  READ = 'READ',
}

export type ChatMessageAcknowledgeDTO = {
  userId: string;
  roomId: string;
  eventId: string;
  lastAckTimestamp: string;
  type: AcknowledgeType.RECEIVED | AcknowledgeType.READ;
};

export type RoomAcknowledgeDTO = {
  roomId: string;
  minReceivedTs: string;
  minReadTs: string;
};
