import { Component, ContentChild, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { SimpleUserDTO } from '@portal/wen-backend-api';
import { Observable, ReplaySubject, Subject, debounceTime, distinctUntilChanged, map, of, startWith, switchMap, takeUntil, tap } from 'rxjs';
import { UserSearchService } from '../../../views/chat/chat-creation/providers/user-search-service';
import { UserSearchEmptyListDirective } from './user-search-empty-list.directive';
import { UserSearchListItem } from './user-search-list.types';

type UserSearchListItemModel = {
  user: UserSearchListItem;
  disabled?: boolean;
};

@Component({
  selector: 'wen-user-search-list',
  templateUrl: './user-search-list.component.html',
  styleUrls: ['./user-search-list.component.scss']
})
export class UserSearchListComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();
  private selectedUserIds$ = new ReplaySubject<string[]>(1);

  resultList$: Observable<UserSearchListItemModel[]>;
  searchTerm$ = new Subject<string>();
  isEmptyListOverridden = false;

  @ContentChild(UserSearchEmptyListDirective) set er(val: TemplateRef<any>) {
    this.isEmptyListOverridden = !!val;
  }
  @Input() set selectedUserIds(val: string[]) {
    this.selectedUserIds$.next(val);
  }
  @Input() set searchTerm(value: string) {
    this.searchTerm$.next(value);
  }
  @Input() filterUserResults = false;
  @Output() selectedUser = new EventEmitter<UserSearchListItem>();
  @Output() resultListCountChanged = new EventEmitter<number>();

  constructor(
    private userSearchService: UserSearchService
  ) { }

  ngOnInit() {
    this.resultList$ = this.searchTerm$.pipe(
      startWith(''),
      distinctUntilChanged(),
      debounceTime(200),
      switchMap(searchTerm => {
        if (searchTerm) {
          if (this.filterUserResults) {
            return this.userSearchService.searchForUsersWithRoomDuplicateFiltering(searchTerm);
          } else {
            return this.userSearchService.searchForUsers(searchTerm);
          }
        } else {
          return of([]);
        }
      }),
      switchMap(users => {
        return this.selectedUserIds$.pipe(
          map(selectedUserIds => {
            const searchListItemModels = this.setDisabledStateOnSelectedItems(users, selectedUserIds);
            return searchListItemModels?.length ? searchListItemModels : null;
          })
        );
      }),
      tap(users => this.resultListCountChanged.emit(users?.length ?? 0)),
      takeUntil(this.onDestroy$),
    );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onUserSelected(user: UserSearchListItem) {
    this.selectedUser.emit(user);
  }

  private setDisabledStateOnSelectedItems(searchedUsers: SimpleUserDTO[], selectedUserIds: string[]): UserSearchListItemModel[] {
    if (!searchedUsers) {
      return;
    }
    if (!selectedUserIds) {
      return searchedUsers.map(user => ({ user, disabled: false }));
    }

    return searchedUsers.map(user => {
      const isSameUser = selectedUserIds.includes(user.userId);

      return {
        user,
        disabled: isSameUser
      };
    });
  }

}
