import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HeaderModule } from '../../../../../frame/components/header/header.module';
import { SharedModule } from '../../../../../shared/shared.module';
import { ChannelVisibilitySettingsComponent } from './channel-visibility-settings.component';

@NgModule({
  imports: [
    SharedModule,
    HeaderModule,
    ReactiveFormsModule,
    MatFormFieldModule,
  ],
  declarations: [
    ChannelVisibilitySettingsComponent,
  ],
  exports: [
    ChannelVisibilitySettingsComponent,
  ]
})
export class ChannelVisibilitySettingsModule { }
