import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { SourceMediaReference, TargetMediaReferences } from '../../../../data-types/media/media';
import { SocketIoService } from '../../../api-providers/socket-io-service';

@Injectable()
export class EmbedMediaReplicator {

  private socketIoService = inject(SocketIoService);

  /** Delete action should clean-up the media files relating to the deleted message.
   *  The target media file is copied in order to "survive" if a forwarding happens.
   */
  createCopy(from: SourceMediaReference, to: TargetMediaReferences): Observable<{ success: boolean }> {
    return this.socketIoService.media.copy.acknowledgement$({ from, to });
  }
}
