import { WenRoutes } from '../../../frame/routing/types';
import {
  addChatInfoRoute,
  addChatUsersRoute,
  chatCreationRoute,
  chatInfoEditRoute,
  chatGroupDetailRoute, chatListRoute,
  chatViewRoute,
  chatGroupAddMembers,
  addChatAutoReplyEditRoute,
  chatSchedulesRoute
} from './chat.routes';

export const singleOutletChatRoutes: WenRoutes = [
  chatListRoute,
  chatCreationRoute,
  addChatUsersRoute,
  addChatInfoRoute,
  chatInfoEditRoute,
  chatGroupDetailRoute,
  chatGroupAddMembers,
  chatViewRoute,
  addChatAutoReplyEditRoute,
  chatSchedulesRoute,
  {
    path: '**',
    redirectTo: 'list'
  }
];
