import { NgxMatDatepickerBase } from '@angular-material-components/datetime-picker';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgxDatePickerUtils, WenBreakpointObserver } from '@portal/wen-components';
import { Observable, Subject, distinctUntilChanged, filter, first, map, takeUntil } from 'rxjs';
import { DateUtil } from '../../../../../core/common/date/date-util';
import { MessageSendFieldDataSource } from '../../../../../shared/components/message-send-field/providers/message-send-field-data-source';

@Component({
  selector: 'wen-chat-send-scheduled-message',
  templateUrl: './chat-send-scheduled-message.component.html',
  styleUrls: ['./chat-send-scheduled-message.component.scss']
})
export class ChatSendScheduledMessageComponent implements OnInit, AfterViewInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  minDate: Date;
  defaultTime: number[];

  datePickerControl = new FormControl();

  isEdit$: Observable<boolean>;
  isSaveDisabled$: Observable<boolean>;
  isMobileView = !this.breakPointObserver.isDesktopStyleDevice;

  get hasDateAdded() {
    return Boolean(this.datePickerControl.value);
  }

  constructor(
    private readonly dataSource: MessageSendFieldDataSource,
    private readonly breakPointObserver: WenBreakpointObserver,
  ) { }

  ngOnInit(): void {
    this.isEdit$ = this.dataSource.draftMessage$.pipe(
      map((draftMessage) => Boolean(draftMessage?.id))
    );
    this.updateMinDateAndTime();
    this.dataSource.draftMessage$.pipe(
      filter((draftMessage) => Boolean(draftMessage?.scheduled && draftMessage?.timestamp)),
      map(draftMessage => draftMessage.timestamp),
      distinctUntilChanged()
    ).subscribe(timestamp => {
      const timestampDate = DateUtil.fromIsoString(timestamp).toJSDate();
      this.datePickerControl.setValue(timestampDate);
    });
  }

  ngAfterViewInit(): void {
    this.datePickerControl.valueChanges.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(newDateValue => {
      if (!newDateValue) {
        this.scheduleDateChange(null);
        return;
      }
      const clonedDate = new Date(newDateValue.getTime());
      clonedDate.setSeconds(0);
      clonedDate.setMilliseconds(0);
      this.scheduleDateChange(clonedDate);
    });
  }

  onPickerOpen(datePickerComponent: NgxMatDatepickerBase<any, any, any>) {
    const pickerUtils = new NgxDatePickerUtils(datePickerComponent);
    if (!this.datePickerControl.value) {
      // TODO: remove workaround once [defaultTime] binding is fixed for detapicker lib
      pickerUtils.forceSeletion(this.createMinDate());
    }
    this.isSaveDisabled$ = pickerUtils.selectedDate$.pipe(
      map((selectedDate) => {
        const currentDate = new Date();
        currentDate.setSeconds(0);
        currentDate.setMilliseconds(0);
        const comp = DateUtil.compareNullSafeDates(currentDate, selectedDate);
        return comp !== 1;
      })
    );
  }

  handleDateCleared() {
    this.datePickerControl.setValue(null);
  }

  private scheduleDateChange(date: Date | null) {
    this.isEdit$.pipe(
      first()
    ).subscribe(isEdit => {
      let timestamp = null;
      if (date) {
        date.setSeconds(0);
        date.setMilliseconds(0);
        timestamp = date.toISOString();
      }
      if (isEdit) {
        this.dataSource.updateDraftMessage({ timestamp });
      } else {
        this.dataSource.updateDraftMessage({ timestamp, scheduled: Boolean(timestamp) });
      }
    });
  }

  updateMinDateAndTime() {
    this.minDate = this.createMinDate();
    this.defaultTime = [this.minDate.getHours(), this.minDate.getMinutes(), this.minDate.getSeconds()];
  }

  private createMinDate() {
    const minDate = new Date();
    minDate.setSeconds(0);
    minDate.setMilliseconds(0);
    minDate.setMinutes(minDate.getMinutes() + 1);
    return minDate;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
