import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { Subject, scan, startWith, takeUntil, merge, ReplaySubject, filter, map, debounce, timer } from 'rxjs';

const DEFAULT_HIDE_TIME = 3000;

@Injectable()
export class OverlayVisibilityService implements OnDestroy {

  private hammerManager = new Hammer.Manager(this.elRef.nativeElement, { domEvents: true });
  private lightboxTap = new Subject<void>();
  private hideTimer: number;
  private onDestroy$ = new Subject<void>();

  private isOverlayVisibleOnStart = true;

  private isVisible = new ReplaySubject<boolean>(1);
  isVisible$ = this.isVisible.asObservable();

  childEvent$ = new Subject<number>();

  constructor(private elRef: ElementRef<HTMLElement>) { }

  init(): void {
    this.setUpTapEventListener();

    this.childEvent$.pipe(
      map(hideTimer => {
        const safeHideTimer = (hideTimer ?? DEFAULT_HIDE_TIME);
        return safeHideTimer < 0 ? DEFAULT_HIDE_TIME : safeHideTimer;
      }),
      takeUntil(this.onDestroy$)
    ).subscribe(value => this.hideTimer = value);

    const keepUp$ = this.childEvent$.pipe(
      map(() => true)
    );

    const autoHide$ = merge(
      this.isVisible,
      keepUp$
    ).pipe(
      debounce(() => timer(this.hideTimer ?? DEFAULT_HIDE_TIME)),
      filter(value => !!value)
    );

    merge(
      autoHide$,
      this.lightboxTap,
    ).pipe(
      scan((isOverlayVisible) => !isOverlayVisible, this.isOverlayVisibleOnStart),
      startWith(this.isOverlayVisibleOnStart),
      takeUntil(this.onDestroy$)
    ).subscribe(value => this.isVisible.next(value));
  }

  private setUpTapEventListener() {
    const doubleTapRecognizer = new Hammer.Tap({ event: 'doubletap', taps: 2 });
    const tapRecognizer = new Hammer.Tap({ event: 'tap' });
    this.hammerManager.add([doubleTapRecognizer, tapRecognizer]);
    this.hammerManager.get('tap').requireFailure('doubletap');
    this.hammerManager.on('tap doubletap', (event) => {
      if (event.type === 'tap') {
        this.lightboxTap.next();
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
