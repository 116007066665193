/* eslint-disable no-underscore-dangle */
import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, forwardRef, HostBinding, Input, OnDestroy, Output, QueryList, ViewChildren } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SelectionListItemComponent } from './selection-list-item/selection-list-item.component';
import { SelectionOption, SelectionOptionBorder, SelectionOptionGroup, SelectionOptionPosition } from './types';

type SelectionItemType<T> = (SelectionOption<T> | SelectionOptionGroup)[];

@Component({
  selector: 'wen-selection-list',
  templateUrl: './wen-selection-list.component.html',
  styleUrls: ['./wen-selection-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WenSelectionListComponent),
      multi: true,
    }
  ]
})
export class WenSelectionListComponent<T> implements AfterViewInit, OnDestroy, ControlValueAccessor {
  private onDestroy$ = new Subject<void>();
  private _onChange: (value: any) => void = (_: any) => { };
  private _onTouched: () => void = () => { };

  private isViewInitialized = false;
  private scheduledViewUpdate: () => void;

  visibleSelectionItems: SelectionItemType<T>;
  isGroupedItems: boolean;
  isDisabled = false;

  @ViewChildren(SelectionListItemComponent) options: QueryList<SelectionListItemComponent>;

  @Input() set selectionItems(selectionItems: SelectionItemType<T>) {
    this.isGroupedItems = selectionItems?.every(item => {
      const anyItem: any = item;
      return typeof anyItem?.label === 'string' && !!anyItem?.items;
    });
    this.visibleSelectionItems = selectionItems;
  }
  @Input() selectionModel: SelectionModel<SelectionOption<T>>;
  @Input() selectionOptionPosition: SelectionOptionPosition = 'prefix';
  @Input() selectionOptionBorderType: SelectionOptionBorder = 'normal';
  @Input() allowEmptyList = true;
  @Input() @HostBinding('class.wen-selection-list-with-image') withImage = false;

  @Output() selectionChanged = new EventEmitter<string[]>();

  ngAfterViewInit() {
    this.isViewInitialized = true;
    if (this.scheduledViewUpdate) {
      this.scheduledViewUpdate();
      this.scheduledViewUpdate = null;
    }
    if (this.options.length) {
      this.selectionModel.changed.pipe(
        takeUntil(this.onDestroy$)
      ).subscribe((change: SelectionChange<SelectionOption<T>>) => {
        this.options.forEach(el => el.refresh());
        const selectedOptionIds = this.options
          .filter(option => option.selected)
          .map((option) => option.selectionOption.id);
        this.selectionChanged.emit(selectedOptionIds);
        this._onChange(this.options.filter(option => option.selected).map((option) => option.selectionOption));
        this._onTouched();
      });
    }
  }

  writeValue(value: SelectionOption<T>[]): void {
    if (!this.isViewInitialized) {
      this.scheduledViewUpdate = () => {
        this.updateUiState(value);
      };
    }
    if (!this.options || !this.selectionModel) {
      return;
    }
    this.updateUiState(value);
  }

  private updateUiState(value: SelectionOption<T>[]) {
    if (Array.isArray(value) && value.length && !this.selectionModel.selected.length) {
      value.forEach(selectedValue => {
        const optionById = this.options.find(option => option.selectionOption.id === selectedValue.id);
        if (optionById) {
          optionById.setSelection(true);
        }
      });
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  registerOnChange(fn: (value: any) => void): void { this._onChange = fn; }
  registerOnTouched(fn: () => void): void { this._onTouched = fn; }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
