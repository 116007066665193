import { Injectable } from '@angular/core';
import { VgApiService, VgStates } from '@videogular/ngx-videogular/core';

@Injectable()
export class VideoHideDelayService {
  playingHideDelay = 2000;
  pausedHideDelay = -1;

  constructor(private vgApiService: VgApiService) { }

  getHideDelay() {
    const state = this.vgApiService.state;
    switch (state) {
      case VgStates.VG_PLAYING:
        return this.playingHideDelay;
      case VgStates.VG_PAUSED:
        return this.pausedHideDelay;
      case VgStates.VG_ENDED:
        return this.pausedHideDelay;
    }
  }
}
