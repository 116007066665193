import { createFeature, createReducer, on } from '@ngrx/store';
import { pinFeatureKey } from './constants';
import { addPinItem, removePinnedItem, updatePinEntities } from './pin.actions';
import { pinEntityAdapter, PinState } from './pin.state';

export const pinInitialState: PinState = {
  pinEntities: pinEntityAdapter.getInitialState()
};

export const pinFeature = createFeature({
  name: pinFeatureKey,
  reducer: createReducer(
    pinInitialState,
    on(updatePinEntities, (state, { entities }) => {
      const newEntities = pinEntityAdapter.upsertMany(entities, state.pinEntities);
      return {
        state,
        pinEntities: newEntities
      };
    }),
    on(removePinnedItem, (state, { id }) => {
      const updatedEntities = pinEntityAdapter.removeOne(id, state.pinEntities);
      return {
        ...state,
        pinEntities: updatedEntities
      };
    }),
    on(addPinItem, (state, { pinEntity }) => {
      const updatedEntities = pinEntityAdapter.upsertOne(pinEntity, state.pinEntities);
      return {
        ...state,
        pinEntities: updatedEntities
      };
    })
  )
});
