import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ChannelUpdateResponse, SocketIoService } from '@portal/wen-backend-api';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { filter, first, map, switchMap, switchMapTo, tap } from 'rxjs/operators';
import { selectCurrentUserData } from '../../auth/auth.selectors';
import { deleteComments, fetchCommentsForParentElements } from '../../comments/comments.actions';
import { deleteReactions, fetchReactionsForMessages } from '../../reactions/reaction.actions';
import { RootState } from '../../root/public-api';
import { acknowledgeDeletedCurrentChannel, reloadDiscoverChannelList, subscribeToChannelUpdates, updateChannel, updateChannelDetail } from '../channel.actions';
import { selectChannelMessages, selectCurrentChannel, selectDiscoverChannelIds } from '../channel.selectors';
import { ChannelEntity } from '../channel.state';

export const createChannelUpdateEffect = (
  actions$: Actions,
  channelUpdates$: Observable<ChannelEntity>,
  channelDetails$: Observable<ChannelEntity>,
) => {
  return createEffect(() => actions$.pipe(
    ofType(subscribeToChannelUpdates),
    first(),
    switchMap(() => {
      return merge(
        channelUpdates$.pipe(map((channel) => updateChannel({ channel }))),
        channelDetails$.pipe(map((channel) => updateChannelDetail({ channel })))
      );
    })
  ));
};

export const createReactionCleanerEffect = (
  actions$: Actions,
  channelUpdates$: Observable<ChannelUpdateResponse>,
  store: Store<RootState>
) => {
  return createEffect(() => actions$.pipe(
    ofType(subscribeToChannelUpdates),
    first(),
    switchMapTo(channelUpdates$),
    switchMap((channel) => store.pipe(
      select(selectChannelMessages),
      first(),
      map((channelMessagesFn) => channelMessagesFn(channel.id)),
      map((messages) => {
        if (channel.emoji) {
          const messageIds = messages.map((message) => message.id);
          return fetchReactionsForMessages({ messageIds, parentIds: messageIds });
        } else {
          return deleteReactions({ referenceIds: messages.map(message => message.id) });
        }
      })))
  ));
};

export const createCommentCleanerEffect = (
  actions$: Actions,
  channelUpdates$: Observable<ChannelUpdateResponse>,
  store: Store<RootState>
) => {
  return createEffect(() => actions$.pipe(
    ofType(subscribeToChannelUpdates),
    first(),
    switchMapTo(channelUpdates$),
    switchMap((channel) => store.pipe(
      select(selectChannelMessages),
      first(),
      map((channelMessagesFn) => channelMessagesFn(channel.id)),
      map((messages) => {
        if (channel.comment) {
          return fetchCommentsForParentElements({ parentIds: messages.map(message => message.id) });
        } else {
          return deleteComments({ messageIds: messages.map(message => message.id) });
        }
      })))
  ));
};

export const createChannelDeleteAcknowledgeEffect = (
  actions$: Actions,
  store: Store<RootState>,
  socketIoService: Pick<SocketIoService, 'channel'>
) => {
  return createEffect(() => actions$.pipe(
    ofType(acknowledgeDeletedCurrentChannel),
    switchMap(() => combineLatest([
      store.pipe(select(selectCurrentChannel), first()),
      store.pipe(select(selectCurrentUserData), first())
    ])),
    tap(([{ id }, { userId }]) => socketIoService.channel.deleteAcknowledge.emit({ userId, channelId: id })),
    switchMap(([{ id }]) => {
      return combineLatest([
        of(id),
        store.pipe(select(selectDiscoverChannelIds), first())
      ]);
    }),
    filter(([id, discoverChannelIds]) => discoverChannelIds.includes(id)),
    map(() => {
      return reloadDiscoverChannelList();
    })
  ));
};
