import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ResizeHandlerProvider } from '@portal/wen-components';
import { Observable, Subject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { AppEntity } from '../../../../../core/store/apps/apps.state';
import { DeletedAppHelper } from '../../../app-detail/components/app-edit/providers/deleted-app-helper';
import { AppListLoadResult } from '../../models/AppLoadResult';
import { AppListHeaderDataUpdater } from '../../providers/app-list-header-data-updater';
import { UserAppListDataSource } from './user-app-list-data-source';

@Component({
  selector: 'wen-user-app-list',
  templateUrl: './user-app-list.component.html',
  styleUrls: ['./user-app-list.component.scss'],
  providers: [
    UserAppListDataSource,
    ResizeHandlerProvider,
    AppListHeaderDataUpdater,
  ]
})
export class UserAppListComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  @HostBinding('class.wen-user-app-list') className = true;

  loadResult$: Observable<AppListLoadResult>;

  constructor(
    private navigationHelper: WenNavigationHelper,
    private appListDataSource: UserAppListDataSource,
    private appListHeaderDataUpdater: AppListHeaderDataUpdater,
    private deletedAppHelper: DeletedAppHelper,
  ) { }

  ngOnInit(): void {
    this.appListHeaderDataUpdater.initialize();
    this.loadResult$ = this.appListDataSource.loadResult$;
  }

  onAppClick(entity: AppEntity) {
    if (!entity) {
      return;
    }
    this.deletedAppHelper.isDeleted(entity).pipe(
      first(),
      filter(deleted => !deleted)
    ).subscribe(() => this.navigationHelper.navigateToApp(entity));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
