import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, distinctUntilChanged, filter, map, Observable, shareReplay, Subject, switchMap, takeUntil } from 'rxjs';
import { prepareChannelMessage, removeDraftChannelMessage, updateDraftChannelMessage } from '../../../../core/store/channel/channel.actions';
import { selectDraftChannelMessageById } from '../../../../core/store/channel/channel.selectors';
import { DraftMessageEntity } from '../../../../core/store/common/models';
import { RootState } from '../../../../core/store/root/public-api';
import { selectRouteParam } from '../../../../core/store/root/root.selectors';
import { MessageSendFieldDataSource } from '../../../../shared/components/message-send-field/providers/message-send-field-data-source';
import { channelViewIdentifier } from '../../tokens';

@Injectable()
export class ChannelMessageSendFieldDataSource extends MessageSendFieldDataSource implements OnDestroy {

  private channelId$ = new BehaviorSubject<string>(null);
  private onDestroy$ = new Subject<void>();

  draftMessage$: Observable<DraftMessageEntity> = this.channelId$.pipe(
    distinctUntilChanged(),
    switchMap((channelId) => this.store.pipe(
      filter(() => Boolean(channelId)),
      select(selectDraftChannelMessageById),
      map(projectionFn => projectionFn(channelId)),
    )),
    shareReplay(1),
    takeUntil(this.onDestroy$),
  );

  contextId$ = this.store.pipe(
    select(selectRouteParam(channelViewIdentifier)),
    distinctUntilChanged(),
    shareReplay(1)
  );

  constructor(
    private store: Store<RootState>
  ) {
    super();
  }

  initialize(channelId: string) {
    this.channelId$.next(channelId);
  }

  updateDraftMessage(message: Omit<DraftMessageEntity, 'contextId'>) {
    this.store.dispatch(updateDraftChannelMessage({ message: { ...message, contextId: this.channelId$.getValue() } }));
  }

  discardDraftMessage() {
    this.store.dispatch(removeDraftChannelMessage({ channelId: this.channelId$.getValue() }));
  }

  sendMessage() {
    this.store.dispatch(prepareChannelMessage({ channelId: this.channelId$.getValue() }));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
