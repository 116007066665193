import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'wen-dialog-layout',
  templateUrl: './dialog-layout.component.html',
  styleUrls: ['./dialog-layout.component.scss']
})
export class DialogLayoutComponent {

  @HostBinding('class.wen-dialog-layout') className = true;
  @Input() headerGravity: 'top' | 'bottom' = 'bottom';

}
