import { Component, Input } from '@angular/core';

@Component({
  selector: 'wen-we-feed-empty-placeholder',
  templateUrl: './we-feed-empty-placeholder.component.html',
  styleUrls: ['./we-feed-empty-placeholder.component.scss']
})
export class WeFeedEmptyPlaceholderComponent {
  @Input() text: string;
  @Input() imageUrl: string;
}
