import { Injectable } from '@angular/core';
import { BackNavigationBehavior, BackNavigationBehaviorResult } from './back-navigation-behavior';

@Injectable()
export class SuppressBackNavigation implements BackNavigationBehavior {

  execute(): BackNavigationBehaviorResult {
    return {
      canHandle: true
    };
  }

}
