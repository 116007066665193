import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Contactability, UserProfileResponse, UserProfileSettings } from '@portal/wen-backend-api';
import { SelectionOption } from '@portal/wen-components';
import { Observable, ReplaySubject, Subject, first, map, merge, shareReplay, takeUntil, distinctUntilChanged } from 'rxjs';
import { FeatureEnablementService } from '../../../../../core/services/configuration/feature-enablement';
import { FormValueConverter, NoopFormValueConverter } from '../../../../../shared/form-store/form-store.providers';
import { FormStoreService } from '../../../../../shared/form-store/form-store.service';
import { UserProfileService } from '../../user-profile.service';
import { UserVisibilityService } from './providers/user-visibility-settings';

@Component({
  selector: 'wen-user-visibility',
  templateUrl: './user-visibility.component.html',
  styleUrls: ['./user-visibility.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    UserVisibilityService,
    { provide: FormValueConverter, useClass: NoopFormValueConverter }
  ]
})
export class UserVisibilityComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  private initialSelection$ = new ReplaySubject<Contactability>(1);

  @HostBinding('class.custom-form-group') className = true;

  currentUser$: Observable<UserProfileResponse> = this.userProfileService.currentUser$;
  findable$: Observable<boolean>;

  userVisibilityForm = new FormGroup({
    searchableByPhone: new FormControl(false),
    searchableByName: new FormControl(false),
    contactable: new FormControl(''),
    enableChatViaProfile: new FormControl(false),
    findable: new FormControl(false)
  });
  initialSelection: SelectionModel<SelectionOption<Contactability>>;
  visibilityItems: SelectionOption<Contactability>[];
  chatEnabled: boolean;

  constructor(
    private visibilityService: UserVisibilityService,
    private formStoreService: FormStoreService,
    private userProfileService: UserProfileService,
    featureEnablementService: FeatureEnablementService,
  ) {
    this.chatEnabled = featureEnablementService.isChatEnabled();
  }

  ngOnInit() {
    this.currentUser$.pipe(
      first()
    ).subscribe((user) => {
      const visibilitySettings = this.visibilityService.getVisibilitySettings(user);
      this.formStoreService.initializeForm(visibilitySettings);
      this.setupVisibility(visibilitySettings);
    });

    this.userVisibilityForm.controls.findable.valueChanges.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((value) => {
      this.userVisibilityForm.controls.searchableByName.setValue(value);
      this.userVisibilityForm.controls.searchableByPhone.setValue(value);
      this.userVisibilityForm.controls.searchableByName.markAsDirty();
      this.userVisibilityForm.controls.searchableByPhone.markAsDirty();
    });

    const userSettings$ = this.currentUser$.pipe(
      map(user => user.searchableByName || user.searchableByPhone),
      distinctUntilChanged(),
      shareReplay(1)
    );
    const formValueChanged$ = this.userVisibilityForm.valueChanges.pipe(
      map(settings => (settings.searchableByName || settings.searchableByPhone) && settings.findable),
      distinctUntilChanged(),
      shareReplay(1)
    );

    this.findable$ = merge(
      userSettings$,
      formValueChanged$,
    ).pipe(
      map(value => value),
      distinctUntilChanged(),
      shareReplay(1)
    );

    this.findable$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(value => this.userVisibilityForm.controls.findable.setValue(value));
  }

  onSelectionChange(changedValue: Contactability) {
    this.userVisibilityForm.controls.contactable.markAsDirty();
    this.userVisibilityForm.controls.contactable.setValue(changedValue);
  }

  private setupVisibility(visibilitySettings: UserProfileSettings) {
    this.visibilityItems = this.visibilityService.getVisibilitySettingItems();
    this.setupInitialSelection(visibilitySettings);
  }

  private setupInitialSelection(visibilitySettings: UserProfileSettings) {
    const initialSelection = visibilitySettings.contactable;
    this.initialSelection$.next(initialSelection);
    this.initialSelection = new SelectionModel(
      false,
      this.visibilityItems.filter(item => item.id === initialSelection)
    );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
