import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map, switchMapTo, takeUntil } from 'rxjs/operators';
import { FeatureEnablementService } from '../../../core/services/configuration/feature-enablement';
import { EMAIL_VERIFICATION_CODE_QUERY_PARAM } from '../../../core/services/navigation/query-params';
import { RootState } from '../../../core/store/root/public-api';
import { selectUserProfile } from '../../../core/store/user/user.selectors';
import { EmailVerificationService } from './email-verification.service';

@Injectable()
export class EmailVerificationParamWatcher implements OnDestroy {

  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store<RootState>,
    private readonly activatedRoute: ActivatedRoute,
    private featureEnablementService: FeatureEnablementService,
    private readonly emailVerificationService: EmailVerificationService
  ) { }

  initialize() {
    if (!this.featureEnablementService.featureFlagMethods.isEnableEmailVerification()) {
      return;
    }
    this.store.pipe(
      select(selectUserProfile),
      filter((profile) => {
        return profile && !profile.emailVerified;
      }),
      switchMapTo(this.activatedRoute.queryParams.pipe(
        map(params => params[EMAIL_VERIFICATION_CODE_QUERY_PARAM]),
        filter(verificationCode => verificationCode)
      )),
      takeUntil(this.onDestroy$)
    ).subscribe((verificationCode) => {
      this.emailVerificationService.verifyCode(verificationCode);
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
