import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { ChannelConfiguration, ChannelConfigurationResponseDTO, ChannelConfigurationUpdateDTO, SocketIoService } from '@portal/wen-backend-api';
import { filter, first, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { FormStoreMediator } from '../../../../shared/form-store/form-store-mediator';
import { switchMapFirst } from '../../../common/operators/switch-map-first';
import { fetchConfigurationForChannel, updateChannelConfiguration } from '../../channel/channel.actions';
import { selectCurrentChannel } from '../../channel/channel.selectors';
import { DataObjectType } from '../../common/data-objects';
import { doBackNavigation } from '../../header/header.actions';
import { RootState } from '../../root/public-api';
import { clearFormValues } from '../form.actions';
import { selectEditFormById } from '../form.selectors';

@Injectable()
export class ChannelConfigureFormEffects {

  fetchConfigurationForChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchConfigurationForChannel),
      switchMapFirst(() => this.store.pipe(select(selectCurrentChannel))),
      tap(({ id: channelId }) => this.socketIoService.channel.configureDetail.emit({ channelId })),
      switchMap(() => {
        return this.socketIoService.channel.configureDetail.listen;
      }),
      map((response: ChannelConfigurationResponseDTO) => {
        return updateChannelConfiguration({ response });
      })
    )
  );

  saveChannelConfiguration$ = this.formStoreMediator.createSaveEditFormEffect((saveAction) => of(saveAction).pipe(
    filter(action => action.dataObjectType === DataObjectType.CHANNEL_SETTING),
    switchMap(({ formId }) => this.store.pipe(
      select(selectEditFormById(formId)),
      first()
    )),
    withLatestFrom(this.store.pipe(select(selectCurrentChannel))),
    switchMap(([editForm, { id: channelId }]) => {
      const { formId, initialValues, changedValues } = editForm;
      const actions: Action[] = [];
      const initialValuesTyped = initialValues as ChannelConfiguration;
      const changedValuesTyped = changedValues as ChannelConfiguration;
      const mergedValues: ChannelConfiguration['config'] = Object.entries(changedValuesTyped).reduce((acc, [key, value]) => {
        if (!!initialValuesTyped[key]) {
          acc = { ...acc, [key]: value };
        }
        return acc;
      }, {} as ChannelConfiguration['config']);
      const newChannelConfiguration: ChannelConfigurationUpdateDTO = {
        channelId,
        config: mergedValues
      };
      this.socketIoService.channel.configure.emit({channelId, config: mergedValues });
      actions.push(clearFormValues({ formId }));
      actions.push(doBackNavigation());
      actions.push(updateChannelConfiguration({ response: newChannelConfiguration as ChannelConfigurationResponseDTO }));
      return actions;
    })
  ));

  constructor(
    private store: Store<RootState>,
    private socketIoService: SocketIoService,
    private actions$: Actions,
    private formStoreMediator: FormStoreMediator
  ) { }
}
