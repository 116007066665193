import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { EmbeddedExpandable } from '../../../../../../core/store/channel/channel.state';

@Component({
  selector: 'wen-embed-expandable',
  templateUrl: './embed-expandable.component.html',
  styleUrls: ['./embed-expandable.component.scss']
})
export class EmbedExpandableComponent implements OnInit {

  @HostBinding('class.wen-embed-expandable') className = true;

  @Input() model: EmbeddedExpandable;

  constructor() { }

  ngOnInit(): void {
  }

}
