import { Directive, HostListener } from '@angular/core';

@Directive({ selector: '[wenTouchEndListener]' })
export class TouchEndListenerDirective {

  /**
   * Usually the hammer tap fires a ghost click event as well. Since on touch devices it contains the touch-end event, it is best to
   * prevent it from any further propagation.
   * Since the click event does not really work on touch devices, the tap is more favorized in such cases.
   */
  @HostListener('touchend', ['$event'])
  onTouchEnd(event: TouchEvent) {
    event.preventDefault();
  }

}
