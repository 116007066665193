import { WenRoutes } from '../../../frame/routing/types';
import { dashboardRoute, homePageRoute } from './home.routes';

export const sidebarOutletHomeRoutes: WenRoutes = [
  homePageRoute
];

export const primaryOutletHomeRoutes: WenRoutes = [
  dashboardRoute,
  {
    path: '**',
    redirectTo: '/home/dashboard(sidebar:home)'
  }
];
