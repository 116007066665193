import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { BroadcastInformation, ReactionContext, ReactionSummary, SocketIoService } from '@portal/wen-backend-api';
import { ReactionHandler, AncestorReference } from '@portal/wen-components';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { sendReactionAction } from '../../store/reactions/reaction.actions';
import { selectReactionSummaryFor } from '../../store/reactions/reaction.selectors';
import { RootState } from '../../store/root/public-api';
@Injectable()
export class ReactionService extends ReactionHandler {
  private emojiCollection$ = new ReplaySubject<Map<number, SafeResourceUrl>>(1);
  private emojiAnimations$ = new ReplaySubject<Map<number, string>>(1);
  private reactionsSentByCurrentUser = new Map<string, number>();

  constructor(
    private socketIOService: SocketIoService,
    private sanitizer: DomSanitizer,
    private store: Store<RootState>
  ) {
    super();
    this.socketIOService.reactions.getEmojis.listen.pipe(
      first(),
      map((serverEmojis) => {
        const emojis = new Map<number, SafeResourceUrl>();
        const animations = new Map<number, string>();
        serverEmojis.forEach((emoji) => {
            emojis.set(emoji.id, this.transform(emoji.image));
            animations.set(emoji.id, emoji.animation);
          }
        );
        return { emojis, animations };
      })
    ).subscribe(({ emojis, animations }) => {
      this.emojiCollection$.next(emojis);
      this.emojiAnimations$.next(animations);
    });
    this.init();
  }

  transform(image: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(image);
  }

  getEmoji(id: number): Observable<SafeResourceUrl> {
    return this.emojiCollection$.pipe(map((em) => em.get(id)));
  }

  getAnimation(id: number): Observable<string> {
    return this.emojiAnimations$.pipe(map((animationMap) => animationMap.get(id)));
  }

  private init() {
    this.socketIOService.reactions.getEmojis.emit();
    this.reactionsFromUser$ = new Subject<{ emojiId: number; referenceId: string}>();
  }

  getAvailableEmojiIds(): Observable<number[]> {
    return this.emojiCollection$.pipe(map((em) => Array.from(em.keys()).sort()));
  }

  sendUserReaction(
    emojiId: number,
    referenceId: string,
    parentType: ReactionContext,
    broadcastInformation?: BroadcastInformation,
    possibleBlockingAncestor?: AncestorReference
  ): void {
    this.reactionsSentByCurrentUser.set(referenceId, emojiId);
    this.store.dispatch(sendReactionAction({ emojiId, parentId: referenceId, parentType, broadcastInformation, possibleBlockingAncestor }));
  }

  getReactionSummaryFor(referenceId: string): Observable<ReactionSummary | null> {
    return this.store.pipe(
      select(selectReactionSummaryFor(referenceId)),
      tap(reactionSummary => {
        const reactionSentByCurrentUser = this.reactionsSentByCurrentUser.get(referenceId);
        const currentUserSelection = reactionSummary?.currentUsersSelection;
        if (reactionSentByCurrentUser && currentUserSelection && reactionSentByCurrentUser === currentUserSelection) {
          this.reactionsFromUser$.next({ emojiId: currentUserSelection, referenceId });
          this.reactionsSentByCurrentUser.delete(referenceId);
        }
      })
    );
  }
}
