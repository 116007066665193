import {
  ElementRef,
  Renderer2
} from '@angular/core';
import { Dimensions2D } from '@portal/wen-components';

export enum FitType {
  FIT,
  TOO_TALL,
  TOO_WIDE
}

export type ScaleResult = Dimensions2D & { fitType: FitType };

export class ScaleHelper {
  constructor(
    private element: ElementRef,
    private renderer: Renderer2
  ) { }

  public setElementSize(
    availableWidth: number
  ) {
    this.renderer.setStyle(
      this.element.nativeElement,
      'width',
      `${availableWidth}px`
    );
    this.renderer.setStyle(
      this.element.nativeElement,
      'height',
      `100%`
    );
  }

  public static computeScale(
    originalWidth: number,
    originalHeight: number,
    availableWidth: number,
    maxHeight: number = Number.MAX_SAFE_INTEGER,
    minHeight: number = 0
  ): ScaleResult {
    if (!originalWidth || !originalHeight) {
      return null;
    }
    let fitType = FitType.FIT;
    const fitToWidthRatio = availableWidth / originalWidth;
    const heightToFit = Math.round(fitToWidthRatio * originalHeight);
    let height = Math.min(heightToFit, maxHeight);
    let width = Math.round(availableWidth);

    if (height < heightToFit) {
      fitType = FitType.TOO_TALL;
    }

    if (height < minHeight) {
      fitType = FitType.TOO_WIDE;
      const fitToHeightRatio = minHeight / originalHeight;
      height = minHeight;
      width = Math.min(Math.round(fitToHeightRatio * originalWidth), availableWidth);
    }

    return { width, height, fitType };
  }

  public scaleElementToWidth(
    originalWidth: number,
    originalHeight: number,
    availableWidth: number,
    maxHeight?: number,
    minHeight?: number
  ) {
    if (!originalWidth || !originalHeight) {
      return null;
    }
    const {width, height} = ScaleHelper.computeScale(
      originalWidth,
      originalHeight,
      availableWidth,
      maxHeight,
      minHeight
    );

    this.renderer.setStyle(
      this.element.nativeElement,
      'width',
      `${width}px`
    );
    this.renderer.setStyle(
      this.element.nativeElement,
      'height',
      `${height}px`
    );
  }

}
