import { ChatMessagePreprocessorResult, EditMessageEventDTO, EditRelation, EncryptionAlgorithm, EncryptionType, SendMessageContent, SendToRoomEventPayload, ToRoomEventType } from '@portal/wen-backend-api';
import { MegOlmEncryptionResult } from '@portal/wen-chat-client';


export class EncryptedEditMessageEventResult implements ChatMessagePreprocessorResult {

  constructor(
    public readonly encryptionResult: MegOlmEncryptionResult,
    public readonly sendMessageContent: SendMessageContent<false>,
    private readonly senderCurve25519: string,
    private readonly insertUserId: string,
    private readonly senderDeviceId: string,
    private readonly roomId: string,
    private readonly relation: EditRelation
  ) { }

  getPayload(): SendToRoomEventPayload {
    return {
      roomId: this.roomId,
      payload: this.asSendToRoomPayload()
    };
  }

  private asSendToRoomPayload() {
    if (!this.encryptionResult) {
      return;
    }
    const { usedSession: { sessionId } } = this.encryptionResult;
    const payload: EditMessageEventDTO = {
      type: EncryptionType.ENCRYPTED,
      eventType: ToRoomEventType.EDIT_MESSAGE,
      content: {
        algorithm: EncryptionAlgorithm.Megolm,
        ciphertext: { type: 0, body: this.encryptionResult.encrypted },
        senderKey: this.senderCurve25519,
        deviceId: this.senderDeviceId,
        sessionId
      },
      senderUserId: this.insertUserId,
      relatesTo: this.relation,
      roomId: this.roomId
    };
    return payload;
  }

}
