import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { WenOAuthService } from '../../../core/services/user-management/wen-oauth.service';

@Directive({
  selector: '[wenIsCurrentUser]',
})
export class IsCurrentUserDirective implements AfterViewInit {
  @Input('wenIsCurrentUser') userId: string;
  @Input('wenIsCurrentUserClassToAdd') classToAdd = 'wen-current-user';

  constructor(
    private element: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private oAuthService: WenOAuthService
  ) {}

  ngAfterViewInit() {
    if (this.oAuthService.isCurrentUserId(this.userId)) {
      this.renderer.addClass(this.element.nativeElement, this.classToAdd);
    }
  }
}
