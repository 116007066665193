import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommentCountResponse, SocketIoService } from '@portal/wen-backend-api';
import { filter, map } from 'rxjs';
import { mapWithFirstFrom } from '../../../common/operators/map-with-first-from';
import { fetchCommentCountForMessages, fetchCommentCountForParentId, updateCommentCount } from '../comments.actions';

export const fetchCommentCountForMessagesEffect = (
  actions$: Actions,
  socketIoService: Pick<SocketIoService, 'comment'>,
) => {
  return createEffect(() =>
    actions$.pipe(
      ofType(fetchCommentCountForMessages),
      filter(({ messageIds }) => messageIds.length > 0),
      mapWithFirstFrom(({  messageIds }) => {
        return socketIoService.comment.count.acknowledgement$({ parentIds: messageIds });
      }),
      map(([{ messageIds }, response]) => {
        return updateRequestedCommentCounts(messageIds, response);
      })
    )
  );
};

export const fetchCommentCountForSingleParentEffect = (
  actions$: Actions,
  socketIoService: Pick<SocketIoService, 'comment'>,
) => {
  return createEffect(() =>
    actions$.pipe(
      ofType(fetchCommentCountForParentId),
      filter(({ parentId }) => !!parentId),
      mapWithFirstFrom(({ parentId }) => {
        const parentIdArray = [parentId];
        return socketIoService.comment.count.acknowledgement$({ parentIds: parentIdArray });
      }),
      map(([{ parentId }, response]) => {
        const parentIdArray = [parentId];
        return updateRequestedCommentCounts(parentIdArray, response);
      })
    )
  );
};

const updateRequestedCommentCounts = (
  requestedMessageIds: string[],
  { commentCountByParentId }: CommentCountResponse
) => {
  const responseIds = Object.keys(commentCountByParentId);
  requestedMessageIds.forEach(id => {
    if (!responseIds.includes(id)) {
      commentCountByParentId[id] = 0;
    }
  });

  return updateCommentCount({ commentCountByParentId });
};
