import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize'
})

export class FileSizePipe implements PipeTransform {
  private sizeStep = 1024;
  private fileSizes: string[] = ['B', 'kB', 'MB', 'GB'];

  transform(bytes: number, ...args: any[]): any {
    if (bytes === null || bytes === 0) {
      return 0 + this.fileSizes[0];
    }
    const powerLevel = Math.floor(Math.log(bytes) / Math.log(this.sizeStep));
    const adjustedSize = bytes / Math.pow(this.sizeStep, powerLevel);
    const displayedSize = parseFloat(adjustedSize.toFixed(powerLevel > 1 ? 2 : 0));
    return `${displayedSize} ${this.fileSizes[powerLevel]}`;
  }
}
