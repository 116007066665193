import { Component, Inject, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { map, startWith, Subject, takeUntil } from 'rxjs';
import { WenCustomFilterDirective } from '../../../directives/custom-filter.directive';
import { dateRangeValidator } from './date-range-validator';
import { LuxonFormatter, RangeFormatter } from './range-formatter';
import { DateRangeAdapter, DateRangeValueAdapter } from './date-range-value-adapter';
import { DateUtil } from '../../../../../../core/common/date/date-util';

const DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};
@Component({
  selector: 'wen-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    { provide: DateAdapter, useExisting: DateRangeAdapter },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    DateRangeValueAdapter,
    { provide: RangeFormatter, useClass: LuxonFormatter }
  ]
})
export class DateRangePickerComponent extends WenCustomFilterDirective implements OnInit, AfterViewInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  rangeFormGroup = new UntypedFormGroup({
    start: new UntypedFormControl(''),
    end: new UntypedFormControl('')
  }, dateRangeValidator);

  isFormInvalid$ = this.rangeFormGroup.statusChanges.pipe(
    map(status => status !== 'VALID'),
    startWith(true)
  );

  startMinDate: Date = new Date();
  endMinDate: Date = new Date();
  startOfEndDate: Date;

  constructor(
    @Inject(DateRangeValueAdapter)
    public readonly valueAdapter: DateRangeValueAdapter,
    private rangeFormatter: RangeFormatter
  ) {
    super();
  }

  ngOnInit() {
    this.valueAdapter.setLocale(DateUtil.getLocale());
  }

  ngAfterViewInit() {
    this.rangeFormGroup.valueChanges.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(({ start, end }) => this.updateDateLimits(start, end));
  }

  onCancel() {
    this.cancel.emit();
  }

  onApply() {
    this.apply.emit(this.rangeFormatter.format(this.rangeFormGroup.value));
  }

  private updateDateLimits(startDate: string, endDate: string) {
    const endControl = this.rangeFormGroup.controls.end;
    if (startDate && (!endDate) && !endControl.dirty) {
      this.rangeFormGroup.patchValue({ end: startDate }, { emitEvent: false });
    }
    this.startMinDate = new Date();
    this.endMinDate = startDate ? new Date(startDate) : new Date();
    this.startOfEndDate = endDate ? new Date(endDate) : new Date();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
