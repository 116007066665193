import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { firstExisty } from '../../core/common/operators/first-existy';
import { WenStorageService } from '../../core/services/storage/wen-storage.service';
import { WenOAuthService } from '../../core/services/user-management/wen-oauth.service';
import { selectActiveNetwork } from '../../core/store/network/network.selectors';
import { RootState } from '../../core/store/root/public-api';

@Injectable()
export class MediaInterceptor implements HttpInterceptor {

  constructor(
    private store: Store<RootState>,
    private oauthService: WenOAuthService,
    private storageService: WenStorageService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes('media/')) {
      return next.handle(req);
    }

    const fromDevice = this.storageService.getDeviceId();
    const idToken = this.oauthService.getIdToken();

    return this.store.pipe(
      select(selectActiveNetwork),
      firstExisty(),
      switchMap((activeNetwork) => {
        const request = req.clone({
          headers: req.headers
            .append('fromDevice', fromDevice)
            .append('jwtToken', idToken)
            .append('networks', [activeNetwork.id])
        });
        return next.handle(request);
      })
    );
  }

}
