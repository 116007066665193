import { Modifier } from '@popperjs/core';

export const createPreventScrollModifier = (
  scrollElement: HTMLElement
): Modifier<string, any> => ({
  name: 'preventScrolling',
  enabled: true,
  effect: () => {
    scrollElement.style.setProperty('overflow-y', 'hidden');
    return () => scrollElement.style.setProperty('overflow-y', 'auto');
  },
  phase: 'beforeWrite',
  fn: () => { }
});
