import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MediaContextTypes, MediaUseCases, UserUpdatePayload, WeLocalImageHelper } from '@portal/wen-backend-api';
import { combineLatest, EMPTY, merge, Observable, of } from 'rxjs';
import { filter, first, mergeAll, mergeMap, switchMap, tap } from 'rxjs/operators';
import { FormStoreMediator } from '../../../../shared/form-store/form-store-mediator';
import { hasProperty } from '../../../common/operators/null-check-util';
import { CapitalizeFieldValueAdapter } from '../../../common/util/field-adapters/capitalize-field-value-adapter';
import { SocketIoService } from '@portal/wen-backend-api';
import { selectCurrentUserData } from '../../auth/auth.selectors';
import { DataObjectType } from '../../common/data-objects';
import { doBackNavigation } from '../../header/header.actions';
import { RootState } from '../../root/public-api';
import { updateProfileImageState } from '../../user/user.actions';
import { ProfileImageState } from '../../user/user.state';
import { clearFormValues, showSaveSuccessToast } from '../form.actions';
import { selectEditFormById } from '../form.selectors';

@Injectable()
export class ProfileFormEffects {

  private readonly valueAdapter: CapitalizeFieldValueAdapter = new CapitalizeFieldValueAdapter();

  saveUserForm$ = this.formStoreMediator.createSaveEditFormEffect((saveAction) => of(saveAction).pipe(
    filter(action => action.dataObjectType === DataObjectType.USER),
    switchMap(({ formId }) => combineLatest([
      this.store.pipe(select(selectEditFormById(formId))),
      this.store.pipe(select(selectCurrentUserData))
    ]).pipe(first())),
    switchMap(([editForm, userData]) => {
      const { formId, changedValues } = editForm;

      const { avatarUrl, ...simpleChangedValues } = changedValues;
      const avatarRemoved = hasProperty(changedValues, 'avatarUrl') && !avatarUrl;

      const userUpdate: UserUpdatePayload = {
        ...simpleChangedValues,
        userId: userData.userId
      };

      if (Boolean(userUpdate.christianName)) {
        userUpdate.christianName = this.valueAdapter.format(userUpdate.christianName);
      }
      if (Boolean(userUpdate.lastName)) {
        userUpdate.lastName = this.valueAdapter.format(userUpdate.lastName);
      }

      const afterSaveActions: Observable<Action>[] = [of([
        clearFormValues({ formId }),
        doBackNavigation(),
      ]).pipe(mergeAll())];
      if (avatarRemoved) {
        userUpdate.avatarUrl = '';
        afterSaveActions.push(of(updateProfileImageState({ state: ProfileImageState.LOADED })));
      } else if (avatarUrl) {
        const updateImageAction = this.uploadAndUpdateUserAvatar(avatarUrl, userData.userId);
        afterSaveActions.push(updateImageAction);
        afterSaveActions.push(of(updateProfileImageState({ state: ProfileImageState.PENDING_UNSAFE })));
      } else {
        afterSaveActions.push(of(updateProfileImageState({ state: ProfileImageState.LOADED })));
      }
      if (hasProperty(userUpdate, 'email')) {
        afterSaveActions.push(of(showSaveSuccessToast({
          notificationText: this.translateService.instant('SNACKBAR_LABEL_EMAIL_HAS_BEEN_CHANGED')
        })));
      }
      this.socketIoService.user.update.emit(userUpdate);
      const result$ = merge(
        afterSaveActions
      ).pipe(mergeAll());
      return result$;
    })
  ));

  constructor(
    private store: Store<RootState>,
    private socketIoService: SocketIoService,
    private translateService: TranslateService,
    private imageHelper: WeLocalImageHelper,
    private formStoreMediator: FormStoreMediator,
  ) { }

  private uploadAndUpdateUserAvatar(icon: File, userId: string) {
    return this.imageHelper.uploadImage(icon, [{ type: MediaContextTypes.PROFILE, id: userId}], MediaUseCases.PROFILE).pipe(
      tap((result) => {
        this.socketIoService.user.update.emit({
          userId,
          avatarUrl: result.thumbnailUrl
        });
      }),
      mergeMap(() => EMPTY),
    );
  }

}
