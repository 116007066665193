import { inject, Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { RootState } from '../../../../core/store/root/public-api';
import { Store } from '@ngrx/store';
import { FILTER_QUERY_PARAM } from '../../../../core/services/navigation/query-params';
import { SectionProps } from '../../../../core/common/util/section.model';
import { removeFilter, upsertActiveFilter } from '../../../../core/store/filter/filter.actions';
import { FilterId } from '../../../../core/common/types/filter-id';
import { FilterEntityType, FilterType } from '../../../../core/store/filter/models/filter';
import { Filterable } from '../../../../shared/components/filter-view/models/filter-item';

export const discoverAppListDataResolverFn = () => {
  inject(DiscoverAppListDataResolver).resolve();
};

@Injectable()
export class DiscoverAppListDataResolver  {
  constructor(private store: Store<RootState>, private router: Router) { }

  resolve(): void {
    const queryParams: Params = this.router.getCurrentNavigation().extras.queryParams;
    if (!!queryParams && queryParams[FILTER_QUERY_PARAM] === 'clearFilter') {
      this.store.dispatch(removeFilter({ filterEntityType: FilterEntityType.APP_LISTS }));
    } else if (!!queryParams && queryParams[FILTER_QUERY_PARAM]) {
      const props: SectionProps = queryParams[FILTER_QUERY_PARAM];
      this.store.dispatch(upsertActiveFilter({
        filterEntityType: props.filterEntityType,
        filter: {
          filterId: FilterId.APP_CATEGORIES,
          filterValue: props.filterRef as Filterable,
          filterType: FilterType.SELECT
        }
      }));
    }
  }
}
