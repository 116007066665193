import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { toUserRole, WenUserRole } from '@portal/wen-backend-api';

@Component({
  selector: 'wen-user-role',
  templateUrl: './user-role.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserRoleComponent {

  @HostBinding('class.wen-user-role') className = true;

  roleTranslationKey: string;

  @Input() set userRole(id: string | WenUserRole) {
    if (!id) {
      return;
    }
    let roleKey: string;
    if (id in WenUserRole) {
      roleKey = this.getUserRoleToTranslationKey(id as WenUserRole);
    } else {
      roleKey = this.getUserRoleToTranslationKey(toUserRole(id));
    }
    this.roleTranslationKey = roleKey;
  }

  private getUserRoleToTranslationKey(userRole: WenUserRole) {
    switch (userRole) {
      case WenUserRole.ADMINISTRATOR:
      case WenUserRole.MANAGED_ADMINISTRATOR:
        return 'USER_ROLE_ADMINISTRATOR';
      case WenUserRole.CONTENT_PROVIDER:
        return 'USER_ROLE_CONTENT_PROVIDER';
      default:
        return '';
    }
  }

}
