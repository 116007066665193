import { AfterViewInit, Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Store, select } from '@ngrx/store';
import { OverlayManager, WenBreakpointObserver } from '@portal/wen-components';
import 'hammerjs';
import { Observable, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, first, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { FeatureEnablementService } from '../../../core/services/configuration/feature-enablement';
import { rootOutletProviders } from '../../../core/services/navigation/outlet-specific/outlet-providers';
import { selectCurrentUserData } from '../../../core/store/auth/auth.selectors';
import { selectCurrentHeaderData } from '../../../core/store/header/header.selectors';
import { SearchConfig } from '../../../core/store/header/models/HeaderData';
import { selectIsNavBarVisible } from '../../../core/store/navigation-bar/navigation-bar.selectors';
import { RootState } from '../../../core/store/root/public-api';
import { selectOutletIds } from '../../../core/store/root/root.selectors';
import { AppBannerVisibility } from '../../../shared/components/app-banner/app-banner-visibility';
import { DataProtectionDialogOpener } from '../../../shared/components/data-protection-dialog/data-protection-dialog-opener';
import { SwipeDetectorEvent } from '../../../shared/ios-util/ios-swipe/ios-swipe-detector.directive';
import { WenRouteWithId } from '../../routing/types';
import { RouteDialogComponent } from '../route-dialog/route-dialog.component';
import { routeTransitions } from './animations/route-transitions';

@Component({
  selector: 'wen-widget',
  templateUrl: './wen-widget.component.html',
  styleUrls: ['./wen-widget.component.scss'],
  providers: [
    ...rootOutletProviders
  ],
  animations: [
    routeTransitions
  ]
})
export class WenWidgetComponent implements OnInit, AfterViewInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  private ignoreAnimationOnIOSSwipe = false;

  isHeaderVisible$: Observable<boolean>;
  searchConfig$: Observable<SearchConfig>;
  isNavigationBarVisible$: Observable<boolean>;
  isWideDevice = this.breakpointObserver.isDesktopStyleDevice;
  showSidebar$ = this.store.pipe(
    select(selectOutletIds),
    map((outletIds) => Boolean(outletIds?.sidebarId))
  );
  isAppBannerVisible$ = this.appBannerVisibility.isVisible();
  showRefreshBanner$: Observable<boolean>;

  constructor(
    private store: Store<RootState>,
    private featureEnablementService: FeatureEnablementService,
    public breakpointObserver: WenBreakpointObserver,
    public router: Router,
    public overlayManager: OverlayManager,
    private dataProtectionDialogOpener: DataProtectionDialogOpener,
    private appBannerVisibility: AppBannerVisibility,
    @Optional() private swUpdate: SwUpdate
  ) {
    this.store.pipe(
      select(selectOutletIds),
      map(outlets => Boolean(outlets?.dialogId)),
      switchMap((dialogId) => this.router.events.pipe(
        filter(e => e instanceof NavigationEnd),
        takeUntil(this.router.events.pipe(
          filter(e => e instanceof NavigationStart),
          first()
        )),
        map(() => dialogId)
      )),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$)
    ).subscribe((dialogId) => {
      if (!this.breakpointObserver.isDesktopStyleDevice) {
        this.overlayManager.dialog.closeInputDialog();
        return;
      }
      if (dialogId) {
        this.overlayManager.dialog.openInputDialog(RouteDialogComponent, null, {
          autoFocus: false,
          closeOnNavigation: false
        });
      } else {
        this.overlayManager.dialog.closeInputDialog();
      }
    });

    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.overlayManager.dialog.closeLightboxDialog();
    });
  }

  swipeEvent(event: SwipeDetectorEvent) {
    this.ignoreAnimationOnIOSSwipe = event.type === 'start';
  }

  ngOnInit() {
    const isLoggedIn$ = this.store.pipe(
      select(selectCurrentUserData),
      map(Boolean)
    );
    this.isHeaderVisible$ = this.store.pipe(
      select(selectCurrentHeaderData)
    ).pipe(
      map((data) => Boolean(data))
    );
    this.isNavigationBarVisible$ = combineLatest([
      isLoggedIn$,
      this.store.pipe(select(selectIsNavBarVisible))
    ]).pipe(
      map(([isLoggedIn, isNavigationBarVisible]) => {
        return isLoggedIn && isNavigationBarVisible;
      }),
      startWith(false)
    );
    this.searchConfig$ = this.store.pipe(
      select(selectCurrentHeaderData),
      map((data) => data?.searchConfig)
    );
    if (this.swUpdate) {
      this.showRefreshBanner$ = this.swUpdate.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(() => true),
        startWith(false)
      );
    }
  }

  ngAfterViewInit(): void {
    this.dataProtectionDialogOpener.openDialogIfNeeded();
  }

  prepareRouteForAnimation(outlet: RouterOutlet) {
    if (!this.featureEnablementService.featureFlagMethods.isEnableAnimations()) {
      return null;
    }
    if (this.ignoreAnimationOnIOSSwipe) {
      return null;
    }
    const wenRouteData = outlet?.activatedRouteData as WenRouteWithId;
    return wenRouteData?.routeTransitionEnabled ? wenRouteData.routeId : null;
  }

  onContextMenu(event: Event) {
    const { target } = event;
    const canContextMenu =
      target instanceof HTMLTextAreaElement ||
      target instanceof HTMLInputElement;
    return canContextMenu;
  }

  onAppBannerClosed() {
    this.appBannerVisibility.setAppBannerDismissed();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
