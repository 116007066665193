import { EntityState } from '@ngrx/entity';
import { ChatRoomEntity } from '../chat.state';

export const findRoomById = (
  roomId: string,
  rooms: EntityState<ChatRoomEntity>
) => {
  const roomById = rooms.entities[roomId];
  return roomById;
};
