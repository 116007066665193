import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ChannelUpdatePayload, RestrictionType } from '@portal/wen-backend-api';
import { SelectionOption } from '@portal/wen-components';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { firstExisty } from '../../../../../../core/common/operators/first-existy';
import { RootState } from '../../../../../../core/store/root/public-api';
import { selectPrivileges } from '../../../../../../core/store/user/user.selectors';
import { FormValueConverter } from '../../../../../../shared/form-store/form-store.providers';
import { PrivilegeService } from '../../../../../../shared/services/privilege.service';

interface ChannelRestrictionFormModel {
  id: string;
  restrictions: SelectionOption<RestrictionType>[];
  noRestriction: SelectionOption<RestrictionType>[];
  password?: string;
}

@Injectable()
export class ChannelRestrictionFormValueConverter extends FormValueConverter<ChannelRestrictionFormModel, Partial<ChannelUpdatePayload>> {

  public readonly restrictionPossibleValues$: Observable<SelectionOption<RestrictionType>[]>;
  public readonly noRestrictionPossibleValues: SelectionOption<RestrictionType>[];

  constructor(
    readonly translateService: TranslateService,
    readonly privilegeService: PrivilegeService,
    readonly store: Store<RootState>,
  ) {
    super();

    this.noRestrictionPossibleValues = [
      {
        id: '1',
        text: translateService.instant('CHANNEL_RESTRICTION_NO_RESTRICTION'),
        value: RestrictionType.NoRestriction,
        prefixIcon: 'globe'
      },
    ];

    this.restrictionPossibleValues$ = store.pipe(
      select(selectPrivileges),
      firstExisty(),
      map(privileges => {
        const staticRestrictions = [
          {
            id: '2',
            text: translateService.instant('CHANNEL_RESTRICTION_REGISTRATION'),
            value: RestrictionType.RegistrationRequired,
            prefixIcon: 'globe'
          },
          {
            id: '3',
            text: translateService.instant('CHANNEL_RESTRICTION_PASSWORD'),
            value: RestrictionType.PasswordRequired,
            prefixIcon: 'lock'
          },
          {
            id: '4',
            text: translateService.instant('CHANNEL_RESTRICTION_REQUEST_ONLY'),
            value: RestrictionType.RequestOnly,
            prefixIcon: 'lock'
          },
        ];

        if (privileges.length < 1) {
          return staticRestrictions;
        }

        const dynamicRestrictions = privileges.map(privilege => {
          return ({ id: privilege.id, text: privilege.displayName, value: RestrictionType.PrivilegesRequired, prefixIcon: 'lock' });
        });

        return [...staticRestrictions, ...dynamicRestrictions];
      })
    );
  }

  convertToServerModel(formValue: ChannelRestrictionFormModel) {
    let result: Partial<ChannelUpdatePayload> = {};
    const { restrictions, noRestriction, password } = formValue;
    const restrictionObjects = this.privilegeService.convertSelectionToResolveObject(
      [...(restrictions || []), ...(noRestriction || [])],
      password
    );
    result = { ...result, restrictions: restrictionObjects };
    return of(result);
  }

  convertToFormModel(channel: ChannelUpdatePayload) {
    return this.restrictionPossibleValues$.pipe(
      first(),
      map(restrictionPossibleValues => {
        const restrictions = restrictionPossibleValues.filter(value => channel.restrictions?.find(r => r.type === value.value));
        const noRestriction = channel.restricted ? [] : this.noRestrictionPossibleValues;

        return { id: channel.id, restrictions, noRestriction };
      })
    );
  }
}
