import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EMPTY, Observable, catchError } from 'rxjs';
import { AddChatUsersSearchMassDataContainer } from '../../../../../core/store/chat/mass-data/add-chat-users-search-mass-data-config/add-chat-users-search-mass-data-container';
import { EditChatSearchFilterService } from '../../../../../core/store/chat/mass-data/providers/edit-chat-search-filter-service';
import { InputWithChipModel, InputWithChipsComponent } from '../../../../../shared/components/input-with-chips/input-with-chips.component';
import { FormValueConverter } from '../../../../../shared/form-store/form-store.providers';
import { FormStoreService } from '../../../../../shared/form-store/form-store.service';
import { maxNumberOfUsersForMassMessageSending } from '../../../constants';
import { ChatSelectionListItemModel, createChatSelectionListProviders } from '../chat-selection-list/providers/chat-selection-list-providers';
import { ChatUserFormValueConverter } from './chat-user-form-value-converter';
import { AddChatUsersSelectionService } from './providers/list/add-chat-users-selection-service';

@Component({
  selector: 'wen-add-chat-users',
  templateUrl: './add-chat-users.component.html',
  styleUrls: ['./add-chat-users.component.scss'],
  providers: [
    AddChatUsersSelectionService,
    createChatSelectionListProviders(
      AddChatUsersSearchMassDataContainer,
      AddChatUsersSelectionService,
      EditChatSearchFilterService
    ),
    {
      provide: FormValueConverter,
      useClass: ChatUserFormValueConverter
    },
  ]
})
export class AddChatUsersComponent implements OnInit, OnDestroy {

  @ViewChild(InputWithChipsComponent) inputFieldChips: InputWithChipsComponent;

  maxNumberOfSelectableUsers = maxNumberOfUsersForMassMessageSending;
  selectedChips$: Observable<InputWithChipModel[]>;
  selectedChipsCount$: Observable<number>;
  alwaysDisabledItemIds$: Observable<string[]>;

  selectionModeEnabled$: Observable<boolean>;

  chatGroupUsers = new FormGroup({
    users: new FormControl([])
  });
  get usersField(): FormControl {
    return this.chatGroupUsers.controls.users as FormControl;
  }

  constructor(
    private formStoreService: FormStoreService,
    private filterService: EditChatSearchFilterService,
    private selectionService: AddChatUsersSelectionService,
  ) { }

  ngOnInit() {
    this.selectedChips$ = this.selectionService.selectedChips$;
    this.selectedChipsCount$ = this.selectionService.selectedChipsCount$;
    this.selectionModeEnabled$ = this.selectionService.selectionModeEnabled$;
    this.alwaysDisabledItemIds$ = this.selectionService.initiallySelectedIds$;

    this.formStoreService.initializeForm({ users: [] });
  }

  onSearchChange(searchTerm: string) {
    this.filterService.setSearchTerm(searchTerm);
  }

  handleListItemClick(item: ChatSelectionListItemModel) {
    this.selectionService.toggleSelection(item, this.usersField).pipe(
      catchError(() => EMPTY)
    ).subscribe(() => {
      this.filterService.resetSearchTerm();
      this.inputFieldChips.clearInput();
    });
  }

  ngOnDestroy(): void {
    this.filterService.resetSearchTerm();
  }
}
