import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, distinctUntilChanged, filter, first, interval, map, Observable, race, shareReplay, Subject, switchMap, takeUntil } from 'rxjs';
import { DateUtil } from '../../../../core/common/date/date-util';
import { filterBy } from '../../../../core/common/operators/fitler-by';
import { selectCurrentRoomPendingMessages } from '../../../../core/store/chat/chat.selectors';
import { ChatMessageEntity } from '../../../../core/store/chat/chat.state';
import { selectCurrentRoomMessagesHistory } from '../../../../core/store/chat/selectors/chat-message-selectors';
import { RootState } from '../../../../core/store/root/public-api';
import { MessagesDatasource } from '../../../../shared/services/messages.datasource';
import { ChatViewPreloaderGuard } from './chat-view-preloader.guard';

const MESSAGE_LOADING_TIMEOUT = 2000;

@Injectable()
export class ChatViewMessagesDatasource extends MessagesDatasource<ChatMessageEntity> implements OnDestroy {

  private onDestroy$ = new Subject<void>();

  lastUnreadMessage$: Observable<ChatMessageEntity>;
  messages$: Observable<ChatMessageEntity[]>;

  constructor(
    private store: Store<RootState>,
    private chatViewPreloaderGuard: ChatViewPreloaderGuard,
  ) {
    super();
  }

  override initialize(): void {
    const historicalMessages$ = this.store.pipe(
      select(selectCurrentRoomMessagesHistory),
      filterBy(() => this.chatViewPreloaderGuard.isChatHistoryLoaded$)
    );
    const storeMessages$ = combineLatest([
      historicalMessages$,
      this.store.select(selectCurrentRoomPendingMessages)
    ]).pipe(
      distinctUntilChanged(),
      map(([messages, pendingMessages]) => {
        return messages
          .concat(pendingMessages)
          .sort(({ insertTimestamp: timestampMessage1 }, { insertTimestamp: timestampMessage2 }) => {
            return DateUtil.compare(timestampMessage2, timestampMessage1);
          });
      }),
      shareReplay(1),
      takeUntil(this.onDestroy$)
    );

    this.messages$ = race(
      storeMessages$.pipe(
        filter(Boolean),
        map(() => true)
      ),
      interval(MESSAGE_LOADING_TIMEOUT).pipe(
        map(() => true)
      )
    ).pipe(
      first(),
      switchMap(() => storeMessages$.pipe(
        map(messages => messages.length ? messages : null),
      )),
      shareReplay(1),
      takeUntil(this.onDestroy$)
    );
    this.lastUnreadMessage$ = storeMessages$.pipe(
      map((messages) => {
        if (!messages?.length) {
          return null;
        }
        return messages.find(message => message.new);
      }),
      distinctUntilChanged((a, b) => Boolean(a) && Boolean(b) && a.eventId === b.eventId),
      shareReplay(1),
      takeUntil(this.onDestroy$)
    );
    super.initialize();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
