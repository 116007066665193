import { ComponentPortal } from '@angular/cdk/portal';
import { Component, ComponentRef, HostBinding, Injector, Input, OnChanges, SimpleChanges, SkipSelf } from '@angular/core';
import { HeaderCommand, HeaderCommandComponentType, HeaderCommandType, isComponentCommand, isIconCommand, isTextCommand } from '../../../../../core/store/header/models/HeaderCommand';
import { HeaderCommandHandler } from './header-command-handler';

@Component({
  selector: 'wen-header-command',
  styleUrls: ['header-command.component.scss'],
  templateUrl: 'header-command.component.html',
  providers: [
    HeaderCommandHandler
  ]
})
export class HeaderCommandComponent implements OnChanges {

  @HostBinding('class.wen-header-command') className = true;
  @HostBinding('class.wen-header-command-text-command') get textCommandClass(): boolean {
    return this.isCommandWithoutIcon();
  }

  @Input() commandData: HeaderCommand;
  @Input() @HostBinding('class.wen-header-command-disabled') isCommandDisabled: boolean;

  commandComponent: ComponentPortal<HeaderCommandComponentType>;
  commandComponentRef: ComponentRef<HeaderCommandComponentType>;

  constructor(
    @SkipSelf() private readonly injector: Injector,
    private readonly headerCommandHandler: HeaderCommandHandler,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.commandData) {
      const currentValue: HeaderCommand = changes.commandData.currentValue;
      const previousValue: HeaderCommand = changes.commandData.previousValue;
      if (currentValue && currentValue !== previousValue) {
        this.commandComponent = null;
        if (isComponentCommand(currentValue)) {
          const componentType = this.injector.get(currentValue.componentToken);
          this.commandComponent = new ComponentPortal(componentType);
        }
      }
    }
    if(changes.isCommandDisabled && Boolean(this.commandComponentRef)) {
      this.commandComponentRef.instance.disabled = this.isCommandDisabled;
    }
  }

  onComponentAttached(componentRef: ComponentRef<HeaderCommandComponentType>) {
    this.commandComponentRef = componentRef;
    this.commandComponentRef.instance.disabled = this.isCommandDisabled;
  }

  commandClicked() {
    if (this.isCommandDisabled) {
      return;
    }
    this.headerCommandHandler.handleCommand(this.commandData);
  }

  getClassSuffix() {
    switch (this.commandData.type) {
      case HeaderCommandType.BACK_NAVIGATION:
      case HeaderCommandType.CANCEL_BACK_NAVIGATION:
      case HeaderCommandType.CLOSE_DIALOG:
      case HeaderCommandType.CRM_PAUSE_MASS_CHAT_SENDING:
        return 'back';
        case HeaderCommandType.SAVE_BACK_NAVIGATION:
        return 'save-back';
      case HeaderCommandType.OPEN_CHANNEL_DETAIL:
        return 'channel-detail';
      case HeaderCommandType.OPEN_ROOM_DETAIL:
        return 'chat-room-detail';
      case HeaderCommandType.FORWARD_IN_CREATE:
      case HeaderCommandType.FINISH_CREATE:
      case HeaderCommandType.CHAT_ADD_NEW:
      case HeaderCommandType.CHANNEL_ADD_WELCOME:
      case HeaderCommandType.APP_ADD_WELCOME:
      case HeaderCommandType.INVITE:
      case HeaderCommandType.CHAT_MENU:
        return 'create';
      case HeaderCommandType.OPEN_USER_PROFILE:
        return 'open-profile';
      case HeaderCommandType.BACK_TO_PROFILE:
        return 'back-to-profile';
      case HeaderCommandType.CLOSE_PROFILE_NAVIGATION:
        return 'close';
      case HeaderCommandType.FINISH_ADD_MEMBERS_TO_CHAT:
        return 'create';
      case HeaderCommandType.CRM_PROFILE_READ_ONLY_VIEW:
        return 'crm-profile-read-only-view';
      default:
        throw new Error(`Header command type is not supported`);
    }
  }

  getIcon() {
    if (isIconCommand(this.commandData)) {
      return this.commandData.icon;
    }
    return null;
  }

  getTitle() {
    if (isIconCommand(this.commandData) || isTextCommand(this.commandData)) {
      return this.commandData.title;
    }
    return null;
  }

  isCommandWithoutIcon() {
    return isTextCommand(this.commandData);
  }

}
