import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MarkdownTextModule, WenTestIdModule } from '@portal/wen-components';
import { EmbedsModule } from '../embeds/embeds-module';
import { MessageBoxContentComponent } from './message-box-content.component';

@NgModule({
  imports: [
    CommonModule,
    EmbedsModule,
    MarkdownTextModule,
    WenTestIdModule
  ],
  exports: [MessageBoxContentComponent],
  declarations: [MessageBoxContentComponent],
})
export class MessageBoxContentModule { }
