import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wen-quick-action-panel',
  templateUrl: './quick-action-panel.component.html',
  styleUrls: ['./quick-action-panel.component.scss']
})
export class QuickActionPanelComponent {
  @Input()
  public iconName: string;

  @Input()
  public iconColor = null;

  @Output() quickActionTriggered = new EventEmitter<string>();

  onClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.quickActionTriggered.emit();
  }
}
