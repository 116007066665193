import { ActionMenuConfig } from '@portal/wen-components';
import { CommentDeleteAction } from './comment-delete.action';
import { CommentQuoteAction } from './comment-quote.action';
import { CommentCopyAction } from './comment-copy.action';
import { WenComment } from '../types';

export const commentContextMenuId = 'comment-context-menu-id';

export const COMMENT_CONTEXT_MENU_ITEMS: ActionMenuConfig = {
  menuId: commentContextMenuId,
  menuItems: [CommentCopyAction, CommentQuoteAction, CommentDeleteAction]
};

export interface CommentVisibility {
  comment: WenComment;
  messageId: string;
}
