import { Directive } from '@angular/core';

@Directive({
  selector: 'card-image, [card-image]'
})
export class CardImageDirective { }

@Directive({
  selector: 'card-floating-panel, [card-floating-panel]'
})
export class CardIconDirective { }

@Directive({
  selector: 'card-title, [card-title]'
})
export class CardTitleDirective { }

@Directive({
  selector: 'card-description, [card-description]'
})
export class CardDescriptionDirective { }

@Directive({
  selector: 'card-indicator, [card-indicator]'
})
export class CardIndicatorDirective { }
