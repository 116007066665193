import { createAction, props } from '@ngrx/store';
import { MemberState, RoomMemberUpdateResponse } from '@portal/wen-backend-api';
import { ChatUserDTO } from '../chat.state';

export const promoteRoomMemberToAdmin = createAction(
  '[Chat] Promote chat member to admin for current chat room',
  props<{ userId: string }>()
);

export const removeAdminStatusFromRoomMember = createAction(
  '[Chat] Remove admin status from room member',
  props<{ userId: string }>()
);

export const removeMemberFromRoom = createAction(
  '[Chat] Remove member from room',
  props<{ userId: string }>()
);

export const updateMemberState = createAction(
  '[Chat] Update a member state',
  props<RoomMemberUpdateResponse>()
);
export const leaveRoom = createAction(
  '[Chat] Leave a room'
);

export const addMembersToRoom = createAction(
  '[Chat] Add members to room',
  props<{ userIds: string[] }>()
);

export const memberLeftRoom = createAction(
  '[Chat] Member has left a room',
  props<{ userId: string; roomId: string }>()
);

export const upsertOtherRoomMemberLeft = createAction(
  '[Chat] Other Member has left a room',
  props<{ userId: string; roomId: string }>()
);

export const joinToRoom = createAction(
  '[Chat] User joins to room',
  props<{ userId: string; roomId: string; avatarUrl: string; displayName: string; memberSince: string; memberState: MemberState }>()
);

export const upsertNewMemberJoined = createAction(
  '[Chat] New user joins to room',
  props<{ newUser: ChatUserDTO; roomId: string  }>()
);
