import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Filterable } from '../../../shared/components/filter-view/models/filter-item';
import { FilterId } from '../../common/types/filter-id';
import { WithId } from '../common/entity-state-helpers/state-adapter-utils';
import { FilterEntityType } from '../filter/models/filter';
import { LoadingState } from '../../common/types/store-loading-state';

export type FilterData = {
  filterRef: Partial<Filterable>;
  filterType: FilterEntityType;
  filterId?: FilterId;
};

export type SectionEntity<T = any[]> = {
  name?: string;
  content?: T;
} & WithId & Partial<FilterData>;

export interface SectionState {
  sectionEntities: EntityState<SectionEntity>;
  channelExploreSectionLoadingState: LoadingState;
  eventExploreSectionLoadingState: LoadingState;
  appExploreSectionLoadingState: LoadingState;
}

export const sectionAdapter: EntityAdapter<SectionEntity> = createEntityAdapter<SectionEntity>({
  selectId: (entity) => entity.id
});
