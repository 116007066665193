import { Component } from '@angular/core';
import { ColorPalette } from '../../../../../../../shared/directives/colorize/palette-provider';
import { UserProfileViewConfiguration } from '../../user-profile-view-configuration';
import { EntryId } from '../../user-profile-view-types';
import { WenNavigationHelper } from '../../../../../../../core/services/navigation/types';
import { AppNavigator } from '../../../../../../../core/services/navigation/app-navigator';
import { WenRouteId } from '../../../../../../../frame/routing/types';

@Component({
  selector: 'wen-setting-view',
  templateUrl: './setting-view.component.html',
  styleUrls: ['./setting-view.component.scss'],
  providers: [UserProfileViewConfiguration]
})
export class SettingViewComponent {
  protected readonly ColorPalette = ColorPalette;

  constructor(
    public config: UserProfileViewConfiguration,
    private navigationHelper: WenNavigationHelper,
    private appNavigator: AppNavigator,
    ) { }

  handleEntryClick(entryId: EntryId) {
    switch (entryId) {
      case EntryId.SELECT_LANGUAGE: {
        this.navigationHelper.navigateToUserProfileLanguage();
        return;
      }
      case EntryId.PRIVACY_SETTINGS: {
        this.appNavigator.navigateToRoute(WenRouteId.USERPROFILE_PRIVACY_SETTINGS);
        return;
      }
      default: {
        return;
      }
    }
  }
}
