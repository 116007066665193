import { inject, Injectable } from '@angular/core';
import { incrementingRetry } from '@portal/wen-common';
import { Tracer } from '@portal/wen-tracer';
import { deleteDB, IDBPDatabase, IDBPTransaction, openDB, StoreNames } from 'idb';
import { first, from, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { getCurrentDateTime } from '../../util/date-util';
import { asObservable } from '../util';
import { ChatCryptoDB, ObjectStores } from './indexed-db-schema';

export enum TransactionMode {
  READWRITE = 'readwrite',
  READONLY = 'readonly',
}

@Injectable()
export class OlmIndexedDb {

  private readonly DB_NAME = 'wen-chat-client:crypto';
  private readonly DB_VERSION = 2;

  private tracer = inject(Tracer);
  private database: IDBPDatabase<ChatCryptoDB>;

  init() {
    const openDb$ = from(openDB<ChatCryptoDB>(this.DB_NAME, this.DB_VERSION, {
      upgrade: (db, oldVersion, newVersion, transaction) => {
        this.doUpgrade(db, oldVersion, newVersion, transaction);
      }
    })).pipe(
      first(),
      shareReplay(1)
    );
    openDb$.subscribe((database) => this.database = database);
    return openDb$;
  }

  closeDb() {
    if (this.database) {
      this.database.close();
    }
  }

  clearDb() {
    if (this.database) {
      this.database.close();
    }
    return asObservable(deleteDB(this.DB_NAME));
  }

  openTransaction<T, S extends ArrayLike<StoreNames<ChatCryptoDB>>>(
    mode: TransactionMode,
    stores: S,
    operation: (txn: IDBPTransaction<ChatCryptoDB, S, TransactionMode>) => T
  ) {
    return of(null).pipe(
      switchMap(() => {
        return this.tryOpenTransaction(mode, stores);
      }),
      incrementingRetry(3, 200),
      switchMap(tx => {
        const result = operation(tx);
        let resultPromises = [];
        resultPromises = [
          result instanceof Promise ? result : Promise.resolve(result),
          tx.done
        ];
        return asObservable(Promise.all(resultPromises)).pipe(
          map(([resultValue, _]) => {
            return resultValue as Awaited<typeof result>;
          })
        );
      })
    );
  }

  private tryOpenTransaction<S extends ArrayLike<StoreNames<ChatCryptoDB>>>(
    mode: TransactionMode,
    stores: S,
  ): Observable<IDBPTransaction<ChatCryptoDB, S, TransactionMode>> {
    let tx: IDBPTransaction<ChatCryptoDB, S, TransactionMode>;
    try {
      tx = this.database.transaction(stores, mode);
    } catch (error) {
      return this.init().pipe(
        map(() => null),
        tap(() => {
          this.tracer.captureException(error);
          throw error;
        })
      );
    }
    return of(tx);
  }

  private async doUpgrade(
    database: IDBPDatabase<ChatCryptoDB>,
    oldVersion: number,
    newVersion: number | null,
    transaction: IDBPTransaction<ChatCryptoDB, StoreNames<ChatCryptoDB>[], 'versionchange'>
  ) {
    if (oldVersion < 1) {
      database.createObjectStore(ObjectStores.DEVICES);
      database.createObjectStore(ObjectStores.ACCOUNT);
      const sessionStore = database.createObjectStore(ObjectStores.SESSIONS, {
        keyPath: ['curve25519', 'sessionId'],
      });
      sessionStore.createIndex('byCurve25519', 'curve25519');
      database.createObjectStore(ObjectStores.INBOUND_GROUP_SESSIONS, {
        keyPath: ['senderCurve25519', 'sessionId'],
      });
    }
    if (oldVersion === 1 && newVersion === 2) {
      const store = transaction.objectStore(ObjectStores.SESSIONS);
      const currentSessions = await store.getAll();
      currentSessions.forEach(session => {
        session.lastActivityTimestamp = session.lastActivityTimestamp || getCurrentDateTime();
        store.put(session);
      });
    }
  }

}
