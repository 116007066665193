import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfirmationDialogModule } from '@portal/wen-components';
import { CRMClosingDialogComponent } from './crm-closing-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ConfirmationDialogModule,
  ],
  declarations: [
    CRMClosingDialogComponent,
  ],
  exports: [
    CRMClosingDialogComponent,
  ]
})
export class CRMClosingDialogModule { }
