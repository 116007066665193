import { Inject, Injectable, Injector } from '@angular/core';
import { ACTION_MENU_ITEMS, ActionMenuConfig, ActionMenuItem, ActionMenuItemCreator } from '@portal/wen-components';
import { Observable, combineLatest, distinctUntilChanged, map } from 'rxjs';

@Injectable()
export class ActionMenuItemProvider {

  constructor(
    private injector: Injector,
    @Inject(ACTION_MENU_ITEMS) private menuConfig: ActionMenuConfig[]
  ) { }

  selectActions<T, C, R>(menuId: string, contextData?: C): Observable<ActionMenuItem<T, C, R>[]> {
    const relevantActions = this.menuConfig.find(item => item.menuId === menuId)?.menuItems || [];
    const createdActions = ActionMenuItemCreator.createActions<T, C, R>(relevantActions, this.injector);

    const actionsWithVisibility$: Observable<{ action: ActionMenuItem<T, C, R>; isVisible: boolean }>[] = createdActions.map(
      action => action.isVisible$(contextData).pipe(
        distinctUntilChanged(),
        map(isVisible => ({ action, isVisible })),
      )
    );

    return combineLatest(actionsWithVisibility$).pipe(
      map(itemsWithVisibility => {
        return itemsWithVisibility.filter(({ isVisible }) => isVisible).map(({ action }) => action);
      })
    );
  }
}
