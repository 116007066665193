import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { startWith } from 'rxjs';
import { AppNavigator } from '../../../../../core/services/navigation/app-navigator';
import { fetchUserEventNextPage } from '../../../../../core/store/events/event.actions';
import { WenRouteId } from '../../../../../frame/routing/types';
import { EventListDataSource } from '../../providers/event-list-datasource';

@Component({
  selector: 'wen-user-event-list',
  templateUrl: './user-event-list.component.html',
  styleUrls: ['./user-event-list.component.scss'],
  providers: [EventListDataSource]
})
export class UserEventListComponent {

  loadResult$ = this.eventListDataSource.userEventLoadResult$.pipe(
    startWith(false)
  );

  constructor(
    private eventListDataSource: EventListDataSource,
    private appNavigator: AppNavigator,
    private store: Store
  ) { }

  navigateToEventDiscovery() {
    this.appNavigator.navigateToRoute(WenRouteId.EVENT_DISCOVERY);
  }

  endReached() {
    this.store.dispatch(fetchUserEventNextPage());
  }
}
