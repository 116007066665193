import { DraftMessageEntity } from '../../core/store/common/models';

export function checkDraftMessageState(draftMessage: DraftMessageEntity): boolean {
    return Boolean(draftMessage) &&
      (draftMessage?.content !== '' || Boolean(draftMessage?.fileEmbed) || Boolean(draftMessage?.linkEmbed));
  }

export const canSchedule = (draftMessage: DraftMessageEntity): boolean => {
  return !draftMessage?.timestamp || Boolean(draftMessage.scheduled);
};
