import { createFeatureSelector, createSelector } from '@ngrx/store';
import { eventViewIdentifier } from '../../../views/events/tokens';
import { LoadingState } from '../../common/types/store-loading-state';
import { RootState } from '../root/public-api';
import { selectRouteParam } from '../root/root.selectors';
import { eventsFeatureKey } from './constants';
import { eventAdapter, EventState, subscriptionAdapter } from './event.state';

export interface WithFeatureState extends RootState {
  [eventsFeatureKey]: EventState;
}

export const selectEventState = createFeatureSelector<EventState>(eventsFeatureKey);

export const selectEventEntityState = createSelector(
  selectEventState,
  state => state.events
);

export const {
  selectAll: selectAllEvents
} = eventAdapter.getSelectors(selectEventEntityState);

export const selectUserListPaging = createSelector(
  selectEventState,
  state => state?.userEventsPaging
);

export const selectDiscoveryListPaging = createSelector(
  selectEventState,
  state => state?.discoverEventsPaging
);

export const selectEvents = createSelector(
  selectAllEvents,
  events => events
);

const selectDiscoveryListOccurenceState = createSelector(
  selectEventState,
  state => state.discoveryListOccurences
);

export const selectDiscoveryListOccerences = createSelector(
  selectDiscoveryListOccurenceState,
  state => {
    return Object.entries(state.entities).map(([key, value]) => value);
  }
);

const selectUserListOccurenceState = createSelector(
  selectEventState,
  state => state.userListOccurences
);

export const selectUserListOccerences = createSelector(
  selectUserListOccurenceState,
  state => {
    return Object.entries(state.entities).map(([key, value]) => value);
  }
);

export const selectCurrentEvent = createSelector(
  selectEvents,
  selectRouteParam(eventViewIdentifier),
  (events, eventId) => {
    return events.find(ev => ev.id === eventId);
  }
);

export const selectSubscriptionEntityState = createSelector(
  selectEventState,
  state => state.subscriptions
);

export const {
  selectAll: selectAllSubscriptions
} = subscriptionAdapter.getSelectors(selectSubscriptionEntityState);

export const selectSubscriptions = createSelector(
  selectAllSubscriptions,
  subscriptions => subscriptions
);

export const selectCurrentSubscription = createSelector(
  selectSubscriptions,
  selectRouteParam(eventViewIdentifier),
  (subscriptions, eventId) => {
    return subscriptions.find(sub => sub.id === eventId);
  }
);

export const selectSubscriptionForEvent = createSelector(
  selectSubscriptions,
  (subscriptions) => (eventId: string) => subscriptions.find(sub => sub.id === eventId)
);

export const selectUserEventsIsLoaded = createSelector(
  selectEventState,
  (state) => state.userEventsLoading !== LoadingState.NOT_LOADED
);

export const selectDiscoveryEventsIsLoaded = createSelector(
  selectEventState,
  (state) => state.discoverEventsLoading !== LoadingState.NOT_LOADED
);

export const selectEventCategories = createSelector(
  selectEventState,
  state => state.categories
);
