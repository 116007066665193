<form (keydown.enter)="onEnter($event)"
  [formGroup]="messageSendFormGroup"
  [wen-test-id]="'wen-message-send-field'"
  wenFocusFirstInput
  [ngClass]="{ 'wen-hide-top-border': hideTopBorder }">
  <ng-content select="wen-message-type-selector, [wen-message-type-selector]"></ng-content>
  <div class="wen-message-send-field-wrapper">
    <mat-form-field class="wen-form-field-white" floatLabel="never">
      <textarea
        #sendInput
        [formControlName]="'sendInput'"
        portal-body-1
        matInput
        autocomplete="off"
        [placeholder]="inputPlaceholder"
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5"
        (focus)="onFocused($event)"
        wenVkInput [wenVkInputKeepFocused]="keepInputFocused"
        (paste)="onPaste($event)"
        [readonly]="readonly"
        [wen-test-id]="'message-send-input'"
        data-cy="wen-message-send-field-input"
      ></textarea>
    </mat-form-field>
  </div>
  <div class="wen-message-send-wrapper">
    <ng-content select="wen-message-left-button-action, [wen-message-left-button-action]"></ng-content>
    <wen-button
      #saveButton
      class="wen-message-send-button"
      [wen-test-id] = "'message-send-button'"
      [class.wen-message-send-field-is-valid]="isSendFieldValid$ | async"
      wenVariant="iconPrimary"
      iconSize="big"
      iconName="send"
      iconColor="white"
      (click)="onClick()"
    ></wen-button>
    <ng-content select="wen-message-right-button-action, [wen-message-right-button-action]"></ng-content>
  </div>
</form>
<ng-content select="wen-message-bottom-action, [wen-message-bottom-action]"></ng-content>
