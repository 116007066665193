import { Directive, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';

@Directive({ selector: '[observeVisibility]' })
export class ObserveVisibilityDirective implements OnDestroy, OnChanges {

  private observer: IntersectionObserver | undefined;

  @Input() rootElement: HTMLElement;

  @Output() visible = new EventEmitter<void>();
  @Output() notVisible = new EventEmitter<void>();

  @Input() isActive: boolean;

  constructor(
    private hostElement: ElementRef<HTMLElement>,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    const isActive = changes.isActive;
    if (!isActive?.previousValue && isActive?.currentValue && !Boolean(this.observer)) {
      const options = { root: this.rootElement, rootMargin: '0px', };
      const rootHeight = options.root?.clientHeight ?? window.innerHeight;
      const threshold = this.calculateThreshold(rootHeight, this.hostElement.nativeElement.clientHeight);
      this.createIntersectionObserver(threshold, options);
      this.startObservingElement();
    }
  }

  private createIntersectionObserver(threshold: number, intersectionOptions: IntersectionObserverInit) {
    this.observer = new IntersectionObserver(([entry]) => {
      if (!this.isActive) {
        return;
      }
      if (entry.isIntersecting) {
        this.visible.emit();
      } else {
        this.notVisible.emit();
      }
    }, { ...intersectionOptions, threshold });
  }

  private startObservingElement() {
    if (!this.observer) {
      return;
    }
    this.observer.observe(this.hostElement.nativeElement);
  }

  private calculateThreshold(viewHeight: number, messageHeight: number): number {
    const viewPortThreshold = viewHeight * 0.3;
    const ratio = viewPortThreshold / messageHeight;
    const clampedRatio = Math.min(Math.max(ratio, 0.1), 0.7);
    const roundedRatio = Math.floor(clampedRatio * 10) / 10;
    return roundedRatio;
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = undefined;
    }
  }
}
