import { Inject, Optional, Pipe, PipeTransform } from '@angular/core';
import { IMAGE_PROXY_BASE_URL } from '../../frame/api/tokens';

@Pipe({
  name: 'toAvatarUrl'
})

export class AvatarUrlPipe implements PipeTransform {
  private defaulUrl = '/assets/wen-widget/icons/anonymous-profile.svg';

  private baseUrl: string;
  constructor(@Optional() @Inject(IMAGE_PROXY_BASE_URL) private imageProxyBaseUrl) {
    this.baseUrl = this.imageProxyBaseUrl ?? location.origin;
  }

  transform(userId: string): string {
    return userId === 'IdentityHider' ? this.defaulUrl : `${this.baseUrl}/redirect/avatar/${userId}/{size}`;
  }
}
