import { AfterViewInit, Component, forwardRef, HostBinding, Input, Self, ViewChild } from '@angular/core';
import { FormControlName, FormGroupDirective, NgControl } from '@angular/forms';
import { PortalImageUploadComponent } from '@portal/ui-kit/image-upload';
import { ImageUrlTransformator, OverlayManager } from '@portal/wen-components';
import { from } from 'rxjs';
import { CAMERA_PERMISSION } from '@portal/wen-native-api';
import { WenNativeApi } from '@portal/wen-native-api';

const ACCEPTED_CROP_EXTENSIONS: string[] = ['png', 'jpg', 'jpeg'];

@Component({
  selector: 'wen-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  viewProviders: [
    forwardRef(() => PortalImageUploadComponent)
  ]
})
export class ImageUploadComponent implements AfterViewInit {

  @ViewChild(PortalImageUploadComponent) portalImageUpload: PortalImageUploadComponent;
  @Input() fallbackImage = 'camera';
  @Input() fallbackText: string;
  @Input() imageShape: 'circle' | 'square' = 'circle';
  @HostBinding('class.wen-image-upload-preview-image-circle') get className() {
    return this.imageShape === 'circle';
  }
  acceptedExtensions: string[] = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'svg'];
  allowedImageSize = 20;

  constructor(
    @Self() private ngControl: NgControl,
    @Self() private formControlName: FormControlName,
    private controlContainer: FormGroupDirective,
    private wenNativeAPI: WenNativeApi,
    private overlayManager: OverlayManager,
    private imageUrlTransformator: ImageUrlTransformator,
    tempPortalImageUpload: PortalImageUploadComponent
  ) {
    this.ngControl.valueAccessor = tempPortalImageUpload;
  }

  ngAfterViewInit() {
    this.setupProxyControl();
  }

  private setupProxyControl() {
    this.controlContainer.removeControl(this.formControlName);
    this.ngControl.valueAccessor = this.portalImageUpload;
    const imagePreviewControl = this.controlContainer.addControl(this.formControlName);

    this.downloadPreviewImageInProperSize(imagePreviewControl);
  }

  private downloadPreviewImageInProperSize(imagePreviewControl) {
    imagePreviewControl.registerOnChange((file: File | string) => {
      if (typeof file !== 'string') {
        return;
      }
      const urlWithProperSize = this.imageUrlTransformator.transform(file, { height: 128 });
      if (imagePreviewControl.value !== urlWithProperSize) {
        imagePreviewControl.patchValue(urlWithProperSize);
      }
    });
  }

  allowCroppingFn(file: File | string) {
    if (typeof file === 'string') {
      return false;
    }
    const fileName = file.name;
    const ext = fileName.toLowerCase().split('.').slice(-1)[0];
    return ACCEPTED_CROP_EXTENSIONS.includes(ext);
  }

  onSelectorClicked(event: Event) {
    if (this.wenNativeAPI.isIOS() || !this.wenNativeAPI.isReactNativeApp()) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    from(this.wenNativeAPI.requestMultiplePermissions(CAMERA_PERMISSION)).subscribe(() => {
      this.portalImageUpload.imageInputClick(this.portalImageUpload.imageInput.nativeElement);
    });
  }

  handleError(hasError: boolean) {
    if (hasError) {
      // generic error handling should be replaced once differentiation is possible
      this.overlayManager.dialog.openErrorDialog(
        {
          errorMessageLabel: 'ERROR_MEDIA_UPLOAD_GENERIC',
          messageParams: {
            maxFileSize: this.allowedImageSize.toString(),
            acceptedExtensions: this.acceptedExtensions.join(', ')
          }
        },
        'ERROR_MEDIA_UPLOAD_TITLE'
      );
    }
  }
}
