import { Injectable } from '@angular/core';
import { filter, fromEvent, map, Observable } from 'rxjs';

export enum PopupFlowEvent {
  LOGGED_OUT = 'LOGGED_OUT',
  CONTINUE = 'CONTINUE'
}

@Injectable()
export class PopupEventHandler {

  listenToMessageEvents(): Observable<MessageEvent> {
    return fromEvent<MessageEvent>(window, 'message').pipe(
      filter(event => event.isTrusted && event.origin === location.origin)
    );
  }

  listenToPopupEvents(...eventTypes: PopupFlowEvent[]): Observable<PopupFlowEvent> {
    if (eventTypes.length === 0) {
      eventTypes = Object.values(PopupFlowEvent);
    }

    return this.listenToMessageEvents().pipe(
      filter(event => eventTypes.includes(event.data)),
      map(event => event.data as PopupFlowEvent)
    );
  }

  postAuthTokens() {
    (window.opener || window.parent)?.postMessage(location.hash || ('#' + location.search), location.origin);
  }

  postPopupEvent(event: PopupFlowEvent) {
    (window.opener || window.parent)?.postMessage(event, location.origin);
  }
}
