<mat-form-field class="wen-chat-scheduled-date-picker-wrapper">
  <input class="wen-chat-scheduled-input" #scheduleInput matInput [ngxMatDatetimePicker]="datePicker"
    [formControl]="datePickerControl" [min]="minDate" readonly />
  <mat-datepicker-toggle matSuffix [for]="datePicker" class="wen-date-picker-toggle"
    (mousedown)="updateMinDateAndTime()" [disableRipple]="true">
    <mat-icon class="wen-svg-icon" matDatepickerToggleIcon
      [svgIcon]="hasDateAdded ? 'schedules_date_added_calendar' : 'schedules_add_date_calendar'"></mat-icon>
  </mat-datepicker-toggle>
  <ngx-mat-datetime-picker [defaultTime]="defaultTime" [touchUi]="isMobileView" (opened)="onPickerOpen(datePicker)"
    #datePicker>
    <ngx-mat-datepicker-actions>
      <wen-button [style.visibility]="(isEdit$ | async) ? 'hidden' : 'visible'" class="wen-button-rounded" wenVariant="primaryBackground"
        ngxMatDatepickerCancel (click)="handleDateCleared()">
        {{ "CHAT_SCHEDULED_DATEPICKER_CLEAR" | translate }}
      </wen-button>
      <wen-button class="wen-button-rounded" wenVariant="primary" ngxMatDatepickerApply
        [isDisabled]="isSaveDisabled$ | async">
        {{ "SCHEDULED_DATEPICKER_APPLY" | translate }}
      </wen-button>
    </ngx-mat-datepicker-actions>
  </ngx-mat-datetime-picker>
</mat-form-field>