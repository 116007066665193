import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent, OverlayManager } from '@portal/wen-components';
import { first, Observable, of, switchMap } from 'rxjs';
import { acknowledgeDeletedApp } from '../../../../../../core/store/apps/apps.actions';
import { AppEntity } from '../../../../../../core/store/apps/apps.state';
import { RootState } from '../../../../../../core/store/root/public-api';

@Injectable()
export class DeletedAppHelper {

  constructor(
    private store: Store<RootState>,
    private overlayManager: OverlayManager,
    private translateService: TranslateService,
  ) { }

  isDeleted(app: AppEntity): Observable<boolean> {
    if (!app) {
      return of(true);
    }
    if (!app.deleted) {
      return of(false);
    }

    const content = this.translateService.instant('DELETED_APP_INFO_DIALOG_CONTENT');
    const dialog = this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, {
      header: this.translateService.instant('DELETED_APP_INFO_DIALOG_TITLE'),
      content,
      okLabel: this.translateService.instant('EVERYTHING_IS_CLEAR_BUTTON_LABEL'),
    }, { disableClose: true });
    return dialog.afterClosed().pipe(
      first(),
      switchMap(() => {
        this.store.dispatch(acknowledgeDeletedApp({ appId: app.id }));
        return of(true);
      }));
  }
}
