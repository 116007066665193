import { WenRouteId, WenRoutes } from '../../../frame/routing/types';
import { ExclusiveInviteComponent } from '../components/exclusive-invite/exclusive-invite.component';
import { exclusiveInviteGuardFn } from '../guards/exclusive-invite.guard';

export const exclusiveInviteRoutes: WenRoutes = [
  {
    path: 'code',
    component: ExclusiveInviteComponent,
    canActivate: [exclusiveInviteGuardFn],
    data: {
      routeId: WenRouteId.EXCLUSIVE_INVITE
    }
  },
  {
    path: '**',
    redirectTo: 'code'
  }
];
