import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ChannelUpdatePayload } from '@portal/wen-backend-api';
import { SelectionOption } from '@portal/wen-components';
import { of } from 'rxjs';
import { isNullOrUndefined } from '../../../../../../core/common/operators/null-check-util';
import { RootState } from '../../../../../../core/store/root/public-api';
import { FormValueConverter } from '../../../../../../shared/form-store/form-store.providers';

interface ChannelVisibilitySettingsFormModel {
  id: string;
  visibility: SelectionOption<boolean>[];
  networkAccess: SelectionOption<boolean>[];
}

@Injectable()
export class ChannelVisibilitySettingFormValueConverter extends
  FormValueConverter<ChannelVisibilitySettingsFormModel, Partial<ChannelUpdatePayload>> {

  public readonly visibilityPossibleValues: SelectionOption<boolean>[];
  public readonly netWorkAccessPossibleValues: SelectionOption<boolean>[];

  constructor(
    readonly translateService: TranslateService,
    readonly store: Store<RootState>,
  ) {
    super();
    this.visibilityPossibleValues = [
      { id: '1', text: translateService.instant('PUBLIC_CHANNEL_SETTING_LABEL'), value: true },
      { id: '2', text: translateService.instant('PRIVATE_CHANNEL_SETTING_LABEL'), value: false },
    ];

    this.netWorkAccessPossibleValues = [
      { id: '1', text: translateService.instant('CHANNEL_NETWORK_ACCESS_THIS_NETWORK'), value: false },
      { id: '2', text: translateService.instant('CHANNEL_NETWORK_ACCESS_ALL_NETWORKS'), value: true },
    ];
  }

  convertToServerModel(formValue: ChannelVisibilitySettingsFormModel) {
    let result: Partial<ChannelUpdatePayload> = {};
    const { visibility, networkAccess } = formValue;
    if (visibility && visibility[0]) {
      result = { ...result, discoverable: formValue.visibility[0].value };
    }
    if (!isNullOrUndefined(networkAccess)) {
      result = { ...result, availableForOthers: formValue.networkAccess[0].value };
    }
    result = { ...result };
    return of(result);
  }

  convertToFormModel(channel: ChannelUpdatePayload) {
    const visibility = this.visibilityPossibleValues.filter(value => value.value === Boolean(channel.discoverable));
    const networkAccess = this.netWorkAccessPossibleValues.filter(value => value.value === Boolean(channel.availableForOthers));

    return of({ id: channel.id, visibility, networkAccess });
  }

}
