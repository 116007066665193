import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, distinctUntilChanged, filter, shareReplay, switchMap, takeUntil } from 'rxjs';
import { selectorWithParam } from '../../../../core/common/util/selector-with-param';
import { actualizeDraftChatRoom, removeDraftChatMessage, updateChatDraftMessage } from '../../../../core/store/chat/chat.actions';
import { selectDraftChatMessageById } from '../../../../core/store/chat/chat.selectors';
import { DraftMessageEntity } from '../../../../core/store/common/models';
import { RootState } from '../../../../core/store/root/public-api';
import { selectRouteParam } from '../../../../core/store/root/root.selectors';
import { MessageSendFieldDataSource } from '../../../../shared/components/message-send-field/providers/message-send-field-data-source';
import { CHAT_MESSAGE_TYPE_SELECTOR_MENU_ID } from '../../../../shared/components/message-type-selector/constants/type-selector-ids';
import { chatViewIdentifier } from '../../tokens';

@Injectable()
export class ChatMessageSendFieldDataSource extends MessageSendFieldDataSource implements OnDestroy {

  private roomId$ = new BehaviorSubject<string>(null);
  private onDestroy$ = new Subject<void>();

  messageTypeSelectorId = CHAT_MESSAGE_TYPE_SELECTOR_MENU_ID;

  draftMessage$: Observable<DraftMessageEntity> = this.roomId$.pipe(
    distinctUntilChanged(),
    switchMap((roomId) => this.store.pipe(
      filter(() => Boolean(roomId)),
      selectorWithParam(selectDraftChatMessageById, roomId),
    )),
    shareReplay(1),
    takeUntil(this.onDestroy$),
  );

  contextId$ = this.store.pipe(
    select(selectRouteParam(chatViewIdentifier)),
    distinctUntilChanged(),
    shareReplay(1)
  );

  constructor(
    private store: Store<RootState>
  ) {
    super();
  }

  initialize(roomId: string) {
    this.roomId$.next(roomId);
  }

  updateDraftMessage(message: Omit<DraftMessageEntity, 'contextId'>) {
    this.store.dispatch(updateChatDraftMessage({ message: { ...message, contextId: this.roomId$.getValue() } }));
  }

  discardDraftMessage() {
    this.store.dispatch(removeDraftChatMessage({ roomId: this.roomId$.getValue() }));
  }

  sendMessage() {
    this.store.dispatch(actualizeDraftChatRoom({ roomId: this.roomId$.getValue() }));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
