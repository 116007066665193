import { EditMessageEventDTO } from '../send-to-room/modify-message';

export type MessageRelations = {
  [RelationType.EDIT]: EditMessageEventDTO & RelatesTo<EditRelation>;
};

export enum RelationType {
  EDIT = 'replace'
}

type MappedRelation<T extends RelationType = RelationType> = {
  [K in T]: {
    eventId: string;
  };
};

/** To Differentiate the different kind of relations */
interface Relation<T extends RelationType> {
  type: T;
}

export type RelatesTo<T extends RelationTypes = RelationTypes> = {
  relatesTo: T;
};

export type EditRelation = Relation<RelationType.EDIT> & MappedRelation<RelationType.EDIT>;

/** Extend this type with other relations later on */
export type RelationTypes = EditRelation;

export const isEditRelation = (relation: RelationTypes): relation is EditRelation => {
  return relation?.type === RelationType.EDIT;
};
