import { createAction, props } from '@ngrx/store';

export const subscribeToContactsUpdates = createAction(
  '[Contacts] Subscribe to contacts updates',
);

export const updateContactsOpenRequestCount = createAction(
  '[Contacts] Update the open request count for contacts',
  props<{ openRequestCount: number }>()
);
