import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DownloaderService, LightboxImageData, WenSnackbarOpener } from '@portal/wen-components';
import { map, Observable } from 'rxjs';

@Injectable()
export class BrowserFileDownloader extends DownloaderService {

  constructor(private http: HttpClient,
    snackBar: WenSnackbarOpener,
    translateService: TranslateService,
  ) {
    super(snackBar, translateService);
  }

  canDownload(mediaData: LightboxImageData): boolean {
    return true;
  }

  downloadFileImplementation(url: string, mimeType: string, fileType: string = 'document', fileName?: string): Observable<boolean> {
    const downloadName = fileName ?? this.getFileNameFrom(url);
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((blob) => {
        const downloadUrl = URL.createObjectURL(blob);
        const fakeDownloadLink = document.createElement('a');
        document.body.appendChild(fakeDownloadLink);

        fakeDownloadLink.setAttribute('style', 'display: none');
        fakeDownloadLink.setAttribute('href', downloadUrl);
        fakeDownloadLink.setAttribute('download', downloadName);

        fakeDownloadLink.click();
        fakeDownloadLink.remove();

        URL.revokeObjectURL(downloadUrl);
        return true;
      })
    );
  }

}
