import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { WenSelectionListModule } from '../../wen-selection-list/wen-selection-list.module';
import { ReadOnlyPollComponent } from './components/poll-representation/components/read-only-poll/read-only-poll.component';
import { StatusBarComponent } from './components/poll-representation/components/status-bar/status-bar.component';
import { PollRepresentationComponent } from './components/poll-representation/poll-representation.component';

@NgModule({
    declarations: [
      PollRepresentationComponent,
      StatusBarComponent,
      ReadOnlyPollComponent
    ],
    imports: [
      WenSelectionListModule,
      CommonModule,
      TranslateModule,
      MatCheckboxModule
    ],
    exports: [PollRepresentationComponent]
})
export class PollRepresentationModule { }
