import { inject, Injectable } from '@angular/core';
import { ChatMessageComposerExtras, ChatMessagePreprocessorResult, EncryptionType, isEditRelation, PlainEditMessageEventDTO, PlainMessageEventResponses, PlainSendMessageEventDTO, SendToRoomEventPayload, ToRoomEventType } from '@portal/wen-backend-api';
import { ChatMessageBatchLoader, ChatMessageComposerTransactionFactory } from '@portal/wen-chat-client';
import { of } from 'rxjs';
import { WenOAuthService } from '../../user-management/wen-oauth.service';

@Injectable()
export class PlainChatMessageSender {

  private transactionFactory = inject(ChatMessageComposerTransactionFactory);
  private oAuthService = inject(WenOAuthService);

  sendMessages(itemLoader: ChatMessageBatchLoader) {
    const extras: ChatMessageComposerExtras = {
      prepareMessage: (rawPayload) => {
        const { roomId, roomMessage: { message }, relation } = rawPayload;
        const senderUserId = this.oAuthService.getUserData().userId;

        let payload: PlainMessageEventResponses;
        if (isEditRelation(relation)) {
          payload = {
            roomId,
            eventType: ToRoomEventType.EDIT_MESSAGE,
            senderUserId,
            content: { message },
            type: EncryptionType.PLAIN,
            relatesTo: relation
          } as PlainEditMessageEventDTO;
        } else {
          payload = {
            roomId,
            eventType: ToRoomEventType.SEND_MESSAGE,
            senderUserId,
            content: { message },
            type: EncryptionType.PLAIN,
          } as PlainSendMessageEventDTO;
        }
        return of({
          getPayload() {
            return { roomId, payload } as SendToRoomEventPayload;
          },
        } as ChatMessagePreprocessorResult);
      },
      enableEncryption: false
    };
    return this.transactionFactory.create(itemLoader, extras);
  }

}
