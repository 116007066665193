import { EntityState } from '@ngrx/entity';
import { AppEntity } from '../apps.state';

export const findAppById = (
  appId: string,
  apps: EntityState<AppEntity>
) => {
  const channelById = apps.entities[appId];
  return channelById;
};
