import { EmbeddedLinkDTO } from '../embed/embed';

export interface TextViewDTO {
  articleId: string;
  articleTitle: string;
  magazineTitle: string;
  ebinr: string;
  issueNumber: number;
  issueYear: number;
  pageNumber: number;
  docId: string;
  contentText: string;
  readTimeInMin: number;
  textToSpeechSrc?: string;
  articleForFree: boolean;
}

export enum ReadingError {
  NOT_AUTHORIZED_FOR_CONTENT = 'NOT_AUTHORIZED_FOR_CONTENT',
  INVALID_RESPONSE_RECEIVED = 'INVALID_RESPONSE_RECEIVED',
  NO_RESPONSE_RECEIVED = 'NO_RESPONSE_RECEIVED',
  CONTENT_NOT_FOUND = 'CONTENT_NOT_FOUND',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export interface ReadingTextViewPayload {
  articleId: string;
}

export interface ReadingTextViewResponse {
  ok: boolean;
  errorCode: ReadingError;
  textView: TextViewDTO;
}

export interface ReadingTextViewPreviewPayload {
  requestId?: number;
  content: string;
}

export interface ReadingTextViewPreviewResponse {
  requestId: number;
  embed: EmbeddedLinkDTO;
}
