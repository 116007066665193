import { Injectable } from '@angular/core';
import { InviteHandler } from './types';
import { WenNavigationHelper } from '../../../core/services/navigation/types';

@Injectable()
export class BrowserInviteHandler implements InviteHandler {

  constructor(
    private readonly navigationHelper: WenNavigationHelper
  ) { }

  initInviteFlow() {
    this.navigationHelper.navigateToInviteToNetwork();
  }
}
