import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, animationFrameScheduler, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, observeOn, pluck, shareReplay, takeUntil } from 'rxjs/operators';
import { mapAllBool } from '../../../../../core/common/operators/map-all-bool';
import { selectCurrentHeaderData, selectSearchbarActive } from '../../../../../core/store/header/header.selectors';
import { BaseHeaderCommand, HeaderCommand } from '../../../../../core/store/header/models/HeaderCommand';
import { HeaderData, SearchConfig } from '../../../../../core/store/header/models/HeaderData';
import { RootState } from '../../../../../core/store/root/public-api';

@Component({
  selector: 'wen-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
  animations: [
    trigger(
      'headerTopFlyoutAnimation', [
      state('true', style({ height: '8px', visibility: 'hidden' })),
      state('false', style({ height: '54px', visibility: 'visible' })),
      transition('true <=> false', [animate('100ms')])
    ])
  ]
})
export class MobileHeaderComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  @HostBinding('class.wen-mobile-header') className = true;

  private isSearchBarActive = new BehaviorSubject(false);
  searchBarAnimationTrigger$: Observable<boolean>;

  showLogo$: Observable<boolean>;
  title$: Observable<string>;
  subTitle$: Observable<string>;
  logoIcon$: Observable<string>;
  titleIcon$: Observable<string>;
  prefixTitleIcon$: Observable<string>;
  searchConfig$: Observable<SearchConfig>;
  leftCommand$: Observable<HeaderCommand>;
  rightCommand$: Observable<HeaderCommand>;
  centerCommand$: Observable<BaseHeaderCommand>;
  isTransparent: boolean;
  isFloating: boolean;

  constructor(
    private readonly store: Store<RootState>,
  ) { }

  ngOnInit(): void {
    this.title$ = this.mapHeaderDataProperty('title');
    this.subTitle$ = this.mapHeaderDataProperty('subTitle');
    this.titleIcon$ = this.mapHeaderDataProperty('titleIcon');
    this.prefixTitleIcon$ = this.mapHeaderDataProperty('prefixTitleIcon');
    this.showLogo$ = this.mapHeaderDataProperty('showLogo').pipe(
      distinctUntilChanged()
    );
    this.searchConfig$ = this.mapHeaderDataProperty('searchConfig');
    this.leftCommand$ = this.mapHeaderDataProperty('leftCommand');
    this.rightCommand$ = this.mapHeaderDataProperty('rightCommand');
    this.centerCommand$ = this.mapHeaderDataProperty('centerCommand');
    this.logoIcon$ = this.mapHeaderDataProperty('logoIcon');
    const enableAnimations$ = this.searchConfig$.pipe(
      map((searchConfig) => {
        return searchConfig && !searchConfig?.disableAnimations;
      })
    );
    this.searchBarAnimationTrigger$ = combineLatest([
      this.isSearchBarActive.pipe(observeOn(animationFrameScheduler)),
      enableAnimations$
    ]).pipe(
      mapAllBool()
    );

    const currentHeaderData$ = this.store.pipe(
      select(selectCurrentHeaderData),
      shareReplay(1),
      takeUntil(this.onDestroy$)
    );

    currentHeaderData$.pipe(
      map(headerData => headerData?.floating),
      takeUntil(this.onDestroy$)
    ).subscribe(isFloating => this.isFloating = isFloating);

    currentHeaderData$.pipe(
      map(headerData => headerData?.transparent),
      takeUntil(this.onDestroy$)
    ).subscribe(isTransparent => this.isTransparent = isTransparent);

    combineLatest([
      this.store.pipe(
        select(selectSearchbarActive),
        takeUntil(this.onDestroy$)
      ),
      currentHeaderData$
    ]).subscribe(([isActive, headerData]) => {
      if (isActive && Boolean(headerData.searchConfig) && !headerData.searchConfig.hideSearchBar) {
        this.onSearchActivated();
      } else {
        this.onSearchDeactivated();
      }
    });
  }

  onSearchActivated() {
    this.isSearchBarActive.next(true);
  }

  onSearchDeactivated() {
    this.isSearchBarActive.next(false);
  }

  private mapHeaderDataProperty<K extends keyof HeaderData>(key: K) {
    return this.store.pipe(
      select(selectCurrentHeaderData),
      pluck(key)
    );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
