export enum WenUserRole {
  ADMINISTRATOR = 'ADMINISTRATOR',
  MANAGED_ADMINISTRATOR = 'MANAGED_ADMINISTRATOR',
  CONTENT_PROVIDER = 'CONTENT_PROVIDER'
}

export const toUserRole = (roleId: string) => {
  if (!roleId) {
    return null;
  }
  const lowerCaseRoleId = roleId.toLowerCase();
  switch (lowerCaseRoleId) {
    case 'af0f4582-80b7-4231-acbb-35f55a8f2f90':
      return WenUserRole.CONTENT_PROVIDER;
    case '192fc7aa-3808-4cbc-a179-2fb604c62568':
      return WenUserRole.ADMINISTRATOR;
    case '4f7ffa10-3e8f-4e02-a0d0-fe5f879a2a8e':
      return WenUserRole.MANAGED_ADMINISTRATOR;
    default:
      return null;
  }
};
