import { Component, Input } from '@angular/core';

@Component({
  selector: 'wen-expandable-container',
  templateUrl: './expandable-container.component.html',
  styleUrls: ['./expandable-container.component.scss']
})
export class ExpandableContainerComponent {

  @Input() maxHeight: number;

  constructor() { }

}
