<div class="wen-help-container" *ngIf="config.helpViewEntries$ | async; let helpViewEntries">
  <ng-container *ngIf="helpViewEntries.length > 0">
    <wen-menu-entry *ngFor="let helpViewEntry of helpViewEntries" class="wen-user-view-profile-entry-primary" (click)="handleEntryClick(helpViewEntry.entryId)" [attr.entry-id]="helpViewEntry.entryId">
      <wen-icon class="wen-icon-medium" [fillColor]="helpViewEntry.fillColor" color="white" [iconName]="helpViewEntry.iconName" menuEntryIcon>
      </wen-icon>
      <span portal-body-1 menuEntryTitle>{{helpViewEntry.translationKey | translate}}</span>
    </wen-menu-entry>
  </ng-container>
</div>
<div class="wen-help-container" *ngIf="config.helpRightsEntries$ | async; let helpRightsEntries">
  <ng-container *ngIf="helpRightsEntries.length > 0">
    <wen-menu-entry *ngFor="let helpRightEntry of helpRightsEntries" class="wen-user-view-profile-entry-primary" (click)="handleEntryClick(helpRightEntry.entryId)" [attr.entry-id]="helpRightEntry.entryId">
      <wen-icon class="wen-icon-medium" [fillColor]="helpRightEntry.fillColor" color="white" [iconName]="helpRightEntry.iconName" menuEntryIcon>
      </wen-icon>
      <span portal-body-1 menuEntryTitle>{{helpRightEntry.translationKey | translate}}</span>
    </wen-menu-entry>
  </ng-container>
</div>
