import { animate, state, style, transition, trigger } from '@angular/animations';

export const highlightBackgroundAnimation = (highlightColor) => {
  return trigger('highlight', [
    state(
      'false',
      style({})
    ),
    state(
      'true',
      style({
        'background-color': highlightColor
      })
    ),
    transition('false <=> true', [animate('500ms linear')]),
    transition('true <=> false', [animate('500ms linear')]),
  ]);
};
