import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, filter, first, map, Observable } from 'rxjs';
import { selectIsChatInitialized } from '../../../core/store/chat/chat.selectors';
import { RootState } from '../../../core/store/root/public-api';
import { ForbiddenForAnonymousUserDialogService } from '../../../shared/services/forbidden-for-anonymous-user-dialog.service';

export const chatRootGuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(ChatRootGuard).canActivateChild(route, state);
};

@Injectable()
export class ChatRootGuard  {

  constructor(
    private store: Store<RootState>,
    private readonly anonymousDialogService: ForbiddenForAnonymousUserDialogService
  ) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([
      this.anonymousDialogService.openChatCreationForbiddenWhenAnonymous(),
      this.store.pipe(select(selectIsChatInitialized))
    ]).pipe(
      map(([result, isChatInitialized]) => !Boolean(result) && isChatInitialized),
      filter(result => result),
      first(),
    );
  }
}
