
import { Injectable } from '@angular/core';

@Injectable()
export class SliderHelper {

  calculateMatchingValueFor(newIndex: number, rangeValues?: number[]): number {
    if (!rangeValues.length) {
      return null;
    }
    return rangeValues[newIndex];
  }

  calculateMatchingIndexFor(newRadius: number, rangeValues?: number[]): number {
    if (!rangeValues?.length) {
      return 0;
    }

    const maxRangeValue = [...rangeValues].pop();
    if (newRadius > maxRangeValue) {
      return rangeValues.length - 1;
    } else {
      return this.findClosest(newRadius, rangeValues);
    }
  }

  private findClosest(newRadius: number, rangeValues: number[]): number {
    let minDiff = Number.MAX_SAFE_INTEGER;

    return rangeValues.reduce((closestIndex, currentRangeValue, currentIndex) => {
      const diff = Math.abs(newRadius - currentRangeValue);
      if (diff < minDiff) {
        minDiff = diff;
        closestIndex = currentIndex;
      }
      return closestIndex;
    }, 0);
  }
}
