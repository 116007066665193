import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormProgressModule } from '../../../shared/components/form-progress/form-progress.module';
import { RoundedButtonModule } from '../../../shared/components/rounded-button/rounded-button.module';
import { SharedModule } from '../../../shared/shared.module';
import { DistributeChatComponent } from './components/distribute-chat/distribute-chat.component';

@NgModule({
  imports: [
    CommonModule,
    FormProgressModule,
    RoundedButtonModule,
    SharedModule,
  ],
  declarations: [
    DistributeChatComponent,
  ],
  exports: [
    DistributeChatComponent,
  ],
})
export class DistributeChatModule { }
