import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChannelInviteState, InvitedToChannelDTO, RestrictionDTO, RestrictionType, WalletCredentialType } from '@portal/wen-backend-api';
import { isNullOrUndefined } from '../../../../../core/common/operators/null-check-util';
import { RestrictionModel } from './channel-restriction/channel-restriction.component';
import { WalletService } from '../../../../wallet/providers/wallet.service';

@Component({
  selector: 'wen-channel-content-curtain',
  templateUrl: './channel-content-curtain.component.html'
})
export class ChannelContentCurtainComponent implements OnChanges {

  restrictionModel: RestrictionModel;

  @Input() restrictions: RestrictionDTO[];
  @Input() inviteData: InvitedToChannelDTO;

  constructor(
    private translateService: TranslateService,
    private walletService: WalletService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!isNullOrUndefined(changes.restrictions) || !isNullOrUndefined(changes.inviteData)) {
      this.restrictionModel = this.updateRestrictionModel();
    }
  }

  private updateRestrictionModel(): RestrictionModel {
    const firstRestriction = this.restrictions && this.restrictions[0]?.restrictionType;
    const inviteState = this.inviteData?.state;
    if (inviteState === ChannelInviteState.INVITED) {
      const { insertUserName } = this.inviteData;
      return {
        titleTranslationKey: 'INVITE_CHANNEL_CONTENT_CURTAIN_TITLE',
        descriptionTranslationKey: this.translateService.instant(
          'INVITE_CHANNEL_CONTENT_CURTAIN_DESCRIPTION', { name: insertUserName }
        ),
        imagePath: '/assets/wen-widget/image/pic-new_invitation.svg'
      };
    }
    switch (firstRestriction) {
      case RestrictionType.PasswordRequired:
        return {
          titleTranslationKey: 'DEFAULT_RESTRICTION_TITLE',
          descriptionTranslationKey: 'PASSWORD_RESTRICTION_DESCRIPTION',
          imagePath: '/assets/wen-widget/image/pic-restricted_channel.svg'
        };
      case RestrictionType.RequestOnly:
        if (inviteState === ChannelInviteState.REQUESTED) {
          return {
            titleTranslationKey: 'PENDING_REQUEST_RESTRICTION_TITLE',
            descriptionTranslationKey: 'PENDING_REQUEST_RESTRICTION_DESCRIPTION',
            imagePath: '/assets/wen-widget/image/empty-placeholders/pic-empty-list-wen-sitting-robot.svg'
          };
        } else {
          return {
            titleTranslationKey: 'DEFAULT_RESTRICTION_TITLE',
            descriptionTranslationKey: 'REQUEST_ONLY_RESTRICTION_DESCRIPTION',
            imagePath: '/assets/wen-widget/image/pic-restricted_channel.svg'
          };
        }
      case RestrictionType.RegistrationRequired:
        return {
          titleTranslationKey: 'DEFAULT_RESTRICTION_TITLE',
          descriptionTranslationKey: 'REGISTRATION_REQUIRED_RESTRICTION_DESCRIPTION',
          imagePath: '/assets/wen-widget/image/pic-restricted_channel.svg'
        };
      case RestrictionType.PrivilegesRequired:
        return {
          titleTranslationKey: 'DEFAULT_RESTRICTION_TITLE',
          descriptionTranslationKey: 'EMAIL_VERIFICATION_REQUIRED_RESTRICTION_DESCRIPTION',
          imagePath: '/assets/wen-widget/image/pic-restricted_channel.svg'
        };
      case RestrictionType.IsManaged:
        return {
          titleTranslationKey: 'DEFAULT_RESTRICTION_TITLE',
          descriptionTranslationKey: 'MANAGED_CHANNEL_RESTRICTION_DESCRIPTION',
          imagePath: '/assets/wen-widget/image/pic-restricted_channel.svg',
        };
      case RestrictionType.WalletCredentialRequired:
        return {
          titleTranslationKey: 'PREMIUM_READING_CHANNEL_RESTRICTION_TITLE',
          descriptionTranslationKey: 'PREMIUM_READING_CHANNEL_RESTRICTION_DESCRIPTION',
          imagePath: '/assets/wen-widget/image/wallet-united-kiosk-restriction.png',
          layoutType: 'buttonLayout',
          action: {
            translationKey: 'PREMIUM_READING_CHANNEL_RESTRICTION_BUTTON',
            handler: () => this.walletService.handleWalletRestrictionButton(WalletCredentialType.UNITED_KIOSK_NEWS_FLAT)
          },
          premiumReadingChannel: true
        };
      default:
        return {
          titleTranslationKey: 'DEFAULT_RESTRICTION_TITLE',
          descriptionTranslationKey: '',
          imagePath: '/assets/wen-widget/image/pic-restricted_channel.svg'
        };
    }
  }

}
