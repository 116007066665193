import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wen-onboarding-progress',
  templateUrl: './onboarding-progress.component.html',
  styleUrls: ['./onboarding-progress.component.scss'],
  animations: [
    trigger('activateDeactivate', [
      state('active', style({
        backgroundColor: '#707070'
      })),
      state('inactive', style({
        backgroundColor: '#EDEDED'
      })),
      transition('active => inactive', [
        animate('200ms')
      ]),
      transition('inactive => active', [
        animate('200ms')
      ]),
    ]),
  ]
})
export class OnboardingProgressComponent implements OnInit {

  segmentArray: number[] = [];

  @Input() set segments(value: number) {
    this.segmentArray = new Array(value).fill(0);
  }
  @Input() currentProgress = 0;

  constructor() { }

  ngOnInit(): void {
  }

}
